

const DataPicker = () => {
    return (
          <div className="relative shadow-[0px_10px_60px_rgba(0,_0,_0,_0.1)] rounded-3xs box-border flex flex-row items-start justify-start max-w-full max-h-full overflow-auto text-left text-sm text-neutrals-neutral-900 font-open-sans-ff-meta-link border-[1px] border-solid border-neutrals-neutral-100">
                <div className="rounded-tl-3xs rounded-tr-none rounded-br-none rounded-bl-3xs bg-neutrals-neutral-50 box-border h-[468px] flex flex-col items-start justify-start py-3 px-4 gap-[6px] border-r-[1px] border-solid border-neutrals-neutral-100">
                      <div className="w-40 flex flex-row items-center justify-start py-2 px-4 box-border">
                            <div className="relative font-semibold">Today</div>
                      </div>
                      <div className="w-40 flex flex-row items-center justify-start py-2 px-4 box-border">
                            <div className="relative font-semibold">Yesterday</div>
                      </div>
                      <div className="w-40 flex flex-row items-center justify-start py-2 px-4 box-border">
                            <div className="relative font-semibold">This week</div>
                      </div>
                      <div className="w-40 rounded-3xs bg-primary-primary-50 flex flex-row items-center justify-start py-2 px-4 box-border">
                            <div className="relative font-semibold">Last week</div>
                      </div>
                      <div className="w-40 flex flex-row items-center justify-start py-2 px-4 box-border">
                            <div className="relative font-semibold">This month</div>
                      </div>
                      <div className="w-40 flex flex-row items-center justify-start py-2 px-4 box-border">
                            <div className="relative font-semibold">Last month</div>
                      </div>
                      <div className="w-40 flex flex-row items-center justify-start py-2 px-4 box-border">
                            <div className="relative font-semibold">This year</div>
                      </div>
                      <div className="w-40 flex flex-row items-center justify-start py-2 px-4 box-border">
                            <div className="relative font-semibold">Last year</div>
                      </div>
                      <div className="w-40 flex flex-row items-center justify-start py-2 px-4 box-border">
                            <div className="relative font-semibold">All time</div>
                      </div>
                </div>
                <div className="flex flex-col items-start justify-start text-base">
                      <div className="flex flex-row items-start justify-start border-b-[1px] border-solid border-neutrals-neutral-100">
                            <div className="bg-neutrals-neutral-50 flex flex-col items-start justify-start py-5 px-6 gap-[10px] border-r-[1px] border-solid border-neutrals-neutral-100">
                                  <div className="w-[280px] flex flex-row items-center justify-between">
                                        <div className="flex flex-row items-start justify-start p-2.5">
                                              <img className="w-5 relative h-5 overflow-hidden shrink-0 object-contain" alt="" src="assets/arrow-left-ios.svg"/>
                                        </div>
                                        <div className="relative font-semibold">January 2024</div>
                                        <div className="flex flex-row items-start justify-start p-2.5">
                                              <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/arrow-right-ios.svg"/>
                                        </div>
                                  </div>
                                  <div className="flex flex-col items-start justify-start gap-[4px] text-sm">
                                        <div className="flex flex-row items-start justify-start">
                                              <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                    <div className="relative font-semibold">Mon</div>
                                              </div>
                                              <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                    <div className="relative font-semibold">Tue</div>
                                              </div>
                                              <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                    <div className="relative font-semibold">Wed</div>
                                              </div>
                                              <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                    <div className="relative font-semibold">Thu</div>
                                              </div>
                                              <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                    <div className="relative font-semibold">Fri</div>
                                              </div>
                                              <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                    <div className="relative font-semibold">Sat</div>
                                              </div>
                                              <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                    <div className="relative font-semibold">Sun</div>
                                              </div>
                                        </div>
                                        <div className="flex flex-col items-start justify-start gap-[4px]">
                                              <div className="flex flex-row items-start justify-start text-neutrals-neutral-300">
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">26</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">27</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">28</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">29</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">30</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">31</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border text-center text-neutrals-neutral-900">
                                                          <div className="relative">1</div>
                                                    </div>
                                              </div>
                                              <div className="flex flex-row items-start justify-start">
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">2</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">3</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">4</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">5</div>
                                                    </div>
                                                    <div className="w-10 rounded-tl-3xs rounded-tr-none rounded-br-none rounded-bl-3xs bg-primary-primary-500 h-10 flex flex-row items-center justify-center p-2.5 box-border text-neutrals-neutral-50">
                                                          <div className="relative">6</div>
                                                    </div>
                                                    <div className="w-10 bg-primary-primary-50 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative font-semibold">7</div>
                                                    </div>
                                                    <div className="w-10 rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none bg-primary-primary-50 h-10 flex flex-row items-center justify-center p-2.5 box-border text-center">
                                                          <div className="relative font-semibold">8</div>
                                                    </div>
                                              </div>
                                              <div className="flex flex-row items-start justify-start">
                                                    <div className="w-10 rounded-tl-3xs rounded-tr-none rounded-br-none rounded-bl-3xs bg-primary-primary-50 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative font-semibold">9</div>
                                                    </div>
                                                    <div className="w-10 bg-primary-primary-50 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative font-semibold">10</div>
                                                    </div>
                                                    <div className="w-10 bg-primary-primary-50 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative font-semibold">11</div>
                                                    </div>
                                                    <div className="w-10 rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none bg-primary-primary-500 h-10 flex flex-row items-center justify-center p-2.5 box-border text-neutrals-neutral-50">
                                                          <div className="relative">12</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">13</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">14</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border text-center">
                                                          <div className="relative">15</div>
                                                    </div>
                                              </div>
                                              <div className="flex flex-row items-start justify-start">
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">16</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">17</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">18</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">19</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">20</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">21</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border text-center">
                                                          <div className="relative">22</div>
                                                    </div>
                                              </div>
                                              <div className="flex flex-row items-start justify-start">
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">23</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">24</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">25</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">26</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">27</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">28</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border text-center">
                                                          <div className="relative">29</div>
                                                    </div>
                                              </div>
                                              <div className="flex flex-row items-start justify-start text-neutrals-neutral-300">
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border text-neutrals-neutral-900">
                                                          <div className="relative">30</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border text-neutrals-neutral-900">
                                                          <div className="relative">31</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">1</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">2</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">3</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">4</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border text-center">
                                                          <div className="relative">5</div>
                                                    </div>
                                              </div>
                                        </div>
                                  </div>
                            </div>
                            <div className="rounded-tl-none rounded-tr-3xs rounded-b-none bg-neutrals-neutral-50 flex flex-col items-start justify-start py-5 px-6 gap-[10px]">
                                  <div className="w-[280px] flex flex-row items-center justify-between">
                                        <div className="flex flex-row items-start justify-start p-2.5">
                                              <img className="w-5 relative h-5 overflow-hidden shrink-0 object-contain" alt="" src="assets/arrow-left-ios.svg"/>
                                        </div>
                                        <div className="relative font-semibold">February 2024</div>
                                        <div className="flex flex-row items-start justify-start p-2.5">
                                              <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/arrow-right-ios.svg"/>
                                        </div>
                                  </div>
                                  <div className="flex flex-col items-start justify-start gap-[4px] text-sm">
                                        <div className="flex flex-row items-start justify-start">
                                              <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                    <div className="relative font-semibold">Mon</div>
                                              </div>
                                              <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                    <div className="relative font-semibold">Tue</div>
                                              </div>
                                              <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                    <div className="relative font-semibold">Wed</div>
                                              </div>
                                              <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                    <div className="relative font-semibold">Thu</div>
                                              </div>
                                              <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                    <div className="relative font-semibold">Fri</div>
                                              </div>
                                              <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                    <div className="relative font-semibold">Sat</div>
                                              </div>
                                              <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                    <div className="relative font-semibold">Sun</div>
                                              </div>
                                        </div>
                                        <div className="flex flex-col items-start justify-start gap-[4px]">
                                              <div className="flex flex-row items-start justify-start">
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border text-neutrals-neutral-300">
                                                          <div className="relative">30</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border text-neutrals-neutral-300">
                                                          <div className="relative">31</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">1</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">2</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">3</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">4</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border text-center">
                                                          <div className="relative">5</div>
                                                    </div>
                                              </div>
                                              <div className="flex flex-row items-start justify-start">
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">6</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">7</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative opacity-[0.8]">8</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">9</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">10</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">11</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border text-center">
                                                          <div className="relative">12</div>
                                                    </div>
                                              </div>
                                              <div className="flex flex-row items-start justify-start">
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">13</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">14</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">15</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">16</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">17</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">18</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border text-center">
                                                          <div className="relative">19</div>
                                                    </div>
                                              </div>
                                              <div className="flex flex-row items-start justify-start">
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">20</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">21</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">22</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">23</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">24</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">25</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border text-center">
                                                          <div className="relative">26</div>
                                                    </div>
                                              </div>
                                              <div className="flex flex-row items-start justify-start text-neutrals-neutral-300">
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border text-neutrals-neutral-900">
                                                          <div className="relative">27</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border text-neutrals-neutral-900">
                                                          <div className="relative">28</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border text-neutrals-neutral-900">
                                                          <div className="relative">29</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">1</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">2</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">3</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border text-center">
                                                          <div className="relative">4</div>
                                                    </div>
                                              </div>
                                              <div className="flex flex-row items-start justify-start text-neutrals-neutral-300">
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">5</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">6</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">7</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">8</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">9</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border">
                                                          <div className="relative">10</div>
                                                    </div>
                                                    <div className="w-10 h-10 flex flex-row items-center justify-center p-2.5 box-border text-center">
                                                          <div className="relative">11</div>
                                                    </div>
                                              </div>
                                        </div>
                                  </div>
                            </div>
                      </div>
                      <div className="w-[656px] rounded-t-none rounded-br-3xs rounded-bl-none bg-neutrals-neutral-50 box-border flex flex-row items-center justify-between p-4 border-t-[1px] border-solid border-neutrals-neutral-100">
                            <div className="flex flex-row items-center justify-start gap-[10px]">
                                  <div className="w-[136px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
                                        <div className="flex flex-row items-center justify-center">
                                              <div className="relative">Jan 6, 2024</div>
                                        </div>
                                  </div>
                                  <div className="relative text-neutrals-neutral-300">-</div>
                                  <div className="w-[136px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
                                        <div className="flex flex-row items-center justify-center">
                                              <div className="relative">Jan 12, 2024</div>
                                        </div>
                                  </div>
                            </div>
                            <div className="flex flex-row items-start justify-start gap-[10px] text-sm">
                                  <div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
                                        <div className="flex flex-row items-center justify-center">
                                              <div className="relative font-semibold">Cancel</div>
                                        </div>
                                  </div>
                                  <div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-primary-primary-500 flex flex-row items-center justify-center py-2.5 px-3.5 text-neutrals-neutral-50">
                                        <div className="flex flex-row items-center justify-center">
                                              <div className="relative font-semibold">Apply</div>
                                        </div>
                                  </div>
                            </div>
                      </div>
                </div>
          </div>);
};

export default DataPicker;