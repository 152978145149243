import { useState, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import DownloadModalTables from "../../components/DownloadModalTables";
import NavigationPanelLawyer from "../../components/NavigationPanelLawyer";
import DebtCollectionNav from '../../components/DebtCollectionNav';
import PortalPopup from "../../components/PortalPopup";
import CourtStatusFilter from "../../components/CourtStatusFilter";
import LogoutConfirmation from "../../components/LogoutConfirmation";
import Breadcrumb from "../../components/Breadcrumb";

const Legal_proceedings_2 = () => {
	const navigate = useNavigate();
  	const [isDownloadModalTablesOpen, setDownloadModalTablesOpen] = useState(false);
  	const [isCourtStatusFilterOpen, setCourtStatusFilterOpen] = useState(false);
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	
  	const openDownloadModalTables = useCallback(() => {
    		setDownloadModalTablesOpen(true);
  	}, []);
  	
  	const closeDownloadModalTables = useCallback(() => {
    		setDownloadModalTablesOpen(false);
  	}, []);
  	
  	
  	const openCourtStatusFilter = useCallback(() => {
    		setCourtStatusFilterOpen(true);
  	}, []);
  	
  	const closeCourtStatusFilter = useCallback(() => {
    		setCourtStatusFilterOpen(false);
  	}, []);
  	
  	
  	const onClickBillingSchedule = useCallback(() => {
		navigate('/legal_2');
  	}, [navigate]);
  	
  	const onClickReportAndAnalytics = useCallback(() => {
		navigate('/legal_3');
  	}, [navigate]);
  	
  	const openLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(true);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);

	const navItems = [
		{ text: "Dashboard", bgColor: "bg-neutrals-neutral-50", textColor: "text-neutrals-neutral-300", hoverBgColor: "bg-neutrals-neutral-50", hoverTextColor: "text-primary-primary-500", path: "" },
		{ text: "Legal proceedings & billing", bgColor: "bg-primary-primary-50", textColor: "text-neutrals-neutral-900", hoverBgColor: "bg-primary-primary-50", hoverTextColor: "text-neutrals-neutral-900", path: "" },
		{ text: "Pre-LBA checks", bgColor: "bg-neutrals-neutral-50", textColor: "text-neutrals-neutral-300", hoverBgColor: "bg-neutrals-neutral-50", hoverTextColor: "text-primary-primary-500", path: "/prelbacheck" },
		{ text: "Client-debtor interaction", bgColor: "bg-neutrals-neutral-50", textColor: "text-neutrals-neutral-300", hoverBgColor: "bg-neutrals-neutral-50", hoverTextColor: "text-primary-primary-500", path: "/cdi1" },
	];

	const breadcrumb = [
		{ label: "", path: "", icon: "assets/home.svg", active: true },
		{ label: "Legal proceedings & billing", path: "", icon: "", active: false },
	];
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1821px] overflow-hidden text-left text-sm text-neutrals-neutral-300 font-open-sans-ff-body-strong">
      			<div className="absolute top-[179px] left-[96px] bg-neutrals-neutral-50 box-border w-[1248px] flex flex-col items-start justify-start pt-0 px-0 pb-6 gap-[6px] text-9xl text-neutrals-neutral-900 border-b-[1px] border-solid border-neutrals-neutral-100">
        				<b className="relative">Legal proceedings and billing</b>
        				<div className="relative text-base text-neutrals-neutral-300">Get to know time remaining for your LBA</div>
      			</div>
      			<div className="absolute right-[96px] bottom-[64px]">Copyright © Think-AI 2023</div>

      			<div className="absolute top-[358px] left-[96px] rounded-3xs bg-neutrals-neutral-50 box-border w-[400px] flex flex-col items-start justify-start text-base border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-start justify-between p-6">
          					<div className="flex flex-col items-start justify-start gap-[24px]">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<img className="w-5 relative h-5" alt="" src="assets/tick-circle.svg" />
            						</div>
            						<div className="flex flex-col items-start justify-start gap-[10px]">
              							<div className="relative">Total active cases</div>
              							<b className="relative text-13xl text-neutrals-neutral-900">45</b>
            						</div>
          					</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
        				</div>
      			</div>
      			<div className="absolute top-[358px] left-[520px] rounded-3xs bg-neutrals-neutral-50 box-border w-[400px] flex flex-col items-start justify-start text-base border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-start justify-between p-6">
          					<div className="flex flex-col items-start justify-start gap-[24px]">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<img className="w-5 relative h-5" alt="" src="assets/judge.svg" />
            						</div>
            						<div className="flex flex-col items-start justify-start gap-[10px]">
              							<div className="relative">Cases in court</div>
              							<b className="relative text-13xl text-neutrals-neutral-900">15</b>
            						</div>
          					</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg"/>
        				</div>
      			</div>

      			<div className="absolute top-[358px] left-[944px] rounded-3xs bg-neutrals-neutral-50 box-border w-[400px] flex flex-col items-start justify-start text-base border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-start justify-between p-6">
          					<div className="flex flex-col items-start justify-start gap-[24px]">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<img className="w-5 relative h-5" alt="" src="assets/chart.svg" />
            						</div>
            						<div className="flex flex-col items-start justify-start gap-[10px]">
              							<div className="relative">Total revenue</div>
              							<b className="relative text-13xl text-neutrals-neutral-900">£200,000</b>
            						</div>
          					</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
        				</div>
      			</div>
      			<div className="absolute top-[570px] left-[96px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] flex flex-col items-start justify-start text-neutrals-neutral-900">
        				<div className="w-[1248px] rounded-t-3xs rounded-b-none box-border flex flex-row items-center justify-between py-5 px-6 text-lg border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-l-[1px]">
          					<div className="flex flex-row items-center justify-start gap-[10px]">
            						<div className="relative font-semibold">Case list</div>
            						<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="w-1.5 relative rounded-[50%] bg-primary-primary-500 h-1.5" />
              							<div className="relative font-semibold">10 cases</div>
            						</div>
          					</div>
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 cursor-pointer text-sm border-[1px] border-solid border-neutrals-neutral-100" onClick={openDownloadModalTables}>
            						<div className="flex flex-row items-center justify-center gap-[6px]">
              							<img className="w-5 relative h-5" alt="" src="assets/download.svg" />
              							<div className="relative font-semibold">Download</div>
            						</div>
          					</div>
        				</div>
        				<div className="w-[1248px] box-border flex flex-row items-center justify-between py-3 px-6 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-l-[1px]">
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-start">
            						<div className="rounded-tl-3xs rounded-tr-none rounded-br-none rounded-bl-3xs bg-column-bg flex flex-row items-center justify-center py-2 px-4 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">View all</div>
            						</div>
            						<div className="flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-b-[1px] border-t-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">Paid</div>
            						</div>
            						<div className="flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-b-[1px] border-t-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">Pending</div>
            						</div>
            						<div className="rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none flex flex-row items-center justify-center py-2 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-b-[1px]">
              							<div className="relative font-semibold">Unpaid</div>
            						</div>
          					</div>
          					<div className="flex flex-row items-start justify-start gap-[10px] text-neutrals-neutral-300">
            						<div className="w-[294px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-start py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-start gap-[10px]">
                								<img className="w-5 relative h-5" alt="" src="assets/search.svg" />
                								<div className="relative">Search</div>
              							</div>
            						</div>
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 cursor-pointer text-neutrals-neutral-900 border-[1px] border-solid border-neutrals-neutral-100" onClick={openCourtStatusFilter}>
              							<div className="flex flex-row items-center justify-center gap-[6px]">
                								<img className="w-5 relative h-5" alt="" src="assets/filter.svg" />
                								<div className="relative font-semibold">Filter</div>
              							</div>
            						</div>
          					</div>
        				</div>
        				<div className="flex flex-col items-start justify-start">
          					<div className="w-[1248px] flex flex-col items-start justify-start">
            						<div className="self-stretch flex flex-row items-start justify-start">
              							<div className="w-[188px] flex flex-col items-start justify-start">
                								<div className="w-[188px] bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
                  									<div className="relative font-semibold">Case id</div>
                								</div>
                								<div className="w-[188px] flex flex-col items-start justify-start">
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">REF - 01</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">REF - 02</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">REF - 03</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">REF - 04</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">REF - 05</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">REF - 06</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">REF - 07</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">REF - 08</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">REF - 09</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">REF - 10</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[188px] flex flex-col items-start justify-start">
                								<div className="w-[188px] bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">Client name</div>
                								</div>
                								<div className="w-[188px] flex flex-col items-start justify-start">
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Paras Mistry</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Nathan James</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Julie Wyse</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Baanji</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Megan Gibson</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Rotana West</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Diane Elgar</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Ervins Puksts</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Elisa Gashi</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Laura Blake</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[188px] flex flex-col items-start justify-start">
                								<div className="w-[188px] bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">Current stage</div>
                								</div>
                								<div className="w-[188px] flex flex-col items-start justify-start">
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Stage 2</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Stage 3</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Stage 1</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Stage 3</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Stage 3</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Stage 3</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Stage 2</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Stage 2</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Stage 3</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Stage 3</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[321px] h-[594px] flex flex-col items-center justify-between">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-center py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">Court status</div>
                								</div>
                								<div className="self-stretch flex flex-col items-center justify-start">
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">In progress</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-secure-blue-500 h-1.5" />
                      											<div className="relative font-semibold">Completed</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
                      											<div className="relative font-semibold">Awaiting action</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-secure-blue-500 h-1.5" />
                      											<div className="relative font-semibold">Completed</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-secure-blue-500 h-1.5" />
                      											<div className="relative font-semibold">Completed</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-secure-blue-500 h-1.5" />
                      											<div className="relative font-semibold">Completed</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">In progress</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">In progress</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-secure-blue-500 h-1.5" />
                      											<div className="relative font-semibold">Completed</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-secure-blue-500 h-1.5" />
                      											<div className="relative font-semibold">Completed</div>
                    										</div>
                  									</div>
                								</div>
              							</div>
              							<div className="flex-1 h-[594px] flex flex-col items-center justify-between">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-center py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-b-[1px]">
                  									<div className="relative font-semibold">Billing status</div>
                								</div>
                								<div className="self-stretch flex flex-col items-center justify-start text-semantics-low-low-500">
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-center p-4 text-semantics-moderate-moderate-500 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="rounded-xl bg-semantics-moderate-moderate-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-moderate-moderate-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
                      											<div className="relative font-semibold">Pending</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Paid</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 text-semantics-high-high-500 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="rounded-xl bg-semantics-high-high-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-high-high-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-high-high-500 h-1.5" />
                      											<div className="relative font-semibold">Unpaid</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Paid</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Paid</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Paid</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-center p-4 text-semantics-moderate-moderate-500 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="rounded-xl bg-semantics-moderate-moderate-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-moderate-moderate-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
                      											<div className="relative font-semibold">Pending</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-center p-4 text-semantics-moderate-moderate-500 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="rounded-xl bg-semantics-moderate-moderate-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-moderate-moderate-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
                      											<div className="relative font-semibold">Pending</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Paid</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Paid</div>
                    										</div>
                  									</div>
                								</div>
              							</div>
            						</div>
          					</div>
          					<div className="w-[1248px] rounded-t-none rounded-b-3xs box-border flex flex-row items-center justify-between pt-3 px-6 pb-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">Previous</div>
              							</div>
            						</div>
            						<div className="relative">Page 1 of 10</div>
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">Next</div>
              							</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[293px] left-[96px] flex flex-row items-start justify-start gap-[24px] text-base">
        				<div className="bg-neutrals-neutral-50 box-border h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 text-primary-primary-500 border-b-[2px] border-solid border-primary-primary-500">
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Cases overview</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 box-border cursor-pointer" onClick={onClickBillingSchedule}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Billing schedule</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 box-border cursor-pointer" onClick={onClickReportAndAnalytics}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Reports and analytics</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[1439px] left-[96px] flex flex-col items-start justify-start gap-[6px] text-lg text-neutrals-neutral-900">
        				<div className="relative font-semibold">{`Document repository `}</div>
        				<div className="relative text-sm text-neutrals-neutral-300">Get your history of actions over here.</div>
      			</div>
      			<div className="absolute top-[1513px] left-[96px] w-[1248px] flex flex-row items-center justify-between">
        				<div className="w-[294px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-start py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="flex flex-row items-center justify-start gap-[10px]">
            						<img className="w-5 relative h-5" alt="" src="assets/search.svg" />
            						<div className="relative">Search your documents</div>
          					</div>
        				</div>
        				<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 text-neutrals-neutral-900 border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="flex flex-row items-center justify-center gap-[6px]">
            						<img className="w-5 relative h-5" alt="" src="assets/filter.svg" />
            						<div className="relative font-semibold">Filter</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[1577px] left-[96px] flex flex-row items-start justify-start gap-[24px] text-center text-neutrals-neutral-900">
        				<div className="flex flex-col items-center justify-start gap-[6px]">
          					<img className="w-10 relative h-10" alt="" src="assets/folder_close.svg"/>
          					<div className="relative font-semibold">Court Submissions</div>
          					<div className="relative text-base text-neutrals-neutral-300">5 items</div>
        				</div>
        				<div className="flex flex-col items-center justify-start gap-[6px]">
          					<img className="w-10 relative h-10" alt="" src="assets/folder_close.svg" />
          					<div className="relative font-semibold">Acknowledgments</div>
          					<div className="relative text-base text-neutrals-neutral-300">2 items</div>
        				</div>
        				<div className="flex flex-col items-center justify-start gap-[6px]">
          					<img className="w-10 relative h-10" alt="" src="assets/folder_close.svg" />
          					<div className="relative font-semibold">Legal documents</div>
          					<div className="relative text-base text-neutrals-neutral-300">10 items</div>
        				</div>
        				<div className="flex flex-col items-center justify-start">
          					<div className="flex flex-col items-center justify-start gap-[6px]">
            						<div className="flex flex-row items-start justify-start relative gap-[10px]">
              							<img className="w-10 relative h-10 z-[0]" alt="" src="assets/word_file.svg" />
            						</div>
            						<div className="relative font-semibold">Legal_proceedings.docx</div>
            						<div className="relative text-base text-neutrals-neutral-300">24KB</div>
          					</div>
        				</div>
        				<div className="flex flex-col items-center justify-start gap-[6px]">
          					<img className="w-10 relative h-10" alt="" src="assets/pdf.svg" />
          					<div className="flex flex-col items-center justify-start gap-[6px]">
            						<div className="relative font-semibold">Contract.pdf</div>
            						<div className="relative text-base text-neutrals-neutral-300">2.6MB</div>
          					</div>
        				</div>
        				<div className="flex flex-col items-center justify-start">
          					<div className="flex flex-col items-center justify-start gap-[6px]">
            						<img className="w-10 relative h-10" alt="" src="assets/pdf.svg" />
            						<div className="relative font-semibold">Defence_01.pdf</div>
            						<div className="relative text-base text-neutrals-neutral-300">1.3MB</div>
          					</div>
        				</div>
      			</div>

				{/* Navigation Panel */}
				<NavigationPanelLawyer navItems={navItems}/>

				<Breadcrumb breadcrumbs={breadcrumb} textColor="text-primary-primary-500" position="top-[24px] left-[90px]"/>
      			
    		</div>
    		{isDownloadModalTablesOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeDownloadModalTables}
        				>
        				<DownloadModalTables onClose={closeDownloadModalTables} />
      			</PortalPopup>
    		)}
    		{isCourtStatusFilterOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeCourtStatusFilter}
        				>
        				<CourtStatusFilter onClose={closeCourtStatusFilter} />
      			</PortalPopup>
    		)}
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}</>);
};

export default Legal_proceedings_2;