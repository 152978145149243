import { useState, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import ToastMsg from "../../components/ToastMsg";
import PortalPopup from "../../components/PortalPopup";
import LogoutConfirmation from "../../components/LogoutConfirmation";
import NavigationPanel from '../../components/NavigationPanel';
import Breadcrumb from '../../components/Breadcrumb';
import CTAs from '../../components/CTAs';
import Toggle from '../../components/Toggle';
import RadioButton from '../../components/RadioButton';

const SettingsLBAConfigPage = () => {
    const navigate = useNavigate();
  	const [isToastMsgOpen, setToastMsgOpen] = useState(false);
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	
  	const onClickGeneral = useCallback(() => {
        navigate('/setting-general');
  	}, [navigate]);
  	
  	
  	const onClickPreferences = useCallback(() => {
        navigate('/setting-preferences');
  	}, [navigate]);
  	
  	
  	const onClickLegalProceedings = useCallback(() => {
		navigate('/setting-legalproceedings');
  	}, []);
  	
  	
  	const onClickAIIntegration = useCallback(() => {
		navigate('/setting-aiintegration');
  	}, [navigate]);
  	
  	
  	const onClickSecurity = useCallback(() => {
		navigate('/setting-security');
  	}, [navigate]);
  	
  	
  	const onClickBilling = useCallback(() => {
		navigate('/setting-billing');
  	}, [navigate]);
  	
  	
  	const onClickHelpSupport = useCallback(() => {
		navigate('/setting-help&support');
  	}, [navigate]);
  	
  	
  	const onClickDataManagement = useCallback(() => {
		navigate('/setting-datamanagement');
  	}, [navigate]);
  	
  	
  	const onClickAccount = useCallback(() => {
		navigate('/setting-account');
  	}, [navigate]);
  	
  	
  	const openToastMsg = useCallback(() => {
    		setToastMsgOpen(true);
  	}, []);
  	
  	const closeToastMsg = useCallback(() => {
    		setToastMsgOpen(false);
  	}, []);
  	
  	const openLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(true);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);

	const breadcrumb = [
		{ label: "", path: "/postlba2", icon: "assets/Home.svg", active: true },
		{ label: "Settings", path: "", icon: "", active: false },
		{ label: "LBA configuration", path: "", icon: "", active: true },
	];

	const handleToggle = (state) => {
		console.log("Toggle state is:", state);
	};
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[2157px] overflow-hidden text-left text-base text-neutrals-neutral-900 font-open-sans-ff-heading-1">
      			<div className="absolute top-[293px] left-[96px] w-[188px] flex flex-col items-start justify-start gap-[6px] text-neutrals-neutral-300">
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickGeneral}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">General</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickPreferences}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Preferences</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 box-border h-[41px] flex flex-row items-center justify-center py-2 px-3 text-primary-primary-500 border-l-[2px] border-solid border-primary-primary-500">
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">LBA configuration</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickLegalProceedings}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Legal proceedings</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickAIIntegration}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">AI integration</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickSecurity}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Security</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickBilling}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Billing</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickHelpSupport}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">{`Help & support`}</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickDataManagement}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Data management</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickAccount}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Account</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[2074px] right-[96px] text-sm text-neutrals-neutral-300">Copyright © Think-AI 2023</div>
      			<div className="absolute top-[1096.5px] left-[389.5px] box-border w-[955px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
      			<div className="absolute top-[179px] left-[96px] bg-neutrals-neutral-50 box-border w-[1248px] flex flex-col items-start justify-start pt-0 px-0 pb-6 gap-[6px] text-9xl text-primary-primary-900 border-b-[1px] border-solid border-neutrals-neutral-100">
        				<b className="relative">Settings</b>
        				<div className="relative text-base text-neutrals-neutral-300">Get your billing information, generate/review invoices.</div>
      			</div>
      			<div className="absolute top-[293px] left-[390px] box-border w-[954px] flex flex-row items-start justify-between pt-0 px-0 pb-6 text-lg border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">LBA configuration</div>
          					<div className="relative text-sm text-neutrals-neutral-300">Allow users to configure how and when communication is initiated with debtors.</div>
        				</div>
        				<div className="flex flex-row items-start justify-start gap-[12px] text-sm">
							<CTAs
							type="Ghostbtn"
							text="Cancel"
							textColor="text-neutrals-neutral-900"
							bgColor="bg-neutrals-neutral-50"
							borderColor="border-neutrals-neutral-100"
							position="relative"
							/>
          					
							<CTAs
							type="Primarybtn"
							text="Save"
							textColor="text-neutrals-neutral-50"
							bgColor="bg-primary-primary-500"
							borderColor="border-primary-primary-500"
							position="relative"
							onClick={openToastMsg}
							/>
        				</div>
      			</div>

				{/* Navigation Panel */}
				<NavigationPanel/>

				{/* Breadcrumb Navigation */}
				< Breadcrumb breadcrumbs={breadcrumb} position="top-[24px] left-[90px]" />

      			<div className="absolute top-[392px] left-[390px] font-semibold">LBA settings</div>
      			<div className="absolute top-[438px] left-[390px] w-[954px] flex flex-col items-start justify-start gap-[10px] text-sm">
        				<div className="flex flex-row items-center justify-start gap-[6px]">
          					<div className="relative font-semibold">Default content</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg" />
        				</div>
        				<div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-[282px] flex flex-col items-start justify-start p-4 gap-[24px] text-base border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="flex flex-row items-center justify-start gap-[10px]">
            						<div className="flex flex-row items-center justify-start gap-[10px]">
              							<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                								<div className="relative font-semibold">Inter</div>
                								<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/arrow-down-font.svg" />
              							</div>
              							<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="format_size.svg" />
            						</div>
            						<div className="flex flex-row items-start justify-start gap-[10px]">
              							<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="format_bold.svg" />
              							<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="format_underlined.svg" />
              							<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="format_color_text.svg" />
              							<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="format_align_left.svg" />
              							<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="format_list_numbered.svg" />
              							<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="format_list_bulleted.svg" />
              							<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="format_indent_decrease.svg" />
              							<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="format_indent_increase.svg" />
            						</div>
          					</div>
          					<div className="relative text-neutrals-neutral-300">Enter text for the email</div>
        				</div>
      			</div>
      			<div className="absolute top-[773px] left-[390px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-start justify-between">
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
            						<img className="w-5 relative h-5" alt="" src="assets/tick-circle.svg" />
          					</div>

							{/* Toggle */}
							<Toggle
							onToggle={handleToggle}
							isOn={true}
							onColor='bg-primary-primary-500'
							offColor='bg-primary-primary-50'
							knobColor='bg-neutrals-neutral-50'
							/>

        				</div>
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Auto-generation</div>
          					<div className="w-[294px] relative text-neutrals-neutral-300 inline-block">LBA will be generated automatically for selected frequency.</div>
        				</div>
      			</div>
      			<div className="absolute top-[773px] left-[756px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-start justify-between">
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
            						<img className="w-5 relative h-5" alt="" src="assets/timer.svg" />
          					</div>
							{/* Toggle */}
							<Toggle
							onToggle={handleToggle}
							isOn={true}
							onColor='bg-primary-primary-500'
							offColor='bg-primary-primary-50'
							knobColor='bg-neutrals-neutral-50'
							/>
        				</div>
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Status tracking</div>
          					<div className="w-[294px] relative text-neutrals-neutral-300 inline-block">Track the status of the LBA and get notified about changes.</div>
        				</div>
      			</div>
      			<div className="absolute top-[997px] left-[390px] flex flex-col items-start justify-start gap-[24px]">
        				<div className="relative font-semibold">Frequency settings</div>
        				<div className="flex flex-row items-start justify-start gap-[24px]">
							<RadioButton options={["Daily", "Weekly", "Monthly"]}/>
        				</div>
      			</div>
      			<div className="absolute top-[1129px] left-[390px] text-lg font-semibold">LBA format settings</div>
      			<div className="absolute top-[1178px] left-[390px] font-semibold">Template management</div>
      			<div className="absolute top-[1942px] left-[390px] flex flex-col items-start justify-start gap-[24px]">
        				<div className="relative font-semibold">Delivery option</div>
        				<div className="flex flex-row items-start justify-start gap-[24px]">
							<RadioButton options={["Third party", "In-house post machines"]}/>
        				</div>
      			</div>
      			<div className="absolute top-[1294px] left-[1132px] shadow-[0px_3.208913564682007px_8.02px_rgba(0,_0,_0,_0.05)] rounded-[8.02px] bg-neutrals-neutral-50 box-border w-[576px] flex flex-col items-end justify-start p-[19.3px] gap-[32.1px] opacity-[0.5] text-[14.44px] border-[1.6px] border-solid border-primary-primary-500">
        				<div className="self-stretch flex flex-col items-start justify-start">
          					<div className="self-stretch flex flex-col items-end justify-start gap-[19.3px]">
            						<div className="self-stretch flex flex-row items-start justify-between pt-0 px-0 pb-2 border-b-[0.8px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">Company name</div>
              							<div className="relative font-semibold text-right">Solicitors</div>
            						</div>
            						<div className="relative text-[12.84px] text-right">
              							<p className="m-0">PRE - ISSUE SECTION</p>
              							<p className="m-0">LLOYDS CHAMBERS</p>
              							<p className="m-0">19-21 SEAVIEW ROAD</p>
              							<p className="m-0">WALLASEY</p>
              							<p className="m-0">CH45 4TH</p>
            						</div>
          					</div>
          					<div className="flex flex-col items-start justify-start text-[12.84px]">
            						<div className="flex flex-row items-center justify-start gap-[4.8px]">
              							<div className="relative font-semibold">Dear</div>
              							<div className="w-[60.2px] shadow-[0px_0.8022283911705017px_1.6px_rgba(0,_0,_0,_0.05)] rounded-[8.02px] bg-neutrals-neutral-50 box-border h-[33.7px] border-[0.8px] border-solid border-neutrals-neutral-100" />
            						</div>
            						<div className="flex flex-row items-center justify-start gap-[8px]">
              							<div className="relative font-semibold">Re: Invoice Number:</div>
              							<div className="w-[60.2px] shadow-[0px_0.8022283911705017px_1.6px_rgba(0,_0,_0,_0.05)] rounded-[8.02px] bg-neutrals-neutral-50 box-border h-[33.7px] border-[0.8px] border-solid border-neutrals-neutral-100" />
            						</div>
          					</div>
        				</div>
        				<div className="self-stretch flex flex-col items-start justify-start gap-[32.1px] text-[12.84px]">
          					<div className="self-stretch flex flex-col items-start justify-start gap-[19.3px]">
            						<div className="w-[595.3px] flex flex-col items-start justify-start gap-[4.8px]">
              							<div className="w-[576px] relative inline-block">We have been instructed by our above named client to apply to you for payment of their</div>
              							<div className="flex flex-row items-center justify-start gap-[4.8px]">
                								<div className="relative">outstanding account amounting to</div>
                								<div className="w-[60.2px] shadow-[0px_0.8022283911705017px_1.6px_rgba(0,_0,_0,_0.05)] rounded-[8.02px] bg-neutrals-neutral-50 box-border h-[33.7px] border-[0.8px] border-solid border-neutrals-neutral-100" />
              							</div>
            						</div>
            						<div className="self-stretch relative">We must advice you that unless payment is received in settlement of this account within seven days from the date of this letter proceedings will be commenced for the principal sum interest and court costs without further notice to you.</div>
          					</div>
          					<div className="flex flex-col items-start justify-start gap-[19.3px]">
            						<div className="relative">Yours faithfully,</div>
            						<div className="flex flex-col items-start justify-start gap-[8px]">
              							<div className="w-[150.8px] shadow-[0px_0.8022283911705017px_1.6px_rgba(0,_0,_0,_0.05)] rounded-[8.02px] bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start p-[9.6px] border-[0.8px] border-solid border-neutrals-neutral-100">
                								<div className="flex flex-row items-center justify-start gap-[4.8px]">
                  									<img className="w-4 relative h-4" alt="" src="signature.svg" />
                  									<div className="relative">Signature</div>
                								</div>
              							</div>
              							<div className="relative">Company name</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[1224px] left-[390px] shadow-[0px_4px_10px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border w-[718px] h-[665px] overflow-hidden flex flex-col items-start justify-start p-6 gap-[40px] border-[2px] border-solid border-primary-primary-500">
        				<div className="self-stretch flex flex-row items-center justify-between text-center text-sm text-neutrals-neutral-50">
          					<img className="w-[143px] relative h-[49px] object-cover" alt="" src="assets/logo-horizontal.svg" />
          					<div className="bg-primary-primary-500 flex flex-col items-start justify-start p-2.5">
            						<div className="flex flex-col items-center justify-start gap-[6px]">
              							<div className="relative">Debt Co-Pilot Account Number:</div>
              							<b className="relative text-13xl">D9001</b>
            						</div>
          					</div>
        				</div>
        				<div className="self-stretch relative">
          					<p className="m-0">Date: 25 April 2024</p>
          					<p className="m-0">Recipient Name: Dominic Reynolds</p>
          					<p className="m-0">Business Name: Llanelli Performance Centre Ltd.</p>
          					<p className="m-0">Address: Fairways, Pendline, Carmarthen, Carmarthenshire, SA33 4PA</p>
        				</div>
        				<div className="self-stretch relative font-semibold">
          					<span>{`Subject: `}</span>
          					<span className="text-semantics-high-high-500">Urgent:</span>
          					<span> Outstanding Account Balance – Invoice no. 2517, 2290, 2397</span>
        				</div>
        				<div className="self-stretch flex flex-col items-start justify-start gap-[24px]">
          					<div className="self-stretch flex flex-row items-center justify-start gap-[10px]">
            						<div className="relative">Dear</div>
            						<div className="w-[164px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-[42px] border-[1px] border-solid border-neutrals-neutral-100" />
          					</div>
          					<div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
            						<div className="self-stretch flex flex-row items-center justify-start gap-[10px]">
              							<div className="relative">Account Number:</div>
              							<div className="w-[152px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-[42px] border-[1px] border-solid border-neutrals-neutral-100" />
            						</div>
            						<div className="self-stretch relative">Invoice Number: 2517, 2290, 2397</div>
            						<div className="self-stretch flex flex-row items-center justify-start gap-[10px]">
              							<div className="relative">Due Date:</div>
              							<div className="w-[232px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-[42px] border-[1px] border-solid border-neutrals-neutral-100" />
            						</div>
            						<div className="self-stretch flex flex-row items-center justify-start gap-[10px]">
              							<div className="relative">
                								<span>{`Outstanding Amount: `}</span>
                								<span className="font-semibold text-semantics-high-high-500">£</span>
              							</div>
              							<div className="w-[164px] rounded-3xs bg-neutrals-neutral-50 box-border h-[42px] border-[1px] border-solid border-neutrals-neutral-100" />
            						</div>
          					</div>
        				</div>
        				<div className="self-stretch flex flex-col items-start justify-start gap-[40px]">
          					<div className="self-stretch flex flex-col items-start justify-start gap-[24px]">
            						<div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
              							<div className="self-stretch relative">This letter serves as a formal notice regarding the outstanding balance on your account. Despite previous communications, we have not received payment for the above-</div>
              							<div className="self-stretch flex flex-row items-center justify-start gap-[6px]">
                								<div className="relative">mentioned invoice, which was due on</div>
                								<div className="w-[75px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-[42px] border-[1px] border-solid border-neutrals-neutral-100" />
              							</div>
            						</div>
            						<div className="self-stretch relative">As we value our relationship and wish to resolve this matter amicably, we are keen to offer assistance and discuss potential solutions that may be available to you. It is important to address this issue promptly to avoid any further implications.</div>
          					</div>
          					<div className="self-stretch flex flex-row items-start justify-start">
            						<div className="flex flex-col items-center justify-start gap-[10px]">
              							<div className="relative font-semibold">Advantages of Settling</div>
              							<div className="bg-primary-primary-500 flex flex-col items-end justify-start py-6 px-[13px] gap-[16px] text-bg-primary">
                								<div className="relative">
                  									<ul className="m-0 pl-[21px]">
                    										<li className="mb-0">
                      											<span className="font-semibold font-open-sans-ff-heading-1">
                        												<span className="text-base">Quick Resolution:</span>
                        												<span className="text-sm"> U</span>
                      											</span>
                      											<span>
                        												<span>sing debt co-pilot,</span>
                      											</span>
                    										</li>
                  									</ul>
                  									<p className="m-0">
                    										<span>
                      											<span>      we can quickly resolve and monitor all</span>
                    										</span>
                  									</p>
                  									<p className="m-0">
                    										<span>
                      											<span>      of your debts</span>
                    										</span>
                  									</p>
                  									<ul className="m-0 pl-[21px]">
                    										<li className="mb-0">
                      											<span>
                        												<span className="font-semibold font-open-sans-ff-heading-1">Improved Cash Flow:</span>
                        												<span> By clearing debt</span>
                      											</span>
                    										</li>
                  									</ul>
                  									<p className="m-0">
                    										<span>
                      											<span>      earlier, you free up the monthly funds</span>
                    										</span>
                  									</p>
                  									<p className="m-0">
                    										<span>
                      											<span>      that were previously allocated to debt</span>
                    										</span>
                  									</p>
                  									<p className="m-0">
                    										<span>
                      											<span>      payments</span>
                    										</span>
                  									</p>
                  									<ul className="m-0 pl-[21px]">
                    										<li className="mb-0">
                      											<span>
                        												<span className="font-semibold font-open-sans-ff-heading-1">Interest Savings:</span>
                        												<span> Avoid additional</span>
                      											</span>
                    										</li>
                  									</ul>
                  									<p className="m-0">
                    										<span>
                      											<span>      interest and charges by setting your</span>
                    										</span>
                  									</p>
                  									<p className="m-0">
                    										<span>
                      											<span>      balance promptly</span>
                    										</span>
                  									</p>
                								</div>
                								<img className="w-10 relative h-10 overflow-hidden shrink-0" alt="" src="gg:check-o.svg" />
              							</div>
            						</div>
            						<div className="self-stretch flex-1 flex flex-col items-center justify-start gap-[10px]">
              							<div className="relative font-semibold">Consequences of Non-Payment</div>
              							<div className="self-stretch flex-1 bg-semantics-high-high-600 flex flex-col items-center justify-start py-6 px-[13px] text-bg-primary">
                								<div className="self-stretch relative">
                  									<ul className="m-0 pl-[21px]">
                    										<li className="mb-0">
                      											<span className="font-semibold font-open-sans-ff-heading-1">
                        												<span className="text-base">Legal Action:</span>
                        												<span className="text-sm">{` `}</span>
                      											</span>
                      											<span>
                        												<span>Facing legal action for unpaid debts can have serious consequences</span>
                      											</span>
                    										</li>
                    										<li className="mb-0">
                      											<span>
                        												<span className="font-semibold font-open-sans-ff-heading-1">Late Payment Fees:</span>
                        												<span className="font-open-sans-ff-heading-1"> Additional fees may be applied to the outstanding amount</span>
                      											</span>
                    										</li>
                    										<li>
                      											<span>
                        												<span className="font-semibold font-open-sans-ff-heading-1">Country Court Judgements:</span>
                        												<span className="font-open-sans-ff-heading-1">{` Non-payment will result in a CCJ being applied against your business. This will affect your credit score which will significantly reduce and will directly affect your ability to borrow & utlise credit facilities.`}</span>
                      											</span>
                    										</li>
                  									</ul>
                								</div>
              							</div>
            						</div>
          					</div>
          					<div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
            						<div className="self-stretch relative font-semibold">How to settle your debt</div>
            						<div className="self-stretch flex flex-row items-start justify-between">
              							<div className="self-stretch w-[215px] flex flex-col items-start justify-start gap-[10px]">
                								<div className="self-stretch bg-primary-primary-700 flex flex-col items-start justify-start py-6 px-[13px] gap-[16px]">
                  									<div className="flex flex-row items-center justify-center gap-[16px]">
                    										<div className="flex flex-row items-center justify-center relative gap-[10px]">
                      											<div className="w-[30px] relative rounded-[50%] bg-neutrals-neutral-50 h-[30px] z-[0]" />
                      											<div className="w-2.5 absolute !m-[0] top-[4.5px] left-[10px] font-semibold inline-block z-[1]">1</div>
                    										</div>
                    										<div className="relative font-semibold text-bg-primary">Direct to client</div>
                  									</div>
                  									<div className="self-stretch relative text-bg-primary">
                    										<p className="m-0">
                      											<span className="font-semibold font-open-sans-ff-heading-1">{`Full Payment: `}</span>
                      											<span>You can settle your account via paying our client (the company you owe money to) directly via their invoice, ensure you quote reference D9001 when making payment so we receive notification.</span>
                    										</p>
                    										<p className="m-0">&nbsp;</p>
                    										<p className="m-0">Who do I owe money:</p>
                    										<p className="m-0">Peach Loves Digital Limited.</p>
                  									</div>
                								</div>
                								<div className="self-stretch flex-1 bg-primary-primary-700 flex flex-col items-start justify-start py-6 px-[13px] text-bg-primary">
                  									<div className="self-stretch relative">
                    										<p className="m-0 font-semibold">How to pay:</p>
                    										<p className="m-0 whitespace-pre-wrap">Use your original  invoice</p>
                  									</div>
                								</div>
              							</div>
              							<div className="self-stretch w-[215px] flex flex-col items-start justify-start gap-[10px]">
                								<div className="self-stretch bg-primary-primary-500 h-[402px] flex flex-col items-start justify-start py-6 px-[13px] box-border gap-[16px]">
                  									<div className="flex flex-row items-center justify-center gap-[16px]">
                    										<div className="flex flex-row items-center justify-center relative gap-[10px]">
                      											<div className="w-[30px] relative rounded-[50%] bg-neutrals-neutral-50 h-[30px] z-[0]" />
                      											<div className="w-2.5 absolute !m-[0] top-[4.5px] left-[10px] font-semibold inline-block z-[1]">2</div>
                    										</div>
                    										<div className="relative font-semibold text-bg-primary">Direct to us</div>
                  									</div>
                  									<div className="self-stretch relative text-bg-primary">
                    										<p className="m-0">
                      											<span className="font-semibold font-open-sans-ff-heading-1">{`Full Payment: `}</span>
                      											<span>You can settle your account by bank transfer, online payment, or by sending a cheque.</span>
                    										</p>
                    										<p className="m-0">Full payment will resolve the account immediately and no further actions will be required.</p>
                    										<p className="m-0">&nbsp;</p>
                    										<ul className="m-0 text-inherit pl-[21px]">
                      											<li className="mb-0">
                        												<span>Full: £8,100</span>
                      											</li>
                      											<li className="mb-0">
                        												<span>Half: £4,050</span>
                      											</li>
                      											<li>
                        												<span>Quarter: £2,025</span>
                      											</li>
                    										</ul>
                  									</div>
                								</div>
                								<div className="self-stretch bg-primary-primary-500 flex flex-col items-start justify-start py-6 px-[13px] text-bg-primary">
                  									<div className="self-stretch relative">
                    										<p className="m-0 font-semibold">How to pay:</p>
                    										<p className="m-0">Online at www.debt.finance.co.uk</p>
                  									</div>
                								</div>
              							</div>
              							<div className="self-stretch w-[215px] flex flex-col items-start justify-start gap-[10px]">
                								<div className="self-stretch bg-primary-primary-300 h-[402px] flex flex-col items-start justify-start py-6 px-[13px] box-border gap-[16px]">
                  									<div className="flex flex-row items-center justify-center gap-[16px]">
                    										<div className="flex flex-row items-center justify-center relative gap-[10px]">
                      											<div className="w-[30px] relative rounded-[50%] bg-neutrals-neutral-50 h-[30px] z-[0]" />
                      											<div className="w-2.5 absolute !m-[0] top-[4.5px] left-[10px] font-semibold inline-block z-[1]">3</div>
                    										</div>
                    										<div className="relative font-semibold text-bg-primary">Arrangement</div>
                  									</div>
                  									<div className="self-stretch relative text-bg-primary">
                    										<p className="m-0">
                      											<span className="font-semibold font-open-sans-ff-heading-1">{`Monthly Direct Debit: `}</span>
                      											<span>This can provide you with the convenience of paying over a period of time, with the assurance that your payments are made on time, avoiding any potential late fees.</span>
                    										</p>
                    										<p className="m-0">&nbsp;</p>
                    										<ul className="m-0 text-inherit pl-[21px]">
                      											<li>
                        												<span>12 Payments: £675</span>
                      											</li>
                    										</ul>
                  									</div>
                								</div>
                								<div className="self-stretch bg-primary-primary-300 flex flex-col items-start justify-start py-6 px-[13px] text-bg-primary">
                  									<div className="self-stretch relative">
                    										<p className="m-0 font-semibold">How to pay:</p>
                    										<p className="m-0">Online at www.debt.finance.co.uk</p>
                  									</div>
                								</div>
              							</div>
            						</div>
          					</div>
          					<div className="self-stretch flex flex-row items-start justify-start p-4 text-center border-[1px] border-solid border-neutrals-neutral-900">
            						<div className="flex-1 relative">
              							<p className="m-0">
                								<span className="font-open-sans-ff-heading-1">{`We urge you to contact us by `}</span>
                								<span className="font-semibold font-open-sans-ff-heading-1">1st May 2024</span>
                								<span> to arrange payment or discuss a possible payment plan. Please note that failure to respond to this notice may necessitate further actions, which we would prefer to avoid.</span>
              							</p>
              							<p className="m-0">&nbsp;</p>
              							<p className="m-0">You can reach us directly at Debt-CO phone or [Debt-co Email Address] to ensure a prompt resolution to this matter before any further action is initiated.</p>
            						</div>
          					</div>
          					<div className="self-stretch flex flex-col items-start justify-start gap-[24px]">
            						<div className="self-stretch relative">Yours sincerely,</div>
            						<div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
              							<div className="w-[188px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-center justify-center p-3 border-[1px] border-solid border-neutrals-neutral-100">
                								<div className="flex flex-row items-center justify-start gap-[6px]">
                  									<img className="w-5 relative h-5" alt="" src="signature.svg" />
                  									<div className="relative">Signature</div>
                								</div>
              							</div>
              							<div className="self-stretch relative">Debt Co-Pilot Ltd.</div>
            						</div>
          					</div>
          					<div className="self-stretch flex flex-row items-center justify-end gap-[40px] text-sm">
            						<div className="flex-1 relative">Terms and Conditions: Please be aware that this communication is intended for settlement purposes only and is not to be construed as a legally binding document. It is a pre-legal attempt to resolve the outstanding balance and avoid potential legal proceedings.. We appreciate your immediate attention to this matter and look forward to your cooperation in settling this account. Thank you for your prompt response.</div>
            						<img className="w-[123px] relative h-[43px] object-cover" alt="" src="Screenshot 2024-04-25 at 4.38 1.png" />
          					</div>
        				</div>
      			</div>
    		</div>
    		{isToastMsgOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeToastMsg}
        				>
        				<ToastMsg onClose={closeToastMsg} />
      			</PortalPopup>
    		)}
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}</>);
};

export default SettingsLBAConfigPage;