import { useState, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import ToastMsg from "../../components/ToastMsg";
import PortalPopup from "../../components/PortalPopup";
import LogoutConfirmation from "../../components/LogoutConfirmation";
import NavigationPanel from '../../components/NavigationPanel';
import Breadcrumb from '../../components/Breadcrumb';
import CTAs from '../../components/CTAs';
import Toggle from '../../components/Toggle';

const SettingsAIIntegrationPage = () => {
    const navigate = useNavigate();
  	const [isToastMsgOpen, setToastMsgOpen] = useState(false);
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	  	
  	const onClickGeneral = useCallback(() => {
        navigate('/setting-general');
  	}, [navigate]);
  	
  	
  	const onClickPreferences = useCallback(() => {
        navigate('/setting-preferences');
  	}, [navigate]);
  	
  	
  	const onClickLBAConfig = useCallback(() => {
        navigate('/setting-lbaconfig');
  	}, [navigate]);
  	
  	
  	const onClickLegalProceedings = useCallback(() => {
        navigate('/setting-legalproceedings');
  	}, [navigate]);
  	
  	
  	const onClickSecurity = useCallback(() => {
		navigate('/setting-security');
  	}, [navigate]);
  	
  	
  	const onClickBilling = useCallback(() => {
		navigate('/setting-billing');
  	}, [navigate]);
  	
  	
  	const onClickHelpSupport = useCallback(() => {
		navigate('/setting-help&support');
  	}, [navigate]);
  	
  	
  	const onClickDataManagement = useCallback(() => {
		navigate('/setting-datamanagement');
  	}, [navigate]);
  	
  	
  	const onClickAccount = useCallback(() => {
		navigate('/setting-account');
  	}, []);
  	
  	const openToastMsg = useCallback(() => {
    		setToastMsgOpen(true);
  	}, []);
  	
  	const closeToastMsg = useCallback(() => {
    		setToastMsgOpen(false);
  	}, []);
  	
  	const openLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(true);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);

	const breadcrumb = [
		{ label: "", path: "/postlba2", icon: "assets/Home.svg", active: true },
		{ label: "Settings", path: "", icon: "", active: false },
		{ label: "AI integration", path: "", icon: "", active: true },
	];

	const handleToggle = (state) => {
		console.log("Toggle state is:", state);
	};
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1024px] overflow-hidden text-left text-base text-neutrals-neutral-900 font-open-sans-ff-body-strong">
      			<div className="absolute top-[293px] left-[96px] w-[188px] flex flex-col items-start justify-start gap-[6px] text-neutrals-neutral-300">
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickGeneral}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">General</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickPreferences}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Preferences</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickLBAConfig}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">LBA configuration</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickLegalProceedings}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Legal proceedings</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 box-border h-[41px] flex flex-row items-center justify-center py-2 px-3 text-primary-primary-500 border-l-[2px] border-solid border-primary-primary-500">
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">AI integration</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickSecurity}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Security</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickBilling}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Billing</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickHelpSupport}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">{`Help & support`}</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickDataManagement}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Data management</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickAccount}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Account</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[941px] right-[96px] text-sm text-neutrals-neutral-300">Copyright © Think-AI 2023</div>
      			<div className="absolute top-[466px] left-[390px] flex flex-col items-start justify-start gap-[24px] text-sm">
        				<div className="flex flex-row items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Select your confidence thresholds</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg" />
        				</div>
        				<div className="w-[400px] relative h-[52px] text-base text-neutrals-neutral-300">
          					<div className="absolute h-[42.31%] w-full top-[57.69%] right-[0%] bottom-[0%] left-[0%] flex flex-row items-center justify-between">
            						<div className="relative">0</div>
            						<div className="w-[5px] relative font-semibold font-inter-family-font-body-strong text-right hidden">.</div>
            						<div className="relative">10</div>
            						<div className="relative text-right">100</div>
          					</div>
          					<div className="absolute h-[46.15%] w-full top-[0%] right-[0%] bottom-[53.85%] left-[0%]">
            						<div className="absolute h-[33.33%] w-full top-[33.33%] right-[0%] bottom-[33.33%] left-[0%] rounded-81xl bg-primary-primary-50" />
            						<div className="absolute h-full w-[52%] top-[0%] right-[48%] bottom-[0%] left-[0%]">
              							<div className="absolute h-[33.33%] w-[94.71%] top-[33.33%] right-[5.29%] bottom-[33.33%] left-[0%] rounded-81xl bg-primary-primary-500" />
              							<img className="absolute h-full w-[11.54%] top-[0%] right-[0%] bottom-[0%] left-[88.46%] max-w-full overflow-hidden max-h-full" alt="" src="Ellipse 21.svg" />
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[392px] left-[390px] flex flex-col items-start justify-start gap-[6px]">
        				<div className="relative font-semibold">AI matching</div>
        				<div className="relative text-neutrals-neutral-300">Configure AI matching parameters and thresholds.</div>
      			</div>
      			<div className="absolute top-[602px] left-[390px] flex flex-col items-start justify-start gap-[6px]">
        				<div className="relative font-semibold">AI alerts configuration</div>
        				<div className="relative text-neutrals-neutral-300">Configure AI matching parameters and thresholds.</div>
      			</div>
      			<div className="absolute top-[179px] left-[96px] bg-neutrals-neutral-50 box-border w-[1248px] flex flex-col items-start justify-start pt-0 px-0 pb-6 gap-[6px] text-9xl text-primary-primary-900 border-b-[1px] border-solid border-neutrals-neutral-100">
        				<b className="relative">Settings</b>
        				<div className="relative text-base text-neutrals-neutral-300">Get your billing information, generate/review invoices.</div>
      			</div>
      			<div className="absolute top-[293px] left-[390px] box-border w-[954px] flex flex-row items-start justify-between pt-0 px-0 pb-6 text-lg border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">AI integration</div>
          					<div className="relative text-sm text-neutrals-neutral-300">Allow users to configure how and when communication is initiated with debtors.</div>
        				</div>
        				<div className="flex flex-row items-start justify-start gap-[12px] text-sm">
							<CTAs
							type="Ghostbtn"
							text="Cancel"
							textColor="text-neutrals-neutral-900"
							bgColor="bg-neutrals-neutral-50"
							borderColor="border-neutrals-neutral-100"
							position="relative"
							/>
          					
							<CTAs
							type="Primarybtn"
							text="Save"
							textColor="text-neutrals-neutral-50"
							bgColor="bg-primary-primary-500"
							borderColor="border-primary-primary-500"
							position="relative"
							onClick={openToastMsg}
							/>
        				</div>
      			</div>

                {/* Navigation Panel */}
      			< NavigationPanel />

				{/* Breadcrumb Navigation */}
				<Breadcrumb breadcrumbs={breadcrumb} position="top-[24px] left-[90px]" />

      			<div className="absolute top-[676px] left-[390px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-start justify-between">
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
            						<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/AI.svg" />
          					</div>
							{/* Toggle */}
							<Toggle
							onToggle={handleToggle}
							isOn={true}
							onColor='bg-primary-primary-500'
							offColor='bg-primary-primary-50'
							knobColor='bg-neutrals-neutral-50'
							/>
        				</div>
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Mismatched names</div>
          					<div className="w-[294px] relative text-neutrals-neutral-300 inline-block">Get notified before your court date so you can be prepared in advance.</div>
        				</div>
      			</div>
      			<div className="absolute top-[676px] left-[756px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-start justify-between">
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
            						<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/AI.svg" />
          					</div>
							{/* Toggle */}
							<Toggle
							onToggle={handleToggle}
							isOn={true}
							onColor='bg-primary-primary-500'
							offColor='bg-primary-primary-50'
							knobColor='bg-neutrals-neutral-50'
							/>
        				</div>
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Potential issues</div>
          					<div className="w-[294px] relative text-neutrals-neutral-300 inline-block">Get notified before your court date so you can be prepared in advance.</div>
        				</div>
      			</div>
    		</div>
    		{isToastMsgOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeToastMsg}
        				>
        				<ToastMsg onClose={closeToastMsg} />
      			</PortalPopup>
    		)}
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}</>);
};

export default SettingsAIIntegrationPage;