import { useState, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import ToastMsg from "../../components/ToastMsg";
import PortalPopup from "../../components/PortalPopup";
import LogoutConfirmation from "../../components/LogoutConfirmation";
import ExportModalTables from "../../components/ExportModalTables";
import NavigationPanel from '../../components/NavigationPanel';
import Breadcrumb from '../../components/Breadcrumb';
import CTAs from '../../components/CTAs';
import Dropdown from "../../components/Dropdown";

const SettingsSecurityPage = () => {
    const navigate = useNavigate();
  	const [isToastMsgOpen, setToastMsgOpen] = useState(false);
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	const [isLogoutConfirmation1Open, setLogoutConfirmation1Open] = useState(false);
  	const [isExportModalTablesOpen, setExportModalTablesOpen] = useState(false);
  	
  	const onClickGeneral = useCallback(() => {
        navigate('/setting-general');
  	}, [navigate]);
  	
  	
  	const onClickPreferences = useCallback(() => {
        navigate('/setting-preferences');
  	}, [navigate]);
  	
  	
  	const onClickLBAConfig = useCallback(() => {
        navigate('/setting-lbaconfig');
  	}, [navigate]);
  	
  	const onClickLegalProceedings = useCallback(() => {
        navigate('/setting-legalproceedings');
  	}, [navigate]);
  	
  	
  	const onClickAIIntegration = useCallback(() => {
        navigate('/setting-aiintegration');
  	}, [navigate]);
  	
  	const onClickBilling = useCallback(() => {
		navigate('/setting-billing');
  	}, [navigate]);
  	
  	
  	const onClickHelpSupport = useCallback(() => {
		navigate('/setting-help&support');
  	}, [navigate]);
  	
  	
  	const onClickDataManagement = useCallback(() => {
		navigate('/setting-datamanagement');
  	}, [navigate]);
  	
  	
  	const onClickAccount = useCallback(() => {
		navigate('/setting-account');
  	}, [navigate]);
  	
  	
  	const openToastMsg = useCallback(() => {
    		setToastMsgOpen(true);
  	}, []);
  	
  	const closeToastMsg = useCallback(() => {
    		setToastMsgOpen(false);
  	}, []);
  	  	
  	const openLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(true);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);
  	
  	
  	const openLogoutConfirmation1 = useCallback(() => {
    		setLogoutConfirmation1Open(true);
  	}, []);
  	
  	const closeLogoutConfirmation1 = useCallback(() => {
    		setLogoutConfirmation1Open(false);
  	}, []);
  	
  	
  	const openExportModalTables = useCallback(() => {
    		setExportModalTablesOpen(true);
  	}, []);
  	
  	const closeExportModalTables = useCallback(() => {
    		setExportModalTablesOpen(false);
  	}, []);

	const breadcrumb = [
		{ label: "", path: "/postlba2", icon: "assets/Home.svg", active: true },
		{ label: "Settings", path: "", icon: "", active: false },
		{ label: "Security", path: "", icon: "", active: true },
	];

	const dropdownContents = ["5 minutes", "10 minutes", "15 minutes"];
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[2605px] overflow-hidden text-left text-base text-neutrals-neutral-900 font-open-sans-font-family-meta">
      			<div className="absolute top-[293px] left-[96px] w-[188px] flex flex-col items-start justify-start gap-[6px] text-neutrals-neutral-300">
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickGeneral}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">General</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickPreferences}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Preferences</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickLBAConfig}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">LBA configuration</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickLegalProceedings}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Legal proceedings</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickAIIntegration}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">AI integration</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 box-border h-[41px] flex flex-row items-center justify-center py-2 px-3 text-primary-primary-500 border-l-[2px] border-solid border-primary-primary-500">
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Security</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickBilling}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Billing</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickHelpSupport}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Help & support</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickDataManagement}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Data management</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickAccount}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Account</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[2522px] right-[96px] text-sm text-neutrals-neutral-300">Copyright © Think-AI 2023</div>
      			<div className="absolute top-[179px] left-[96px] bg-neutrals-neutral-50 box-border w-[1248px] flex flex-col items-start justify-start pt-0 px-0 pb-6 gap-[6px] text-9xl text-primary-primary-900 border-b-[1px] border-solid border-neutrals-neutral-100">
        				<b className="relative">Settings</b>
        				<div className="relative text-base text-neutrals-neutral-300">Get your billing information, generate/review invoices.</div>
      			</div>
      			<div className="absolute top-[293px] left-[390px] box-border w-[954px] flex flex-row items-start justify-between pt-0 px-0 pb-6 text-lg border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Security</div>
          					<div className="relative text-sm text-neutrals-neutral-300">Allow users to configure how and when communication is initiated with debtors.</div>
        				</div>
        				<div className="flex flex-row items-start justify-start gap-[12px] text-sm">
							<CTAs
							type="Ghostbtn"
							text="Cancel"
							textColor="text-neutrals-neutral-900"
							bgColor="bg-neutrals-neutral-50"
							borderColor="border-neutrals-neutral-100"
							position="relative"
							/>
          					
							<CTAs
							type="Primarybtn"
							text="Save"
							textColor="text-neutrals-neutral-50"
							bgColor="bg-primary-primary-500"
							borderColor="border-primary-primary-500"
							position="relative"
							onClick={openToastMsg}
							/>
        				</div>
      			</div>

				{/* Navigation Panel */}
				<NavigationPanel/>
      			
				{/* Breadcrumb Navigation */}
				< Breadcrumb breadcrumbs={breadcrumb} position="top-[24px] left-[90px]" />

      			<div className="absolute top-[392px] left-[390px] flex flex-col items-start justify-start gap-[24px]">
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Privacy settings</div>
          					<div className="relative text-neutrals-neutral-300">Set your data related preferences.</div>
        				</div>
        				<div className="flex flex-col items-start justify-start gap-[10px]">
          					<div className="flex flex-row items-center justify-start gap-[6px]">
            						<img className="w-5 relative h-5" alt="" src="assets/checkbox.svg" />
            						<div className="relative">Allow data sharing for improvement purposes</div>
          					</div>
          					<div className="flex flex-row items-center justify-start gap-[6px]">
            						<img className="w-5 relative h-5" alt="" src="assets/checkbox.svg" />
            						<div className="relative">Share anonymised data for research</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[560px] left-[390px] flex flex-col items-start justify-start gap-[24px]">
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Access control</div>
          					<div className="relative text-neutrals-neutral-300">Set your data related preferences.</div>
        				</div>
        				<div className="flex flex-row items-center justify-start gap-[6px]">
          					<img className="w-5 relative h-5" alt="" src="assets/checkbox.svg" />
          					<div className="relative">Enable role-based access control (RBAC)</div>
        				</div>
      			</div>
      			<div className="absolute top-[2112px] left-[390px] flex flex-col items-start justify-start gap-[24px]">
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Logout options</div>
          					<div className="relative text-neutrals-neutral-300">Set your logout preferences.</div>
        				</div>
        				<div className="flex flex-col items-start justify-start gap-[24px]">
          					<div className="flex flex-row items-center justify-start gap-[6px]">
            						<img className="w-5 relative h-5" alt="" src="assets/checkbox.svg" />
            						<div className="relative">Enable Automatic Logout After Inactivity</div>
          					</div>

							{/* Dropdown */}
          					<div className="w-[400px] flex flex-col items-start justify-start gap-[10px] text-sm">
								<Dropdown
								title="Select timeout duration"
								icon="assets/help-circle.svg"
								placeholder="Select duration"
								dropdownContents={dropdownContents}
								/>
            						{/* <div className="flex flex-row items-start justify-start gap-[6px]">
              							<div className="relative font-semibold">Select timeout duration</div>
              							<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg" />
            						</div>
            						<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-end justify-start py-3.5 px-2.5 gap-[175px] text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="w-[165px] relative inline-block shrink-0">Select duration</div>
              							<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/arrow-down.svg" />
            						</div> */}
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[1210.5px] left-[389.5px] box-border w-[955px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
      			<div className="absolute top-[2079.5px] left-[389.5px] box-border w-[955px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
      			<div className="absolute top-[696px] left-[390px] flex flex-col items-start justify-start gap-[24px]">
        				<div className="w-[954px] box-border flex flex-row items-start justify-between pt-0 px-0 pb-6 border-b-[1px] border-solid border-neutrals-neutral-100">
          					<div className="flex flex-col items-start justify-start gap-[6px]">
            						<div className="relative font-semibold">Login history</div>
            						<div className="relative text-neutrals-neutral-300">Customise automated actions based on debtor behavior.</div>
          					</div>
          					<div className="flex flex-row items-start justify-end gap-[10px] text-sm">
								<CTAs
								type="Ghostbtn"
								text="Clear login history"
								textColor="text-neutrals-neutral-900"
								bgColor="bg-neutrals-neutral-50"
								borderColor="border-neutrals-neutral-100"
								position="relative"
								/>

								<CTAs
								type="Ghostbtn"
								text="Logout from all devices"
								textColor="text-neutrals-neutral-900"
								bgColor="bg-neutrals-neutral-50"
								borderColor="border-neutrals-neutral-100"
								position="relative"
								/>
          					</div>
        				</div>
        				<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] flex flex-col items-start justify-start text-right text-sm">
          					<div className="w-[954px] flex flex-row items-start justify-start">
            						<div className="w-[212px] flex flex-col items-start justify-start text-left">
              							<div className="self-stretch rounded-tl-3xs rounded-tr-none rounded-b-none bg-column-bg flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
                								<div className="relative font-semibold">Date and time</div>
              							</div>
              							<div className="self-stretch flex flex-col items-start justify-start">
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                  									<div className="flex-1 relative">05/02/2024, 09:00 AM</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                  									<div className="flex-1 relative">03/02/2024, 03:00 PM</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                  									<div className="flex-1 relative">05/02/2024, 09:00 AM</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                  									<div className="flex-1 relative">05/02/2024, 09:00 AM</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                  									<div className="flex-1 relative">05/02/2024, 09:00 AM</div>
                								</div>
              							</div>
            						</div>
            						<div className="w-[165px] flex flex-col items-start justify-start">
              							<div className="self-stretch bg-column-bg flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                								<div className="relative font-semibold">IP address</div>
              							</div>
              							<div className="self-stretch flex flex-col items-start justify-start text-left">
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="flex-1 relative">192.168.1.1</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="flex-1 relative">203.45.67.8</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="flex-1 relative">192.168.2.8</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="flex-1 relative">203.45.67.8</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="flex-1 relative">203.45.67.8</div>
                								</div>
              							</div>
            						</div>
            						<div className="w-[365px] flex flex-col items-start justify-start">
              							<div className="self-stretch bg-column-bg flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                								<div className="relative font-semibold">Device type</div>
              							</div>
              							<div className="self-stretch flex flex-col items-start justify-start">
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative">Desktop - MacOS Ventura 13.2.4</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative">Desktop - Windows 11</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative">Tablet</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative">Mobile - iPhone 13 ios 17.1</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative">Desktop - MacOS Monterey 12.1.1</div>
                								</div>
              							</div>
            						</div>
            						<div className="w-[212px] flex flex-col items-start justify-start">
              							<div className="self-stretch rounded-tl-none rounded-tr-3xs rounded-b-none bg-column-bg flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-b-[1px]">
                								<div className="relative font-semibold">Actions</div>
              							</div>
              							<div className="self-stretch flex flex-col items-start justify-start text-primary-primary-500">
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative [text-decoration:underline]">View details</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative [text-decoration:underline]">View details</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative [text-decoration:underline]">View details</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative [text-decoration:underline]">View details</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative [text-decoration:underline]">View details</div>
                								</div>
              							</div>
            						</div>
          					</div>
          					<div className="w-[954px] rounded-t-none rounded-b-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-between pt-3 px-6 pb-4 text-left border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">Previous</div>
              							</div>
            						</div>
            						<div className="relative">Page 1 of 10</div>
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">Next</div>
              							</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[1243px] left-[390px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] w-[954px] flex flex-col items-start justify-start text-sm">
        				<div className="self-stretch rounded-t-3xs rounded-b-none flex flex-row items-center justify-between py-5 px-6 text-lg border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-l-[1px]">
          					<div className="flex flex-row items-center justify-start gap-[10px]">
            						<div className="relative font-semibold">Security logs</div>
            						<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="w-1.5 relative rounded-[50%] bg-primary-primary-500 h-1.5" />
              							<div className="relative font-semibold">10 logs</div>
            						</div>
          					</div>
          					<div className="flex flex-row items-start justify-start gap-[10px] text-sm">
								<CTAs
								type="Ghostbtn"
								text="Export"
								textColor="text-neutrals-neutral-900"
								bgColor="neutrals-neutral-50"
								borderColor="border-neutrals-neutral-100"
								icon="assets/export.svg"
								position="relative"
								onClick={openExportModalTables}
								/>

								<CTAs
								type="Ghostbtn"
								text="Clear logs"
								textColor="text-neutrals-neutral-900"
								bgColor="neutrals-neutral-50"
								borderColor="border-neutrals-neutral-100"
								position="relative"
								/>
          					</div>
        				</div>
        				<div className="self-stretch flex flex-row items-center justify-between py-3 px-6 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-l-[1px]">
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-start border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="rounded-tl-3xs rounded-tr-none rounded-br-none rounded-bl-3xs bg-column-bg flex flex-row items-center justify-center py-2 px-4 border-solid border-r-[1px] border-neutrals-neutral-100">
              							<div className="relative font-semibold">View all</div>
            						</div>
            						<div className="flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">Password change</div>
            						</div>
            						<div className="flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">Login</div>
            						</div>
            						<div className="rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none flex flex-row items-center justify-center py-2 px-4">
              							<div className="relative font-semibold">Access attempt</div>
            						</div>
          					</div>
          					<div className="flex flex-row items-start justify-start gap-[10px] text-neutrals-neutral-300">
            						<div className="w-[294px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-start py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-start gap-[10px]">
                								<img className="w-5 relative h-5" alt="" src="assets/search.svg" />
                								<div className="relative">Search</div>
              							</div>
            						</div>
									<CTAs
									type="Ghostbtn"
									text="Filter"
									textColor="text-neutrals-neutral-900"
									bgColor="bg-neutrals-neutral-50"
									borderColor="border-neutrals-neutral-100"
									icon="assets/filter.svg"
									position="relative"
									/>
          					</div>
        				</div>
        				<div className="self-stretch h-[661px] flex flex-col items-start justify-between">
          					<div className="self-stretch h-[594px] flex flex-col items-start justify-start">
            						<div className="self-stretch flex flex-row items-start justify-start">
              							<div className="w-[188px] flex flex-col items-start justify-start">
                								<div className="w-[188px] bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
                  									<div className="relative font-semibold">Date and time</div>
                								</div>
                								<div className="w-[188px] flex flex-col items-start justify-start">
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">02/02/2024</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">15/01/2024</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">14/01/2024</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">12/01/2024</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">06/01/2024</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">02/01/2024</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">01/01/2024</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">28/12/2023</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">28/12/2023</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">27/12/2023</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[213px] h-[594px] flex flex-col items-center justify-between">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-center py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">Event type</div>
                								</div>
                								<div className="self-stretch flex flex-col items-center justify-start">
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Login</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Login</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-secure-blue-500 h-1.5" />
                      											<div className="relative font-semibold">Password change</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-high-high-500 h-1.5" />
                      											<div className="relative font-semibold">Access attempt</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-secure-blue-500 h-1.5" />
                      											<div className="relative font-semibold">Password change</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-secure-blue-500 h-1.5" />
                      											<div className="relative font-semibold">Password change</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Login</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-high-high-500 h-1.5" />
                      											<div className="relative font-semibold">Access attempt</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Login</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Login</div>
                    										</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[184px] flex flex-col items-center justify-start">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-center py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">IP address</div>
                								</div>
                								<div className="self-stretch flex flex-col items-center justify-start">
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">192.168.1.1</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">203.45.67.89</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">192.168.1.101</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">103.22.33.44</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">201.45.64.89</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">192.168.1.103</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">192.168.1.1</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">192.168.1.1</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">192.168.1.1</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">192.168.1.1</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[206px] flex flex-col items-start justify-start">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">Location (if applicable)</div>
                								</div>
                								<div className="self-stretch flex flex-col items-start justify-start">
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="flex-1 relative">London, UK</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="flex-1 relative">New york, USA</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="flex-1 relative">Southampton, UK</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="flex-1 relative">Tokyo, Japan</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="flex-1 relative">Sydney, Australia</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="flex-1 relative">Southampton, UK</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="flex-1 relative">Toronto, Canada</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="flex-1 relative">London, UK</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="flex-1 relative">San Francisco, USA</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="flex-1 relative">Tokyo, Japan</div>
                  									</div>
                								</div>
              							</div>
              							<div className="flex-1 flex flex-col items-start justify-start">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-b-[1px]">
                  									<div className="relative font-semibold">User (if applicable)</div>
                								</div>
                								<div className="self-stretch flex flex-col items-start justify-start">
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="flex-1 relative">-</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="flex-1 relative">-</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="flex-1 relative">-</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="flex-1 relative">-</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="flex-1 relative">-</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="flex-1 relative">Paras Mistry</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="flex-1 relative">Nathan James</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="flex-1 relative">Baanji</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="flex-1 relative">Megan Gibson</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="flex-1 relative">Ervins Puksts</div>
                  									</div>
                								</div>
              							</div>
            						</div>
          					</div>
          					<div className="self-stretch rounded-t-none rounded-b-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-between pt-3 px-6 pb-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">Previous</div>
              							</div>
            						</div>
            						<div className="relative">Page 1 of 10</div>
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">Next</div>
              							</div>
            						</div>
          					</div>
        				</div>
      			</div>
    		</div>
    		{isToastMsgOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeToastMsg}
        				>
        				<ToastMsg onClose={closeToastMsg} />
      			</PortalPopup>
    		)}
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}
    		{isLogoutConfirmation1Open && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLogoutConfirmation1}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation1} />
      			</PortalPopup>
    		)}
    		{isExportModalTablesOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeExportModalTables}
        				>
        				<ExportModalTables onClose={closeExportModalTables} />
      			</PortalPopup>
    		)}</>);
};

export default SettingsSecurityPage;