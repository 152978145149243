import { useCallback, useState } from 'react';
import AccountType from '../../components/AccountType';

const SelectAccountPage = () => {
      const [continueFlag, setContinueFlag] = useState(false);

    return (
          <div className="w-full relative bg-neutrals-neutral-50 h-[1024px] overflow-hidden text-left text-9xl text-neutrals-neutral-900 font-open-sans-ff-meta-strong">
                <img className="absolute top-[308px] left-[calc(50%_-_30px)] w-[60px] h-[67px] object-cover" alt="" src="assets/logo-vertical.svg"/>
                <div className="absolute top-[399px] left-[calc(50%_-_178px)] flex flex-col items-center justify-start gap-[10px]">
                      <b className="relative">Welcome to Debt Co-Pilot</b>
                      <div className="relative text-base text-neutrals-neutral-300 text-center">Please select your account type to continue</div>
                </div>
                <div className="absolute top-[941px] right-[96px] text-sm text-neutrals-neutral-300">Copyright © Think-AI 2023</div>

                <div className="absolute top-[533px] left-[calc(50%_-_200px)] flex flex-col items-start justify-start gap-[40px] text-center text-lg">
                  
                      <div className="flex flex-row items-start justify-start gap-[24px]">
                        <AccountType/>
                      </div>
                </div>
          </div>);
};

export default SelectAccountPage;