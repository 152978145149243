import { useCallback, useState } from "react";
import PropTypes from 'prop-types';
import CTAs from "./CTAs";
import Dropdown from "../components/Dropdown";

const BillingAdjustmentForm = ({ onClose }) => {

      const dropdowncontents = ["Error in initial calculation", "Additional services rendered", "Client requested changes", "Other (specify)"];

      return (
          <div className="relative shadow-[0px_10px_60px_rgba(0,_0,_0,_0.1)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start max-w-full max-h-full overflow-auto text-left text-9xl text-neutrals-neutral-900 font-open-sans-font-family-meta border-[1px] border-solid border-neutrals-neutral-100 ${className}">
                <div className="flex flex-col items-start justify-start gap-[40px]">
                      <div className="flex flex-col items-start justify-start gap-[24px]">
                            <div className="w-[480px] flex flex-col items-start justify-start pt-6 px-6 pb-0 box-border">
                                  <div className="self-stretch flex flex-col items-start justify-start gap-[24px]">
                                        <div className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-6 gap-[6px] border-b-[1px] border-solid border-neutrals-neutral-100">
                                              <b className="relative">Billing adjustment form</b>
                                              <div className="relative text-sm text-neutrals-neutral-300">Submit your billing request form for modifications.</div>
                                        </div>
                                        <div className="flex flex-col items-start justify-start gap-[6px] text-lg">
                                              <div className="relative font-semibold">Basic information</div>
                                              <div className="relative text-sm text-neutrals-neutral-300">Enter your basic information.</div>
                                        </div>
                                  </div>
                            </div>
                            <div className="self-stretch flex flex-col items-start justify-start py-0 px-6 gap-[16px] text-sm text-wireframes-black">
                                  <div className="self-stretch flex flex-row items-start justify-start">
                                        <div className="w-[432px] flex flex-col items-start justify-start gap-[10px]">
                                          <Dropdown
                                          title="Select reason for adjustment"
                                          placeholder="Select specific reason"
                                          icon="assets/help-circle.svg"
                                          width="432px"
                                          dropdownContents={dropdowncontents}
                                          />
                                        </div>
                                  </div>
                                  <div className="self-stretch flex flex-row items-start justify-start text-neutrals-neutral-900">
                                        <div className="w-[432px] flex flex-col items-start justify-start gap-[10px]">
                                              <div className="relative font-semibold">Amount</div>
                                              <div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-between p-3 text-base border-[1px] border-solid border-neutrals-neutral-100">
                                                    <div className="flex-1 flex flex-row items-center justify-between">
                                                          <div className="relative">
                                                                <span className="font-semibold">
                                                                      <span>£</span>
                                                                </span>
                                                                <span className="text-neutrals-neutral-300">
                                                                      <span className="font-semibold">{` `}</span>
                                                                      <span>1,000.00</span>
                                                                </span>
                                                          </div>
                                                          <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg"/>
                                                    </div>
                                                    <div className="flex flex-row items-center justify-start py-0 px-2.5 gap-[6px] text-right">
                                                          <div className="relative">GBP</div>
                                                          <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/arrow-down.svg" />
                                                    </div>
                                              </div>
                                        </div>
                                  </div>
                            </div>
                      </div>
                      <div className="flex flex-row items-start justify-start pt-0 px-6 pb-6 gap-[12px] text-sm">
                        <CTAs
                        type="Ghostbtn"
                        text="Cancel"
                        textColor="text-neutrals-neutral-900"
                        bgColor="bg-neutrals-neutral-50"
                        borderColor="border-neutrals-neutral-100"
                        width="210px"
                        position="relative"
                        onClick={onClose}
                        />

                        <CTAs
                        type="Primarybtn"
                        text="Continue"
                        textColor="text-neutrals-neutral-50"
                        bgColor="bg-primary-primary-500"
                        borderColor="border-primary-primary-500"
                        width="210px"
                        position="relative"
                        />
                      </div>
                </div>
          </div>);
};

BillingAdjustmentForm.propTypes = {
    className: PropTypes.string
};

export default BillingAdjustmentForm;