import { useState, useCallback } from 'react';
import DataPicker from "./DataPicker";
import PortalPopup from "./PortalPopup";
import CTAs from './CTAs';

const DebtorListFilter = ({onClose}) => {
  	const [isDataPickerOpen, setDataPickerOpen] = useState(false);
  	
  	const openDataPicker = useCallback(() => {
    		setDataPickerOpen(true);
  	}, []);
  	
  	const closeDataPicker = useCallback(() => {
    		setDataPickerOpen(false);
  	}, []);
  	
  	return (<>
    		<div className="relative shadow-[0px_10px_60px_rgba(0,_0,_0,_0.1)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start max-w-full max-h-full overflow-auto text-left text-sm text-neutrals-neutral-900 font-open-sans-font-family-meta border-[1px] border-solid border-neutrals-neutral-100">
      			<div className="flex flex-col items-start justify-start py-6 px-4 gap-[24px]">
        				<div className="flex flex-col items-start justify-start gap-[10px]">
          					<div className="relative font-semibold">Dates</div>
          					<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-between py-3.5 px-2.5 cursor-pointer text-base border-[1px] border-solid border-neutrals-neutral-100" onClick={openDataPicker}>
            						<div className="flex flex-row items-center justify-start gap-[6px]">
              							<img className="w-5 relative h-5" alt="" src="assets/calendar.svg"/>
              							<div className="relative">12/02/2024 - 15/02/2024</div>
            						</div>
            						<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/arrow-down.svg"/>
          					</div>
        				</div>
        				<div className="w-[400px] flex flex-row items-start justify-start">
          					<div className="w-[400px] flex flex-col items-start justify-start gap-[10px]">
            						<div className="relative font-semibold">Subject</div>
            						<div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-between py-3.5 px-2.5 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative">Select subject</div>
              							<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/arrow-down.svg"/>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="flex flex-row items-start justify-start pt-4 px-4 pb-6 gap-[24px]">
				  	<CTAs
                    type="Ghostbtn"
                    text="Cancel"
                    textColor="text-neutrals-neutral-900"
                    bgColor="bg-neutrals-neutral-50"
                    borderColor="border-neutrals-neutral-100"
                    width="188px"
                    position="relative"
                    onClick={onClose}
                    />

					<CTAs
                    type="Primarybtn"
                    text="Apply"
                    textColor="text-neutrals-neutral-50"
                    bgColor="bg-primary-primary-500"
                    borderColor="border-primary-primary-500"
                    width="188px"
                    position="relative"
                    onClick={onClose}
                    />
      			</div>
    		</div>
    		{isDataPickerOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeDataPicker}
        				>
        				<DataPicker onClose={closeDataPicker} />
      			</PortalPopup>
    		)}</>);
};

export default DebtorListFilter;