import { useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import CTAs from '../../components/CTAs';
import Upload from '../../components/Upload';

const DocumentUploadPage = () => {
	const navigate = useNavigate();
  	
  	const onCTAsContainerClick = useCallback(() => {
		navigate('/registrationcomplete')
  	}, []);
  	
  	const onLogInTextClick = useCallback(() => {
		navigate('/login')
  	}, []);
  	
  	return (
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1024px] overflow-hidden text-left text-sm text-neutrals-neutral-900 font-open-sans-ff-meta">
      			<div className="absolute top-[941px] right-[96px] text-neutrals-neutral-300">Copyright © Think-AI 2023</div>
      			<div className="absolute top-[130px] left-[696px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border w-12 h-12 flex flex-row items-center justify-center p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
        				<img className="w-5 relative h-5" alt="" src="assets/document-upload.svg" />
      			</div>
      			<div className="absolute top-[202px] left-[552.5px] flex flex-col items-center justify-start gap-[10px] text-center text-9xl">
        				<b className="relative">Upload your documents</b>
        				<div className="relative text-base text-neutrals-neutral-300">Register to your account</div>
      			</div>
      			<div className="absolute top-[755px] left-[520px] flex flex-col items-center justify-start gap-[24px] text-neutrals-neutral-50">
        				<div className="self-stretch flex flex-col items-center justify-start gap-[16px]">
						<CTAs
						type="Primarybtn"
						text="Continue"
						textColor="text-neutrals-neutral-50"
						bgColor="bg-primary-primary-500"
						borderColor="border-primary-primary-500"
						width="400px"
						position="relative"
						onClick={onCTAsContainerClick}
						/>

						<CTAs
						type="Primarybtn"
						text="Sign up with Google"
						textColor="text-neutrals-neutral-900"
						bgColor="bg-neutrals-neutral-50"
						borderColor="border-neutrals-neutral-100"
						icon="assets/google.svg"
						width="400px"
						position="relative"
						/>
						
        				</div>
        				<div className="flex flex-row items-start justify-center gap-[6px] text-neutrals-neutral-300">
          					<div className="relative">Already have an account?</div>
            						<div className="relative font-semibold text-primary-primary-500 cursor-pointer" onClick={onLogInTextClick}>Log in</div>
            						</div>
            						</div>
            						<div className="absolute top-[336px] left-[520px] w-[400px] flex flex-col items-center justify-start gap-[10px]">
										<Upload width="400px"/>
            						</div>
            						</div>);
          					};
          					
          					export default DocumentUploadPage;