import { useState, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import DownloadModalTables from "../../components/DownloadModalTables";
import DebtCollectionNav from '../../components/DebtCollectionNav';
import NavigationPanelLawyer from '../../components/NavigationPanelLawyer';
import PortalPopup from "../../components/PortalPopup";
import CostFilter from "../../components/CostFilter";
import DataPicker from "../../components/DataPicker";
import BillingAdjustmentForm from "../../components/BillingAdjustmentForm";
import LogoutConfirmation from "../../components/LogoutConfirmation";
import Breadcrumb from '../../components/Breadcrumb';
import CTAs from '../../components/CTAs';

const Legal_proceedings_2 = () => {
    const navigate = useNavigate();
  	const [isDownloadModalTablesOpen, setDownloadModalTablesOpen] = useState(false);
  	const [isCostFilterOpen, setCostFilterOpen] = useState(false);
  	const [isDataPickerOpen, setDataPickerOpen] = useState(false);
  	const [isBillingAdjustmentForm1Open, setBillingAdjustmentForm1Open] = useState(false);
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	
  	const openDownloadModalTables = useCallback(() => {
    		setDownloadModalTablesOpen(true);
  	}, []);
  	
  	const closeDownloadModalTables = useCallback(() => {
    		setDownloadModalTablesOpen(false);
  	}, []);
  	
  	const openCostFilter = useCallback(() => {
    		setCostFilterOpen(true);
  	}, []);
  	
  	const closeCostFilter = useCallback(() => {
    		setCostFilterOpen(false);
  	}, []);
  	
  	const onClickCasesOverview = useCallback(() => {
        navigate('/legal_1');
  	}, [navigate]);
  	
  	const onClickReportsAndAnalytics = useCallback(() => {
		navigate('/legal_3');
  	}, [navigate]);
  	
  	const openDataPicker = useCallback(() => {
    		setDataPickerOpen(true);
  	}, []);
  	
  	const closeDataPicker = useCallback(() => {
    		setDataPickerOpen(false);
  	}, []);
  	
  	const onClickGenerate = useCallback(() => {
		navigate('/invoice');
  	}, [navigate]);
  	
  	const openBillingAdjustmentForm1 = useCallback(() => {
    		setBillingAdjustmentForm1Open(true);
  	}, []);
  	
  	const closeBillingAdjustmentForm1 = useCallback(() => {
    		setBillingAdjustmentForm1Open(false);
  	}, []);
  	
  	const openLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(true);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);

	const breadcrumb = [
		{ label: "", path: "", icon: "assets/home.svg", active: true },
		{ label: "Legal proceedings & billing", path: "", icon: "", active: false },
	];

	const navItems = [
		{ text: "Dashboard", bgColor: "bg-neutrals-neutral-50", textColor: "text-neutrals-neutral-300", hoverBgColor: "bg-neutrals-neutral-50", hoverTextColor: "text-primary-primary-500", path: "" },
		{ text: "Legal proceedings & billing", bgColor: "bg-primary-primary-50", textColor: "text-neutrals-neutral-900", hoverBgColor: "bg-primary-primary-50", hoverTextColor: "text-neutrals-neutral-900", path: "" },
		{ text: "Pre-LBA checks", bgColor: "bg-neutrals-neutral-50", textColor: "text-neutrals-neutral-300", hoverBgColor: "bg-neutrals-neutral-50", hoverTextColor: "text-primary-primary-500", path: "" },
		{ text: "Client-debtor interaction", bgColor: "bg-neutrals-neutral-50", textColor: "text-neutrals-neutral-300", hoverBgColor: "bg-neutrals-neutral-50", hoverTextColor: "text-primary-primary-500", path: "" },
	];
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1601px] overflow-hidden text-left text-base text-neutrals-neutral-900 font-open-sans-font-family-meta">
      			<div className="absolute top-[179px] left-[96px] bg-neutrals-neutral-50 box-border w-[1248px] flex flex-col items-start justify-start pt-0 px-0 pb-6 gap-[6px] text-9xl border-b-[1px] border-solid border-neutrals-neutral-100">
        				<b className="relative">Legal proceedings and billing</b>
        				<div className="relative text-base text-neutrals-neutral-300">Get to know time remaining for your LBA</div>
      			</div>
      			<div className="absolute right-[96px] bottom-[64px] text-sm text-neutrals-neutral-300">Copyright © Think-AI 2023</div>

				

      			<div className="absolute top-[649px] left-[96px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] flex flex-col items-start justify-start text-sm">
        				<div className="w-[1248px] rounded-t-3xs rounded-b-none box-border flex flex-row items-center justify-between py-5 px-6 text-lg border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-l-[1px]">
          					<div className="flex flex-row items-center justify-start gap-[10px]">
            						<div className="relative font-semibold">Billing details</div>
            						<div className="rounded-[5px] bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="w-1.5 relative rounded-[50%] bg-primary-primary-500 h-1.5" />
              							<div className="relative font-semibold">10 cases</div>
            						</div>
          					</div>
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 cursor-pointer text-sm border-[1px] border-solid border-neutrals-neutral-100" onClick={openDownloadModalTables}>
            						<div className="flex flex-row items-center justify-center gap-[6px]">
              							<img className="w-5 relative h-5" alt="" src="assets/download.svg"/>
              							<div className="relative font-semibold">Download</div>
            						</div>
          					</div>
        				</div>
        				<div className="self-stretch flex flex-row items-center justify-end py-3 px-6 text-neutrals-neutral-300 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-l-[1px]">
          					<div className="w-full relative flex flex-row items-start justify-between">
            						<div className="w-[294px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-start py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-start gap-[10px]">
                								<img className="w-5 relative h-5" alt="" src="assets/search.svg"/>
                								<div className="relative">{`Search`}</div>
              							</div>
            						</div>
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 cursor-pointer text-neutrals-neutral-900 border-[1px] border-solid border-neutrals-neutral-100" onClick={openCostFilter}>
              							<div className="flex flex-row items-center justify-center gap-[6px]">
                								<img className="w-5 relative h-5" alt="" src="assets/filter.svg" />
                								<div className="relative font-semibold">Filter</div>
              							</div>
            						</div>
          					</div>
        				</div>
        				<div className="flex flex-col items-start justify-start">
          					<div className="w-[1248px] h-[594px] flex flex-col items-start justify-start">
            						<div className="self-stretch flex flex-row items-start justify-start">
              							<div className="w-[353px] flex flex-col items-start justify-start">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
                  									<div className="relative font-semibold">Case id</div>
                								</div>
                								<div className="self-stretch flex flex-col items-start justify-start">
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">REF - 01</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">REF - 02</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">REF - 03</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">REF - 04</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">REF - 05</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">REF - 06</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">REF - 07</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">REF - 08</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">REF - 09</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">REF - 10</div>
                  									</div>
                								</div>
              							</div>
              							<div className="flex-1 flex flex-col items-start justify-start">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">Legal fees</div>
                								</div>
                								<div className="self-stretch flex flex-col items-start justify-start">
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£2,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£3,500</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£1,500</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£2,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£1,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£3,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£2,500</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£4,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£1,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£1,800</div>
                  									</div>
                								</div>
              							</div>
              							<div className="flex-1 flex flex-col items-start justify-start">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">Court fees</div>
                								</div>
                								<div className="self-stretch flex flex-col items-start justify-start">
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£100</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£100</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£150</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£300</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£350</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£250</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£190</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£200</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£500</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£350</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[188px] flex flex-col items-start justify-start">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-b-[1px]">
                  									<div className="relative font-semibold">Additional charges</div>
                								</div>
                								<div className="self-stretch flex flex-col items-start justify-start">
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">-</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">-</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">£60</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">£45</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">-</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">£40</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">-</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">£65</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">£20</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">£55</div>
                  									</div>
                								</div>
              							</div>
            						</div>
          					</div>
          					<div className="w-[1248px] rounded-t-none rounded-b-3xs box-border flex flex-row items-center justify-between pt-3 px-6 pb-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">Previous</div>
              							</div>
            						</div>
            						<div className="relative">Page 1 of 10</div>
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">Next</div>
              							</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[293px] left-[96px] flex flex-row items-start justify-start gap-[24px] text-neutrals-neutral-300">
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 box-border cursor-pointer" onClick={onClickCasesOverview}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Cases overview</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 box-border h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 text-primary-primary-500 border-b-[2px] border-solid border-primary-primary-500">
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Billing schedule</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 box-border cursor-pointer" onClick={onClickReportsAndAnalytics}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Reports and analytics</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[358px] left-[96px] rounded-3xs bg-neutrals-neutral-50 box-border w-[400px] flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch h-[195px] flex flex-row items-start justify-between p-6 box-border">
          					<div className="flex flex-col items-start justify-start gap-[24px]">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<img className="w-5 relative h-5" alt="" src="assets/calendar.svg"/>
            						</div>
            						<div className="flex flex-col items-start justify-start gap-[10px]">
              							<div className="relative font-semibold">Billing schedule</div>
              							<div className="relative text-neutrals-neutral-300">
                								<p className="m-0">Next billing date:</p>
                								<p className="m-0">28/11/23</p>
              							</div>
            						</div>
          					</div>
          					<div className="rounded-[20px] bg-primary-primary-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm text-primary-primary-500 border-[1px] border-solid border-primary-primary-500">
            						<div className="w-1.5 relative rounded-[50%] bg-primary-primary-500 h-1.5" />
            						<div className="relative font-semibold">Weekly</div>
          					</div>
        				</div>
        				<div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-t-none rounded-b-3xs bg-neutrals-neutral-50 flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
							<CTAs
							type="Ghostbtn"
							text="Customise"
							textColor="text-neutrals-neutral-900"
							bgColor="bg-neutrals-neutral-50"
							borderColor="border-neutrals-neutral-100"
							icon="assets/customise.svg"
							onClick={openDataPicker}
							/>
        				</div>
      			</div>
      			<div className="absolute top-[358px] left-[520px] rounded-3xs bg-neutrals-neutral-50 box-border w-[400px] flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch h-[195px] flex flex-row items-start justify-start p-6 box-border">
          					<div className="flex-1 flex flex-col items-start justify-start gap-[24px]">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<img className="w-5 relative h-5" alt="" src="assets/lba.svg"/>
            						</div>
            						<div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
              							<div className="self-stretch relative font-semibold">Generate invoice</div>
              							<div className="self-stretch relative text-neutrals-neutral-300">
                								<p className="m-0">Last invoice generated:</p>
                								<p className="m-0">28/11/23</p>
              							</div>
            						</div>
          					</div>
        				</div>
        				<div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-t-none rounded-b-3xs bg-neutrals-neutral-50 flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
							<CTAs
							type="Ghostbtn"
							text="Generate"
							textColor="text-neutrals-neutral-900"
							bgColor="bg-neutrals-neutral-50"
							borderColor="border-neutrals-neutral-100"
							icon="assets/scan.svg"
							onClick={onClickGenerate}
							/>
        				</div>
      			</div>
      			<div className="absolute top-[358px] left-[944px] rounded-3xs bg-neutrals-neutral-50 box-border w-[400px] flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch h-[195px] flex flex-row items-start justify-start p-6 box-border">
          					<div className="flex-1 flex flex-col items-start justify-start gap-[24px]">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<img className="w-5 relative h-5" alt="" src="assets/calendar.svg" />
            						</div>
            						<div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
              							<div className="self-stretch relative font-semibold">Adjust billing</div>
              							<div className="self-stretch relative text-neutrals-neutral-300">
                								<p className="m-0">Next billing date:</p>
                								<p className="m-0">28/11/23</p>
              							</div>
            						</div>
          					</div>
        				</div>
        				<div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-t-none rounded-b-3xs bg-neutrals-neutral-50 flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
							<CTAs
							type="Ghostbtn"
							text="Fill form"
							textColor="text-neutrals-neutral-900"
							bgColor="bg-neutrals-neutral-50"
							borderColor="border-neutrals-neutral-100"
							icon="assets/arrow-right.svg"
							onClick={openBillingAdjustmentForm1}
							/>
        				</div>
      			</div>

                {/* Navigation Panel */}
				<NavigationPanelLawyer navItems={navItems}/>

				{/* Breadcrumb Navigation */}
				<Breadcrumb breadcrumbs={breadcrumb} textColor="text-primary-primary-500" position="top-[24px] left-[90px]"/>

    		</div>
    		{isDownloadModalTablesOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeDownloadModalTables}
        				>
        				<DownloadModalTables onClose={closeDownloadModalTables} />
      			</PortalPopup>
    		)}
    		{isCostFilterOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeCostFilter}
        				>
        				<CostFilter onClose={closeCostFilter} />
      			</PortalPopup>
    		)}
    		{isDataPickerOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				onOutsideClick={closeDataPicker}
        				>
        				<DataPicker onClose={closeDataPicker} />
      			</PortalPopup>
    		)}
    		{isBillingAdjustmentForm1Open && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				onOutsideClick={closeBillingAdjustmentForm1}
        				>
        				<BillingAdjustmentForm onClose={closeBillingAdjustmentForm1} />
      			</PortalPopup>
    		)}
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}</>);
};

export default Legal_proceedings_2;