// import React, { useState } from "react";
// import DropdownHover from "./DropdownHover";

// const Dropdown = ({
//   title,
//   placeholder,
//   dropdownContents,
//   icon,
//   width = "400px"
// }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [selectedOption, setSelectedOption] = useState(null);

//   const handleToggleDropdown = () => {
//     setIsOpen(!isOpen);
//   };

//   const handleSelectOption = (option) => {
//     setSelectedOption(option);
//     setIsOpen(false);
//   };

//   return (
//     <div className={`relative flex flex-col items-start justify-start gap-[6px] text-left text-sm text-neutrals-neutral-900 font-open-sans-ff-body-strong`} style={{ width }}>
//       <div className="flex flex-row items-start justify-start gap-[6px]">
//         <div className="relative font-semibold">{title}</div>
//         {icon && <img className="w-5 h-5 overflow-hidden" alt="" src={icon} />}
//       </div>
//       <div
//         className={`shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-between py-3.5 px-2.5 text-base border-[1px] border-solid ${
//           isOpen ? "border-primary-primary-500" : "border-neutrals-neutral-100"
//         }`}
//         onClick={handleToggleDropdown}
//         style={{ width }}
//       >
//         <div className={`relative ${selectedOption ? "font-semibold" : "text-neutrals-neutral-300"}`}>
//           {selectedOption || placeholder}
//         </div>
//         <img className="w-5 h-5 overflow-hidden" alt="" src="assets/arrow-down.svg" />
//       </div>
//       {isOpen && (
//         <div
//           className="shadow-[0px_10px_60px_rgba(0,_0,_0,_0.1)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start py-1 px-0 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100"
//           style={{ width }}
//         >
//           {dropdownContents.map((content, index) => (
//             <div
//               key={index}
//               className={`w-full flex flex-col items-start justify-start py-px px-1.5 box-border cursor-pointer ${selectedOption === content ? "font-semibold" : ""}`}
//               onClick={() => handleSelectOption(content)}
//             >
//               <div className="self-stretch rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-start p-2.5">
//                 {content}
//               </div>
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default Dropdown;

//Code 2
import React, { useState } from "react";
import DropdownHover from "./DropdownHover";

const Dropdown = ({ title, placeholder, dropdownContents, icon, width = "400px" }) => {
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleSelect = (index) => {
    setSelectedIndex(index);
  };

  return (
    <DropdownHover
      title={title}
      placeholder={placeholder}
      dropdownContents={dropdownContents}
      icon={icon}
      width={width}
      selectedIndex={selectedIndex}
      onSelect={handleSelect}
    />
  );
};

export default Dropdown;