import React from 'react';
import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const EmailInputField  = ({
      title,
      placeholder,
      width,
      value,
      onChange,
      validationMessage,
      iconColor,
      iconSize,
      apiEndpoint,
    }) => {
      const [error, setError] = useState('');
    
      const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };
    
      const handleBlur = () => {
        if (!value.trim()) {
          setError(validationMessage || 'This field is required.');
        } else if (!validateEmail(value)) {
          setError('Please provide a valid email address.');
        } else {
          setError('');
          // Submit data to the backend if valid
          axios
            .post(apiEndpoint, { email: value })
            .then(() => console.log('Email saved successfully.'))
            .catch((err) => console.error('Error saving email:', err));
        }
};

return (
      <div className="flex flex-col items-start justify-start gap-[10px]">
        <div className="flex flex-row items-center justify-start gap-[6px]">
          <div className="relative font-semibold">{title}</div>
          <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="/assets/help-circle.svg" />
        </div>
  
        {/* Creating extra div class - Start */}
        <div className='relative w-full' style={{width}}>
          <input
            className={`shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border overflow-hidden flex flex-row items-center justify-start p-3 text-base border-[1px] border-solid ${error ? 'border-red-500 text-red-500' : 'border-neutrals-neutral-100 text-neutrals-neutral-300'}`}
            type='text'
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={handleBlur}
            style={{ width, color: error ? '#232323' : '#2D404E' }}
          />
          {error && (
            <div className='absolute inset-y-0 right-3 flex items-center'>
              <HelpOutlineIcon style={{ color: iconColor, fontSize: iconSize }} titleAccess={error}/>
            </div>
          )}
        </div>

      </div>
    );
};
EmailInputField.propTypes = {
      title: PropTypes.string.isRequired,
      placeholder: PropTypes.string.isRequired,
      width: PropTypes.string,
      value: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
      validationMessage: PropTypes.string,
      iconColor: PropTypes.string,
      iconSize: PropTypes.string,
      tooltipPosition: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
      apiEndpoint: PropTypes.string.isRequired,
    };
    
    EmailInputField.defaultProps = {
      width: '400px',
      validationMessage: 'This field is required.',
      iconColor: '#2D404E',
      iconSize: '20px',
      tooltipPosition: 'right',
    };

export default EmailInputField;
