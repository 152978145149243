import { useState, useCallback } from 'react';
import LBAConfirmation from '../../components/LBAConfirmation';
import PortalPopup from '../../components/PortalPopup';
import LogoutConfirmation from '../../components/LogoutConfirmation';
import NavigationPanel from '../../components/NavigationPanel';
import DebtCollectionNav from '../../components/DebtCollectionNav';
import ProgressBar from '../../components/ProgressBar';
import LbaForm from '../../components/LbaForm';

const DebtorDetailsPage = () => {
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	
  	const openLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(true);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);

	const navItems = [
		{
			text: 'Debt collection', 
    		bgColor: 'bg-neutrals-neutral-50', 
    		textColor: 'text-neutrals-neutral-300', 
    		hoverBgColor: 'bg-neutrals-neutral-50', 
    		hoverTextColor: 'text-primary-primary-500', 
    		path: "",
		},

		{
			text: "Outcomes & costs",
			bgColor: 'bg-neutrals-neutral-50', 
    		textColor: 'text-neutrals-neutral-300', 
    		hoverBgColor: 'bg-neutrals-neutral-50', 
    		hoverTextColor: 'text-primary-primary-500', 
    		path: "/outcome&cost",
    		icon: ''
		},

		{
			text: "AI indicators",
			bgColor: 'bg-neutrals-neutral-50', 
    		textColor: 'text-neutrals-neutral-300', 
    		hoverBgColor: 'bg-neutrals-neutral-50', 
    		hoverTextColor: 'text-primary-primary-500', 
    		path: "/ai1",
    		icon: ''
		},
	];
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[2876px] overflow-hidden text-left text-sm text-neutrals-neutral-900 font-open-sans-ff-heading-2">
				<NavigationPanel navItems={navItems}/>
      			<div className="absolute top-[2793px] right-[96px] text-neutrals-neutral-300">Copyright © Think-AI 2023</div>
      			<div className="absolute top-[307px] left-[96px] box-border w-[1248px] flex flex-row items-start justify-start pt-0 px-0 pb-6 text-lg border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="relative font-semibold">Debtor's details</div>
      			</div>

      			<div className="absolute top-[380px] left-[calc(50%_-_624px)] flex flex-row items-start justify-start gap-[130px]">
					<LbaForm/>
      			</div>
				<ProgressBar/>
    		</div>
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}</>);
};

export default DebtorDetailsPage;