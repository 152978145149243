import { useState, useCallback } from 'react';
import EmailTemplate from "../components/EmailTemplate";
import PortalPopup from "../components/PortalPopup";
import CTAs from "../components/CTAs";


const LawFirm = ({onClose}) => {
  	const [isEmailTemplateOpen, setEmailTemplateOpen] = useState(false);
  	
  	const openEmailTemplate = useCallback(() => {
    		setEmailTemplateOpen(true);
  	}, []);
  	
  	const closeEmailTemplate = useCallback(() => {
    		setEmailTemplateOpen(false);
  	}, []);
  	
  	return (<>
    		<div className="relative shadow-[0px_10px_60px_rgba(0,_0,_0,_0.1)] rounded-3xs bg-neutrals-neutral-50 box-border w-full flex flex-col items-start justify-start gap-[24px] text-left text-sm text-neutrals-neutral-900 font-open-sans-ff-meta-strong border-[1px] border-solid border-neutrals-neutral-100">
      			<div className="flex flex-col items-start justify-start pt-6 px-6 pb-0 gap-[24px] text-lg">
        				<div className="flex flex-row items-start justify-start gap-[292px]">
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
            						<img className="w-5 relative h-5" alt="" src="assets/judge.svg"/>
          					</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0 cursor-pointer" alt="" src="assets/cancel.svg" onClick={onClose}/>
        				</div>
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<div className="w-[352px] relative font-semibold inline-block">Select your preferred law firm</div>
          					<div className="w-[352px] relative text-sm text-design-light-mode-neutral-neutral-300 inline-block">Send mail directly to your law firm to investigate further.</div>
        				</div>
      			</div>
      			<div className="flex flex-col items-start justify-start py-0 px-6">
        				<div className="w-[352px] flex flex-col items-start justify-start gap-[10px]">
          					<div className="relative font-semibold">Recommended law firm</div>
          					<div className="self-stretch rounded-3xs bg-neutrals-neutral-50 flex flex-row items-end justify-start py-3.5 px-2.5 gap-[140px] text-base text-design-light-mode-neutral-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="w-[165px] relative inline-block shrink-0">Select</div>
            						<img className="w-5 relative h-5 overflow-hidden shrink-0 object-contain" alt="" src="assets/arrow-down.svg"/>
          					</div>
        				</div>
      			</div>
      			<div className="w-[400px] flex flex-row items-start justify-center pt-0 px-6 pb-6 box-border gap-[24px]">
					<CTAs
					type="Ghostbtn"
					text="Cancel"
					textColor="text-neutrals-neutral-900"
					bgColor="bg-neutrals-neutral-50"
					borderColor="border-neutrals-neutral-100"
					width="165px"
					position="relative"
					onClick={onClose}
					/>

					<CTAs
					type="Primarybtn"
					text="Preview"
					textColor="text-neutrals-neutral-50"
					bgColor="bg-primary-primary-500"
					borderColor="border-primary-primary-500"
					width="165px"
					position="relative"
					onClick={openEmailTemplate}
					/>
      			</div>
    		</div>
    		{isEmailTemplateOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				onOutsideClick={closeEmailTemplate}
        				>
        				<EmailTemplate onClose={closeEmailTemplate} />
      			</PortalPopup>
    		)}</>);
};

export default LawFirm;