import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useCallback, useState } from 'react';
import { BorderColor } from '@mui/icons-material';

const PasswordInputField = ({ title, placeholder, width, value, onChange, validationMessage, iconColor, iconSize, apiEndpoint }) => {
  const [error, setError] = useState('');

  const validatePassword = (password) => {
    if(!password.trim()) {
      return 'Password is required.';
    }
    if(password.length < 8){
      return 'Password must be at least 8 characters long.';
    }
    if(!/[A-Z]/.test(password)) {
      return 'Password must include at least one uppercase letter.';
    }
    if(!/[a-z]/.test(password)) {
      return 'Password must include at least one lowercase letter.';
    }
    if(!/[0-9]/.test(password)) {
      return 'Password must include at least one number.';
    }
    if(!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      return 'Password must include at least one special character.';
    }

    return '';
  };

const [password, setPassword] = useState('');

const handleBlur = () => {
  const validationError = validatePassword(value);
  if (validationError) {
    setError(validationError);
  } else {
    setError('');
    // Submit data to the backend if valid
    axios
      .post(apiEndpoint, {password: value})
      .then(() => console.log('Password saved successfully.'))
      .catch((err) => console.error('Error saving password:', err));
  }
};

  const inputStyle = {
    width,
    color: error ? '#232323' : '#2D404E',
    borderColor: error ? '#D32F2F' : '#E0E0E0',
  };

    return (
      <div className="flex flex-col items-start justify-start gap-[10px]">
        <div className="flex flex-row items-center justify-start gap-[6px]">
          <div className="relative font-semibold">{title}</div>
          <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="/assets/help-circle.svg" />
        </div>
  
        {/* Creating extra div class - Start */}
        <div className='relative w-full' style={{width}}>
          <input
            className={`shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border overflow-hidden flex flex-row items-center justify-start p-3 text-base border-[1px] border-solid ${password ? 'border-red-500 text-red-500' : 'border-neutrals-neutral-100 text-neutrals-neutral-300'}`}
            type='password' // Hides input as dots
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={handleBlur}
            style={inputStyle}
          />
          {error && (
            <div className='absolute inset-y-0 right-3 flex items-center group'>
              <HelpOutlineIcon
                style={{color: iconColor, fontSize: iconSize}}
                titleAccess={error}
              />
              <div className='absolute top-full right-0 mt-1 hidden group-hover:block p-2 bg-gray-800 text-white text-sm rounded' style={{whiteSpace: 'nowrap'}}>
                {error}
              </div>
            </div>
          )}
        </div>
      </div>
    );
};

PasswordInputField.propTypes = {
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  width: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,

  validationMessage: PropTypes.string,
  iconColor: PropTypes.string,
  iconSize: PropTypes.string,
  apiEndpoint: PropTypes.string.isRequired,
};

PasswordInputField.defaultProps = {
  width: '400px',

  validationMessage: 'Password is required.',
  iconColor: '#D32F2F',
  iconSize: '20px'
};

export default PasswordInputField;