import React, { useCallback, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import EmailInputField from "../../components/EmailInputField";
import PasswordInputField from "../../components/PasswordInputField";
import CTAs from '../../components/CTAs';

function LogInPage() {
  const navigate = useNavigate();

  const handleRegisterClick = useCallback(() => {
    navigate('/selectaccount');
  }, [navigate]);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = (value) => {
    setEmail(value);
  };
  const handlePasswordChange = (value) => {
    setPassword(value);
  };

  const handleForgotPasswordClick = useCallback(() => {
    navigate('/forgetpassword');
  }, [navigate]);

  const handleLoginClick = useCallback(() => {
    // Perform login action
    navigate('/lbaform');
  }, [navigate]);

  return (
    <div className="w-full relative bg-neutrals-neutral-50 h-[1024px] overflow-hidden text-left text-sm text-neutrals-neutral-900 font-open-sans-ff-meta">
      <div className="absolute top-[797px] left-[613.5px] flex flex-row items-start justify-center gap-[6px] text-neutrals-neutral-300">
        <div className="relative">Don’t have an account?</div>
        <div className="relative font-semibold text-primary-primary-500 cursor-pointer" onClick={handleRegisterClick}>Register</div>
      </div>
      <img className="absolute top-[208px] left-[calc(50%_-_30px)] w-[60px] h-[67px] object-cover" alt="" src="assets/logo-vertical.svg" />
      <div className="absolute top-[299px] left-[564px] flex flex-col items-center justify-start gap-[10px] text-9xl">
        <b className="relative">Log in to your account</b>
        <div className="relative text-base text-neutrals-neutral-300 text-center">Welcome back! Please enter your details.</div>
      </div>

	  {/* Email Input Field */}
      <div className="absolute top-[433px] left-[520px] flex flex-col items-start justify-start gap-[10px]">
		    <EmailInputField title="Email"
              placeholder="name@yourcompany.com"
              value={email}
              onChange={(e) => handleEmailChange(e.target.value)}
              validationMessage="Please provide a valid email address."
              iconColor="#D93C3D"
              iconSize="20px"
              apiEndpoint="https://example.com/api/email"
        />
      </div>

	  {/* Password Input Field */}
      <div className="absolute top-[533px] left-[520px] flex flex-col items-start justify-start gap-[10px]">
		    <PasswordInputField
              title="Password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              validationMessage="Please provide your password."
              iconColor="#D93C3D"
              iconSize="20px"
              apiEndpoint="https://example.com/api/email" />
      </div>

      <div className="absolute top-[619px] left-[520px] w-[400px] flex flex-row items-center justify-between">
        <div className="flex flex-row items-center justify-start gap-[6px]">
          <img className="w-4 relative h-4" alt="" src="assets/checkbox.svg" />
          <div className="relative font-semibold">Remember for 30 days</div>
        </div>
        <div className="relative font-semibold text-primary-primary-500 text-right cursor-pointer" onClick={handleForgotPasswordClick}>Forgot password</div>
      </div>

      <div className="absolute top-[678px] left-[520px] w-[400px] flex flex-col items-center justify-start gap-[16px] text-bg-primary">
        <CTAs
        type="Primarybtn"
        text="Log in"
        textColor="text-neutrals-neutral-50"
        bgColor="bg-primary-primary-500"
        borderColor="border-primary-primary-500"
        width="400px"
        position="relative"
        onClick={handleLoginClick}
        />

        <CTAs
        type="Googlebtn"
        text="Sign in with Google"
        textColor="text-neutrals-neutral-900"
        bgColor="bg-neutrals-neutral-50"
        borderColor="border-neutrals-neutral-100"
        icon="assets/google.svg"
        width="400px"
        position="relative"
        onClick={handleLoginClick}
        />

      </div>
	  
      <div className="absolute top-[941px] right-[96px] text-neutrals-neutral-300">Copyright © Think-AI 2023</div>
    </div>
  );
}

export default LogInPage;
