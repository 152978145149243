// import React, { useState } from "react";

// const Fqs = () => {
//   const [activeIndex, setActiveIndex] = useState(null);

//   const toggleFAQ = (index) => {
//     if (activeIndex === index) {
//       setActiveIndex(null);
//     } else {
//       setActiveIndex(index);
//     }
//   };

//   const faqs = [
//     {
//       question: "What is the difference between LBA and LPD?",
//       answer:
//         "The main distinction between Letter Before Action (LBA) and Late Payment Demand (LPD) lies in their purpose and timing. The LBA is typically the initial step in the debt collection process, notifying the debtor of an outstanding amount and providing a grace period for payment. On the other hand, the LPD serves to clarify additional costs and consequences if the debtor fails to pay after receiving the LBA. It is a follow-up communication that emphasizes the urgency of payment and outlines specific repercussions for delayed payment. Both letters play crucial roles in the debt recovery process, with the LBA initiating the communication and the LPD reinforcing the consequences of non-payment.",
//     },
//     // Add more FAQ objects here as needed
//   ];

//   return (
//     <div className="w-[954px] relative rounded-[5px] box-border overflow-hidden text-left text-lg text-neutrals-neutral-900 font-open-sans-ff-body">
//       {faqs.map((faq, index) => (
//         <div key={index} className="mb-4 rounded-3xs">
//           <div
//             className={`w-full flex flex-row rounded-t-3xs rounded-r-3xs items-center justify-between p-6 cursor-pointer ${activeIndex === index ? 'bg-primary-primary-50 border-solid border-t-[1px] border-l-[1px] border-r-[1px] border-neutrals-neutral-100' : 'bg-neutrals-neutral-50'}`}
//             onClick={() => toggleFAQ(index)}
//           >
//             <div className="flex flex-row items-center gap-[24px]">
//               <img className="w-5 h-5" alt="" src="assets/add-circle.svg" />
//               <div className="relative font-semibold">{faq.question}</div>
//             </div>
//             <img
//               className={`w-5 h-5 transition-transform duration-200 ${activeIndex === index ? 'transform rotate-180' : ''}`}
//               alt=""
//               src="keyboard_arrow_down.svg"
//             />
//           </div>
//           {activeIndex === index && (
//             <div className="p-6 bg-primary-primary-50 border-solid border-l-[1px] border-b-[1px] border-r-[1px] border-neutrals-neutral-100 shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-b-3xs">
//               <div className="relative text-base">{faq.answer}</div>
//             </div>
//           )}
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Fqs;

//Code 2
// import React, { useState } from "react";

// const Fqs = ({ questions }) => {
//   const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

//   const toggleQuestion = (index) => {
//     setActiveQuestionIndex(activeQuestionIndex === index ? null : index);
//   };

//   return (
//     <div className="w-[954px] relative rounded-[5px] box-border overflow-hidden text-left text-lg text-neutrals-neutral-900 font-open-sans-ff-body">
//       {questions.map((q, index) => (
//         <div key={index} className="w-full mb-4">
//           <div
//             className="cursor-pointer flex flex-row items-center justify-start p-6 box-border gap-[24px] bg-neutrals-neutral-50 rounded-3xs"
//             onClick={() => toggleQuestion(index)}
//           >
//             <img className="w-5 h-5" alt="" src="assets/add-circle.svg" />
//             <div className="font-semibold">{q.question}</div>
//           </div>
//           {activeQuestionIndex === index && (
//             <div className="mt-2 shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-primary-primary-50 box-border p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
//               <div className="flex flex-row items-start justify-start gap-[24px]">
//                 <img className="w-5 h-5" alt="" src="assets/minus-circle.svg"/>
//                 <div className="flex-1 flex flex-col items-start justify-center gap-[10px]">
//                   <div className="font-semibold">{q.question}</div>
//                   <div className="text-base">{q.answer}</div>
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Fqs;

//Code 3
// import React, { useState } from "react";

// const Property1OnClick = () => {
//   const [expanded, setExpanded] = useState(false);

//   const toggleExpansion = () => {
//     setExpanded(!expanded);
//   };

//   return (
//     <div className={`w-[954px] cursor-pointer relative rounded-[10px] bg-neutrals-neutral-50 box-border flex flex-row items-start justify-start p-6 gap-[24px] text-left text-lg text-neutrals-neutral-900 font-open-sans-ff-body ${expanded ? "h-auto border-solid border-[1px] border-neutrals-neutral-100 bg-primary-primary-50 shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)]" : "h-[72px] overflow-hidden"}`} onClick={toggleExpansion}>
//       <img className="w-5 h-5" alt="" src="assets/add-circle.svg" />
//       <div className="flex-1 flex flex-col items-start justify-center gap-[10px]">
//         <div className="w-[656px] font-semibold">{expanded ? "What is the difference between LBA and LPD?" : "What is the difference between LBA and LPD?"}</div>
//         {expanded && (
//           <div className="text-base">
//             The main distinction between Letter Before Action (LBA) and Late Payment Demand (LPD) lies in their purpose and timing. The LBA is typically the initial step in the debt collection process, notifying the debtor of an outstanding amount and providing a grace period for payment. On the other hand, the LPD serves to clarify additional costs and consequences if the debtor fails to pay after receiving the LBA. It is a follow-up communication that emphasizes the urgency of payment and outlines specific repercussions for delayed payment. Both letters play crucial roles in the debt recovery process, with the LBA initiating the communication and the LPD reinforcing the consequences of non-payment.
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Property1OnClick;

//Code 4
import React, { useState } from "react";

const FAQItem = ({ question, answer }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={`w-[954px] relative rounded-[10px] bg-neutrals-neutral-50 box-border flex flex-row items-start justify-start p-6 gap-[24px] text-left text-lg text-neutrals-neutral-900 font-open-sans-ff-body  ${expanded ? "h-auto bg-primary-primary-50 border-[1px] border-solid border-neutrals-neutral-100 shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)]" : "h-[72px] overflow-hidden"}`} onClick={toggleExpansion}>
      <img className="w-5 h-5" alt="" src="assets/add-circle.svg" />
      <div className="flex-1 flex flex-col items-start justify-center gap-[10px]">
        <div className="w-full font-semibold">{question}</div>
        {expanded && (
          <div className="text-base">
            {answer}
          </div>
        )}
      </div>
    </div>
  );
};

const Fqs = () => {
  const faqs = [
    {
      question: "What is the difference between LBA and LPD?",
      answer: "The main distinction between Letter Before Action (LBA) and Late Payment Demand (LPD) lies in their purpose and timing. The LBA is typically the initial step in the debt collection process, notifying the debtor of an outstanding amount and providing a grace period for payment. On the other hand, the LPD serves to clarify additional costs and consequences if the debtor fails to pay after receiving the LBA. It is a follow-up communication that emphasizes the urgency of payment and outlines specific repercussions for delayed payment. Both letters play crucial roles in the debt recovery process, with the LBA initiating the communication and the LPD reinforcing the consequences of non-payment."
    },
    {
      question: "What will be the cost implications?",
      answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    },

    {
      question: "What will be security measures to be taken?",
      answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    },

    {
      question: "How do I contact support for further assistance?",
      answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    },

  ];

  return (
    <div className="w-[954px] flex flex-col items-start justify-start gap-[10px]">
      {faqs.map((faq, index) => (
        <FAQItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
};

export default Fqs;
