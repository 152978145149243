import { useCallback, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import NavigationPanelLP from '../../components/NavigationPanelLP';
import CTAs from '../../components/CTAs';
import EmailInputField from '../../components/EmailInputField';
import NameInput from '../../components/NameInput';
import Dropdown from '../../components/Dropdown';

const LandingPage = () => {

	// Added for testing
	const [name, setName] = useState('');
	const [isDarkMode, setIsDarkMode] = useState(true);

	const handleNameChange = (event) => {
		setName(event.target.value);
	};
	// End
  	
  	const onCTAsContainerClick = useCallback(() => {
    		// Add your code here
  	}, []);
  	
  	return (
    		<div className="w-full relative bg-neutrals-neutral-50 h-[5850px] overflow-hidden text-center text-base text-neutrals-neutral-900 font-open-sans-font-family-body-strong">
      			<div className="absolute top-[1847px] left-[0px] w-full flex flex-col items-center justify-start py-24 px-0 box-border gap-16 text-xl">
        				<div className="w-[611px] flex flex-col items-center justify-start gap-2.5 text-base text-design-light-mode-primary-primary-500">
          					<div className="rounded-81xl bg-design-light-mode-primary-primary-50 border-design-light-mode-primary-primary-200 border-[1px] border-solid flex flex-row items-center justify-start py-1 px-3">
            						<div className="relative font-semibold">Features</div>
          					</div>
          					<b className="relative text-13xl text-neutrals-neutral-900">What We Offer?</b>
            						<div className="self-stretch relative text-neutrals-neutral-300">Powerful Features to Streamline Debt Collection</div>
            						</div>
            						<div className="flex flex-row items-start justify-start gap-8">
              							<div className="w-96 flex flex-col items-center justify-start gap-6">
                								<div className="w-12 shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 border-neutrals-neutral-100 border-[1px] border-solid box-border h-12 flex flex-row items-center justify-center p-2.5">
                  									<img className="w-6 relative h-6" alt="" src="assets/flash.svg" />
                								</div>
                								<div className="self-stretch flex flex-col items-center justify-start gap-1.5">
                  									<div className="self-stretch relative font-semibold">Track Debts Easily</div>
                  									<div className="self-stretch relative text-base text-neutrals-neutral-300">Keep tabs on outstanding amounts, due dates, and payment statuses in one centralised dashboard.</div>
                								</div>
              							</div>
              							<div className="w-96 flex flex-col items-center justify-start gap-6">
                								<div className="w-12 shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 border-neutrals-neutral-100 border-[1px] border-solid box-border h-12 flex flex-row items-center justify-center p-2.5">
                  									<img className="w-6 relative h-6" alt="" src="assets/ai.png"/>
                								</div>
                								<div className="self-stretch flex flex-col items-center justify-start gap-1.5">
                  									<div className="self-stretch relative font-semibold">Effortless Communication</div>
                  									<div className="self-stretch relative text-base text-neutrals-neutral-300">Communicate with debtors directly through the platform, streamlining the collection process.</div>
                								</div>
              							</div>
              							<div className="w-96 flex flex-col items-center justify-start gap-6">
                								<div className="w-12 shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 border-neutrals-neutral-100 border-[1px] border-solid box-border h-12 flex flex-row items-center justify-center p-2.5">
                  									<img className="w-6 relative h-6" alt="" src="assets/timer.svg" />
                								</div>
                								<div className="self-stretch flex flex-col items-center justify-start gap-1.5">
                  									<div className="self-stretch relative font-semibold">Automated Document Generation</div>
                  									<div className="self-stretch relative text-base text-neutrals-neutral-300">Generate standard legal documents like demand letters and court filings with AI-driven automation.</div>
                								</div>
              							</div>
            						</div>
            						<div className="flex flex-row items-start justify-start gap-8">
              							<div className="w-96 flex flex-col items-center justify-start gap-6">
                								<div className="w-12 shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 border-neutrals-neutral-100 border-[1px] border-solid box-border h-12 flex flex-row items-center justify-center p-2.5">
                  									<img className="w-6 relative h-6" alt="" src="assets/flash.svg" />
                								</div>
                								<div className="self-stretch flex flex-col items-center justify-start gap-1.5">
                  									<div className="self-stretch relative font-semibold">Conflict of Interest Checks</div>
                  									<div className="self-stretch relative text-base text-neutrals-neutral-300">Conduct automated scans to identify potential conflicts of interest across clients and cases.</div>
                								</div>
              							</div>
              							<div className="w-96 flex flex-col items-center justify-start gap-6">
                								<div className="w-12 shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 border-neutrals-neutral-100 border-[1px] border-solid box-border h-12 flex flex-row items-center justify-center p-2.5">
                  									<img className="w-6 relative h-6" alt="" src="assets/ai.png"/>
                								</div>
                								<div className="self-stretch flex flex-col items-center justify-start gap-1.5">
                  									<div className="self-stretch relative font-semibold">Customisable Billing</div>
                  									<div className="self-stretch relative text-base text-neutrals-neutral-300">Flexible billing options tailored to your needs, whether you're a client managing debts or a lawyer offering debt collection services.</div>
                  									</div>
                								</div>
                								<div className="w-96 flex flex-col items-center justify-start gap-6">
                  									<div className="w-12 shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 border-neutrals-neutral-100 border-[1px] border-solid box-border h-12 flex flex-row items-center justify-center p-2.5">
                    										<img className="w-6 relative h-6" alt="" src="assets/timer.svg" />
                  									</div>
                  									<div className="self-stretch flex flex-col items-center justify-start gap-1.5">
                    										<div className="self-stretch relative font-semibold">AI Insights</div>
                    										<div className="self-stretch relative text-base text-neutrals-neutral-300">Utilise AI to analyse communication patterns, predict recovery rates, and identify potential legal challenges.</div>
                  									</div>
                								</div>
              							</div>
            						</div>
            						<div className="absolute top-[0px] left-[0px] bg-column-bg w-full flex flex-row items-center justify-between py-8 px-24 box-border text-left">
              							<NavigationPanelLP/>
            						</div>
            						<div className="absolute top-[3160px] left-[0px] w-full flex flex-col items-center justify-start py-24 px-0 box-border gap-16 text-primary-primary-500">
              							<div className="flex flex-col items-center justify-start gap-2.5">
                								<div className="rounded-81xl bg-primary-primary-50 border-primary-primary-200 border-[1px] border-solid flex flex-row items-center justify-start py-1 px-3">
                  									<div className="relative font-semibold">Pricing</div>
                								</div>
                								<b className="relative text-13xl text-neutrals-neutral-900">Plans That Fit Your Scale</b>
                								<div className="relative text-neutrals-neutral-300">Flexible Pricing Plans</div>
              							</div>
              							<div className="flex flex-col items-center justify-start gap-16 text-left text-neutrals-neutral-900">
                								<div className="rounded-3xs bg-column-bg border-neutrals-neutral-100 border-[1px] border-solid flex flex-row items-start justify-start p-2 gap-2.5">
                  									<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2 px-3">
                    										<div className="relative font-semibold">Monthly billing</div>
                  									</div>
                  									<div className="rounded-3xs flex flex-row items-center justify-center py-2 px-3 text-neutrals-neutral-300">
                    										<div className="relative font-semibold">Annual billing</div>
                  									</div>
                								</div>
                								<div className="w-[1122px] relative h-[414.5px] text-sm text-neutrals-neutral-300">
                  									<div className="absolute top-[0.47px] left-[382px] shadow-[0px_10px_60px_rgba(0,_0,_0,_0.1)] rounded-3xs bg-neutrals-neutral-50 border-primary-primary-500 border-[2px] border-solid box-border h-[414px] overflow-hidden flex flex-col items-start justify-start py-6 px-8 gap-6">
                    										<div className="flex flex-col items-start justify-start">
                      											<div className="flex flex-col items-start justify-start gap-1.5">
                        												<div className="w-[294px] flex flex-row items-center justify-between text-center">
                          													<div className="relative">Business plan</div>
                          													<div className="rounded-81xl bg-design-light-mode-primary-primary-50 border-primary-primary-200 border-[1px] border-solid flex flex-row items-center justify-center py-0.5 px-2 text-left text-primary-primary-500">
                            														<div className="relative font-semibold">Most popular</div>
                          													</div>
                        												</div>
                        												<div className="relative text-13xl text-neutrals-neutral-900">
                          													<b>{`£99.99 `}</b>
                          													<span className="text-sm text-neutrals-neutral-300">per month</span>
                        												</div>
                        												<div className="w-[294px] relative inline-block">Everything you need for a growing business</div>
                      											</div>
                    										</div>
                    										<div className="flex flex-col items-start justify-start gap-6 text-lg text-neutrals-neutral-900">
                      											<div className="w-[295px] relative border-bg-primary border-t-[1px] border-solid box-border h-px" />
                      											<div className="flex flex-col items-start justify-start gap-6">
                        												<div className="flex flex-col items-start justify-start gap-1.5">
                          													<div className="relative font-semibold">FEATURES</div>
                          													<div className="relative text-sm text-neutrals-neutral-300">Everything in our advance plan.</div>
                        												</div>
                        												<div className="flex flex-col items-start justify-start gap-2.5 text-sm">
                          													<div className="flex flex-row items-center justify-start gap-1.5">
                            														<img className="w-5 relative h-5" alt="" src="Icons.svg" />
                            														<div className="w-[243px] relative inline-block shrink-0">Up to 10 users</div>
                          													</div>
                          													<div className="flex flex-row items-center justify-start gap-1.5">
                            														<img className="w-5 relative h-5" alt="" src="Icons.svg" />
                            														<div className="w-[243px] relative inline-block shrink-0">Product support</div>
                          													</div>
                          													<div className="flex flex-row items-center justify-start gap-1.5">
                            														<img className="w-5 relative h-5" alt="" src="Icons.svg" />
                            														<div className="w-[243px] relative inline-block shrink-0">Plan features</div>
                          													</div>
                        												</div>
                      											</div>
                      											<div className="w-[294px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-primary-primary-500 flex flex-row items-center justify-center py-2.5 px-3.5 box-border text-center text-sm text-neutrals-neutral-50">
                        												<div className="flex flex-row items-center justify-center">
                          													<div className="relative font-semibold">Sign up</div>
                        												</div>
                      											</div>
                    										</div>
                  									</div>
                  									<div className="absolute top-[0px] left-[0px] rounded-3xs bg-neutrals-neutral-50 border-neutrals-neutral-100 border-[1px] border-solid flex flex-col items-start justify-start py-6 px-8 gap-6 text-base">
                    										<div className="flex flex-col items-start justify-start gap-2.5">
                      											<div className="relative">Basic plan</div>
                      											<div className="flex flex-col items-start justify-start gap-1.5 text-13xl text-neutrals-neutral-900">
                        												<div className="relative">
                          													<b>{`Free `}</b>
                          													<span className="text-sm text-neutrals-neutral-300">for 30 days</span>
                        												</div>
                        												<div className="relative text-sm text-neutrals-neutral-300">Get started with basic features</div>
                      											</div>
                    										</div>
                    										<div className="flex flex-col items-start justify-start gap-6 text-lg text-neutrals-neutral-900">
                      											<div className="w-[295px] relative border-bg-primary border-t-[1px] border-solid box-border h-px" />
                      											<div className="flex flex-col items-start justify-start gap-6">
                        												<div className="flex flex-col items-start justify-start gap-1.5">
                          													<div className="relative font-semibold">FEATURES</div>
                          													<div className="relative text-sm text-neutrals-neutral-300">Everything in our free plan.</div>
                        												</div>
                        												<div className="flex flex-col items-start justify-start gap-2.5 text-sm">
                          													<div className="flex flex-row items-center justify-start gap-1.5">
                            														<img className="w-5 relative h-5" alt="" src="Icons.svg" />
                            														<div className="w-[243px] relative inline-block shrink-0">Over 10 users</div>
                          													</div>
                          													<div className="flex flex-row items-center justify-start gap-1.5">
                            														<img className="w-5 relative h-5" alt="" src="Icons.svg" />
                            														<div className="w-[243px] relative inline-block shrink-0">Product support</div>
                          													</div>
                          													<div className="flex flex-row items-center justify-start gap-1.5">
                            														<img className="w-5 relative h-5" alt="" src="Icons.svg" />
                            														<div className="w-[243px] relative inline-block shrink-0">Plan features</div>
                          													</div>
                        												</div>
                      											</div>
                      											<div className="w-[294px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 border-neutrals-neutral-100 border-[1px] border-solid box-border flex flex-row items-center justify-center py-2.5 px-3.5 text-center text-sm">
                        												<div className="flex flex-row items-center justify-center">
                          													<div className="relative font-semibold">Sign up</div>
                        												</div>
                      											</div>
                    										</div>
                  									</div>
                  									<div className="absolute top-[2.47px] left-[764px] rounded-3xs bg-neutrals-neutral-50 border-neutrals-neutral-100 border-[1px] border-solid box-border h-[412px] flex flex-col items-start justify-start py-6 px-8 gap-6 text-center">
                    										<div className="w-[294px] flex flex-col items-start justify-start gap-2.5">
                      											<div className="relative">Enterprise plan</div>
                      											<div className="flex flex-col items-start justify-start gap-1.5 text-left text-13xl text-neutrals-neutral-900">
                        												<div className="relative">
                          													<b>{`£69.99 `}</b>
                          													<span className="text-sm text-neutrals-neutral-300">per month</span>
                        												</div>
                        												<div className="w-[294px] relative text-sm text-neutrals-neutral-300 inline-block">Advanced features for scaling your business</div>
                      											</div>
                    										</div>
                    										<div className="flex flex-col items-start justify-start gap-6 text-left text-lg text-neutrals-neutral-900">
                      											<div className="w-[295px] relative border-bg-primary border-t-[1px] border-solid box-border h-px" />
                      											<div className="flex flex-col items-start justify-start gap-6">
                        												<div className="flex flex-col items-start justify-start gap-1.5">
                          													<div className="relative font-semibold">FEATURES</div>
                          													<div className="relative text-sm text-neutrals-neutral-300">Everything in our enterprise plan.</div>
                        												</div>
                        												<div className="flex flex-col items-start justify-start gap-2.5 text-sm">
                          													<div className="flex flex-row items-center justify-start gap-1.5">
                            														<img className="w-5 relative h-5" alt="" src="Icons.svg" />
                            														<div className="w-[243px] relative inline-block shrink-0">5 and under users</div>
                          													</div>
                          													<div className="flex flex-row items-center justify-start gap-1.5">
                            														<img className="w-5 relative h-5" alt="" src="Icons.svg" />
                            														<div className="w-[243px] relative inline-block shrink-0">Product support</div>
                          													</div>
                          													<div className="flex flex-row items-center justify-start gap-1.5">
                            														<img className="w-5 relative h-5" alt="" src="Icons.svg" />
                            														<div className="w-[243px] relative inline-block shrink-0">Plan features</div>
                          													</div>
                        												</div>
                      											</div>
                      											<div className="w-[294px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 border-neutrals-neutral-100 border-[1px] border-solid box-border flex flex-row items-center justify-center py-2.5 px-3.5 text-center text-sm">
                        												<div className="flex flex-row items-center justify-center">
                          													<div className="relative font-semibold">Sign up</div>
                        												</div>
                      											</div>
                    										</div>
                  									</div>
                								</div>
              							</div>
            						</div>
            						<div className="w-full absolute top-[910px] left-[0px] bg-bg-primary1 flex flex-col items-center justify-start p-24 gap-16 text-black-black-50 font-body">
              							<div className="w-[1248px] flex flex-col items-center justify-start gap-1.5">
                								<div className="rounded-81xl bg-primary-primary-900 border-design-light-mode-primary-primary-200 border-[1px] border-solid flex flex-row items-center justify-center py-1 px-3 text-primary-primary-300 font-open-sans-font-family-body-strong">
                  									<div className="relative font-semibold">Process</div>
                								</div>
                								<b className="self-stretch relative text-13xl">How It Works?</b>
                  									<div className="self-stretch relative">How Debt Co-Pilot works.</div>
                  									</div>
                  									<div className="w-[1248px] relative h-[581.8px] text-13xl text-neutrals-neutral-50 font-open-sans-font-family-body-strong">
                    										<div className="absolute top-[0px] left-[0px] flex flex-row items-start justify-start gap-6">
                      											<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-bg-primary1 border-neutrals-neutral-100 border-[1px] border-solid box-border flex flex-col items-start justify-start p-6">
                        												<div className="self-stretch flex flex-row items-start justify-between">
                          													<div className="rounded-3xs bg-black-black-400 border-black-black-100 border-[1px] border-solid flex flex-row items-start justify-start p-[12.7px]">
                            														<img className="w-6 relative h-6" alt="" src="assets/search.svg" />
                          													</div>
                          													<b className="relative">01</b>
                        												</div>
                      											</div>
                      											<div className="flex flex-row items-center justify-start gap-6 text-left text-base">
                        												<div className="w-[190px] relative border-bg-primary border-t-[2px] border-solid box-border h-0.5" />
                        												<div className="relative">Sign up for an account as a client/lawyer.</div>
                      											</div>
                    										</div>
                    										<div className="absolute top-[161.45px] left-[292px] flex flex-row items-start justify-start gap-6 text-left text-base font-body">
                      											<div className="flex flex-row items-center justify-start gap-6">
                        												<div className="relative">
                          													<p className="m-0">Access your personalised dashboard tailored</p>
                          													<p className="m-0">to your needs.</p>
                        												</div>
                        												<div className="w-[190px] relative border-bg-primary border-t-[2px] border-solid box-border h-0.5" />
                      											</div>
                      											<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-bg-primary1 border-neutrals-neutral-100 border-[1px] border-solid box-border flex flex-col items-start justify-start p-6 text-center text-13xl font-open-sans-font-family-body-strong">
                        												<div className="self-stretch flex flex-row items-start justify-between">
                          													<div className="rounded-3xs bg-black-black-400 border-black-black-100 border-[1px] border-solid flex flex-row items-start justify-start p-[12.7px]">
                            														<img className="w-6 relative h-6" alt="" src="assets/search.svg" />
                          													</div>
                          													<b className="relative">02</b>
                        												</div>
                      											</div>
                    										</div>
                    										<div className="absolute top-[322.91px] left-[0px] flex flex-row items-start justify-start gap-6">
                      											<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-bg-primary1 border-neutrals-neutral-100 border-[1px] border-solid box-border flex flex-col items-start justify-start p-6">
                        												<div className="self-stretch flex flex-row items-start justify-between">
                          													<div className="rounded-3xs bg-black-black-400 border-black-black-100 border-[1px] border-solid flex flex-row items-start justify-start p-[12.7px]">
                            														<img className="w-6 relative h-6" alt="" src="assets/search.svg" />
                          													</div>
                          													<b className="relative">03</b>
                        												</div>
                      											</div>
                      											<div className="flex flex-row items-center justify-start gap-6 text-left text-base font-body">
                        												<div className="w-[190px] relative border-bg-primary border-t-[2px] border-solid box-border h-0.5" />
                        												<div className="w-[352px] relative inline-block shrink-0">Utilise our suite of features to streamline debt collection processes.</div>
                      											</div>
                    										</div>
                    										<div className="absolute top-[484.36px] left-[288px] flex flex-row items-start justify-start gap-6 text-left text-base">
                      											<div className="flex flex-row items-center justify-start gap-6">
                        												<div className="w-[324px] relative inline-block shrink-0">Track progress, communicate, and achieve results.</div>
                        												<div className="w-[190px] relative border-bg-primary border-t-[2px] border-solid box-border h-0.5" />
                      											</div>
                      											<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-bg-primary1 border-neutrals-neutral-100 border-[1px] border-solid box-border flex flex-col items-start justify-start p-6 text-center text-13xl">
                        												<div className="self-stretch flex flex-row items-start justify-between">
                          													<div className="rounded-3xs bg-black-black-400 border-black-black-100 border-[1px] border-solid flex flex-row items-start justify-start p-[12.7px]">
                            														<img className="w-6 relative h-6" alt="" src="assets/search.svg" />
                          													</div>
                          													<b className="relative">04</b>
                        												</div>
                      											</div>
                    										</div>
                  									</div>
                  									</div>
                  									<div className="absolute top-[284px] left-[96px] flex flex-row items-start justify-start gap-6 text-left text-design-light-mode-primary-primary-500">
                    										<div className="w-[612px] flex flex-col items-start justify-start gap-10">
                      											<div className="self-stretch flex flex-col items-start justify-start gap-2.5">
                        												<div className="rounded-81xl bg-design-light-mode-primary-primary-50 border-design-light-mode-primary-primary-200 border-[1px] border-solid flex flex-row items-center justify-start py-1 px-3 text-center">
                          													<div className="relative font-semibold">What’s new?</div>
                            														</div>
                            														<b className="self-stretch relative text-[60px] text-neutrals-neutral-900">Streamline Your Debt Collection Process</b>
                            														<div className="self-stretch relative text-xl text-neutrals-neutral-300">Efficient. Automated. Effective.</div>
                            														</div>
                            														<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-primary-primary-500 flex flex-row items-center justify-center py-4 px-[22px] cursor-pointer text-lg text-neutrals-neutral-50" onClick={onCTAsContainerClick}>
                              															<div className="flex flex-row items-center justify-center">
                                																<div className="relative font-semibold">Get started</div>
                              															</div>
                            														</div>
                            														</div>
                            														<div className="w-[612px] relative h-[431px]">
                              															<div className="absolute top-[50px] left-[103px] [filter:blur(100px)] rounded-3xs bg-neutrals-neutral-200 w-[406px] h-[381px]" />
                              															<div className="absolute top-[0px] left-[0px] rounded-3xs w-[612px] h-[420px]">
																							<video class="h-auto w-full relative rounded-3xs" controls>
																								<source src='assets/Think-AI.mp4' type='video/mp4'>
																								</source>
																							</video>
																						</div>
                            														</div>
                            														</div>
                            														<div className="absolute top-[4064.47px] left-[0px] w-full flex flex-col items-start justify-start text-13xl">
                              															<div className="self-stretch flex flex-col items-start justify-start gap-24">
                                																<div className="self-stretch flex flex-col items-center justify-start gap-16">
                                  																	<div className="flex flex-col items-end justify-start gap-2.5">
                                    																		<b className="relative">Frequently Asked Questions</b>
                                    																		<div className="relative text-base text-neutrals-neutral-300">Everything you need to know about the product and billing.</div>
                                  																	</div>
                                  																	<div className="w-full flex flex-col items-center justify-start py-0 px-8 box-border text-left text-lg">
                                    																		<div className="w-[824px] flex flex-row items-start justify-between p-6 box-border">
                                      																			<div className="flex-1 flex flex-col items-start justify-start gap-2.5">
                                        																				<div className="relative font-semibold">Is there a free trial available?</div>
                                          																					<div className="self-stretch relative text-base text-neutrals-neutral-300">Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalised 30-minute onboarding call to get you up and running as soon as possible.</div>
                                          																					</div>
                                          																					<img className="w-6 relative h-6 object-contain" alt="" src="assets/add.svg" />
                                          																					</div>
                                          																					<div className="w-[824px] border-neutrals-neutral-100 border-b-[1px] border-solid box-border flex flex-row items-start justify-between p-6">
                                            																						<div className="flex-1 flex flex-col items-start justify-start">
                                              																							<div className="relative font-semibold">Can I change my plan later?</div>
                                                																								</div>
                                                																								<img className="w-6 relative h-6" alt="" src="assets/add.svg" />
                                                																								</div>
                                                																								<div className="w-[824px] border-neutrals-neutral-100 border-b-[1px] border-solid box-border flex flex-row items-start justify-between p-6">
                                                  																									<div className="flex-1 flex flex-col items-start justify-start">
                                                    																										<div className="relative font-semibold">How does billing work?</div>
                                                      																											</div>
                                                      																											<img className="w-6 relative h-6" alt="" src="assets/add.svg" />
                                                      																											</div>
                                                      																											<div className="w-[824px] border-neutrals-neutral-100 border-b-[1px] border-solid box-border flex flex-row items-start justify-between p-6">
                                                        																												<div className="flex-1 flex flex-col items-start justify-start">
                                                          																													<div className="relative font-semibold">How do I change my account email?</div>
                                                            																														</div>
                                                            																														<img className="w-6 relative h-6" alt="" src="assets/add.svg" />
                                                            																														</div>
                                                            																														<div className="w-[824px] border-neutrals-neutral-100 border-b-[1px] border-solid box-border flex flex-row items-start justify-between p-6">
                                                              																															<div className="flex-1 flex flex-col items-start justify-start">
                                                                																																<div className="relative font-semibold">Can I change my plan later?</div>
                                                                  																																	</div>
                                                                  																																	<img className="w-6 relative h-6" alt="" src="assets/add.svg" />
                                                                  																																	</div>
                                                                  																																	</div>
                                                                  																																	</div>
                                                                  																																	<div className="self-stretch bg-bg-primary1 flex flex-col items-center justify-center py-24 px-0 gap-16 text-neutrals-neutral-50">
                                                                    																																		<div className="self-stretch flex flex-col items-center justify-center gap-2.5">
                                                                      																																			<b className="relative">Register Your Interest</b>
                                                                      																																			<div className="relative text-base text-neutrals-neutral-100">Pre register now and get all the features.</div>
                                                                    																																		</div>
                                                                    																																		<div className="rounded-3xs flex flex-col items-end justify-end gap-10 text-left text-sm">
                                                                      																																			<div className="self-stretch flex flex-col items-start justify-start gap-6">
                                                                        																																				<div className="flex flex-col items-start justify-start gap-2.5">
																																																							<NameInput
																																																								title='Name'
																																																								placeholder='John Doe'
																																																								value={name}
																																																								onChange={handleNameChange}
																																																								width='400px'
																																																								validationMessage='Please provide your full name.'
																																																								iconColor='#D93C3D'
																																																								iconSize='20px'
																																																								apiEndpoint='https://example.com/api/save-name'
																																																								bgColorLight='#FFFFFF'
																																																								bgColorDark='#0C111D'
																																																								isDarkMode={isDarkMode}
																																																							/>

                                                                          																																					{/* <div className="flex flex-row items-center justify-start gap-1.5">
                                                                            																																						<div className="relative font-semibold">Name</div>
                                                                            																																						<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg" />
                                                                          																																					</div>
                                                                          																																					<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-bg-primary1 border-border border-[1px] border-solid box-border overflow-hidden flex flex-row items-center justify-start p-3 text-base text-text-secondary">
                                                                            																																						<div className="relative">John Doe</div>
                                                                          																																					</div> */}
                                                                        																																				</div>
                                                                        																																				<div className="flex flex-col items-start justify-start gap-2.5">
																																																							{/* <EmailInputField/> */}
                                                                          																																					<div className="flex flex-row items-center justify-start gap-1.5">
                                                                            																																						<div className="relative font-semibold">Email</div>
                                                                            																																						<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg" />
                                                                          																																					</div>
                                                                          																																					<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-bg-primary1 border-border border-[1px] border-solid box-border overflow-hidden flex flex-row items-center justify-start p-3 text-base text-text-secondary">
                                                                            																																						<div className="relative">name@yourcompany.com</div>
                                                                          																																					</div>
                                                                        																																				</div>
                                                                        																																				<div className="w-[400px] flex flex-col items-start justify-start gap-2.5">
                                                                          																																					<div className="flex flex-row items-center justify-start gap-1.5">
                                                                            																																						<div className="relative font-semibold">Company name</div>
                                                                            																																						<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg" />
                                                                          																																					</div>
                                                                          																																					<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-bg-primary1 border-border border-[1px] border-solid box-border overflow-hidden flex flex-row items-center justify-start p-3 text-base text-text-secondary">
                                                                            																																						<div className="flex-1 relative">Think-AI</div>
                                                                          																																					</div>
                                                                        																																				</div>
                                                                        																																				<div className="w-[400px] flex flex-col items-start justify-start gap-2.5">
                                                                          																																					<div className="flex flex-row items-start justify-start gap-1.5">
                                                                            																																						<div className="relative font-semibold">Do let us know about your business</div>
                                                                              																																							<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg" />
                                                                            																																						</div>
                                                                            																																						<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-bg-primary1 border-border border-[1px] border-solid box-border flex flex-row items-end justify-start py-3.5 px-2.5 gap-[175px] text-base text-text-secondary">
                                                                              																																							<div className="flex-1 relative">Select type</div>
                                                                              																																							<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="keyboard_arrow_down.svg" />
                                                                            																																						</div>
                                                                          																																					</div>
                                                                        																																				</div>
                                                                        																																				<div className="w-[188px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-primary-primary-500 flex flex-row items-center justify-center py-2.5 px-3.5 box-border">
                                                                          																																					<div className="flex flex-row items-center justify-center">
                                                                            																																						<div className="relative font-semibold">Submit</div>
                                                                          																																					</div>
                                                                        																																				</div>
                                                                      																																			</div>
                                                                    																																		</div>
                                                                  																																	</div>
                                                                  																																	<div className="self-stretch bg-bg-primary1 flex flex-col items-start justify-start py-16 px-24 gap-16 text-left text-base text-neutrals-neutral-50">
                                                                    																																		<div className="flex flex-col items-start justify-start gap-8">
                                                                      																																			<img className="w-[130px] relative h-10 object-cover" alt="" src="assets/logo-horizontal-white.svg" />
                                                                      																																			<div className="flex flex-row items-center justify-start gap-8">
                                                                        																																				<div className="flex flex-row items-start justify-start">
                                                                          																																					<div className="relative font-semibold">Home</div>
                                                                        																																				</div>
                                                                        																																				<div className="flex flex-row items-start justify-start">
                                                                          																																					<div className="relative font-semibold">How it works</div>
                                                                        																																				</div>
                                                                        																																				<div className="flex flex-row items-start justify-start">
                                                                          																																					<div className="relative font-semibold">Features</div>
                                                                        																																				</div>
                                                                        																																				<div className="flex flex-row items-start justify-start">
                                                                          																																					<div className="relative font-semibold">Testimonial</div>
                                                                        																																				</div>
                                                                        																																				<div className="flex flex-row items-start justify-start">
                                                                          																																					<div className="relative font-semibold">Pricing</div>
                                                                        																																				</div>
                                                                      																																			</div>
                                                                    																																		</div>
                                                                    																																		<div className="border-neutrals-neutral-100 border-t-[1px] border-solid flex flex-row items-start justify-start pt-8 px-0 pb-0 gap-[858px] text-neutrals-neutral-100">
                                                                      																																			<div className="relative">© 2024 Think-AI. All rights reserved.</div>
                                                                      																																			<div className="flex flex-row items-start justify-end gap-6 text-right">
                                                                        																																				<div className="relative">Terms</div>
                                                                        																																				<div className="relative">Privacy</div>
                                                                      																																			</div>
                                                                    																																		</div>
                                                                  																																	</div>
                                                                  																																	</div>
                                                                  																																	<div className="absolute top-[2603px] left-[0px] bg-column-bg w-full flex flex-row items-center justify-between p-24 box-border text-left text-primary-primary-500">
                                                                    																																		<div className="w-[506px] flex flex-col items-start justify-start gap-10">
                                                                      																																			<div className="self-stretch flex flex-col items-start justify-start gap-2.5">
                                                                        																																				<div className="rounded-81xl bg-primary-primary-50 border-primary-primary-200 border-[1px] border-solid flex flex-row items-center justify-start py-1 px-3 text-center">
                                                                          																																					<div className="relative font-semibold">Work in progress</div>
                                                                        																																				</div>
                                                                        																																				<b className="self-stretch relative text-13xl text-neutrals-neutral-900">Getting things ready for you</b>
                                                                        																																				<div className="self-stretch relative text-neutrals-neutral-300">We will be launching it live soon.</div>
                                                                      																																			</div>
                                                                      																																			<div className="self-stretch flex flex-row items-center justify-start gap-6 text-sm text-neutrals-neutral-900">
																																																						<CTAs
																																																						type="Primarybtn"
																																																						text="Learn more"
																																																						textColor='text-neutrals-neutral-900'
																																																						bgColor='bg-neutrals-neutral-50'
																																																						borderColor='border-neutrals-neutral-100'
																																																						width='188px'
																																																						position='relative'
																																																						onClick={''}
																																																						/>
                                                                        																																				{/* <div className="w-[188px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 border-neutrals-neutral-100 border-[1px] border-solid box-border flex flex-row items-center justify-center py-2.5 px-3.5">
                                                                          																																					<div className="flex flex-row items-center justify-center">
                                                                            																																						<div className="relative font-semibold">Learn more</div>
                                                                          																																					</div>
                                                                        																																				</div> */}
																																																						<CTAs
																																																						type="Primarybtn"
																																																						text="Get started"
																																																						textColor='text-neutrals-neutral-50'
																																																						bgColor='bg-primary-primary-500'
																																																						borderColor='border-primary-primary-500'
																																																						width='188px'
																																																						position='relative'
																																																						onClick={''}
																																																						
																																																						/>
                                                                        																																				{/* <div className="w-[188px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-primary-primary-500 flex flex-row items-center justify-center py-2.5 px-3.5 box-border text-neutrals-neutral-50">
                                                                          																																					<div className="flex flex-row items-center justify-center">
                                                                            																																						<div className="relative font-semibold">Get started</div>
                                                                          																																					</div>
                                                                        																																				</div> */}
                                                                      																																			</div>
                                                                    																																		</div>
                                                                    																																		<img className="w-[614px] relative rounded-3xs h-[365px] object-cover" alt="" src="assets/snapshot.png " />
                                                                  																																	</div>
                                                                  																																	</div>);
                                                                																																};
                                                                																																
                                                                																																export default LandingPage;
                                                                																																