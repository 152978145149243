import { useState, useCallback } from "react";
import { Navigate, useNavigate } from "react-router-dom";

const NewPayment2 = () => {
    return (
          <div className="relative shadow-[0px_10px_60px_rgba(0,_0,_0,_0.1)] rounded-3xs bg-neutrals-neutral-50 box-border w-full flex flex-col items-start justify-start text-left text-base text-bg-primary font-open-sans-ff-meta-strong border-[1px] border-solid border-neutrals-neutral-100">
                <div className="flex flex-col items-start justify-start pt-6 px-6 pb-0">
                      <div className="rounded-3xs overflow-hidden flex flex-col items-start justify-start py-8 px-[58px] bg-[url('Frame_1239.png')] bg-cover bg-no-repeat bg-[top]">
                            <div className="w-[316px] shadow-[0px_10px_60px_rgba(0,_0,_0,_0.1)] rounded-3xs bg-gray box-border h-[190px] flex flex-col items-start justify-start p-4 gap-[90px] border-[1px] border-solid border-bg-primary">
                                  <div className="self-stretch flex flex-row items-center justify-between">
                                        <div className="relative font-semibold">Debit Card</div>
                                        <img className="w-[10.6px] relative h-[22px]" alt="" src="Vector.svg" />
                                  </div>
                                  <div className="w-[178px] flex flex-row flex-wrap items-start justify-between text-xs">
                                        <div className="relative">PARAS MISTRY</div>
                                        <div className="relative font-semibold">06/24</div>
                                        <div className="flex flex-row items-start justify-start gap-[10px] text-base">
                                              <div className="relative font-semibold">1234</div>
                                              <div className="relative font-semibold">1234</div>
                                              <div className="relative font-semibold">1234</div>
                                              <div className="relative font-semibold">1234</div>
                                        </div>
                                  </div>
                            </div>
                      </div>
                </div>
                <div className="flex flex-col items-start justify-start gap-[40px] text-lg text-neutrals-neutral-900">
                      <div className="flex flex-col items-start justify-start gap-[24px]">
                            <div className="w-[480px] flex flex-col items-start justify-start pt-6 px-6 pb-0 box-border gap-[6px]">
                                  <div className="relative font-semibold">Billing address</div>
                                  <div className="relative text-sm text-neutrals-neutral-300">Enter your billing address.</div>
                            </div>
                            <div className="flex flex-col items-start justify-start py-0 px-6 gap-[16px] text-sm">
                                  <div className="w-[435px] flex flex-row items-start justify-start">
                                        <div className="w-[435px] flex flex-col items-start justify-start gap-[10px]">
                                              <div className="flex flex-row items-center justify-start gap-[6px]">
                                                    <div className="relative font-semibold">Address</div>
                                                    <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="icons-v2.svg" />
                                              </div>
                                              <div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 overflow-hidden flex flex-row items-center justify-start p-3 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
                                                    <div className="relative">Enter your address</div>
                                              </div>
                                        </div>
                                  </div>
                                  <div className="self-stretch flex flex-row items-start justify-start">
                                        <div className="w-[435px] flex flex-col items-start justify-start gap-[10px]">
                                              <div className="flex flex-row items-center justify-start gap-[6px]">
                                                    <div className="relative font-semibold">Postcode</div>
                                                    <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="icons-v2.svg" />
                                              </div>
                                              <div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 overflow-hidden flex flex-row items-center justify-start p-3 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
                                                    <div className="relative">Enter your postcode</div>
                                              </div>
                                        </div>
                                  </div>
                                  <div className="w-[435px] flex flex-row items-start justify-center">
                                        <div className="w-[435px] h-20 flex flex-col items-start justify-between">
                                              <div className="flex flex-row items-start justify-start gap-[6px]">
                                                    <div className="relative font-semibold">Country</div>
                                                    <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="icons-v2.svg" />
                                              </div>
                                              <div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-end justify-start py-3.5 px-2.5 gap-[223px] text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
                                                    <div className="w-[165px] relative inline-block shrink-0">Select country</div>
                                                    <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="keyboard_arrow_down.svg" />
                                              </div>
                                        </div>
                                  </div>
                            </div>
                      </div>
                      <div className="flex flex-row items-start justify-start pt-0 px-6 pb-6 gap-[12px] text-sm">
                            <div className="w-[210px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
                                  <div className="flex flex-row items-center justify-center">
                                        <div className="relative font-semibold">Cancel</div>
                                  </div>
                            </div>
                            <div className="w-[210px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-primary-primary-500 flex flex-row items-center justify-center py-2.5 px-3.5 box-border text-bg-primary">
                                  <div className="flex flex-row items-center justify-center">
                                        <div className="relative font-semibold">Continue</div>
                                  </div>
                            </div>
                      </div>
                </div>
          </div>);
};

export default NewPayment2;