import { useCallback, useState } from "react";
import CTAs from "./CTAs";

const FeesFilter = () => {
    return (
          <div className="relative shadow-[0px_10px_60px_rgba(0,_0,_0,_0.1)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start gap-[24px] max-w-full max-h-full overflow-auto text-left text-base text-primary-primary-500 font-open-sans-ff-meta border-[1px] border-solid border-neutrals-neutral-100">
                <div className="self-stretch flex flex-row items-start justify-start pt-6 px-4 pb-0 gap-[24px]">
                      <div className="bg-neutrals-neutral-50 box-border h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 border-b-[2px] border-solid border-primary-primary-500">
                            <div className="flex flex-row items-center justify-center">
                                  <div className="relative font-semibold">Solicitor fees</div>
                            </div>
                      </div>
                      <div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 box-border text-neutrals-neutral-300">
                            <div className="flex flex-row items-center justify-center">
                                  <div className="relative font-semibold">Court fees</div>
                            </div>
                      </div>
                </div>
                <div className="flex flex-col items-start justify-start gap-[40px] text-sm text-neutrals-neutral-900">
                      <div className="flex flex-col items-start justify-start py-0 px-4 gap-[24px]">
                            <div className="flex flex-row items-start justify-start gap-[6px]">
                                  <div className="relative font-semibold">Price</div>
                                  <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg"/>
                            </div>
                            <div className="w-[400px] relative h-[52px] text-right text-base text-neutrals-neutral-300">
                                  <div className="absolute h-[42.31%] w-full top-[57.69%] right-[0%] bottom-[0%] left-[0%] flex flex-row items-center justify-between">
                                        <div className="relative text-left">£0</div>
                                        <div className="w-[5px] relative font-semibold font-inter-family-font-body-strong hidden">.</div>
                                        <div className="relative">£10,000</div>
                                  </div>
                                  <img className="absolute h-[46.15%] w-full top-[0%] right-[0%] bottom-[53.85%] left-[0%] max-w-full overflow-hidden max-h-full" alt="" src="Group 152.svg" />
                            </div>
                      </div>
                      <div className="flex flex-row items-start justify-start pt-4 px-4 pb-6 gap-[24px]">
                        <CTAs
                        type="Ghostbtn"
                        text="Cancel"
                        textColor="text-neutrals-neutral-900"
                        bgColor="bg-neutrals-neutral-50"
                        borderColor="border-neutrals-neutral-100"
                        width="188px"
                        position="relative"
                        />

                         <CTAs
                        type="Primarybtn"
                        text="Apply"
                        textColor="text-neutrals-neutral-50"
                        bgColor="bg-primary-primary-500"
                        borderColor="border-primary-primary-500"
                        width="188px"
                        position="relative"
                        />
                      </div>
                </div>
          </div>);
};

export default FeesFilter;