import { useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import CTAs from '../../components/CTAs';
import Dropdown from '../../components/Dropdown';
import AddressInputField from '../../components/AddressInputField';

const RegisterPage = () => {
	const navigate = useNavigate();
  	
  	const onCTAsContainerClick = useCallback(() => {
		navigate('/documentupload')
  	}, []);
  	
  	const onLogInTextClick = useCallback(() => {
		navigate('/login')
  	}, []);

	const dropdownContents = ["Individuals", "Traders", "Limited companies", "Charities"];
	const dropdownContents2 = ["Goods sold and delivered", "Services rendered (90% of claims)", "Leasehold issues", "Charities", "Engineering disputes"];
  	
  	return (
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1024px] overflow-hidden text-left text-sm text-neutrals-neutral-900 font-open-sans-ff-body">
      			<div className="absolute top-[209px] left-[592px] flex flex-col items-center justify-start gap-[10px] text-center text-9xl">
        				<b className="relative">Create an account</b>
        				<div className="relative text-base text-neutrals-neutral-300">Register to your account</div>
      			</div>
      			<div className="absolute top-[551px] left-[520px] flex flex-col items-start justify-start gap-[10px]">
					{/* <AddressInputField/> */}
        				<div className="flex flex-row items-center justify-start gap-[6px]">
          					<div className="relative font-semibold">Address</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg" />
        				</div>
						
        				<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border overflow-hidden flex flex-row items-center justify-start p-3 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="relative">Enter your address</div>
        				</div>
      			</div>
      			<div className="absolute top-[651px] left-[520px] flex flex-col items-start justify-start gap-[10px]">
        				<div className="flex flex-row items-center justify-start gap-[6px]">
          					<div className="relative font-semibold">Postcode</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg" />
        				</div>
        				<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border overflow-hidden flex flex-row items-center justify-start p-3 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="relative">Enter your postcode</div>
        				</div>
      			</div>
      			<div className="absolute top-[447px] left-[520px] w-[400px] flex flex-col items-start justify-start gap-[10px]">
					<Dropdown
					title="What’s your claim for?"
					icon="assets/help-circle.svg"
					placeholder="Select categories"
					dropdownContents={dropdownContents2}
					width="400px"
					/>
            			</div>

            						<div className="absolute top-[767px] left-[520px] flex flex-col items-center justify-start gap-[24px] text-neutrals-neutral-50">
              							<div className="self-stretch flex flex-col items-center justify-start gap-[16px]">
										<CTAs
										type="Primarybtn"
										text="Continue"
										textColor="text-neutrals-neutral-50"
										bgColor="bg-primary-primary-500"
										borderColor="border-primary-primary-500"
										width="400px"
										position="relative"
										onClick={onCTAsContainerClick}
										/>

										<CTAs
										type="Googlebtn"
										text="Sign up with Google"
										textColor="text-neutrals-neutral-900"
										bgColor="bg-neutrals-neutral-50"
										borderColor="border-neutrals-neutral-100"
										icon="assets/google.svg"
										width="400px"
										position="relative"
										/>

              							</div>
              							<div className="flex flex-row items-start justify-center gap-[6px] text-neutrals-neutral-300">
                								<div className="relative">Already have an account?</div>
                  									<div className="relative font-semibold text-primary-primary-500 cursor-pointer" onClick={onLogInTextClick}>Log in</div>
                  									</div>
                  									</div>
                  									<div className="absolute top-[941px] right-[96px] text-neutrals-neutral-300">Copyright © Think-AI 2023</div>
                  									<div className="absolute top-[343px] left-[520px] w-[400px] flex flex-col items-start justify-start gap-[10px]">
														<Dropdown
														title="Client type"
														placeholder="Select client type"
														dropdownContents={dropdownContents}
														width="400px"
														icon="assets/help-circle.svg"
														/>
                    										{/* <div className="flex flex-row items-start justify-start gap-[6px]">
                      											<div className="relative font-semibold">Client type</div>
                      											<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg" />
                    										</div>
                    										<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-end justify-start py-3.5 px-2.5 gap-[175px] text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
                      											<div className="w-[162px] relative inline-block shrink-0">Select client type</div>
                      											<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/arrow-down.svg"/>
                    										</div> */}
                  									</div>
                  									<img className="absolute top-[118px] left-[calc(50%_-_30px)] w-[60px] h-[67px] object-cover" alt="" src="assets/logo-vertical.svg" />
                  									</div>);
                								};
                								
                								export default RegisterPage;