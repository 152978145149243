import { useState, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import CustomiseReport from "../../components/CustomiseReport";
import PortalPopup from "../../components/PortalPopup";
import ExportModalImage from "../../components/ExportModalImage";
import LogoutConfirmation from "../../components/LogoutConfirmation";
import DebtCollectionNav from '../../components/DebtCollectionNav';
import NavigationPanel from '../../components/NavigationPanel';
import CTAs from '../../components/CTAs';

const OutcomesAndCostsPage_5 = () => {
    const navigate = useNavigate();
  	const [isCustomiseReportOpen, setCustomiseReportOpen] = useState(false);
  	const [isExportModalImageOpen, setExportModalImageOpen] = useState(false);
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	const [isExportModalImage1Open, setExportModalImage1Open] = useState(false);
  	
  	const onClickOutcomeUpdate = useCallback(() => {
		navigate('/outcome&cost');
  	}, [navigate]);
  	
  	const onClickCostImplication = useCallback(() => {
		navigate('/outcome&cost2');
  	}, [navigate]);
  	
  	const onClickJudgementPhase = useCallback(() => {
		navigate('/outcome&cost3');
  	}, [navigate]);
  	
  	const onClickClientCommunication = useCallback(() => {
		navigate('/outcome&cost4');
  	}, [navigate]);
  	
  	const openCustomiseReport = useCallback(() => {
    		setCustomiseReportOpen(true);
  	}, []);
  	
  	const closeCustomiseReport = useCallback(() => {
    		setCustomiseReportOpen(false);
  	}, []);
  	
  	const openExportModalImage = useCallback(() => {
    		setExportModalImageOpen(true);
  	}, []);
  	
  	const closeExportModalImage = useCallback(() => {
    		setExportModalImageOpen(false);
  	}, []);
  	
  	const openLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(true);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);
  	
  	const openExportModalImage1 = useCallback(() => {
    		setExportModalImage1Open(true);
  	}, []);
  	
  	const closeExportModalImage1 = useCallback(() => {
    		setExportModalImage1Open(false);
  	}, []);

	const navItems = [
		{
			text: 'Debt collection', 
    		bgColor: 'bg-neutrals-neutral-50', 
    		textColor: 'text-neutrals-neutral-300', 
    		hoverBgColor: 'bg-neutrals-neutral-50', 
    		hoverTextColor: 'text-primary-primary-500', 
    		path: "",
    		icon: 'assets/arrow-down.svg'
		},

		{
			text: "Outcomes & costs",
			bgColor: 'bg-primary-primary-50',
    		textColor: 'text-neutrals-neutral-900', 
    		hoverBgColor: "bg-primary-primary-50", 
    		hoverTextColor: "text-neutrals-neutral-900", 
    		path: "",
    		icon: ""
		},

		{
			text: "AI indicators",
			bgColor: 'bg-neutrals-neutral-50', 
    		textColor: 'text-neutrals-neutral-300', 
    		hoverBgColor: 'bg-neutrals-neutral-50', 
    		hoverTextColor: 'text-primary-primary-500', 
    		path: "/ai1",
    		icon: ''
		},
	];
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1775px] overflow-hidden text-left text-base text-neutrals-neutral-900 font-open-sans-ff-body-strong">
      			<div className="absolute top-[1692px] right-[96px] text-sm text-neutrals-neutral-300">Copyright © Think-AI 2023</div>
      			<b className="absolute top-[128px] left-[96px] text-9xl">{`Outcomes & costs`}</b>
      			<div className="absolute top-[190px] left-[96px] flex flex-row items-start justify-start gap-[24px] text-neutrals-neutral-300">
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 box-border cursor-pointer" onClick={onClickOutcomeUpdate}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Outcome updates</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 box-border cursor-pointer" onClick={onClickCostImplication}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Cost implications</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 box-border cursor-pointer" onClick={onClickJudgementPhase}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Judgement phase</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 box-border cursor-pointer" onClick={onClickClientCommunication}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Client communication</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 box-border h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 text-primary-primary-500 border-b-[2px] border-solid border-primary-primary-500">
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">{`Reporting & analytics`}</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[255px] left-[520px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border w-[400px] flex flex-col items-start justify-start p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-center justify-between">
          					<div className="relative font-semibold">Response time</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg"/>
        				</div>
        				<div className="self-stretch h-20 flex flex-col items-start justify-between text-13xl">
          					<b className="relative">2 days</b>
          					<div className="flex flex-row items-center justify-start gap-[10px] text-sm text-semantics-low-low-700">
            						<div className="flex flex-row items-center justify-start gap-[6px]">
              							<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="icons-v1.svg" />
              							<div className="relative">40%</div>
            						</div>
            						<div className="relative font-semibold text-neutrals-neutral-300">vs last month</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[255px] left-[96px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border w-[400px] flex flex-col items-start justify-start p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-center justify-between">
          					<div className="relative font-semibold">Recovery rates</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg"/>
        				</div>
        				<div className="self-stretch h-20 flex flex-col items-start justify-between text-13xl">
          					<b className="relative">75%</b>
          					<div className="flex flex-row items-center justify-start gap-[10px] text-sm text-semantics-low-low-700">
            						<div className="flex flex-row items-center justify-start gap-[6px]">
              							<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="icons-v1.svg" />
              							<div className="relative">20%</div>
            						</div>
            						<div className="relative font-semibold text-neutrals-neutral-300">vs last month</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[255px] left-[944px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border w-[400px] flex flex-col items-start justify-start p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-center justify-between">
          					<div className="relative font-semibold">Total claims</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg"/>
        				</div>
        				<div className="self-stretch h-20 flex flex-col items-start justify-between text-13xl">
          					<b className="relative">100</b>
          					<div className="flex flex-row items-center justify-start gap-[10px] text-sm text-semantics-low-low-700">
            						<div className="flex flex-row items-center justify-start gap-[6px]">
              							<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="icons-v1.svg" />
              							<div className="relative">15%</div>
            						</div>
            						<div className="relative font-semibold text-neutrals-neutral-300">vs last month</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[1467px] left-[96px] flex flex-col items-start justify-start gap-[24px] text-lg">
        				<div className="w-[1248px] box-border flex flex-row items-center justify-start pt-0 px-0 pb-6 border-b-[1px] border-solid border-neutrals-neutral-100">
          					<div className="relative font-semibold">Customisable reports</div>
        				</div>
        				<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-start p-5 gap-[10px] cursor-pointer text-base border-[1px] border-solid border-neutrals-neutral-100" onClick={openCustomiseReport}>
          					<div className="rounded-3xs bg-primary-primary-500 flex flex-row items-start justify-start p-3">
            						<img className="w-6 relative h-6" alt="" src="assets/document.svg" style={{ filter: 'brightness(0) invert(1)' }} />
          					</div>
          					<div className="flex-1 flex flex-col items-start justify-start gap-[6px]">
            						<div className="self-stretch relative font-semibold">Generate your report</div>
            						<div className="self-stretch relative text-sm text-neutrals-neutral-300">Craft your comprehensive report with ease</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[940px] left-[96px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-[463px] flex flex-col items-start justify-start p-6 gap-[24px] text-lg border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="w-[404px] box-border flex flex-row items-center justify-between pt-0 px-0 pb-5 border-b-[1px] border-solid border-neutrals-neutral-100">
          					<div className="relative font-semibold">Claim status</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
        				</div>
        				<div className="flex flex-row items-start justify-start gap-[24px] text-sm">
          					<img className="w-[280px] relative h-[280px]" alt="" src="Group 224.svg" />
          					<div className="flex flex-col items-start justify-start gap-[6px]">
            						<div className="flex flex-row items-center justify-start gap-[6px]">
              							<div className="w-2 relative rounded-[50%] bg-primary-primary-500 h-2" />
              							<div className="relative">Successful</div>
            						</div>
            						<div className="flex flex-row items-center justify-start gap-[6px]">
              							<div className="w-2 relative rounded-[50%] bg-primary-primary-50 h-2" />
              							<div className="relative">Unsuccessful</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[940px] left-[572px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border w-[772px] h-[463px] flex flex-col items-start justify-between p-6 text-sm border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-center justify-between text-lg">
          					<div className="relative font-semibold">Solicitor and time period</div>
							<CTAs
							type="Ghostbtn"
							text="Export"
							textColor="text-neutrals-neutral-900"
							bgColor="bg-neutrals-neutral-50"
							borderColor="border-neutrals-neutral-100"
							icon="assets/export.svg"
							position="relative"
							onClick={openExportModalImage}
							/>
        				</div>
        				<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-start border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-solid border-neutrals-neutral-100">
            						<div className="relative font-semibold">12 months</div>
          					</div>
          					<div className="flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-solid border-neutrals-neutral-100">
            						<div className="relative font-semibold">30 days</div>
          					</div>
          					<div className="bg-column-bg flex flex-row items-center justify-center py-2 px-4 border-solid border-neutrals-neutral-100 border-r-[1px]">
            						<div className="relative font-semibold">7 days</div>
          					</div>
          					<div className="flex flex-row items-center justify-center py-2 px-4">
            						<div className="flex flex-row items-center justify-center gap-[6px]">
              							<img className="w-4 relative h-4" alt="" src="assets/add.svg" />
              							<div className="relative font-semibold">Custom</div>
            						</div>
          					</div>
        				</div>
        				<div className="self-stretch h-[292px] flex flex-col items-start justify-between text-neutrals-neutral-300">
          					<div className="flex flex-row items-start justify-start gap-[10px]">
            						<div className="flex flex-row items-center justify-start gap-[6px]">
              							<div className="w-2 relative rounded-[50%] bg-primary-primary-50 h-2" />
              							<div className="relative">Analysis 1</div>
            						</div>
            						<div className="flex flex-row items-center justify-start gap-[6px]">
              							<div className="w-2 relative rounded-[50%] bg-primary-primary-500 h-2" />
              							<div className="relative">Analysis 2</div>
            						</div>
            						<div className="flex flex-row items-center justify-start gap-[6px]">
              							<div className="w-2 relative rounded-[50%] bg-primary-primary-900 h-2" />
              							<div className="relative">Analysis 3</div>
            						</div>
          					</div>
          					<div className="self-stretch h-[249px] flex flex-col items-start justify-between text-center text-neutrals-neutral-900">
            						<div className="self-stretch flex flex-row items-center justify-start gap-[10px]">
              							<div className="flex flex-row items-center justify-start gap-[10px]">
                								<div className="relative font-semibold [transform:_rotate(-90deg)]">Accuracy percentage</div>
                								<div className="h-[201px] flex flex-col items-end justify-start gap-[11px] text-left text-neutrals-neutral-300">
                  									<div className="relative">60</div>
                  									<div className="relative">50</div>
                  									<div className="relative">40</div>
                  									<div className="relative">30</div>
                  									<div className="relative">20</div>
                  									<div className="relative">10</div>
                  									<div className="relative">0</div>
                								</div>
              							</div>
              							<div className="flex-1 flex flex-col items-start justify-start relative gap-[10px]">
                								<div className="self-stretch flex flex-col items-start justify-start gap-[30px] z-[0]">
                  									<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                								</div>
                								<div className="w-[589px] !m-[0] absolute top-[44.5px] left-[47px] flex flex-row items-end justify-between z-[1]">
                  									<div className="w-8 relative h-[115px]">
                    										<div className="absolute top-[0px] left-[0px] rounded-t-3xs rounded-b-none bg-primary-primary-50 w-8 h-[115px]" />
                    										<div className="absolute top-[30px] left-[0px] rounded-t-3xs rounded-b-none bg-primary-primary-500 w-8 h-[85px]" />
                    										<div className="absolute top-[60px] left-[0px] rounded-t-3xs rounded-b-none bg-primary-primary-900 w-8 h-[55px]" />
                  									</div>
                  									<div className="w-8 relative h-[100px]">
                    										<div className="absolute top-[0px] left-[0px] rounded-t-3xs rounded-b-none bg-primary-primary-50 w-8 h-[100px]" />
                    										<div className="absolute top-[30.63px] left-[0px] rounded-t-3xs rounded-b-none bg-primary-primary-500 w-8 h-[69.4px]" />
                    										<div className="absolute top-[56.25px] left-[0px] rounded-t-3xs rounded-b-none bg-primary-primary-900 w-8 h-[43.8px]" />
                  									</div>
                  									<div className="w-8 relative h-[135px]">
                    										<div className="absolute top-[0px] left-[0px] rounded-t-3xs rounded-b-none bg-primary-primary-50 w-8 h-[135px]" />
                    										<div className="absolute top-[35.22px] left-[0px] rounded-t-3xs rounded-b-none bg-primary-primary-500 w-8 h-[99.8px]" />
                    										<div className="absolute top-[70.43px] left-[0px] rounded-t-3xs rounded-b-none bg-primary-primary-900 w-8 h-[64.6px]" />
                  									</div>
                  									<div className="w-8 relative h-[85px]">
                    										<div className="absolute top-[0px] left-[0px] rounded-t-3xs rounded-b-none bg-primary-primary-50 w-8 h-[85px]" />
                    										<div className="absolute top-[22.17px] left-[0px] rounded-t-3xs rounded-b-none bg-primary-primary-500 w-8 h-[62.8px]" />
                    										<div className="absolute top-[44.35px] left-[0px] rounded-t-3xs rounded-b-none bg-primary-primary-900 w-8 h-[40.7px]" />
                  									</div>
                  									<div className="w-8 relative h-[100px]">
                    										<div className="absolute top-[0px] left-[0px] rounded-t-3xs rounded-b-none bg-primary-primary-50 w-8 h-[100px]" />
                    										<div className="absolute top-[26.09px] left-[0px] rounded-t-3xs rounded-b-none bg-primary-primary-500 w-8 h-[73.9px]" />
                    										<div className="absolute top-[52.17px] left-[0px] rounded-t-3xs rounded-b-none bg-primary-primary-900 w-8 h-[47.8px]" />
                  									</div>
                  									<div className="w-8 relative h-[115px]">
                    										<div className="absolute top-[0px] left-[0px] rounded-t-3xs rounded-b-none bg-primary-primary-50 w-8 h-[115px]" />
                    										<div className="absolute top-[30px] left-[0px] rounded-t-3xs rounded-b-none bg-primary-primary-500 w-8 h-[85px]" />
                    										<div className="absolute top-[60px] left-[0px] rounded-t-3xs rounded-b-none bg-primary-primary-900 w-8 h-[55px]" />
                  									</div>
                  									<div className="w-8 relative h-[115px]">
                    										<div className="absolute top-[0px] left-[0px] rounded-t-3xs rounded-b-none bg-primary-primary-50 w-8 h-[115px]" />
                    										<div className="absolute top-[30px] left-[0px] rounded-t-3xs rounded-b-none bg-primary-primary-500 w-8 h-[85px]" />
                    										<div className="absolute top-[60px] left-[0px] rounded-t-3xs rounded-b-none bg-primary-primary-900 w-8 h-[55px]" />
                  									</div>
                								</div>
              							</div>
            						</div>
            						<div className="self-stretch h-12 flex flex-col items-center justify-between text-neutrals-neutral-300">
              							<div className="self-stretch flex flex-row items-start justify-start py-0 px-[100px] gap-[69px]">
                								<div className="relative">Mon</div>
                								<div className="relative">Tue</div>
                								<div className="relative">Wed</div>
                								<div className="relative">Thu</div>
                								<div className="relative">Fri</div>
                								<div className="relative">Sat</div>
                								<div className="relative">Sun</div>
              							</div>
              							<div className="relative font-semibold text-neutrals-neutral-900">Days</div>
            						</div>
          					</div>
        				</div>
      			</div>

				{/* Navigation Panel */}
				<NavigationPanel navItems={navItems}/>
      			
      			<div className="absolute top-[453px] left-[96px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start p-6 gap-[24px] text-sm border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="w-[1200px] flex flex-row items-center justify-between text-lg">
          					<div className="relative font-semibold">Defence pattern trends</div>
							<CTAs
							type="Ghostbtn"
							text="Export"
							textColor="text-neutrals-neutral-900"
							bgColor="bg-neutrals-neutral-50"
							borderColor="border-neutrals-neutral-100"
							icon="assets/export.svg"
							position="relative"
							onClick={openExportModalImage1}
							/>
        				</div>
        				<div className="flex flex-col items-start justify-start text-neutrals-neutral-300">
          					<div className="flex flex-col items-end justify-start gap-[24px]">
            						<div className="flex flex-row items-start justify-start gap-[10px]">
              							<div className="flex flex-row items-center justify-start gap-[6px]">
                								<div className="w-2 relative rounded-[50%] bg-primary-primary-500 h-2" />
                								<div className="relative">Pattern 1</div>
              							</div>
              							<div className="flex flex-row items-center justify-start gap-[6px]">
                								<div className="w-2 relative rounded-[50%] bg-primary-primary-300 h-2" />
                								<div className="relative">Pattern 2</div>
              							</div>
              							<div className="flex flex-row items-center justify-start gap-[6px]">
                								<div className="w-2 relative rounded-[50%] bg-primary-primary-700 h-2" />
                								<div className="relative">Pattern 3</div>
              							</div>
            						</div>
            						<div className="flex flex-row items-center justify-start gap-[10px] text-center text-neutrals-neutral-900">
              							<div className="flex flex-row items-center justify-start">
                								<div className="flex flex-row items-center justify-start gap-[10px]">
                  									<div className="relative font-semibold [transform:_rotate(-90deg)]">Response time</div>
                  									<div className="h-[201px] flex flex-col items-end justify-start gap-[11px] text-left text-neutrals-neutral-300">
                    										<div className="relative">60</div>
                    										<div className="relative">50</div>
                    										<div className="relative">40</div>
                    										<div className="relative">30</div>
                    										<div className="relative">20</div>
                    										<div className="relative">10</div>
                    										<div className="relative">0</div>
                  									</div>
                								</div>
              							</div>
              							<div className="flex flex-col items-start justify-start relative gap-[10px]">
                								<div className="w-[1144px] relative h-[180px] z-[0]">
                  									<div className="absolute top-[-0.5px] left-[-0.5px] box-border w-[1145px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="absolute top-[29.5px] left-[-0.5px] box-border w-[1145px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="absolute top-[59.5px] left-[-0.5px] box-border w-[1145px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="absolute top-[89.5px] left-[-0.5px] box-border w-[1145px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="absolute top-[119.5px] left-[-0.5px] box-border w-[1145px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="absolute top-[149.5px] left-[-0.5px] box-border w-[1145px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="absolute top-[179.5px] left-[-0.5px] box-border w-[1145px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                								</div>
                								<img className="w-[1144px] absolute !m-[0] top-[12px] left-[0px] h-[168px] z-[1]" alt="" src="Group 233.svg" />
              							</div>
            						</div>
          					</div>
          					<div className="flex flex-col items-start justify-start gap-[10px] text-center">
            						<div className="w-[1200px] flex flex-row items-start justify-between py-0 pr-0 pl-14 box-border">
              							<div className="relative">Mon</div>
              							<div className="relative">Tue</div>
              							<div className="relative">Wed</div>
              							<div className="relative">Thu</div>
              							<div className="relative">Fri</div>
              							<div className="relative">Sat</div>
              							<div className="relative">Sun</div>
            						</div>
            						<div className="w-[1200px] relative font-semibold text-neutrals-neutral-900 inline-block">Days</div>
          					</div>
        				</div>
        				<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-start">
          					<div className="rounded-tl-3xs rounded-tr-none rounded-br-none rounded-bl-3xs bg-column-bg flex flex-row items-center justify-center py-2 px-4 border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="relative font-semibold">Daily</div>
          					</div>
          					<div className="flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-t-[1px] border-b-[1px] border-solid border-neutrals-neutral-100">
            						<div className="relative font-semibold">Weekly</div>
          					</div>
          					<div className="flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-t-[1px] border-b-[1px] border-solid border-neutrals-neutral-100">
            						<div className="relative font-semibold">Monthly</div>
          					</div>
          					<div className="rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none flex flex-row items-center justify-center py-2 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-b-[1px]">
            						<div className="flex flex-row items-center justify-center gap-[6px]">
              							<img className="w-4 relative h-4" alt="" src="assets/add.svg" />
              							<div className="relative font-semibold">Custom</div>
            						</div>
          					</div>
        				</div>
      			</div>
    		</div>
    		{isCustomiseReportOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeCustomiseReport}
        				>
        				<CustomiseReport onClose={closeCustomiseReport} />
      			</PortalPopup>
    		)}
    		{isExportModalImageOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeExportModalImage}
        				>
        				<ExportModalImage onClose={closeExportModalImage} />
      			</PortalPopup>
    		)}
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}
    		{isExportModalImage1Open && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeExportModalImage1}
        				>
        				<ExportModalImage onClose={closeExportModalImage1} />
      			</PortalPopup>
    		)}</>);
};

export default OutcomesAndCostsPage_5;