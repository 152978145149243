import { useState, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import DownloadModalTables from "../../components/DownloadModalTables";
import PortalPopup from "../../components/PortalPopup";
import DateFilter from "../../components/DateFilter";
import LawFirm from "../../components/LawFirm";
import NavigationDropdown from "../../components/NavigationDropdown";
import NavigationPanel from '../../components/NavigationPanel';
import LogoutConfirmation from "../../components/LogoutConfirmation";
import Breadcrumb from '../../components/Breadcrumb';
import CTAs from '../../components/CTAs';

const LPDPage = () => {
	const navigate = useNavigate();
  	const [isDownloadModalTablesOpen, setDownloadModalTablesOpen] = useState(false);
  	const [isDateFilterOpen, setDateFilterOpen] = useState(false);
  	const [isLawFirmOpen, setLawFirmOpen] = useState(false);
  	const [isLawFirm1Open, setLawFirm1Open] = useState(false);
  	const [isLawFirm2Open, setLawFirm2Open] = useState(false);
  	const [isNavigationDropdownOpen, setNavigationDropdownOpen] = useState(false);
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	
  	const openDownloadModalTables = useCallback(() => {
    		setDownloadModalTablesOpen(true);
  	}, []);
  	
  	const closeDownloadModalTables = useCallback(() => {
    		setDownloadModalTablesOpen(false);
  	}, []);
  	
  	
  	const openDateFilter = useCallback(() => {
    		setDateFilterOpen(true);
  	}, []);
  	
  	const closeDateFilter = useCallback(() => {
    		setDateFilterOpen(false);
  	}, []);
  	
  	
  	const openLawFirm = useCallback(() => {
    		setLawFirmOpen(true);
  	}, []);
  	
  	const closeLawFirm = useCallback(() => {
    		setLawFirmOpen(false);
  	}, []);
  	
  	
  	const openLawFirm1 = useCallback(() => {
    		setLawFirm1Open(true);
  	}, []);
  	
  	const closeLawFirm1 = useCallback(() => {
    		setLawFirm1Open(false);
  	}, []);
  	
  	
  	const openLawFirm2 = useCallback(() => {
    		setLawFirm2Open(true);
  	}, []);
  	
  	const closeLawFirm2 = useCallback(() => {
    		setLawFirm2Open(false);
  	}, []);
  	
  	const openNavigationDropdown = useCallback(() => {
    		setNavigationDropdownOpen(true);
  	}, []);
  	
  	const closeNavigationDropdown = useCallback(() => {
    		setNavigationDropdownOpen(false);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);
  	
  	
  	const onCTAsContainerClick = useCallback(() => {
		navigate('/lpd2');
  	}, [navigate]);

	const navItems = [
		{
			text: 'Debt collection', 
    		bgColor: 'bg-primary-primary-50', 
    		textColor: 'text-neutrals-neutral-900', 
    		hoverBgColor: 'bg-primary-primary-50', 
    		hoverTextColor: 'text-neutrals-neutral-900', 
    		path: "",
    		icon: 'assets/arrow-down.svg'
		},

		{
			text: "Outcomes & costs",
			bgColor: 'bg-neutrals-neutral-50', 
    		textColor: 'text-neutrals-neutral-300', 
    		hoverBgColor: 'bg-neutrals-neutral-50', 
    		hoverTextColor: 'text-primary-primary-500', 
    		path: "/outcome&cost",
    		icon: ''
		},

		{
			text: "AI indicators",
			bgColor: 'bg-neutrals-neutral-50', 
    		textColor: 'text-neutrals-neutral-300', 
    		hoverBgColor: 'bg-neutrals-neutral-50', 
    		hoverTextColor: 'text-primary-primary-500', 
    		path: "/ai1",
    		icon: ''
		},
	];

	const breadcrumb = [
		{ label: "", path: "/postlba2", icon: "assets/home.svg", active: true},
		{ label: "Late Payment Demand (LPD)", path: "", active: false },
	]
  	
  	return (<>
    		<div className="w-full relative bg-wireframes-white h-[1457px] overflow-hidden text-left text-base text-neutrals-neutral-300 font-open-sans-ff-heading-1">
      			<div className="absolute top-[505px] left-[96px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] flex flex-col items-start justify-start text-sm text-neutrals-neutral-900">
        				<div className="w-[1248px] rounded-t-3xs rounded-b-none box-border flex flex-row items-center justify-between py-5 px-6 text-lg border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-l-[1px]">
          					<div className="flex flex-row items-center justify-start gap-[10px]">
            						<div className="relative font-semibold">Generated LPD’s</div>
            						<div className="rounded-[5px] bg-wireframes-white flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="w-1.5 relative rounded-[50%] bg-primary-primary-500 h-1.5" />
              							<div className="relative font-semibold">10 entries</div>
            						</div>
          					</div>
							<CTAs
							type="Ghostbtn"
							text="Download"
							textColor="text-neutrals-neutral-900"
							bgColor="bg-neutrals-neutral-50"
							borderColor="border-neutrals-neutral-100"
							icon="assets/download.svg"
							onClick={openDownloadModalTables}
							position="relative"
							/>
        				</div>
        				<div className="w-[1248px] box-border flex flex-row items-center justify-between py-3 px-6 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-l-[1px]">
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-wireframes-white flex flex-row items-center justify-start border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="rounded-tl-3xs rounded-tr-none rounded-br-none rounded-bl-3xs bg-column-bg flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">View all</div>
            						</div>
            						<div className="flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">Sent</div>
            						</div>
            						<div className="flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">Pending</div>
            						</div>
            						<div className="flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">Overdue</div>
            						</div>
            						<div className="rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none flex flex-row items-center justify-center py-2 px-4">
              							<div className="relative font-semibold">Settled</div>
            						</div>
          					</div>
          					<div className="flex flex-row items-start justify-start gap-[10px] text-neutrals-neutral-300">
            						<div className="w-[294px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-wireframes-white box-border flex flex-row items-center justify-start py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-start gap-[10px]">
                								<img className="w-5 relative h-5" alt="" src="assets/search.svg"/>
                								<div className="relative">Search your LBA</div>
              							</div>
            						</div>
									<CTAs
									type="Ghostbtn"
									text="Filter"
									textColor="text-neutrals-neutral-900"
									bgColor="bg-neutrals-neutral-50"
									borderColor="border-neutrals-neutral-100"
									icon="assets/filter.svg"
									onClick={openDateFilter}
									position="relative"
									/>
          					</div>
        				</div>
        				<div className="flex flex-col items-start justify-start">
          					<div className="w-[1248px] flex flex-col items-start justify-start">
            						<div className="self-stretch flex flex-row items-start justify-start">
              							<div className="w-[188px] flex flex-col items-start justify-start">
                								<div className="w-[188px] bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
                  									<div className="relative font-semibold">Debtor’s name</div>
                								</div>
                								<div className="w-[188px] flex flex-col items-start justify-start">
                  									<div className="w-[188px] bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">Paras Mistry</div>
                  									</div>
                  									<div className="w-[188px] bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">Nathan James</div>
                  									</div>
                  									<div className="w-[188px] bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">Hannibal Higgins</div>
                  									</div>
                  									<div className="w-[188px] bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">Baanji</div>
                  									</div>
                  									<div className="w-[188px] bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">Megan Gibson</div>
                  									</div>
                  									<div className="w-[188px] bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">Ervin Puksts</div>
                  									</div>
                  									<div className="w-[188px] bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">Laura Blake</div>
                  									</div>
                  									<div className="w-[188px] bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">Diane Elgar</div>
                  									</div>
                  									<div className="w-[188px] bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">Elisa Gashi</div>
                  									</div>
                  									<div className="w-[188px] bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">Julie Wyse</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[212px] h-[594px] flex flex-col items-center justify-between">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-center py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">Status</div>
                								</div>
                								<div className="self-stretch flex flex-col items-center justify-start text-semantics-low-low-500">
                  									<div className="self-stretch bg-wireframes-white flex flex-col items-center justify-start p-4 text-semantics-high-high-500 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-high-high-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-high-high-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-high-high-500 h-1.5" />
                      											<div className="relative font-semibold">Overdue</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-wireframes-white flex flex-col items-center justify-start p-4 text-semantics-secure-blue-500 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-secure-blue-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-secure-blue-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-secure-blue-500 h-1.5" />
                      											<div className="relative font-semibold">Sent</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-wireframes-white flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Settled</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-wireframes-white flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Settled</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-wireframes-white flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Settled</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-wireframes-white flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Settled</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-wireframes-white flex flex-col items-center justify-start p-4 text-semantics-secure-blue-500 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-secure-blue-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-secure-blue-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-secure-blue-500 h-1.5" />
                      											<div className="relative font-semibold">Sent</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-wireframes-white flex flex-col items-center justify-start p-4 text-semantics-moderate-moderate-500 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-moderate-moderate-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-moderate-moderate-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
                      											<div className="relative font-semibold">Pending</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-wireframes-white flex flex-col items-center justify-start p-4 text-semantics-high-high-500 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-high-high-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-high-high-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-high-high-500 h-1.5" />
                      											<div className="relative font-semibold">Overdue</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-wireframes-white flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Settled</div>
                    										</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[276px] flex flex-col items-center justify-start">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-center py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">Amount due</div>
                								</div>
                								<div className="self-stretch flex flex-col items-center justify-start">
                  									<div className="self-stretch bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£3000</div>
                  									</div>
                  									<div className="self-stretch bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£2000</div>
                  									</div>
                  									<div className="self-stretch bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">-</div>
                  									</div>
                  									<div className="self-stretch bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">-</div>
                  									</div>
                  									<div className="self-stretch bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">-</div>
                  									</div>
                  									<div className="self-stretch bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">-</div>
                  									</div>
                  									<div className="self-stretch bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£1000</div>
                  									</div>
                  									<div className="self-stretch bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£55,000</div>
                  									</div>
                  									<div className="self-stretch bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£3000</div>
                  									</div>
                  									<div className="self-stretch bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">_</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[360px] flex flex-col items-center justify-start">
                								<div className="w-[448px] bg-column-bg box-border h-11 flex flex-row items-start justify-center py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">Due date</div>
                								</div>
                								<div className="w-[448px] flex flex-col items-center justify-start">
                  									<div className="w-[448px] bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">01/02/24</div>
                  									</div>
                  									<div className="w-[448px] bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">01/02/24</div>
                  									</div>
                  									<div className="w-[448px] bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">20/12/23</div>
                  									</div>
                  									<div className="w-[448px] bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">19/12/23</div>
                  									</div>
                  									<div className="w-[448px] bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">19/12/23</div>
                  									</div>
                  									<div className="w-[448px] bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">18/12/23</div>
                  									</div>
                  									<div className="w-[448px] bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">15/12/23</div>
                  									</div>
                  									<div className="w-[448px] bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">13/12/23</div>
                  									</div>
                  									<div className="w-[448px] bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">11/12/23</div>
                  									</div>
                  									<div className="w-[448px] bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">10/12/23</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[212px] flex flex-col items-center justify-start">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-center py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-b-[1px]">
                  									<div className="relative font-semibold">Action</div>
                								</div>
                								<div className="self-stretch flex flex-col items-center justify-start">
                  									<div className="self-stretch bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-center p-4 text-primary-primary-500 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative [text-decoration:underline] cursor-pointer" onClick={openLawFirm}>Contact law firm</div>
                  									</div>
                  									<div className="self-stretch bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">-</div>
                  									</div>
                  									<div className="self-stretch bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">-</div>
                  									</div>
                  									<div className="self-stretch bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">-</div>
                  									</div>
                  									<div className="self-stretch bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">-</div>
                  									</div>
                  									<div className="self-stretch bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">-</div>
                  									</div>
                  									<div className="self-stretch bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">-</div>
                  									</div>
                  									<div className="self-stretch bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-center p-4 text-primary-primary-500 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative [text-decoration:underline] cursor-pointer" onClick={openLawFirm1}>Contact law firm</div>
                  									</div>
                  									<div className="self-stretch bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-center p-4 text-primary-primary-500 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative [text-decoration:underline] cursor-pointer" onClick={openLawFirm2}>Contact law firm</div>
                  									</div>
                  									<div className="self-stretch bg-wireframes-white box-border h-[55px] flex flex-row items-start justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">-</div>
                  									</div>
                								</div>
              							</div>
            						</div>
          					</div>
          					<div className="w-[1248px] rounded-t-none rounded-b-3xs box-border flex flex-row items-center justify-between pt-3 px-6 pb-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-wireframes-white flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">Previous</div>
              							</div>
            						</div>
            						<div className="relative">Page 1 of 10</div>
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-wireframes-white flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">Next</div>
              							</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[1374px] right-[96px] text-sm">Copyright © Think-AI 2023</div>
      			
      			<div className="absolute top-[293px] left-[96px] rounded-3xs bg-wireframes-white box-border w-[400px] flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-start justify-between p-6">
          					<div className="flex flex-col items-start justify-start gap-[24px]">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-wireframes-white flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<img className="w-5 relative h-5" alt="" src="assets/timer.svg"/>
            						</div>
            						<div className="flex flex-col items-start justify-start gap-[10px]">
              							<div className="relative">Total LPD’s generated</div>
              							<b className="relative text-13xl text-neutrals-neutral-900">65</b>
            						</div>
          					</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg"/>
        				</div>
      			</div>
      			<div className="absolute top-[293px] left-[520px] rounded-3xs bg-wireframes-white box-border w-[400px] flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-start justify-between p-6">
          					<div className="flex flex-col items-start justify-start gap-[24px]">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-wireframes-white flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<img className="w-5 relative h-5" alt="" src="assets/tick-circle.svg" />
            						</div>
            						<div className="flex flex-col items-start justify-start gap-[10px]">
              							<div className="relative">Settled LPD’s</div>
              							<b className="relative text-13xl text-neutrals-neutral-900">50</b>
            						</div>
          					</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg"/>
        				</div>
      			</div>
      			<div className="absolute top-[293px] left-[944px] rounded-3xs bg-wireframes-white box-border w-[400px] flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-start justify-between p-6">
          					<div className="flex flex-col items-start justify-start gap-[24px]">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-wireframes-white flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<img className="w-5 relative h-5" alt="" src="assets/danger.svg"/>
            						</div>
            						<div className="flex flex-col items-start justify-start gap-[10px]">
              							<div className="relative">Pending LPD’s</div>
              							<b className="relative text-13xl text-neutrals-neutral-900">15</b>
            						</div>
          					</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg"/>
        				</div>
      			</div>

				{/* Navigation Panel */}
				<NavigationPanel navItems={navItems}/>

				<Breadcrumb breadcrumbs={breadcrumb} textColor="text-primary-primary-500" position="top-[24px] left-[90px]"/>
      			
      			<div className="absolute top-[179px] left-[96px] box-border w-[1248px] flex flex-row items-start justify-between pt-0 px-0 pb-6 text-9xl text-neutrals-neutral-900 border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<b className="relative">Late payment demand (LPD)</b>
          					<div className="relative text-base text-neutrals-neutral-300">All information regarding your LPD.</div>
        				</div>
						<CTAs
						type="Ghostbtn"
						text="New LPD"
						textColor="text-neutrals-neutral-900"
						bgColor="bg-neutrals-neutrals-50"
						borderColor="border-neutrals-neutral-100"
						icon="assets/add.svg"
						position="relative"
						onClick={onCTAsContainerClick}
						/>
      			</div>
    		</div>
    		{isDownloadModalTablesOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeDownloadModalTables}
        				>
        				<DownloadModalTables onClose={closeDownloadModalTables} />
      			</PortalPopup>
    		)}
    		{isDateFilterOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeDateFilter}
        				>
        				<DateFilter onClose={closeDateFilter} />
      			</PortalPopup>
    		)}
    		{isLawFirmOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLawFirm}
        				>
        				<LawFirm onClose={closeLawFirm} />
      			</PortalPopup>
    		)}
    		{isLawFirm1Open && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLawFirm1}
        				>
        				<LawFirm onClose={closeLawFirm1} />
      			</PortalPopup>
    		)}
    		{isLawFirm2Open && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLawFirm2}
        				>
        				<LawFirm onClose={closeLawFirm2} />
      			</PortalPopup>
    		)}
    		{isNavigationDropdownOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeNavigationDropdown}
        				>
        				<NavigationDropdown onClose={closeNavigationDropdown} />
      			</PortalPopup>
    		)}
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}</>);
};

export default LPDPage;