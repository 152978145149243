import { useState, useCallback } from 'react';
import CTAs from './CTAs';

const NewTriggerModal = ({onClose}) => {
    return (
          <div className="relative shadow-[0px_10px_60px_rgba(0,_0,_0,_0.1)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start gap-[24px] max-w-full max-h-full overflow-auto text-left text-sm text-neutrals-neutral-900 font-open-sans-ff-body-strong border-[1px] border-solid border-neutrals-neutral-100">
                <div className="flex flex-col items-start justify-start pt-6 px-6 pb-0 gap-[24px] text-lg">
                      <div className="flex flex-row items-start justify-start gap-[292px]">
                            <div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
                                  <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/new-trigger.svg"/>
                            </div>
                            <img className="w-5 relative h-5 overflow-hidden shrink-0 cursor-pointer" alt="" src="assets/cancel.svg" onClick={onClose}/>
                      </div>
                      <div className="flex flex-col items-start justify-start gap-[6px]">
                            <div className="w-[352px] relative font-semibold inline-block">New trigger</div>
                            <div className="w-[352px] relative text-base text-neutrals-neutral-300 inline-block">Add new trigger for automation.</div>
                      </div>
                </div>
                <div className="flex flex-col items-start justify-start py-0 px-6 gap-[24px]">
                      <div className="w-[352px] flex flex-col items-start justify-start gap-[10px]">
                            <div className="flex flex-row items-center justify-start gap-[6px]">
                                  <div className="relative font-semibold">Name</div>
                                  <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg"/>
                            </div>
                            <div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 overflow-hidden flex flex-row items-center justify-start p-3 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
                                  <div className="relative">Enter trigger name</div>
                            </div>
                      </div>
                      <div className="w-[352px] flex flex-col items-start justify-start gap-[10px]">
                            <div className="flex flex-row items-center justify-start gap-[6px]">
                                  <div className="relative font-semibold">Condition</div>
                                  <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg"/>
                            </div>
                            <div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 overflow-hidden flex flex-row items-center justify-start p-3 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
                                  <div className="relative">Enter condition</div>
                            </div>
                      </div>
                      <div className="w-[352px] flex flex-col items-start justify-start gap-[10px]">
                            <div className="flex flex-row items-center justify-start gap-[6px]">
                                  <div className="relative font-semibold">Action</div>
                                  <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg"/>
                            </div>
                            <div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 overflow-hidden flex flex-row items-center justify-start p-3 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
                                  <div className="relative">Enter action</div>
                            </div>
                      </div>
                </div>
                <div className="w-[400px] flex flex-row items-start justify-center pt-0 px-6 pb-6 box-border gap-[24px]">
                  <CTAs
		      type="Ghostbtn"
			text="Cancel"
			textColor="text-neutrals-neutral-900"
			bgColor="bg-neutrals-neutral-50"
			borderColor="border-neutrals-neutral-100"
                  width="165px"
			position="relative"
                  onClick={onClose}
			/>
          					
			<CTAs
			type="Primarybtn"
			text="Save"
			textColor="text-neutrals-neutral-50"
			bgColor="bg-primary-primary-500"
			borderColor="border-primary-primary-500"
                  width="165px"
			position="relative"
			/>
                </div>
          </div>);
};

export default NewTriggerModal;