import React, { useState } from 'react';
import BasingPricingCard from '../components/BasicPricingCard';

const PricingLandingPage = () => {
  const [billingCycle, setBillingCycle] = useState('monthly');

  const toggleBillingCycle = (cycle) => {
    setBillingCycle(cycle);
  };

  const pricingPlans = [
    {
      title: 'Basic plan',
      monthlyPrice: '£49.99',
      annualPrice: 'Free',
      description: 'Get started with basic features',
      features: ['Up to 3 users', 'Product support', 'Plan features'],
    },
    {
      title: 'Business plan',
      monthlyPrice: '£59.99',
      annualPrice: '£150',
      description: 'Everything you need for a growing business',
      features: ['5 users', 'Product support', 'Plan features'],
      mostPopular: true,
    },
    {
      title: 'Enterprise plan',
      monthlyPrice: '£69.99',
      annualPrice: '£1,200',
      description: 'Advanced features for scaling your business',
      features: ['Up to 10 users', 'Product support', 'Plan features'],
    },
  ];

  return (
    <div className="w-full relative rounded-[5px] box-border h-[1168.9px] overflow-hidden text-left text-base text-neutrals-neutral-900 font-open-sans-font-family-body">
      <div className="absolute top-[20px] left-[20px] flex flex-col items-center justify-start gap-[64px]">
        <div className="rounded-3xs bg-column-bg flex flex-row items-start justify-start p-2 gap-[10px] border-[1px] border-solid border-neutrals-neutral-100">
          <div
            onClick={() => toggleBillingCycle('monthly')}
            className={`rounded-3xs flex flex-row items-center justify-center py-2 px-3 ${billingCycle === 'monthly' ? 'shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] bg-neutrals-neutral-50' : 'text-neutrals-neutral-300'}`}
          >
            <div className="relative font-semibold">Monthly billing</div>
          </div>
          <div
            onClick={() => toggleBillingCycle('annually')}
            className={`rounded-3xs flex flex-row items-center justify-center py-2 px-3 ${billingCycle === 'annually' ? 'shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] bg-neutrals-neutral-50' : 'text-neutrals-neutral-300'}`}
          >
            <div className="relative font-semibold">Annual billing</div>
          </div>
        </div>

        <div className="w-[1122px] relative h-[414.5px] text-sm text-neutrals-neutral-300 flex justify-between">
          {pricingPlans.map((plan, index) => (
            <BasingPricingCard
              key={index}
              title={plan.title}
              price={billingCycle === 'monthly' ? plan.monthlyPrice : plan.annualPrice}
              description={plan.description}
              features={plan.features}
              mostPopular={plan.mostPopular}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingLandingPage;