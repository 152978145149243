import { useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import CTAs from '../../components/CTAs';

const RegistrationCompletedPage = () => {
	const navigate = useNavigate();
  	
  	const onCTAsContainerClick = useCallback(() => {
		navigate('/login');
  	}, [navigate]);
  	
  	const onLogInTextClick = useCallback(() => {
		navigate('/login');
  	}, [navigate]);
  	
  	return (
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1024px] overflow-hidden text-center text-sm text-neutrals-neutral-900 font-open-sans-ff-meta">
      			<div className="absolute top-[357px] left-[531px] flex flex-col items-center justify-start gap-[24px] text-9xl">
        				<div className="w-12 shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-12 flex flex-row items-center justify-center p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
          					<img className="w-5 relative h-5" alt="" src="assets/tick-circle.svg"/>
        				</div>
        				<div className="flex flex-col items-center justify-start gap-[10px]">
          					<b className="relative">Registration completed</b>
          					<div className="relative text-base text-neutrals-neutral-300">
            						<p className="m-0">Your registration has been successfully completed.</p>
            						<p className="m-0">Click below to log in manually.</p>
          					</div>
        				</div>
      			</div>
      			
				<div className="absolute top-[564px] left-[520px] flex flex-col items-center justify-start gap-[16px]">
						<CTAs
						type="Primarybtn"
						text="Continue"
						textColor="text-neutrals-neutral-50"
						bgColor="bg-primary-primary-500"
						borderColor="border-primary-primary-500"
						width="400px"
						position="relative"
						onClick={onCTAsContainerClick}
						/>
        		</div>
      			
      			<div className="absolute top-[643px] left-[663.5px] flex flex-row items-start justify-center gap-[6px] cursor-pointer" onClick={onLogInTextClick}>
        				<img className="w-5 relative h-5" alt="" src="assets/arrow-left.svg" />
        				<div className="flex flex-row items-start justify-center">
          					<div className="relative font-semibold">Back to login</div>
        				</div>
      			</div>
      			<div className="absolute top-[941px] right-[96px] text-neutrals-neutral-300 text-left">Copyright © Think-AI 2023</div>
    		</div>);
};

export default RegistrationCompletedPage;