import { useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import CTAs from '../components/CTAs';

const NavigationPanelLP = () => {
    const navigate = useNavigate();
  	
  	const handleLoginClick = useCallback(() => {
        navigate('/login');
  	}, [navigate]);

    const handleSignUpClick = useCallback(() => {
        navigate('/selectaccount');
    })
  	
  	return (
    		<div className="w-full relative bg-column-bg flex flex-row items-center justify-between box-border text-left text-base text-neutrals-neutral-900 font-open-sans-ff-meta-strong">
      			<div className="flex flex-row items-center justify-start gap-[82px]">
        				<img className="w-[118px] relative h-10 object-cover" alt="" src="assets/logo-horizontal.svg"/>
        				<div className="flex flex-row items-start justify-start py-3 px-0 gap-[35px]">
          					<div className="flex flex-row items-start justify-start">
            						<div className="relative font-semibold">Home</div>
          					</div>
          					<div className="flex flex-row items-start justify-start">
            						<div className="relative font-semibold">How it works</div>
          					</div>
          					<div className="flex flex-row items-start justify-start">
            						<div className="relative font-semibold">Features</div>
          					</div>
          					<div className="flex flex-row items-start justify-start">
            						<div className="relative font-semibold">Testimonial</div>
          					</div>
          					<div className="flex flex-row items-start justify-start">
            						<div className="relative font-semibold">Pricing</div>
          					</div>
        				</div>
      			</div>

                {/* Login Btn */}
      			<div className="flex flex-row items-start justify-start gap-2.5 text-sm">
                    <div className="rounded-3xs bg-column-bg flex flex-row items-center justify-center py-2.5 px-3.5 cursor-pointer" onClick={handleLoginClick}>
                        <div className="flex flex-row items-center justify-center">
                            <div className="relative font-semibold">Login</div>
                        </div>
                    </div>

                {/* Sign up btn */}
                <div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-primary-primary-500 flex flex-row items-center justify-center py-2.5 px-3.5 cursor-pointer text-neutrals-neutral-50" onClick={handleSignUpClick}>
                    <div className="flex flex-row items-center justify-center">
                        <div className="relative font-semibold">Sign up</div>
                    </div>
                </div>
      			</div>
    		</div>
            );
};

export default NavigationPanelLP;