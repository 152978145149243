import { useState, useCallback } from 'react';
import LogoutConfirmation from "../../components/LogoutConfirmation";
import PortalPopup from "../../components/PortalPopup";
import ExportModalImage from "../../components/ExportModalImage";
import { Navigate, useNavigate} from "react-router-dom";
import NavigationPanel from "../../components/NavigationPanel";
import DebtCollectionNav from '../../components/DebtCollectionNav';

const AI_Indicators_1 = () => {
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	const [isExportModalImageOpen, setExportModalImageOpen] = useState(false);
  	const navigate = useNavigate();
  	
  	const onDebtCollectionNavContainer2Click = useCallback(() => {
    		navigate("/");
  	}, [navigate]);
  	
  	
  	const openLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(true);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);
  	
  	
  	const openExportModalImage = useCallback(() => {
    		setExportModalImageOpen(true);
  	}, []);
  	
  	const closeExportModalImage = useCallback(() => {
    		setExportModalImageOpen(false);
  	}, []);
  	
  	
  	const onCTAsContainerClick = useCallback(() => {
        navigate('/ai2');
  	}, [navigate]);

	const navItems = [
		{
			text: 'Debt collection', 
    		bgColor: 'bg-neutrals-neutral-50', 
    		textColor: 'text-neutrals-neutral-300', 
    		hoverBgColor: 'bg-neutrals-neutral-50', 
    		hoverTextColor: 'text-primary-primary-500', 
    		path: "",
    		icon: 'assets/arrow-down.svg'
		},

		{
			text: "Outcomes & costs",
			bgColor: 'bg-neutrals-neutral-50',
    		textColor: 'text-neutrals-neutral-300', 
    		hoverBgColor: "bg-neutrals-neutral-50", 
    		hoverTextColor: "text-primary-primary-500", 
    		path: "/outcome&cost",
    		icon: ""
		},

		{
			text: "AI indicators",
			bgColor: 'bg-primary-primary-50', 
    		textColor: 'text-neutrals-neutral-900', 
    		hoverBgColor: 'bg-primary-primary-50', 
    		hoverTextColor: 'text-neutrals-neutral-900', 
    		path: "",
    		icon: ''
		},
	];
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1653px] overflow-hidden text-left text-base text-neutrals-neutral-900 font-open-sans-ff-heading-1">
      			<div className="absolute top-[1570px] right-[96px] text-sm text-neutrals-neutral-300">Copyright © Think-AI 2023</div>
      			<b className="absolute top-[128px] left-[96px] text-9xl">AI indicators</b>
      			<div className="absolute top-[190px] left-[520px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border w-[400px] flex flex-col items-start justify-start p-6 gap-[10px] border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-center justify-between">
          					<div className="relative font-semibold">Total red flags</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
        				</div>
        				<div className="self-stretch flex flex-row items-center justify-between text-13xl">
          					<b className="relative">15</b>
          					<div className="rounded-81xl bg-semantics-high-high-50 flex flex-col items-start justify-start py-0.5 px-2 text-sm text-semantics-high-high-500 border-[1px] border-solid border-semantics-high-high-500">
            						<div className="flex flex-row items-center justify-start gap-[6px]">
              							<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="icons-v1.svg" />
              							<div className="relative font-semibold">10%</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[190px] left-[96px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border w-[400px] flex flex-col items-start justify-start p-6 gap-[10px] border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-center justify-between">
          					<div className="relative font-semibold">Ongoing cases</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg"/>
        				</div>
        				<div className="self-stretch flex flex-row items-center justify-between text-13xl">
          					<b className="relative">50</b>
          					<div className="rounded-81xl bg-semantics-low-low-50 flex flex-col items-start justify-start py-0.5 px-2 text-sm text-semantics-low-low-500 border-[1px] border-solid border-semantics-low-low-500">
            						<div className="flex flex-row items-center justify-start gap-[6px]">
              							<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="icons-v1.svg" />
              							<div className="relative font-semibold">10%</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[190px] left-[944px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border w-[400px] flex flex-col items-start justify-start p-6 gap-[10px] border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-center justify-between">
          					<div className="relative font-semibold">Total green flags</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
        				</div>
        				<div className="self-stretch flex flex-row items-center justify-between text-13xl">
          					<b className="relative">35</b>
          					<div className="rounded-81xl bg-semantics-low-low-50 flex flex-col items-start justify-start py-0.5 px-2 text-sm text-semantics-low-low-500 border-[1px] border-solid border-semantics-low-low-500">
            						<div className="flex flex-row items-center justify-start gap-[6px]">
              							<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="icons-v1.svg" />
              							<div className="relative font-semibold">10%</div>
            						</div>
          					</div>
        				</div>
      			</div>

                {/* Navigation Panel */}
				<NavigationPanel navItems={navItems}/>
      			
      			<div className="absolute top-[1043px] left-[96px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border w-[1248px] flex flex-col items-start justify-start p-6 gap-[24px] text-sm border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-center justify-between text-lg">
          					<div className="relative font-semibold">Trends over time</div>
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 cursor-pointer text-sm border-[1px] border-solid border-neutrals-neutral-100" onClick={openExportModalImage}>
            						<div className="flex flex-row items-center justify-center gap-[6px]">
              							<img className="w-5 relative h-5" alt="" src="assets/export.svg" />
              							<div className="relative font-semibold">Export</div>
            						</div>
          					</div>
        				</div>
        				<div className="self-stretch flex flex-col items-start justify-start text-neutrals-neutral-300">
          					<div className="self-stretch flex flex-col items-end justify-start gap-[24px]">
            						<div className="flex flex-row items-start justify-start gap-[10px]">
              							<div className="flex flex-row items-center justify-start gap-[6px]">
                								<div className="w-2 relative rounded-[50%] bg-primary-primary-500 h-2" />
                								<div className="relative">Green flag</div>
              							</div>
              							<div className="flex flex-row items-center justify-start gap-[6px]">
                								<div className="w-2 relative rounded-[50%] bg-primary-primary-300 h-2" />
                								<div className="relative">Red flag</div>
              							</div>
            						</div>
            						<div className="self-stretch flex flex-row items-center justify-start gap-[10px] text-center text-neutrals-neutral-900">
              							<div className="flex flex-row items-center justify-start">
                								<div className="flex flex-row items-center justify-start gap-[10px]">
                  									<div className="relative font-semibold [transform:_rotate(-90deg)]">Flags</div>
                  									<div className="h-[201px] flex flex-col items-end justify-start gap-[11px] text-left text-neutrals-neutral-300">
                    										<div className="relative">60</div>
                    										<div className="relative">50</div>
                    										<div className="relative">40</div>
                    										<div className="relative">30</div>
                    										<div className="relative">20</div>
                    										<div className="relative">10</div>
                    										<div className="relative">0</div>
                  									</div>
                								</div>
              							</div>
              							<div className="flex-1 flex flex-col items-start justify-start relative gap-[10px]">
                								<div className="self-stretch relative h-[180px] z-[0]">
                  									<div className="absolute top-[-0.5px] left-[-0.5px] box-border w-[1145px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="absolute top-[29.5px] left-[-0.5px] box-border w-[1145px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="absolute top-[59.5px] left-[-0.5px] box-border w-[1145px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="absolute top-[89.5px] left-[-0.5px] box-border w-[1145px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="absolute top-[119.5px] left-[-0.5px] box-border w-[1145px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="absolute top-[149.5px] left-[-0.5px] box-border w-[1145px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="absolute top-[179.5px] left-[-0.5px] box-border w-[1145px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                								</div>
                								<img className="w-[1144px] absolute !m-[0] top-[12px] left-[0px] h-[168px] z-[1]" alt="" src="Group 233.svg" />
              							</div>
            						</div>
          					</div>
          					<div className="self-stretch h-12 flex flex-col items-start justify-between text-center">
            						<div className="self-stretch flex flex-row items-start justify-between py-0 pr-0 pl-14">
              							<div className="relative">Mon</div>
              							<div className="relative">Tue</div>
              							<div className="relative">Wed</div>
              							<div className="relative">Thu</div>
              							<div className="relative">Fri</div>
              							<div className="relative">Sat</div>
              							<div className="relative">Sun</div>
            						</div>
            						<div className="self-stretch relative font-semibold text-neutrals-neutral-900">Days</div>
          					</div>
        				</div>
        				<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-start border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="rounded-tl-3xs rounded-tr-none rounded-br-none rounded-bl-3xs bg-column-bg flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-solid border-neutrals-neutral-100">
            						<div className="relative font-semibold">Daily</div>
          					</div>
          					<div className="flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-solid border-neutrals-neutral-100">
            						<div className="relative font-semibold">Weekly</div>
          					</div>
          					<div className="flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-solid border-neutrals-neutral-100">
            						<div className="relative font-semibold">Monthly</div>
          					</div>
          					<div className="rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none flex flex-row items-center justify-center py-2 px-4  border-solid border-neutrals-neutral-100 ">
            						<div className="flex flex-row items-center justify-center gap-[6px]">
              							<img className="w-5 relative h-5" alt="" src="assets/add.svg" />
              							<div className="relative font-semibold">Custom</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[403px] left-[96px] w-[1248px] flex flex-row flex-wrap items-start justify-start gap-[24px]">
        				<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="self-stretch flex flex-col items-start justify-start p-6 gap-[24px]">
            						<div className="self-stretch flex flex-row items-start justify-between">
              							<div className="flex flex-col items-start justify-start gap-[10px]">
                								<div className="relative font-semibold">Paras Mistry</div>
                								<b className="relative text-13xl">£2000</b>
              							</div>
              							<img className="w-6 relative h-6" alt="" src="assets/flag.svg"/>
            						</div>
            						<div className="flex flex-row items-start justify-start gap-[6px] text-neutrals-neutral-300">
              							<img className="w-5 relative h-5" alt="" src="assets/calendar.svg" />
              							<div className="relative">21/01/24</div>
            						</div>
            						<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="w-1.5 relative rounded-[50%] bg-semantics-high-high-500 h-1.5" />
              							<div className="relative font-semibold">Document mismatch</div>
            						</div>
          					</div>
          					<div className="self-stretch flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 cursor-pointer border-[1px] border-solid border-neutrals-neutral-100" onClick={onCTAsContainerClick}>
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">View details</div>
              							</div>
            						</div>
          					</div>
        				</div>
        				<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="self-stretch flex flex-col items-start justify-start p-6 gap-[24px]">
            						<div className="self-stretch flex flex-row items-start justify-between">
              							<div className="flex flex-col items-start justify-start gap-[10px]">
                								<div className="relative font-semibold">Nathan James</div>
                								<b className="relative text-13xl">£3500</b>
              							</div>
              							<img className="w-6 relative h-6" alt="" src="assets/flag.svg" />
            						</div>
            						<div className="flex flex-row items-start justify-start gap-[6px] text-neutrals-neutral-300">
              							<img className="w-5 relative h-5" alt="" src="assets/calendar.svg"/>
              							<div className="relative">21/01/24</div>
            						</div>
            						<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="w-1.5 relative rounded-[50%] bg-semantics-high-high-500 h-1.5" />
              							<div className="relative font-semibold">Document mismatch</div>
            						</div>
          					</div>
          					<div className="self-stretch flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">View details</div>
              							</div>
            						</div>
          					</div>
        				</div>
        				<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="self-stretch flex flex-col items-start justify-start p-6 gap-[24px]">
            						<div className="self-stretch flex flex-row items-start justify-between">
              							<div className="flex flex-col items-start justify-start gap-[10px]">
                								<div className="relative font-semibold">Diane Elgar</div>
                								<b className="relative text-13xl">£1000</b>
              							</div>
              							<img className="w-6 relative h-6" alt="" src="assets/flag.svg" />
            						</div>
            						<div className="flex flex-row items-start justify-start gap-[6px] text-neutrals-neutral-300">
              							<img className="w-5 relative h-5" alt="" src="assets/calendar.svg" />
              							<div className="relative">21/01/24</div>
            						</div>
            						<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="w-1.5 relative rounded-[50%] bg-semantics-high-high-500 h-1.5" />
              							<div className="relative font-semibold">Document mismatch</div>
            						</div>
          					</div>
          					<div className="self-stretch flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">View details</div>
              							</div>
            						</div>
          					</div>
        				</div>
        				<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="self-stretch flex flex-col items-start justify-start p-6 gap-[24px]">
            						<div className="self-stretch flex flex-row items-start justify-between">
              							<div className="flex flex-col items-start justify-start gap-[10px]">
                								<div className="relative font-semibold">Megan Gibson</div>
                								<b className="relative text-13xl">£4500</b>
              							</div>
              							<img className="w-6 relative h-6" alt="" src="assets/flag.svg" />
            						</div>
            						<div className="flex flex-row items-start justify-start gap-[6px] text-neutrals-neutral-300">
              							<img className="w-5 relative h-5" alt="" src="assets/calendar.svg"/>
              							<div className="relative">21/01/24</div>
            						</div>
            						<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="w-1.5 relative rounded-[50%] bg-semantics-high-high-500 h-1.5" />
              							<div className="relative font-semibold">Document mismatch</div>
            						</div>
          					</div>
          					<div className="self-stretch flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">View details</div>
              							</div>
            						</div>
          					</div>
        				</div>
        				<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="self-stretch flex flex-col items-start justify-start p-6 gap-[24px]">
            						<div className="self-stretch flex flex-row items-start justify-between">
              							<div className="flex flex-col items-start justify-start gap-[10px]">
                								<div className="relative font-semibold">Julie Wyse</div>
                								<b className="relative text-13xl">£5000</b>
              							</div>
              							<img className="w-6 relative h-6" alt="" src="assets/flag1.svg" />
            						</div>
            						<div className="flex flex-row items-start justify-start gap-[6px] text-neutrals-neutral-300">
              							<img className="w-5 relative h-5" alt="" src="assets/calendar.svg" />
              							<div className="relative">21/01/24</div>
            						</div>
            						<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
              							<div className="relative font-semibold">Payment received</div>
            						</div>
          					</div>
          					<div className="self-stretch flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">View details</div>
              							</div>
            						</div>
          					</div>
        				</div>
        				<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="self-stretch flex flex-col items-start justify-start p-6 gap-[24px]">
            						<div className="self-stretch flex flex-row items-start justify-between">
              							<div className="flex flex-col items-start justify-start gap-[10px]">
                								<div className="relative font-semibold">Ervins Puksts</div>
                								<b className="relative text-13xl">£6000</b>
              							</div>
              							<img className="w-6 relative h-6" alt="" src="assets/flag1.svg" />
            						</div>
            						<div className="flex flex-row items-start justify-start gap-[6px] text-neutrals-neutral-300">
              							<img className="w-5 relative h-5" alt="" src="assets/calendar.svg" />
              							<div className="relative">21/01/24</div>
            						</div>
            						<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
              							<div className="relative font-semibold">Payment received</div>
            						</div>
          					</div>
          					<div className="self-stretch flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">View details</div>
              							</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[354px] left-[96px] text-lg font-semibold">On going cases</div>
    		</div>
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}
    		{isExportModalImageOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeExportModalImage}
        				>
        				<ExportModalImage onClose={closeExportModalImage} />
      			</PortalPopup>
    		)}</>);
};

export default AI_Indicators_1;