import { useState, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import ExportModalImage from "../../components/ExportModalImage";
import PortalPopup from "../../components/PortalPopup";
import DownloadModalTables from "../../components/DownloadModalTables";
import DebtCollectionNav from '../../components/DebtCollectionNav';
import NavigationPanelLawyer from '../../components/NavigationPanelLawyer';
import RevenueFilter from "../../components/RevenueFilter";
import LogoutConfirmation from "../../components/LogoutConfirmation";
import Breadcrumb from "../../components/Breadcrumb";

const Legal_proceedings_3 = () => {
    const navigate = useNavigate();
  	const [isExportModalImageOpen, setExportModalImageOpen] = useState(false);
  	const [isExportModalImage1Open, setExportModalImage1Open] = useState(false);
  	const [isExportModalImage2Open, setExportModalImage2Open] = useState(false);
  	const [isDownloadModalTablesOpen, setDownloadModalTablesOpen] = useState(false);
  	const [isRevenueFilterOpen, setRevenueFilterOpen] = useState(false);
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	
  	const onIconsV2Click = useCallback(() => {
    		// Add your code here
  	}, []);
  	
  	const onClickCasesOverview = useCallback(() => {
        navigate('/legal_1');
  	}, [navigate]);
  	
  	const onClickBillingSchedule = useCallback(() => {
        navigate('/legal_2');
  	}, [navigate]);
  	
  	const openExportModalImage = useCallback(() => {
    		setExportModalImageOpen(true);
  	}, []);
  	
  	const closeExportModalImage = useCallback(() => {
    		setExportModalImageOpen(false);
  	}, []);
  	
  	const openExportModalImage1 = useCallback(() => {
    		setExportModalImage1Open(true);
  	}, []);
  	
  	const closeExportModalImage1 = useCallback(() => {
    		setExportModalImage1Open(false);
  	}, []);
  	
  	const openExportModalImage2 = useCallback(() => {
    		setExportModalImage2Open(true);
  	}, []);
  	
  	const closeExportModalImage2 = useCallback(() => {
    		setExportModalImage2Open(false);
  	}, []);
  	
  	const openDownloadModalTables = useCallback(() => {
    		setDownloadModalTablesOpen(true);
  	}, []);
  	
  	const closeDownloadModalTables = useCallback(() => {
    		setDownloadModalTablesOpen(false);
  	}, []);
  	
  	const openRevenueFilter = useCallback(() => {
    		setRevenueFilterOpen(true);
  	}, []);
  	
  	const closeRevenueFilter = useCallback(() => {
    		setRevenueFilterOpen(false);
  	}, []);
  	
  	const openLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(true);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);

	const navItems = [
		{ text: "Dashboard", bgColor: "bg-neutrals-neutral-50", textColor: "text-neutrals-neutral-300", hoverBgColor: "bg-neutrals-neutral-50", hoverTextColor: "text-primary-primary-500", path: "" },
		{ text: "Legal proceedings & billing", bgColor: "bg-primary-primary-50", textColor: "text-neutrals-neutral-900", hoverBgColor: "bg-primary-primary-50", hoverTextColor: "text-neutrals-neutral-900", path: "" },
		{ text: "Pre-LBA checks", bgColor: "bg-neutrals-neutral-50", textColor: "text-neutrals-neutral-300", hoverBgColor: "bg-neutrals-neutral-50", hoverTextColor: "text-primary-primary-500", path: "" },
		{ text: "Client-debtor interaction", bgColor: "bg-neutrals-neutral-50", textColor: "text-neutrals-neutral-300", hoverBgColor: "bg-neutrals-neutral-50", hoverTextColor: "text-primary-primary-500", path: "" },
	];

	const breadcrumb = [
		{ label: "", path: "", icon: "assets/home.svg", active: true },
		{ label: "Legal proceedings & billing", path: "", icon: "", active: false },
	];
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[3022px] overflow-hidden text-left text-base text-neutrals-neutral-900 font-open-sans-font-family-meta">
      			<div className="absolute top-[179px] left-[96px] bg-neutrals-neutral-50 box-border w-[1248px] flex flex-col items-start justify-start pt-0 px-0 pb-6 gap-[6px] text-9xl border-b-[1px] border-solid border-neutrals-neutral-100">
        				<b className="relative">Legal proceedings and billing</b>
        				<div className="relative text-base text-neutrals-neutral-300">Get to know time remaining for your LBA</div>
      			</div>
      			<div className="absolute right-[96px] bottom-[64px] text-sm text-neutrals-neutral-300">Copyright © Think-AI 2023</div>

      			<div className="absolute top-[293px] left-[96px] flex flex-row items-start justify-start gap-[24px] text-neutrals-neutral-300">
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 box-border cursor-pointer" onClick={onClickCasesOverview}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Cases overview</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 box-border cursor-pointer" onClick={onClickBillingSchedule}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Billing schedule</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 box-border h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 text-primary-primary-500 border-b-[2px] border-solid border-primary-primary-500">
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Reports and analytics</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[1913px] left-[96px] w-[1248px] flex flex-col items-start justify-start gap-[24px]">
        				<div className="self-stretch flex flex-row items-start justify-between pt-0 px-0 pb-6 border-b-[1px] border-solid border-neutrals-neutral-100">
          					<div className="relative font-semibold">Financial insights</div>
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 cursor-pointer text-sm border-[1px] border-solid border-neutrals-neutral-100" onClick={openExportModalImage}>
            						<div className="flex flex-row items-center justify-center gap-[6px]">
              							<img className="w-5 relative h-5" alt="" src="assets/export.svg" />
              							<div className="relative font-semibold">Export</div>
            						</div>
          					</div>
        				</div>
        				<div className="flex flex-row items-start justify-start gap-[24px] text-lg">
          					<div className="w-[506px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="self-stretch bg-neutrals-neutral-50 flex flex-row items-center justify-between pt-0 px-0 pb-6 border-b-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">Cost breakdown</div>
              							<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
            						</div>
            						<div className="self-stretch flex flex-row items-start justify-start gap-[24px] text-center text-sm text-neutrals-neutral-300">
              							<div className="w-60 h-60 flex flex-row items-center justify-center relative gap-[10px]">
                								<div className="flex flex-row items-start justify-start relative gap-[10px] z-[0]">
                  									<img className="w-[234px] relative h-[234px] z-[0]" alt="" src="Group 169.svg" />
                  									<img className="w-[194px] absolute !m-[0] top-[calc(50%_-_97px)] left-[calc(50%_-_98px)] h-[194px] z-[1]" alt="" src="Group 168.svg" />
                  									<img className="w-[159.4px] absolute !m-[0] top-[37.32px] left-[36.32px] h-[159.4px] z-[2]" alt="" src="Group 167.svg" />
                								</div>
                								<div className="!m-[0] absolute top-[90px] left-[77px] flex flex-col items-center justify-start gap-[2px] z-[1]">
                  									<div className="relative">Profit margin</div>
                  									<b className="relative text-9xl text-neutrals-neutral-900 text-left">25%</b>
                								</div>
              							</div>
              							<div className="flex-1 h-[216px] flex flex-col items-start justify-between text-right">
                								<div className="flex flex-row items-start justify-start gap-[6px]">
                  									<div className="flex flex-row items-start justify-start pt-1.5 px-0 pb-0">
                    										<div className="w-2 relative rounded-[50%] bg-primary-primary-500 h-2" />
                  									</div>
                  									<div className="flex flex-col items-start justify-start gap-[4px]">
                    										<div className="relative">Legal fees</div>
                    										<div className="relative text-5xl font-semibold text-neutrals-neutral-900 text-left">£130,000</div>
                  									</div>
                								</div>
                								<div className="flex flex-row items-start justify-start gap-[6px]">
                  									<div className="flex flex-row items-start justify-start pt-1.5 px-0 pb-0">
                    										<div className="w-2 relative rounded-[50%] bg-primary-primary-300 h-2" />
                  									</div>
                  									<div className="flex flex-col items-start justify-start gap-[4px]">
                    										<div className="relative">Court fees</div>
                    										<div className="relative text-5xl font-semibold text-neutrals-neutral-900 text-left">£2000</div>
                  									</div>
                								</div>
                								<div className="flex flex-row items-start justify-start gap-[6px]">
                  									<div className="flex flex-row items-start justify-start pt-1.5 px-0 pb-0">
                    										<div className="w-2 relative rounded-[50%] bg-primary-primary-100 h-2" />
                  									</div>
                  									<div className="flex flex-col items-start justify-start gap-[4px]">
                    										<div className="relative">Additional charges</div>
                    										<div className="relative text-5xl font-semibold text-neutrals-neutral-900 text-left">£20,000</div>
                  									</div>
                								</div>
              							</div>
            						</div>
          					</div>
          					<div className="w-[506px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start p-6 gap-[24px] text-base border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="self-stretch flex flex-row items-center justify-between pt-0 px-0 pb-6 border-b-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">Comparision</div>
              							<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
            						</div>
            						<div className="self-stretch flex flex-row items-start justify-start gap-[24px] text-right text-sm text-neutrals-neutral-300">
              							<img className="w-60 relative h-60" alt="" src="Group 213.svg" />
              							<div className="flex-1 h-[216px] flex flex-col items-start justify-between">
                								<div className="self-stretch flex flex-row items-start justify-start gap-[6px]">
                  									<div className="flex flex-row items-start justify-start pt-1.5 px-0 pb-0">
                    										<div className="w-2 relative rounded-[50%] bg-primary-primary-500 h-2" />
                  									</div>
                  									<div className="flex flex-col items-start justify-start gap-[4px]">
                    										<div className="relative">Revenue</div>
                    										<div className="relative text-5xl font-semibold text-neutrals-neutral-900 text-left">£200,000</div>
                  									</div>
                								</div>
                								<div className="self-stretch flex flex-row items-start justify-start gap-[6px]">
                  									<div className="flex flex-row items-start justify-start pt-1.5 px-0 pb-0">
                    										<div className="w-2 relative rounded-[50%] bg-primary-primary-300 h-2" />
                  									</div>
                  									<div className="flex flex-col items-start justify-start gap-[4px]">
                    										<div className="relative">Expenses</div>
                    										<div className="relative text-5xl font-semibold text-neutrals-neutral-900 text-left">£2000</div>
                  									</div>
                								</div>
                								<div className="self-stretch flex flex-row items-start justify-start gap-[6px]">
                  									<div className="flex flex-row items-start justify-start pt-1.5 px-0 pb-0">
                    										<div className="w-2 relative rounded-[50%] bg-primary-primary-100 h-2" />
                  									</div>
                  									<div className="flex flex-col items-start justify-start gap-[4px]">
                    										<div className="relative">Net profit</div>
                    										<div className="relative text-5xl font-semibold text-neutrals-neutral-900 text-left">£20,000</div>
                  									</div>
                								</div>
              							</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[2426px] left-[96px] w-[1248px] flex flex-col items-start justify-start gap-[24px]">
        				<div className="self-stretch flex flex-row items-start justify-between pt-0 px-0 pb-6 border-b-[1px] border-solid border-neutrals-neutral-100">
          					<div className="relative font-semibold">Summary statistics</div>
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 cursor-pointer text-sm border-[1px] border-solid border-neutrals-neutral-100" onClick={openExportModalImage1}>
            						<div className="flex flex-row items-center justify-center gap-[6px]">
              							<img className="w-5 relative h-5" alt="" src="assets/export.svg" />
              							<div className="relative font-semibold">Export</div>
            						</div>
          					</div>
        				</div>
        				<div className="flex flex-row items-start justify-start gap-[24px] text-lg">
          					<div className="w-[506px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="self-stretch bg-neutrals-neutral-50 flex flex-row items-center justify-between pt-0 px-0 pb-6 border-b-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">Top clients by revenue</div>
              							<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
            						</div>
            						<div className="self-stretch flex flex-row items-start justify-start gap-[24px] text-center text-sm text-neutrals-neutral-300">
              							<div className="w-60 h-60 flex flex-row items-center justify-center relative gap-[10px]">
                								<div className="flex flex-row items-start justify-start relative gap-[10px] z-[0]">
                  									<img className="w-[234px] relative h-[234px] z-[0]" alt="" src="Group 169.svg" />
                  									<img className="w-[194px] absolute !m-[0] top-[calc(50%_-_97px)] left-[calc(50%_-_98px)] h-[194px] z-[1]" alt="" src="Group 168.svg" />
                  									<img className="w-[159.4px] absolute !m-[0] top-[37.32px] left-[36.32px] h-[159.4px] z-[2]" alt="" src="Group 167.svg" />
                								</div>
                								<div className="!m-[0] absolute top-[90px] left-[77px] flex flex-col items-center justify-start gap-[2px] z-[1]">
                  									<div className="relative">Profit margin</div>
                  									<b className="relative text-9xl text-neutrals-neutral-900 text-left">25%</b>
                								</div>
              							</div>
              							<div className="flex-1 h-[216px] flex flex-col items-start justify-between text-right">
                								<div className="flex flex-row items-start justify-start gap-[6px]">
                  									<div className="flex flex-row items-start justify-start pt-1.5 px-0 pb-0">
                    										<div className="w-2 relative rounded-[50%] bg-primary-primary-500 h-2" />
                  									</div>
                  									<div className="flex flex-col items-start justify-start gap-[4px]">
                    										<div className="relative">Client A</div>
                    										<div className="relative text-5xl font-semibold text-neutrals-neutral-900 text-left">£200,000</div>
                  									</div>
                								</div>
                								<div className="flex flex-row items-start justify-start gap-[6px]">
                  									<div className="flex flex-row items-start justify-start pt-1.5 px-0 pb-0">
                    										<div className="w-2 relative rounded-[50%] bg-primary-primary-300 h-2" />
                  									</div>
                  									<div className="flex flex-col items-start justify-start gap-[4px]">
                    										<div className="relative">Client B</div>
                    										<div className="relative text-5xl font-semibold text-neutrals-neutral-900 text-left">£150,000</div>
                  									</div>
                								</div>
                								<div className="flex flex-row items-start justify-start gap-[6px]">
                  									<div className="flex flex-row items-start justify-start pt-1.5 px-0 pb-0">
                    										<div className="w-2 relative rounded-[50%] bg-primary-primary-100 h-2" />
                  									</div>
                  									<div className="flex flex-col items-start justify-start gap-[4px]">
                    										<div className="relative">Client C</div>
                    										<div className="relative text-5xl font-semibold text-neutrals-neutral-900 text-left">£100,000</div>
                  									</div>
                								</div>
              							</div>
            						</div>
          					</div>
          					<div className="w-[506px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start p-6 gap-[24px] text-base border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="self-stretch flex flex-row items-center justify-between pt-0 px-0 pb-6 border-b-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">Top revenue breakdown by type</div>
              							<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
            						</div>
            						<div className="self-stretch flex flex-row items-start justify-start gap-[24px] text-right text-sm text-neutrals-neutral-300">
              							<img className="w-60 relative h-60" alt="" src="Group 213.svg" />
              							<div className="flex-1 h-[216px] flex flex-col items-start justify-between">
                								<div className="self-stretch flex flex-row items-start justify-start gap-[6px]">
                  									<div className="flex flex-row items-start justify-start pt-1.5 px-0 pb-0">
                    										<div className="w-2 relative rounded-[50%] bg-primary-primary-500 h-2" />
                  									</div>
                  									<div className="flex flex-col items-start justify-start gap-[4px]">
                    										<div className="relative">Debt</div>
                    										<div className="relative text-5xl font-semibold text-neutrals-neutral-900 text-left">£125,000</div>
                  									</div>
                								</div>
                								<div className="self-stretch flex flex-row items-start justify-start gap-[6px]">
                  									<div className="flex flex-row items-start justify-start pt-1.5 px-0 pb-0">
                    										<div className="w-2 relative rounded-[50%] bg-primary-primary-300 h-2" />
                  									</div>
                  									<div className="flex flex-col items-start justify-start gap-[4px]">
                    										<div className="relative">Consultation</div>
                    										<div className="relative text-5xl font-semibold text-neutrals-neutral-900 text-left">£130,000</div>
                  									</div>
                								</div>
                								<div className="self-stretch flex flex-row items-start justify-start gap-[6px]">
                  									<div className="flex flex-row items-start justify-start pt-1.5 px-0 pb-0">
                    										<div className="w-2 relative rounded-[50%] bg-primary-primary-100 h-2" />
                  									</div>
                  									<div className="flex flex-col items-start justify-start gap-[4px]">
                    										<div className="relative">Others</div>
                    										<div className="relative text-5xl font-semibold text-neutrals-neutral-900 text-left">£150,000</div>
                  									</div>
                								</div>
              							</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[358px] left-[96px] font-semibold">Billing performance</div>
      			<div className="absolute top-[404px] left-[96px] rounded-3xs bg-neutrals-neutral-50 box-border w-[400px] flex flex-col items-start justify-start text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-start justify-between p-6">
          					<div className="flex flex-col items-start justify-start gap-[24px]">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<img className="w-5 relative h-5" alt="" src="assets/flash.svg" />
            						</div>
            						<div className="flex flex-col items-start justify-start gap-[10px]">
              							<div className="relative">Total revenue</div>
              							<b className="relative text-13xl text-neutrals-neutral-900">£200,000</b>
            						</div>
          					</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
        				</div>
      			</div>
      			<div className="absolute top-[404px] left-[520px] rounded-3xs bg-neutrals-neutral-50 box-border w-[400px] flex flex-col items-start justify-start text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-start justify-between p-6">
          					<div className="flex flex-col items-start justify-start gap-[24px]">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<img className="w-5 relative h-5" alt="" src="assets/money.svg" />
            						</div>
            						<div className="flex flex-col items-start justify-start gap-[10px]">
              							<div className="relative">Average billing amount</div>
              							<b className="relative text-13xl text-neutrals-neutral-900">£2000</b>
            						</div>
          					</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
        				</div>
      			</div>
      			<div className="absolute top-[404px] left-[944px] rounded-3xs bg-neutrals-neutral-50 box-border w-[400px] flex flex-col items-start justify-start text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-start justify-between p-6">
          					<div className="flex flex-col items-start justify-start gap-[24px]">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<img className="w-5 relative h-5" alt="" src="assets/calendar.svg"/>
            						</div>
            						<div className="flex flex-col items-start justify-start gap-[10px]">
              							<div className="relative">Billing frequency</div>
              							<b className="relative text-13xl text-neutrals-neutral-900">Monthly</b>
            						</div>
          					</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
        				</div>
      			</div>
      			<div className="absolute top-[616px] left-[96px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start p-6 gap-[24px] text-lg border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-center justify-between">
          					<div className="relative font-semibold">Monthly revenue trends</div>
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 cursor-pointer text-sm border-[1px] border-solid border-neutrals-neutral-100" onClick={openExportModalImage2}>
            						<div className="flex flex-row items-center justify-center gap-[6px]">
              							<img className="w-5 relative h-5" alt="" src="assets/export.svg" />
              							<div className="relative font-semibold">Export</div>
            						</div>
          					</div>
        				</div>
        				<div className="self-stretch h-[292px] flex flex-col items-end justify-center text-center text-sm">
          					<div className="flex flex-col items-start justify-start">
            						<div className="flex flex-row items-center justify-start gap-[10px]">
              							<div className="flex flex-row items-center justify-start gap-[10px]">
                								<div className="relative font-semibold [transform:_rotate(-90deg)]">Revenue</div>
                								<div className="h-[201px] flex flex-col items-end justify-start gap-[11px] text-left text-neutrals-neutral-300">
                  									<div className="relative">60</div>
                  									<div className="relative">50</div>
                  									<div className="relative">40</div>
                  									<div className="relative">30</div>
                  									<div className="relative">20</div>
                  									<div className="relative">10</div>
                  									<div className="relative">0</div>
                								</div>
              							</div>
              							<div className="flex flex-col items-start justify-start relative gap-[10px]">
                								<div className="flex flex-col items-start justify-start gap-[30px] z-[0]">
                  									<div className="w-[1145px] relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="w-[1145px] relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="w-[1145px] relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="w-[1145px] relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="w-[1145px] relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="w-[1145px] relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="w-[1145px] relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                								</div>
                								<div className="!m-[0] absolute top-[25px] left-[0px] flex flex-col items-end justify-start z-[1]">
                  									<img className="w-[1144px] relative h-[191px]" alt="" src="Group 227.svg" />
                								</div>
              							</div>
            						</div>
            						<div className="w-[1200px] h-12 flex flex-col items-center justify-between text-neutrals-neutral-300">
              							<div className="w-[1036px] flex flex-row items-start justify-between">
                								<div className="relative">Jan</div>
                								<div className="relative">Feb</div>
                								<div className="relative">March</div>
                								<div className="relative">April</div>
                								<div className="relative">May</div>
                								<div className="relative">June</div>
                								<div className="relative">July</div>
                								<div className="relative">Aug</div>
                								<div className="relative">Sep</div>
                								<div className="relative">Oct</div>
                								<div className="relative">Nov</div>
                								<div className="relative">Dec</div>
              							</div>
              							<div className="self-stretch relative font-semibold text-neutrals-neutral-900">Days</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[1044px] left-[96px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] flex flex-col items-start justify-start text-sm">
        				<div className="w-[1248px] rounded-t-3xs rounded-b-none box-border flex flex-row items-center justify-between py-5 px-6 text-lg border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-l-[1px]">
          					<div className="flex flex-row items-center justify-start gap-[10px]">
            						<div className="relative font-semibold">Revenue breakdown</div>
            						<div className="rounded-[5px] bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="w-1.5 relative rounded-[50%] bg-primary-primary-500 h-1.5" />
              							<div className="relative font-semibold">10 entries</div>
            						</div>
          					</div>
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 cursor-pointer text-sm border-[1px] border-solid border-neutrals-neutral-100" onClick={openDownloadModalTables}>
            						<div className="flex flex-row items-center justify-center gap-[6px]">
              							<img className="w-5 relative h-5" alt="" src="assets/download.svg" />
              							<div className="relative font-semibold">Download</div>
            						</div>
          					</div>
        				</div>
        				<div className="w-[1248px] box-border flex flex-row items-center justify-end py-3 px-6 text-neutrals-neutral-300 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-l-[1px]">
          					<div className="w-[1200px] flex flex-row items-start justify-between">
            						<div className="w-[294px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-start py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-start gap-[10px]">
                								<img className="w-5 relative h-5" alt="" src="assets/search.svg" />
                								<div className="relative">{`Search `}</div>
              							</div>
            						</div>
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 cursor-pointer text-neutrals-neutral-900 border-[1px] border-solid border-neutrals-neutral-100" onClick={openRevenueFilter}>
              							<div className="flex flex-row items-center justify-center gap-[6px]">
                								<img className="w-5 relative h-5" alt="" src="assets/filter.svg" />
                								<div className="relative font-semibold">Filter</div>
              							</div>
            						</div>
          					</div>
        				</div>
        				<div className="flex flex-col items-start justify-start">
          					<div className="w-[1248px] h-[594px] flex flex-col items-start justify-start">
            						<div className="self-stretch flex flex-row items-start justify-start">
              							<div className="w-[212px] flex flex-col items-start justify-start">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
                  									<div className="relative font-semibold">Client name</div>
                								</div>
                								<div className="self-stretch flex flex-col items-start justify-start">
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">Paras Mistry</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">Nathan James</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">Julie Wyse</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">Baanji</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">Megan Gibson</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">Laura Blake</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">Diane Elgar</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">Ervins Puksts</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">Elisa Gashi</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">Rotana West</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[236px] flex flex-col items-start justify-start">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">Case type</div>
                								</div>
                								<div className="self-stretch flex flex-col items-start justify-start">
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Debt</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Contract</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Dispute</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Letigation</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Consultation</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Debt</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Debt</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Consultation</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Letigation</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Debt</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[236px] flex flex-col items-start justify-start">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">Billing amount</div>
                								</div>
                								<div className="self-stretch flex flex-col items-start justify-start">
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£20,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£30,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£60,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£40,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£15,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£20,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£20,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£10,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£50,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£20,000</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[140px] flex flex-col items-start justify-start">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">Court fees</div>
                								</div>
                								<div className="self-stretch flex flex-col items-start justify-start">
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£2000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£3000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£6000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£2000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£4000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£7500</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£5000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£3000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£4000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£2000</div>
                  									</div>
                								</div>
              							</div>
              							<div className="flex-1 flex flex-col items-start justify-start">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">Legal fees</div>
                								</div>
                								<div className="self-stretch flex flex-col items-start justify-start">
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£10,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£13,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£7,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£13,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£11,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£9,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£10,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£15,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£12,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£10,000</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[236px] flex flex-col items-start justify-start">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-b-[1px]">
                  									<div className="relative font-semibold">Total revenue</div>
                								</div>
                								<div className="self-stretch flex flex-col items-start justify-start">
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">£32,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">£46,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">£73,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">£55,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">£30,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">£36,500</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">£35,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">£28,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">£64,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">£32,000</div>
                  									</div>
                								</div>
              							</div>
            						</div>
          					</div>
          					<div className="w-[1248px] rounded-t-none rounded-b-3xs box-border flex flex-row items-center justify-between pt-3 px-6 pb-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">Previous</div>
              							</div>
            						</div>
            						<div className="relative">Page 1 of 10</div>
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">Next</div>
              							</div>
            						</div>
          					</div>
        				</div>
      			</div>

				{/* Navigation Panel */}
				<NavigationPanelLawyer navItems={navItems}/>

				{/* Breadcrumb Navigation */}
				<Breadcrumb breadcrumbs={breadcrumb} textColor="text-primary-primary-500" position="top-[24px] left-[90px]"/>

    		</div>
    		{isExportModalImageOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeExportModalImage}
        				>
        				<ExportModalImage onClose={closeExportModalImage} />
      			</PortalPopup>
    		)}
    		{isExportModalImage1Open && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeExportModalImage1}
        				>
        				<ExportModalImage onClose={closeExportModalImage1} />
      			</PortalPopup>
    		)}
    		{isExportModalImage2Open && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeExportModalImage2}
        				>
        				<ExportModalImage onClose={closeExportModalImage2} />
      			</PortalPopup>
    		)}
    		{isDownloadModalTablesOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeDownloadModalTables}
        				>
        				<DownloadModalTables onClose={closeDownloadModalTables} />
      			</PortalPopup>
    		)}
    		{isRevenueFilterOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeRevenueFilter}
        				>
        				<RevenueFilter onClose={closeRevenueFilter} />
      			</PortalPopup>
    		)}
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}</>);
};

export default Legal_proceedings_3;