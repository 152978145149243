// import React, { useState } from 'react';
// import PropTypes from 'prop-types';
// import { useNavigate } from 'react-router-dom';
// import NavigationDropdown from './NavigationDropdown';

// const DebtCollectionNav = ({ navItems }) => {
//   const navigate = useNavigate();
//   const [activeIndex, setActiveIndex] = useState(null);
//   const [isDropdownOpen, setDropdownOpen] = useState(false);

//   const handleMouseEnter = (index) => {
//     setActiveIndex(index);
//   };

//   const handleMouseLeave = () => {
//     setActiveIndex(null);
//   };

//   const handleClick = (path) => {
//     navigate(path);
//   };

//   return (
//     <div className="w-full flex flex-row items-center gap-[4px] rounded-[5px] box-border h-auto overflow-hidden text-left text-base text-neutrals-neutral-300 font-open-sans-ff-body-strong border-[1px]">
//       {navItems.map((item, index) => (
//         <div
//           key={index}
//           className={`flex flex-row items-center justify-center py-2 px-3 gap-[10px] rounded-3xs cursor-pointer
//             ${activeIndex === index ? item.hoverBgColor : item.bgColor}
//             ${activeIndex === index ? item.hoverTextColor : item.textColor}`}
//           onMouseEnter={() => handleMouseEnter(index)}
//           onMouseLeave={handleMouseLeave}
//           onClick={() => handleClick(item.path)}
//         >
//           <div className="relative font-semibold">{item.text}</div>
//           {item.icon && <img className="w-5 relative h-5" alt="" src={item.icon} />}
//         </div>
//       ))}
//     </div>
//   );
// };

// DebtCollectionNav.propTypes = {
//   navItems: PropTypes.arrayOf(
//     PropTypes.shape({
//       text: PropTypes.string.isRequired,
//       bgColor: PropTypes.string.isRequired,
//       textColor: PropTypes.string.isRequired,
//       hoverBgColor: PropTypes.string.isRequired,
//       hoverTextColor: PropTypes.string.isRequired,
//       onClick: PropTypes.string.isRequired,
//       path: PropTypes.string.isRequired,
//       icon: PropTypes.string,
//     })
//   ).isRequired,
// };

// export default DebtCollectionNav;

//Code 2
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import NavigationDropdown from './NavigationDropdown'; // Adjust the import path as necessary

const DebtCollectionNav = ({ navItems }) => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  const handleClick = (item, index) => {
    if (item.dropdown) {
      setDropdownOpen((prev) => !prev);
    } else {
      navigate(item.path);
    }
  };

  return (
    <div className="w-full h-auto flex flex-row items-center gap-[4px] rounded-[5px] box-border overflow-hidden text-left text-base text-neutrals-neutral-300 font-open-sans-ff-body-strong border-[1px]">
      {navItems.map((item, index) => (
        <div
          key={index}
          className={`flex flex-row items-center justify-center py-2 px-3 gap-[10px] rounded-3xs cursor-pointer
            ${activeIndex === index ? item.hoverBgColor : item.bgColor}
            ${activeIndex === index ? item.hoverTextColor : item.textColor}`}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleClick(item, index)}
        >
          <div className="relative font-semibold">{item.text}</div>
          {item.icon && <img className="w-5 relative h-5" alt="" src={item.icon} />}
          {isDropdownOpen && item.dropdown && index === 0 && (
            <div className="absolute top-full left-0 mt-2 w-full z-10">
              <NavigationDropdown />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

DebtCollectionNav.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      bgColor: PropTypes.string.isRequired,
      textColor: PropTypes.string.isRequired,
      hoverBgColor: PropTypes.string.isRequired,
      hoverTextColor: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      dropdown: PropTypes.bool,
      icon: PropTypes.string,
    })
  ).isRequired,
};

export default DebtCollectionNav;