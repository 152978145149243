import { useCallback, useState } from "react";
import PropTypes from 'prop-types';
import NameInput from '../components/NameInput';
import CTAs from "./CTAs";
import { Navigate, useNavigate } from "react-router-dom";

const NewPayment = ({onClose}) => {
      const navigate = useNavigate();

      const onCTAContinueClick = useCallback(() => {
            navigate('/newpayment2');
      }, [navigate]);

      return (
          <div className="relative shadow-[0px_10px_60px_rgba(0,_0,_0,_0.1)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start max-w-full max-h-full overflow-auto text-left text-base text-neutrals-neutral-50 font-open-sans-ff-meta-link border-[1px] border-solid border-neutrals-neutral-100 ${className}">
                <div className="flex flex-col items-start justify-start pt-6 px-6 pb-0">
                      <div className="rounded-3xs overflow-hidden flex flex-col items-start justify-start py-8 px-[58px] bg-[url('Frame_1239.png')] bg-cover bg-no-repeat bg-[top]">
                            <div className="w-[316px] shadow-[0px_10px_60px_rgba(0,_0,_0,_0.1)] rounded-3xs bg-gray box-border h-[190px] flex flex-col items-start justify-start p-4 gap-[90px] border-[1px] border-solid border-bg-primary"/>
                      </div>
                </div>
                <div className="flex flex-col items-start justify-start gap-[40px] text-lg text-neutrals-neutral-900">
                      <div className="flex flex-col items-start justify-start gap-[24px]">
                            <div className="w-[480px] flex flex-col items-start justify-start pt-6 px-6 pb-0 box-border gap-[6px]">
                                  <div className="relative font-semibold">Billing information</div>
                                  <div className="relative text-sm text-neutrals-neutral-300">Enter your billing information.</div>
                            </div>
                            <div className="flex flex-col items-start justify-start py-0 px-6 gap-[16px] text-sm">
                                  <div className="flex flex-row items-start justify-start gap-[16px]">
                                        <div className="w-[304px] flex flex-col items-start justify-start gap-[10px]">
                                          <NameInput
                                          title="Name on card"
                                          placeholder="John Clark"
                                          width="304px"
                                          />
                                        </div>
                                        <div className="w-28 flex flex-col items-start justify-start gap-[10px]">
                                              <div className="flex flex-row items-center justify-start gap-[6px]">
                                                    <div className="relative font-semibold">Expiry</div>
                                                    <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg" />
                                              </div>
                                              <div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 overflow-hidden flex flex-row items-center justify-start p-3 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
                                                    <div className="relative">01 / 2024</div>
                                              </div>
                                        </div>
                                  </div>
                                  <div className="flex flex-row items-start justify-start gap-[16px]">
                                        <div className="w-[304px] flex flex-col items-start justify-start gap-[10px]">
                                              <div className="flex flex-row items-center justify-start gap-[6px]">
                                                    <div className="relative font-semibold">Card number</div>
                                                    <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg" />
                                              </div>
                                              <div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 overflow-hidden flex flex-row items-center justify-start p-3 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
                                                    <div className="relative">1234 1234 1234 1234</div>
                                              </div>
                                        </div>
                                        <div className="w-28 flex flex-col items-start justify-start gap-[10px]">
                                              <div className="flex flex-row items-center justify-start gap-[6px]">
                                                    <div className="relative font-semibold">CVV</div>
                                                    <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg" />
                                              </div>
                                              <div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 overflow-hidden flex flex-row items-center justify-start p-3 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
                                                    <div className="relative">123</div>
                                              </div>
                                        </div>
                                  </div>
                            </div>
                      </div>
                      <div className="flex flex-row items-start justify-start pt-0 px-6 pb-6 gap-[12px] text-sm">
                        <CTAs
				text="Cancel"
				textColor="text-neutrals-neutral-900"
				bgColor="bg-neutrals-neutral-50"
				borderColor="border-neutrals-neutral-100"
                        width="210px"
				position="relative"
                        onClick={onClose}
				/>

                        <CTAs
				text="Continue"
				textColor="text-neutrals-neutral-50"
				bgColor="bg-primary-primary-500"
				borderColor="border-primary-primary-500"
                        width="210px"
				position="relative"
                        onClick={onCTAContinueClick}
				/>
                      </div>
                </div>
          </div>);
};

NewPayment.propTypes = {
    className: PropTypes.string
};

export default NewPayment;