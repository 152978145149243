import { useState, useCallback } from 'react';
import LogoutConfirmation from "../components/LogoutConfirmation";
import PortalPopup from "../components/PortalPopup";
import DebtCollectionNav from './DebtCollectionNav';

const NavigationPanelLawyer = ({ navItems }) => {
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	
  	const openLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(true);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 box-border flex flex-row items-center justify-between py-8 px-24 text-left text-base text-neutrals-neutral-300 font-open-sans-ff-body-strong border-b-[1px] border-solid border-neutrals-neutral-100">
      			<div className="flex flex-row items-center justify-start gap-[51px]">
        				<img className="w-[118px] relative h-10 object-cover" alt="" src="assets/logo-horizontal.svg" />
						<DebtCollectionNav navItems={navItems}/>
      			</div>
      			<div className="flex flex-row items-start justify-end gap-[6px]">
        				<div className="rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5">
          					<img className="w-5 relative h-5" alt="" src="assets/setting.svg" />
        				</div>
        				<div className="rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5">
          					<div className="flex flex-row items-start justify-start relative gap-[10px]">
            						<img className="w-5 relative h-5 z-[0]" alt="" src="assets/notification.svg" />
            						<div className="w-2.5 absolute !m-[0] top-[0px] left-[10px] rounded-[50%] bg-semantics-high-high-500 box-border h-2.5 z-[1] border-[1.5px] border-solid border-neutrals-neutral-50" />
          					</div>
        				</div>
        				<div className="flex flex-row items-start justify-end p-2.5 cursor-pointer" onClick={openLogoutConfirmation}>
          					<img className="w-5 relative h-5" alt="" src="assets/logout.svg" />
        				</div>
      			</div>
    		</div>
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}</>);
};

export default NavigationPanelLawyer;