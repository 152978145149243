import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const NameInput = ({ title, placeholder, width, value, onChange, validationMessage, iconColor, iconSize, apiEndpoint, bgColorLight, bgColorDark, isDarkMode }) => {

  const [error, setError] = useState('');

  const handleBlur = () => {
    if (!value.trim()) {
      setError(validationMessage);
    } else {
      setError('');
      //Submit data to the backend if valid
      axios.post(apiEndpoint, { name: value })
      .then(() => console.log('Data saved successfully.'))
      .catch((err) => console.error('Error saving data:', err));
    }
  };

  const inputStyle = {
    width,
    color: value.trim() ? '#2D404E' : '#fff',
    backgroundColor: isDarkMode ? bgColorDark : bgColorLight,
    borderColor: error ? '#D32F2F' : '#333741', // Red border only when error is present
  };

  return (
    <div className="flex flex-col items-start justify-start gap-[10px]">
      <div className="flex flex-row items-center justify-start gap-[6px]">
        <div className="relative font-semibold">{title}</div>
        <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="/assets/help-circle.svg" />
      </div>

      {/* Creating extra div class - Start */}
      <div className='relative w-full' style={{width}}>
        <input
          className={`shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border overflow-hidden flex flex-row items-center justify-start p-3 text-base border-[1px] border-solid ${error ? 'border-red-500 text-red-500' : 'border-neutrals-neutral-100 text-neutrals-neutral-300'}`}
          type='text'
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={handleBlur}
          style={inputStyle}
        />
        {error && (
          <div className='absolute inset-y-0 right-3 flex items-center'>
            <HelpOutlineIcon style={{ color: iconColor, fontSize: iconSize }} titleAccess={error}/>
          </div>
        )}
      </div>
      {/* Creating extra div class - End */}

      {/* Commented temporary */}
      {/* <input
        className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border overflow-hidden flex flex-row items-center justify-start p-3 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100"
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        style={{ width, color: error ? "#D32F2F" : "#2D404E" }}
      />
      
      {error && <div className='text-sm text-red-500 mt-1'>{error}</div>}
       */}
    </div>
  );
};

NameInput.propTypes = {
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  width: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,

  validationMessage: PropTypes.string,
  iconColor: PropTypes.string,
  iconSize: PropTypes.string,
  tooltipPosition: PropTypes.oneOf(['top','right','bottom','left']),
  apiEndpoint: PropTypes.string.isRequired,
  bgColorLight: PropTypes.string,
  bgColorDark: PropTypes.string,
  isDarkMode: PropTypes.bool,
};

NameInput.defaultProps = {
  width: '400px',

  validationMessage: 'This field is required.',
  iconColor: '#2D404E',
  iconSize: '20px',
  bgColorLight: '#FFFFFF',
  bgColorDark: '#1E1E1E',
  isDarkMode: false,
};

export default NameInput;