import { useCallback, useState } from "react";
import CTAs from "../components/CTAs";
import Dropdown from "../components/Dropdown";

const CustomiseReport = ({onClose}) => {

const dropdownContents = ["Goods sold and delivered", "Services rendered (90% of claims)", "Leasehold issues", "Charities", "Engineering disputes"];

    return (
          <div className="w-[456px] relative shadow-[0px_10px_60px_rgba(0,_0,_0,_0.1)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start max-w-full max-h-full overflow-auto text-left text-9xl text-neutrals-neutral-900 font-open-sans-ff-body-strong border-[1px] border-solid border-neutrals-neutral-100">
                <div className="w-[456px] flex flex-col items-start justify-start gap-[40px]">
                      <div className="w-[456px] flex flex-col items-start justify-start gap-[24px]">
                            <div className="self-stretch flex flex-col items-start justify-start pt-6 px-6 pb-0">
                                  <div className="w-[400px] flex flex-col items-start justify-start">
                                        <div className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-6 gap-[6px] border-b-[1px] border-solid border-neutrals-neutral-100">
                                              <b className="relative">Customise report</b>
                                              <div className="relative text-sm text-neutrals-neutral-300">Submit your legal inquiry for expert advice.</div>
                                        </div>
                                  </div>
                            </div>
                            <div className="w-[456px] flex flex-col items-start justify-start py-0 px-6 box-border gap-[16px] text-sm">
                                    <div className="w-[400px] flex flex-row items-start justify-start">
                                          <div className="w-[400px] flex flex-col items-start justify-start gap-[10px]">
                                                <Dropdown
                                                title="What’s your claim for?"
                                                placeholder="Select categories"
                                                icon="assets/help-circle.svg"
                                                dropdownContents={dropdownContents}
                                                />
                                          </div>
                                    </div>
                                    <div className="w-[400px] flex flex-row items-start justify-start">
                                          <div className="w-[400px] flex flex-col items-start justify-start gap-[10px]">
                                                <div className="flex flex-row items-center justify-start gap-[6px]">
                                                      <div className="relative font-semibold">Select date</div>
                                                      <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg"/>
                                                </div>
                                          <div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 overflow-hidden flex flex-row items-center justify-start p-3 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
                                          <div className="relative">01/01/2024</div>
                                    </div>
                                    </div>
                                                          </div>
                                                          </div>
                                                          </div>
                                                          <div className="self-stretch flex flex-row items-start justify-between pt-0 px-6 pb-6 text-sm">
                                                            <CTAs
                                                            type="Ghostbtn"
                                                            text="Cancel"
                                                            textColor="text-neutrals-neutral-900"
                                                            bgColor="bg-neutrals-neutral-50"
                                                            borderColor="border-neutrals-neutral-100"
                                                            width="190px"
                                                            position="relative"
                                                            onClick={onClose}
                                                            />

                                                            <CTAs
                                                            type="Primarybtn"
                                                            text="Continue"
                                                            textColor="text-neutrals-neutral-50"
                                                            bgColor="bg-primary-primary-500"
                                                            borderColor="border-primary-primary-500"
                                                            width="190px"
                                                            position="relative"
                                                            />
                                                          </div>
                                                          </div>
                                                          </div>);
                                                    };
                                                    
                                                    export default CustomiseReport;                         