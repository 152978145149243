// import React, { useState } from "react";

// export default function Toggle({
//   onToggle,
//   isOn: defaultIsOn = false,
//   onColor = "bg-primary-primary-500",
//   offColor = "bg-primary-primary-50",
//   knobColor = "bg-neutrals-neutral-50",
//   size = "w-16 h-8"
// }) {
//   const [isOn, setIsOn] = useState(defaultIsOn);

//   const handleToggle = () => {
//     setIsOn(!isOn);
//     if (onToggle) {
//       onToggle(!isOn);
//     }
//   };

//   return (
//     <div
//       className={`${size} relative flex items-center cursor-pointer rounded-[100px] p-1 transition-colors duration-300 ease-in-out ${
//         isOn ? onColor : offColor
//       }`}
//       onClick={handleToggle}
//     >
//       <div
//         className={`w-4 h-4 ${knobColor} rounded-[100px] shadow-md transform transition-transform duration-300 ease-in-out ${
//           isOn ? "translate-x-8" : "translate-x-0"
//         }`}
//       />
//     </div>
//   );
// }

//Code 2
// import React, { useState } from "react";

// export default function Toggle({
//   onToggle,
//   isOn: defaultIsOn = false,
//   onColor = "bg-primary-primary-500",
//   offColor = "bg-primary-primary-50",
//   knobColor = "bg-neutrals-neutral-50"
// }) {
//   const [isOn, setIsOn] = useState(defaultIsOn);

//   const handleToggle = () => {
//     setIsOn(!isOn);
//     if (onToggle) {
//       onToggle(!isOn);
//     }
//   };

//   return (
//     <div
//       className={`relative w-8 h-auto flex items-center cursor-pointer rounded-full p-1 transition-colors duration-300 ease-in-out ${
//         isOn ? onColor : offColor
//       }`}
//       onClick={handleToggle}
//     >
//       <div
//         className={`w-4 h-4 ${knobColor} rounded-full shadow-md transform transition-transform duration-300 ease-in-out ${
//           isOn ? "translate-x-6" : "translate-x-0"
//         }`}
//       />
//     </div>
//   );
// }

//Code 3
import React, { useState } from "react";

export default function Toggle({
  onToggle,
  isOn: defaultIsOn = false,
  onColor = "bg-primary-primary-500",
  offColor = "bg-primary-primary-50",
  knobColor = "bg-neutrals-neutral-50"
}) {
  const [isOn, setIsOn] = useState(defaultIsOn);

  const handleToggle = () => {
    setIsOn(!isOn);
    if (onToggle) {
      onToggle(!isOn);
    }
  };

  return (
    <div
      className={`relative w-9 h-auto flex items-center cursor-pointer rounded-full p-0.5 transition-colors duration-300 ease-in-out ${
        isOn ? onColor : offColor
      }`}
      onClick={handleToggle}
    >
      <div
        className={`w-4 h-4 ${knobColor} rounded-full shadow-md transform transition-transform duration-300 ease-in-out ${
          isOn ? "translate-x-5" : "translate-x-0"
        }`}
      />
    </div>
  );
}