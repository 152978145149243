// import { useState, useCallback } from "react";
// import PropTypes from 'prop-types';

// const DropdownHover = () => {

//     const hoverStyles = {
//         base: "self-stretch rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-start p-2.5",
//         hover: "hover:bg-primary-primary-50",
//         selected: "font-semibold text-neutrals-neutral-900",
//         default: "text-neutrals-neutral-300",
//       };
      
//     return (
//           <div className="w-full relative rounded-[5px] box-border h-[152px] overflow-hidden text-left text-base text-neutrals-neutral-300 font-open-sans-ff-body-strong border-[1px] border-dashed border-blueviolet">
//                 <div className="absolute top-[20px] left-[20px] w-[400px] flex flex-col items-start justify-start py-px px-1.5 box-border">
//                       <div className="self-stretch rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-start p-2.5">
//                             <div className="relative font-semibold">Individuals</div>
//                       </div>
//                 </div>
//                 <div className="absolute top-[88px] left-[20px] w-[400px] flex flex-col items-start justify-start py-px px-1.5 box-border text-neutrals-neutral-900">
//                       <div className="self-stretch rounded-3xs bg-primary-primary-50 flex flex-row items-center justify-start p-2.5">
//                             <div className="relative font-semibold">Individuals</div>
//                       </div>
//                 </div>
//           </div>);
// };

// export default DropdownHover;

//Code 2
// import React from "react";

// const DropdownHover = ({ dropdownContents }) => {
//   return (
//     <div className="w-full relative rounded-3xs box-border overflow-hidden text-left text-base text-neutrals-neutral-300 font-open-sans-ff-body-strong border-[1px] border-solid border-neutrals-neutral-100">
//       {dropdownContents.map((content, index) => (
//         <div
//           key={index}
//           className="w-full flex flex-col items-start justify-start py-px px-1.5 box-border cursor-pointer hover:bg-primary-primary-50"
//         >
//           <div className="self-stretch rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-start p-2.5">
//             <div className="relative font-semibold">{content}</div>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default DropdownHover;

//Code 3
import React, { useState } from "react";

const DropdownHover = ({ title, placeholder, dropdownContents, icon, width = "400px", selectedIndex, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleSelect = (index) => {
    onSelect(index);
    setIsOpen(false);
  };

  return (
    <div className={`relative flex flex-col items-start justify-start gap-[6px] text-left text-sm text-neutrals-neutral-900 font-open-sans-ff-body-strong`} style={{ width }}>
      <div className="flex flex-row items-start justify-start gap-[6px]">
        <div className="relative font-semibold">{title}</div>
        {icon && <img className="w-5 h-5 overflow-hidden" alt="" src={icon} />}
      </div>
      <div
        className={`shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-between py-3.5 px-2.5 text-base border-[1px] border-solid ${
          isOpen ? "border-primary-primary-500" : "border-neutrals-neutral-100"
        }`}
        onClick={handleToggleDropdown}
        style={{ width }}
      >
        <div className={`relative ${selectedIndex !== null ? "font-semibold" : "text-neutrals-neutral-300"}`}>
          {selectedIndex !== null ? dropdownContents[selectedIndex] : placeholder}
        </div>
        <img className="w-5 h-5 overflow-hidden" alt="" src="assets/arrow-down.svg" />
      </div>
      {isOpen && (
        <div
          className="shadow-[0px_10px_60px_rgba(0,_0,_0,_0.1)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start py-1 px-0 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100"
          style={{ width }}
        >
          {dropdownContents.map((content, index) => (
            <div
              key={index}
              className={`w-full flex flex-col items-start justify-start py-px px-1.5 box-border cursor-pointer ${
                hoveredIndex === index || selectedIndex === index ? "bg-primary-primary-50 text-neutrals-neutral-900" : "bg-neutrals-neutral-50"
              }`}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleSelect(index)}
            >
              <div className={`self-stretch rounded-3xs flex flex-row items-center justify-start p-2.5 ${hoveredIndex === index || selectedIndex === index ? "font-semibold" : ""}`}>
                {content}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownHover;