// import React, { useState } from "react";

// const ClientAccountTypeStates = ({ onBlockClick }) => {
//   const [clickedBlock, setClickedBlock] = useState(null);

//   const handleBlockClick = (block) => {
//     setClickedBlock(block);
//     onBlockClick(block);
//   };

//   return (
//     <div className="flex w-[400px] justify-start gap-6">
//       <div
//         className={`w-[188px] bg-neutrals-neutral-50 border-solid ${
//           clickedBlock === "block1" ? "border-2 border-primary-primary-500" : "border-[1px] border-neutrals-neutral-100"
//         } rounded-3xs p-6 transition duration-300 ease-in-out cursor-pointer shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] ${
//           clickedBlock !== "block1" ? "hover:shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] hover:border-2 hover:border-primary-primary-500" : ""
//         }`}
//         onClick={() => handleBlockClick("block1")}
//       >
//         <img
//           className={`w-6 h-6 mb-2 ${clickedBlock === "block1" ? "text-primary-primary-500" : "text-neutrals-neutral-900"}`}
//           alt=""
//           src="assets/building.svg"
//         />
//         <div
//           className={`font-semibold ${
//             clickedBlock === "block1" ? "text-primary-primary-500" : "text-neutrals-neutral-900"
//           }`}
//         >
//           Company
//         </div>
//       </div>
//       <div
//         className={`w-[188px] bg-neutrals-neutral-50 border-solid ${
//           clickedBlock === "block2" ? "border-2 border-primary-primary-500" : "border-[1px] border-neutrals-neutral-100"
//         } rounded-3xs p-6 transition duration-300 ease-in-out cursor-pointer shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] ${
//           clickedBlock !== "block2" ? "hover:shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] hover:border-2 hover:border-primary-primary-500" : ""
//         }`}
//         onClick={() => handleBlockClick("block2")}
//       >
//         <img
//           className={`w-6 h-6 mb-2 ${clickedBlock === "block2" ? "text-primary-primary-500" : "text-neutrals-neutral-900"}`}
//           alt=""
//           src="assets/briefcase.svg"
//         />
//         <div
//           className={`font-semibold ${
//             clickedBlock === "block2" ? "text-primary-primary-500" : "text-neutrals-neutral-900"
//           }`}
//         >
//           Another Type
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ClientAccountTypeStates;

//Code 2
import React, { useState } from "react";

const ClientAccountTypeStates = ({ onBlockClick }) => {
  const [clickedBlock, setClickedBlock] = useState(null);

  const handleBlockClick = (block) => {
    setClickedBlock(block);
    onBlockClick(block);
  };

  return (
    <div className="flex w-[400px] gap-6">
      <div
        className={`w-[188px] bg-neutrals-neutral-50 border-solid ${
          clickedBlock === "block1" ? "border-2 border-primary-primary-500" : "border-[1px] border-neutrals-neutral-100"
        } rounded-3xs p-6 transition duration-300 ease-in-out cursor-pointer shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] ${
          clickedBlock !== "block1" ? "hover:shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] hover:border-2 hover:border-primary-primary-500" : ""
        }`}
        onClick={() => handleBlockClick("block1")}
      >
        <img
          className={`w-6 h-6 mb-2 ${clickedBlock === "block1" ? "text-primary-primary-500" : "text-neutrals-neutral-900"}`}
          alt=""
          src="assets/building.svg"
        />
        <div
          className={`font-semibold ${
            clickedBlock === "block1" ? "text-primary-primary-500" : "text-neutrals-neutral-900"
          }`}
        >
          Company
        </div>
      </div>
      <div
        className={`w-[188px] bg-neutrals-neutral-50 border-solid ${
          clickedBlock === "block2" ? "border-2 border-primary-primary-500" : "border-[1px] border-neutrals-neutral-100"
        } rounded-3xs p-6 transition duration-300 ease-in-out cursor-pointer shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] ${
          clickedBlock !== "block2" ? "hover:shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] hover:border-2 hover:border-primary-primary-500" : ""
        }`}
        onClick={() => handleBlockClick("block2")}
      >
        <img
          className={`w-6 h-6 mb-2 ${clickedBlock === "block2" ? "text-primary-primary-500" : "text-neutrals-neutral-900"}`}
          alt=""
          src="assets/briefcase.svg"
        />
        <div
          className={`font-semibold ${
            clickedBlock === "block2" ? "text-primary-primary-500" : "text-neutrals-neutral-900"
          }`}
        >
          Lawyer
        </div>
      </div>
    </div>
  );
};

export default ClientAccountTypeStates;