import { useState, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import ToastMsg from "../../components/ToastMsg";
import PortalPopup from "../../components/PortalPopup";
import LogoutConfirmation from "../../components/LogoutConfirmation";
import ExportModalTables from "../../components/ExportModalTables";
import NewPayment from "../../components/NewPayment";
import Upload from '../../components/Upload';
import NavigationPanel from '../../components/NavigationPanel';
import Breadcrumb from '../../components/Breadcrumb';
import CTAs from '../../components/CTAs';
import Dropdown from "../../components/Dropdown";
import Toggle from '../../components/Toggle';

const SettingsBillingPage = () => {
    const navigate = useNavigate();
  	const [isToastMsgOpen, setToastMsgOpen] = useState(false);
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	const [isExportModalTablesOpen, setExportModalTablesOpen] = useState(false);
  	const [isNewPayment1Open, setNewPayment1Open] = useState(false);

  	const onClickGeneral = useCallback(() => {
        navigate('/setting-general');
  	}, [navigate]);
  	
  	
  	const onClickPreferences = useCallback(() => {
        navigate('/setting-preferences');
  	}, [navigate]);
  	
  	const onClickLBAConfig = useCallback(() => {
        navigate('/setting-lbaconfig');
  	}, [navigate]);
  	
  	const onClickLegalProceedings = useCallback(() => {
        navigate('/setting-legalproceedings');
  	}, [navigate]);
  	
  	const onClickAIIntegration = useCallback(() => {
        navigate('/setting-aiintegration');
  	}, [navigate]);
  	
  	const onClickSecurity = useCallback(() => {
        navigate('/setting-security');
  	}, [navigate]);
  	
  	const onClickHelpSupport = useCallback(() => {
        navigate('/setting-help&support');
  	}, [navigate]);
  	
  	const onClickDataManagement = useCallback(() => {
		navigate('/setting-datamanagement');
  	}, [navigate]);
  	
  	const onClickAccount = useCallback(() => {
		navigate('/setting-account');
  	}, [navigate]);
  	
  	const openToastMsg = useCallback(() => {
    		setToastMsgOpen(true);
  	}, []);
  	
  	const closeToastMsg = useCallback(() => {
    		setToastMsgOpen(false);
  	}, []);
  	
  	const openLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(true);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);
  	
  	const openExportModalTables = useCallback(() => {
    		setExportModalTablesOpen(true);
  	}, []);
  	
  	const closeExportModalTables = useCallback(() => {
    		setExportModalTablesOpen(false);
  	}, []);
  	
  	const openNewPayment1 = useCallback(() => {
    		setNewPayment1Open(true);
  	}, []);
  	
  	const closeNewPayment1 = useCallback(() => {
    		setNewPayment1Open(false);
  	}, []);
  	
  	
  	const onCTAsContainerClick = useCallback(() => {
        navigate('/setting-billing2');
  	}, [navigate]);

	const breadcrumb = [
		{ label: "", path: "/postlba2", icon: "assets/Home.svg", active: true },
		{ label: "Settings", path: "", icon: "", active: false },
		{ label: "Billing", path: "", icon: "", active: true },
	];

	const dropdownContents = ["Weekly", "Monthly", "Yearly"];

	const handleToggle = (state) => {
		console.log("Toggle state is:", state);
	};
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[3651px] overflow-hidden text-left text-sm text-neutrals-neutral-900 font-open-sans-ff-meta-link">
      			<div className="absolute top-[293px] left-[96px] w-[188px] flex flex-col items-start justify-start gap-[6px] text-base text-neutrals-neutral-300">
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickGeneral}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">General</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickPreferences}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Preferences</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickLBAConfig}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">LBA configuration</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickLegalProceedings}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Legal proceedings</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickAIIntegration}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">AI integration</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickSecurity}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Security</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 box-border h-[41px] flex flex-row items-center justify-center py-2 px-3 text-primary-primary-500 border-l-[2px] border-solid border-primary-primary-500">
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Billing</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickHelpSupport}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">{`Help & support`}</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickDataManagement}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Data management</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickAccount}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Account</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[3568px] right-[96px] text-neutrals-neutral-300">Copyright © Think-AI 2023</div>
      			<div className="absolute top-[179px] left-[96px] bg-neutrals-neutral-50 box-border w-[1248px] flex flex-col items-start justify-start pt-0 px-0 pb-6 gap-[6px] text-9xl text-primary-primary-900 border-b-[1px] border-solid border-neutrals-neutral-100">
        				<b className="relative">Settings</b>
        				<div className="relative text-base text-neutrals-neutral-300">Get your billing information, generate/review invoices.</div>
      			</div>
      			<div className="absolute top-[293px] left-[390px] box-border w-[954px] flex flex-row items-start justify-between pt-0 px-0 pb-6 text-lg border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Billing</div>
          					<div className="relative text-sm text-neutrals-neutral-300">Allow users to configure how and when communication is initiated with debtors.</div>
        				</div>
        				<div className="flex flex-row items-start justify-start gap-[12px] text-sm">
							<CTAs
							type="Ghostbtn"
							text="Cancel"
							textColor="text-neutrals-neutral-900"
							bgColor="bg-neutrals-neutral-50"
							borderColor="border-neutrals-neutral-100"
							position="relative"
							/>
          					
							<CTAs
							type="Primarybtn"
							text="Save"
							textColor="text-neutrals-neutral-50"
							bgColor="bg-primary-primary-500"
							borderColor="border-primary-primary-500"
							position="relative"
							onClick={openToastMsg}
							/>
        				</div>
      			</div>

				{/* Navigation Panel */}
				<NavigationPanel/>
      			
				{/* Breadcrumb Navigation */}
				<Breadcrumb breadcrumbs={breadcrumb} position="top-[24px] left-[90px]" />

      			<div className="absolute top-[1302px] left-[390px] box-border w-[954px] flex flex-row items-start justify-between pt-0 px-0 pb-6 text-lg border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Billing history</div>
          					<div className="relative text-sm text-neutrals-neutral-300">View a detailed history of your billing transactions.</div>
        				</div>
						<CTAs
						text="Export"
						textColor="text-neutrals-neutral-900"
						bgColor="bg-neutrals-neutral-50"
						borderColor="border-neutrals-neutral-100"
						icon="assets/export.svg"
						position="relative"
						onClick={openExportModalTables}
						/>
      			</div>
      			<div className="absolute top-[2079px] left-[390px] box-border w-[954px] flex flex-row items-start justify-between pt-0 px-0 pb-6 text-lg border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Payment method</div>
          					<div className="relative text-sm text-neutrals-neutral-300">Manage your payment preferences.</div>
        				</div>
						<CTAs
						text="New payment"
						textColor="text-neutrals-neutral-900"
						bgColor="bg-neutrals-neutral-50"
						borderColor="border-neutrals-neutral-100"
						icon="assets/add.svg"
						position="relative"
						onClick={openNewPayment1}
						/>
      			</div>
      			<div className="absolute top-[1400px] left-[390px] [filter:drop-shadow(0px_1px_2px_rgba(0,_0,_0,_0.05))] w-[954px] flex flex-col items-start justify-start gap-[24px] text-neutrals-neutral-300">
        				<div className="self-stretch flex flex-row items-start justify-between">
          					<div className="w-[294px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-start py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="flex flex-row items-center justify-start gap-[10px]">
              							<img className="w-5 relative h-5" alt="" src="assets/search.svg" />
              							<div className="relative">Search</div>
            						</div>
          					</div>
          					<div className="flex flex-row items-center justify-end gap-[24px] text-neutrals-neutral-900">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-2.5 px-3.5 gap-[10px] border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">Show:</div>
              							<div className="flex flex-row items-center justify-start gap-[6px]">
                								<div className="relative font-semibold">All</div>
                								<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/arrow-down.svg" />
              							</div>
            						</div>
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center gap-[6px]">
                								<img className="w-5 relative h-5" alt="" src="assets/filter.svg" />
                								<div className="relative font-semibold">Filter</div>
              							</div>
            						</div>
          					</div>
        				</div>
        				<div className="self-stretch flex flex-col items-start justify-start text-neutrals-neutral-900">
          					<div className="self-stretch flex flex-row items-start justify-start">
            						<div className="w-[167px] flex flex-col items-start justify-start">
              							<div className="self-stretch rounded-tl-3xs rounded-tr-none rounded-b-none bg-column-bg flex flex-row items-center justify-start py-3 px-4 gap-[6px] border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
                								<img className="w-5 relative h-5" alt="" src="assets/checkbox.svg" />
                								<div className="relative font-semibold">Date</div>
              							</div>
              							<div className="self-stretch flex flex-col items-start justify-start">
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 gap-[6px] border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                  									<img className="w-5 relative h-5" alt="" src="assets/checkbox.svg" />
                  									<div className="relative">23/01/24</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 gap-[6px] border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                  									<img className="w-5 relative h-5" alt="" src="assets/checkbox.svg" />
                  									<div className="relative">23/01/24</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 gap-[6px] border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                  									<img className="w-5 relative h-5" alt="" src="assets/checkbox.svg" />
                  									<div className="relative">23/01/24</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 gap-[6px] border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                  									<img className="w-5 relative h-5" alt="" src="assets/checkbox.svg" />
                  									<div className="relative">23/01/24</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 gap-[6px] border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                  									<img className="w-5 relative h-5" alt="" src="assets/checkbox.svg" />
                  									<div className="relative">23/01/24</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 gap-[6px] border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                  									<img className="w-5 relative h-5" alt="" src="assets/checkbox.svg" />
                  									<div className="relative">23/01/24</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 gap-[6px] border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                  									<img className="w-5 relative h-5" alt="" src="assets/checkbox.svg" />
                  									<div className="relative">23/01/24</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 gap-[6px] border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                  									<img className="w-5 relative h-5" alt="" src="assets/checkbox.svg" />
                  									<div className="relative">23/01/24</div>
                								</div>
              							</div>
            						</div>
            						<div className="w-[469px] flex flex-col items-start justify-start">
              							<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                								<div className="relative font-semibold">Description</div>
              							</div>
              							<div className="self-stretch flex flex-col items-start justify-start">
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="flex-1 relative"> Initial letter before action (LBA) fee</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="flex-1 relative">Invoice #12345 - Web Development Project</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="flex-1 relative">Legal consultation - client PLD</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="flex-1 relative">Court filing fee - case #12345</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="flex-1 relative">Settlement agreement processing fee</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="flex-1 relative">Monthly retainer fee - April 2023</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="flex-1 relative">Legal consultation - client PLD</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="flex-1 relative">Court filing fee - case #27538</div>
                								</div>
              							</div>
            						</div>
            						<div className="flex-1 flex flex-col items-center justify-start">
              							<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-center py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                								<div className="relative font-semibold">Amount</div>
              							</div>
              							<div className="self-stretch flex flex-col items-center justify-start">
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative">£100</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative">£100</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative">£150</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative">£250</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative">£180</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative">£500</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative">£135</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative">£250</div>
                								</div>
              							</div>
            						</div>
            						<div className="flex-1 flex flex-col items-center justify-start">
              							<div className="self-stretch rounded-tl-none rounded-tr-3xs rounded-b-none bg-column-bg box-border h-11 flex flex-row items-start justify-center py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-b-[1px]">
                								<div className="relative font-semibold">Status</div>
              							</div>
              							<div className="self-stretch flex flex-col items-center justify-start text-semantics-low-low-500">
                								<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                    										<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                    										<div className="relative font-semibold">Paid</div>
                  									</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 text-semantics-moderate-moderate-500 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="rounded-xl bg-semantics-moderate-moderate-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-moderate-moderate-500">
                    										<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
                    										<div className="relative font-semibold">Pending</div>
                  									</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                    										<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                    										<div className="relative font-semibold">Paid</div>
                  									</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                    										<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                    										<div className="relative font-semibold">Paid</div>
                  									</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                    										<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                    										<div className="relative font-semibold">Paid</div>
                  									</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 text-semantics-moderate-moderate-500 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="rounded-xl bg-semantics-moderate-moderate-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-moderate-moderate-500">
                    										<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
                    										<div className="relative font-semibold">Pending</div>
                  									</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 text-semantics-high-high-500 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="rounded-xl bg-semantics-high-high-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-high-high-500">
                    										<div className="w-1.5 relative rounded-[50%] bg-semantics-high-high-500 h-1.5" />
                    										<div className="relative font-semibold">Unpaid</div>
                  									</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 text-semantics-high-high-500 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="rounded-xl bg-semantics-high-high-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-high-high-500">
                    										<div className="w-1.5 relative rounded-[50%] bg-semantics-high-high-500 h-1.5" />
                    										<div className="relative font-semibold">Unpaid</div>
                  									</div>
                								</div>
              							</div>
            						</div>
          					</div>
          					<div className="self-stretch rounded-t-none rounded-b-3xs flex flex-row items-center justify-between pt-3 px-6 pb-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">Previous</div>
              							</div>
            						</div>
            						<div className="relative">Page 1 of 10</div>
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">Next</div>
              							</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[391px] left-[390px] flex flex-col items-start justify-start gap-[6px] text-base">
        				<div className="relative font-semibold">Billing preferences</div>
        				<div className="relative text-neutrals-neutral-300">Customise how billing information is presented.</div>
      			</div>
      			<div className="absolute top-[585px] left-[390px] flex flex-col items-start justify-start gap-[24px] text-base">
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Invoice details</div>
          					<div className="relative text-neutrals-neutral-300">Customise how invoice information is presented.</div>
        				</div>
        				<div className="flex flex-col items-start justify-start gap-[10px]">
          					<div className="flex flex-row items-center justify-start gap-[6px]">
            						<img className="w-5 relative h-5" alt="" src="assets/checkbox.svg" />
            						<div className="relative">Itemised list</div>
          					</div>
          					<div className="flex flex-row items-center justify-start gap-[6px]">
            						<img className="w-5 relative h-5" alt="" src="assets/checkbox.svg" />
            						<div className="relative">Taxes</div>
          					</div>
          					<div className="flex flex-row items-center justify-start gap-[6px]">
            						<img className="w-5 relative h-5" alt="" src="assets/checkbox.svg" />
            						<div className="relative">Discounts</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[785px] left-[390px] flex flex-col items-start justify-start gap-[6px] text-base">
        				<div className="relative font-semibold">Logo and branding</div>
        				<div className="relative text-neutrals-neutral-300">Customise how invoice information is presented.</div>
      			</div>
                
				{/* Uplaod Component */}
      			<div className="absolute top-[859px] left-[390px] w-[954px] flex flex-col items-start justify-start gap-[10px]">
                    <Upload width="954px"/>
      			</div>

      			<div className="absolute top-[1269.5px] left-[389.5px] box-border w-[955px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
      			<div className="absolute top-[2046.5px] left-[389.5px] box-border w-[955px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
      			<div className="absolute top-[2640.5px] left-[389.5px] box-border w-[955px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
      			<div className="absolute top-[2177px] left-[390px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-4 gap-[10px] border-[2px] border-solid border-primary-primary-500">
        				<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 overflow-hidden flex flex-col items-start justify-start py-4 px-2.5 border-[1px] border-solid border-neutrals-neutral-100">
          					<img className="w-10 relative h-[13px]" alt="" src="assets/Vector.svg" />
        				</div>
        				<div className="flex flex-col items-start justify-start gap-[10px]">
          					<div className="flex flex-row items-start justify-start gap-[10px]">
            						<div className="flex flex-col items-start justify-start gap-[1px]">
              							<div className="w-[299px] relative font-semibold inline-block">Visa ending in 1234</div>
              							<div className="w-[299px] relative text-neutrals-neutral-300 inline-block">Expiry 06/2024</div>
            						</div>
									<CTAs
									type="Ghostbtn"
									text="Edit"
									textColor="text-neutrals-neutral-900"
									bgColor="bg-neutrals-neutral-50"
									borderColor="border-neutrals-neutral-100"
									position="relative"
									onClick={onCTAsContainerClick}
									/>
          					</div>
          					<div className="flex flex-row items-center justify-start gap-[6px] text-neutrals-neutral-300">
            						<img className="w-4 relative h-4" alt="" src="assets/mail.svg" />
            						<div className="relative">pm@thinkai.io</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[2177px] left-[879px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-4 gap-[10px] border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 overflow-hidden flex flex-col items-start justify-start py-4 px-2.5 border-[1px] border-solid border-neutrals-neutral-100">
          					<img className="w-10 relative h-[13px]" alt="" src="assets/Vector.svg" />
        				</div>
        				<div className="flex flex-col items-start justify-start gap-[10px]">
          					<div className="flex flex-row items-start justify-start gap-[10px]">
            						<div className="flex flex-col items-start justify-start gap-[1px]">
              							<div className="w-[299px] relative font-semibold inline-block">Visa ending in 2345</div>
              							<div className="w-[299px] relative text-neutrals-neutral-300 inline-block">Expiry 08/2024</div>
            						</div>
									<CTAs
									type="Ghostbtn"
									text="Edit"
									textColor="text-neutrals-neutral-900"
									bgColor="bg-neutrals-neutral-50"
									borderColor="border-neutrals-neutral-100"
									position="relative"
									/>
          					</div>
          					<div className="flex flex-row items-center justify-start gap-[6px] text-neutrals-neutral-300">
            						<img className="w-4 relative h-4" alt="" src="assets/mail.svg" />
            						<div className="relative">pm@thinkai.io</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[2415px] left-[390px] w-[954px] flex flex-row items-start justify-between text-base">
        				<div className="w-[294px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
            						<div className="self-stretch flex flex-row items-start justify-between">
              							<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
                								<img className="w-5 relative h-5" alt="" src="assets/card.svg" />
              							</div>
              							<img className="w-4 relative h-4" alt="" src="assets/security.svg" />
            						</div>
            						<div className="relative font-semibold">Credit/Debit card</div>
          					</div>
          					<img className="w-5 relative h-5" alt="" src="assets/arrow-right.svg" />
        				</div>
        				<div className="w-[294px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
            						<div className="self-stretch flex flex-row items-start justify-start">
              							<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
                								<img className="w-5 relative h-5" alt="" src="assets/bank.svg" />
              							</div>
            						</div>
            						<div className="relative font-semibold">Bank account</div>
          					</div>
          					<img className="w-5 relative h-5" alt="" src="assets/arrow-right.svg" />
        				</div>
        				<div className="w-[294px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
            						<div className="self-stretch flex flex-row items-start justify-start">
              							<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
                								<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/paypal.svg" />
              							</div>
            						</div>
            						<div className="relative font-semibold">Digital wallet</div>
          					</div>
          					<img className="w-5 relative h-5" alt="" src="assets/arrow-right.svg" />
        				</div>
      			</div>
      			<div className="absolute top-[2317px] left-[390px] flex flex-row items-start justify-start pt-0 px-0 pb-6 gap-[423px] text-base border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Available payment methods</div>
          					<div className="relative text-neutrals-neutral-300">Choose from the following payment options.</div>
        				</div>
        				<div className="flex flex-row items-start justify-start gap-[10px] text-sm">
							<CTAs
							type="Ghostbtn"
							text="Remove"
							textColor="text-neutrals-neutral-900"
							bgColor="bg-neutrals-neutral-50"
							borderColor="border-neutrals-neutral-100"
							icon="assets/remove_card.svg"
							position="relative"
							/>

							<CTAs
							type="Ghostbtn"
							text="Edit"
							textColor="text-neutrals-neutral-900"
							bgColor="bg-neutrals-neutral-50"
							borderColor="border-neutrals-neutral-100"
							icon="assets/edit_card.svg"
							position="relative"
							/>
        				</div>
      			</div>
      			<div className="absolute top-[2589px] left-[390px] flex flex-row items-center justify-start gap-[6px] text-neutrals-neutral-300">
        				<img className="w-5 relative h-5" alt="" src="assets/info.svg" />
        				<div className="relative">You can read about our security policy from</div>
        				<div className="relative [text-decoration:underline] text-primary-primary-500">here</div>
      			</div>
      			<div className="absolute top-[3222px] left-[390px] flex flex-col items-start justify-start gap-[24px] text-lg">
        				<div className="w-[954px] box-border flex flex-row items-start justify-start pt-0 px-0 pb-6 border-b-[1px] border-solid border-neutrals-neutral-100">
          					<div className="flex flex-col items-start justify-start gap-[6px]">
            						<div className="relative font-semibold">Notification preferences</div>
            						<div className="relative text-sm text-neutrals-neutral-300">Manage your notification preferences.</div>
          					</div>
        				</div>
        				<div className="flex flex-row items-start justify-start gap-[24px] text-base">
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="self-stretch flex flex-row items-start justify-between">
              							<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
                								<img className="w-5 relative h-5" alt="" src="assets/sms.svg" />
              							</div>
										{/* Toggle */}
										<Toggle
										onToggle={handleToggle}
										isOn={true}
										onColor='bg-primary-primary-500'
										offColor='bg-primary-primary-50'
										knobColor='bg-neutrals-neutral-50'
										/>
            						</div>
            						<div className="flex flex-col items-start justify-start gap-[6px]">
              							<div className="relative font-semibold">SMS</div>
              							<div className="w-[294px] relative text-neutrals-neutral-300 inline-block">Get notified about the billing by getting sms.</div>
            						</div>
          					</div>
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="self-stretch flex flex-row items-start justify-between">
              							<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
                								<img className="w-5 relative h-5" alt="" src="assets/mail.svg" />
              							</div>
										{/* Toggle */}
										<Toggle
										onToggle={handleToggle}
										isOn={true}
										onColor='bg-primary-primary-500'
										offColor='bg-primary-primary-50'
										knobColor='bg-neutrals-neutral-50'
										/>
            						</div>
            						<div className="flex flex-col items-start justify-start gap-[6px]">
              							<div className="relative font-semibold">Email</div>
              							<div className="w-[294px] relative text-neutrals-neutral-300 inline-block">Get notified about the billing by getting email.</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[2673px] left-[390px] box-border w-[954px] flex flex-row items-start justify-start pt-0 px-0 pb-6 text-lg border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Auto-pay settings</div>
          					<div className="relative text-sm text-neutrals-neutral-300">Manage your auto-pay preferences.</div>
        				</div>
      			</div>
      			<div className="absolute top-[2771px] left-[390px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start p-6 gap-[24px] text-base border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-start justify-between">
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
            						<img className="w-5 relative h-5" alt="" src="assets/auto-pay.svg" />
          					</div>
          					<div className="w-9 rounded-81xl bg-primary-primary-500 flex flex-row items-start justify-end p-0.5 box-border">
            						<div className="w-4 relative shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-[50%] bg-neutrals-neutral-50 h-4" />
          					</div>
        				</div>
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Auto-pay</div>
          					<div className="w-[294px] relative text-neutrals-neutral-300 inline-block">Get notified before your court date so you can be prepared in advance.</div>
        				</div>
      			</div>
      			<div className="absolute top-[3083px] left-[390px] flex flex-col items-start justify-start gap-[10px]">
        				<div className="relative font-semibold">Amount</div>
        				<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-between p-3 text-base border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="flex-1 flex flex-row items-center justify-between">
            						<div className="relative">
              							<span className="font-semibold">
                								<span>£</span>
              							</span>
              							<span className="text-neutrals-neutral-300">
                								<span className="font-semibold">{` `}</span>
                								<span>1,000.00</span>
              							</span>
            						</div>
            						<img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg" />
          					</div>
          					<div className="flex flex-row items-center justify-start py-0 px-2.5 gap-[6px] text-right">
            						<div className="relative">GBP</div>
            						<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/arrow-down.svg" />
          					</div>
        				</div>
      			</div>

				{/* Dropdown */}
      			<div className="absolute top-[465px] left-[390px] w-[400px] flex flex-col items-start justify-start gap-[10px]">
					<Dropdown
					title="Select display format"
					placeholder="Select duration"
					icon="assets/help-circle.svg"
					dropdownContents={dropdownContents}
					/>
        				{/* <div className="flex flex-row items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Select display format</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg" />
        				</div>
        				<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-end justify-start py-3.5 px-2.5 gap-[175px] text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="w-[165px] relative inline-block shrink-0">Select duration</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/arrow-down.svg" />
        				</div> */}
      			</div>
      			<div className="absolute top-[2979px] left-[390px] w-[400px] flex flex-col items-start justify-start gap-[10px]">
        				<div className="flex flex-row items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Select payment frequency</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg" />
        				</div>
        				<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-end justify-start py-3.5 px-2.5 gap-[175px] text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="w-[165px] relative inline-block shrink-0">Select frequency</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/arrow-down.svg" />
        				</div>
      			</div>
    		</div>
    		{isToastMsgOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeToastMsg}
        				>
        				<ToastMsg onClose={closeToastMsg} />
      			</PortalPopup>
    		)}
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}
    		{isExportModalTablesOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeExportModalTables}
        				>
        				<ExportModalTables onClose={closeExportModalTables} />
      			</PortalPopup>
    		)}
    		{isNewPayment1Open && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeNewPayment1}
        				>
        				<NewPayment onClose={closeNewPayment1} />
      			</PortalPopup>
    		)}</>);
};

export default SettingsBillingPage;