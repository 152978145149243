import { useState, useCallback } from 'react';
import LPDConfirmation from "../components/LPDConfirmation";
import PortalPopup from "../components/PortalPopup";

const LPDAutoPopulate = () => {
  	const [isLPDConfirmationOpen, setLPDConfirmationOpen] = useState(false);
  	
  	const openLPDConfirmation = useCallback(() => {
    		setLPDConfirmationOpen(true);
  	}, []);
  	
  	const closeLPDConfirmation = useCallback(() => {
    		setLPDConfirmationOpen(false);
  	}, []);
  	
  	return (<>
    		<div className="w-full relative h-[873px] text-left text-lg text-neutrals-neutral-900 font-open-sans-ff-body-strong">
      			<div className="absolute h-full w-[61.54%] top-[0%] right-[0%] bottom-[0%] left-[38.46%] shadow-[0px_4px_10px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-end justify-start p-6 gap-[40px] border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-col items-start justify-start">
          					<div className="flex flex-col items-end justify-start gap-[24px]">
            						<div className="flex flex-row items-start justify-start pt-0 px-0 pb-2.5 gap-[503px] border-b-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold text-nowrap">Company name</div>
              							<div className="relative font-semibold text-right">Solicitors</div>
            						</div>
            						<div className="relative text-base text-right">
              							<p className="m-0">PRE - ISSUE SECTION</p>
              							<p className="m-0">LLOYDS CHAMBERS</p>
              							<p className="m-0">19-21 SEAVIEW ROAD</p>
              							<p className="m-0">WALLASEY</p>
              							<p className="m-0">CH45 4TH</p>
            						</div>
          					</div>
          					<div className="flex flex-col items-start justify-start gap-[6px] text-base">
            						<div className="flex flex-row items-center justify-start gap-[6px]">
              							<div className="relative font-semibold">Dear</div>
              							<div className="w-[75px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-[42px] border-[1px] border-solid border-neutrals-neutral-100" />
            						</div>
            						<div className="flex flex-row items-center justify-start">
              							<div className="relative font-semibold">Re: Invoice Number: 123456</div>
            						</div>
          					</div>
        				</div>
        				<div className="flex flex-col items-start justify-start gap-[40px] text-base">
          					<div className="flex flex-col items-start justify-start gap-[24px]">
            						<div className="w-[718px] relative inline-block">NOTICE OF ATTENTION TO COMMENCE LEGAL PROCEEDINGS pursuant to the Late Payment of Commercial Debts (Interest) Act 1998 as amended and supplemented by the Late Payment of Commercial Debt Regulations 2002 and 2013.</div>
            						<div className="w-[718px] relative inline-block">We hereby give you notice that our client intends to commence Country Court proceedings which will include a claim for late payment compensation, accrued interest and the reasonable cost of this Solicitor’s Letter.</div>
            						<div className="flex flex-col items-start justify-start gap-[10px]">
              							<div className="flex flex-row items-center justify-start gap-[6px]">
                								<div className="relative">The sum now owing to our client is £</div>
                								<div className="w-[70px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-[42px] border-[1px] border-solid border-neutrals-neutral-100" />
                								<div className="w-[364px] relative inline-block shrink-0">{`together with late payment compensation of `}</div>
              							</div>
              							<div className="w-[718px] relative inline-block">£40.00, interest of £4.10 and £25.00 reasonable Solicitor’s Letter costs claimable under Regulation 3 of the Late Payment of Commercial Debt Regulations 2013.</div>
              							<div className="w-[718px] flex flex-row items-center justify-between">
                								<div className="relative">Take notice that unless our client receives payment for the total sum of £</div>
                								<div className="w-[70px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-[42px] border-[1px] border-solid border-neutrals-neutral-100" />
                								<div className="relative">within 7 days</div>
              							</div>
              							<div className="w-[718px] relative inline-block">from the date of this letter proceedings will be issued for the whole balance outstanding together with continuing interest and Court costs without further notice to you.</div>
            						</div>
          					</div>
          					<div className="flex flex-col items-start justify-start gap-[24px]">
            						<div className="relative">Yours faithfully,</div>
            						<div className="flex flex-col items-start justify-start gap-[10px]">
              							<div className="w-[188px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start p-3 border-[1px] border-solid border-neutrals-neutral-100">
                								<div className="flex flex-row items-center justify-start gap-[6px]">
                  									<img className="w-5 relative h-5" alt="" src="assets/edit.svg" />
                  									<div className="relative">Signature</div>
                								</div>
              							</div>
              							<div className="relative">Company name</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute h-[62.54%] w-[32.05%] top-[0%] right-[67.95%] bottom-[37.46%] left-[0%] text-sm text-neutrals-neutral-50">
        				<div className="absolute h-[7.14%] w-[47%] top-[92.86%] right-[53%] bottom-[0%] left-[0%] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-primary-primary-500 flex flex-row items-center justify-center py-2.5 px-3.5 box-border cursor-pointer" onClick={openLPDConfirmation}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Sent</div>
          					</div>
        				</div>
        				<div className="absolute top-[0px] left-[0px] flex flex-col items-start justify-start gap-[24px] text-neutrals-neutral-900">
          					<div className="flex flex-col items-start justify-start gap-[10px]">
            						<div className="flex flex-row items-center justify-start gap-[6px]">
              							<div className="relative font-semibold">Full name</div>
              							<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg"/>
            						</div>
            						<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border overflow-hidden flex flex-row items-center justify-start p-3 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex-1 relative">John Doe</div>
            						</div>
          					</div>
          					<div className="flex flex-col items-start justify-start gap-[10px]">
            						<div className="relative font-semibold">Amount</div>
            						<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-between p-3 text-base border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex-1 flex flex-row items-center justify-between">
                								<div className="relative">
                  									<span className="font-semibold">
                    										<span>£</span>
                  									</span>
                  									<span className="text-neutrals-neutral-300">
                    										<span className="font-semibold">{` `}</span>
                    										<span>1,000.00</span>
                  									</span>
                								</div>
                								<img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg"/>
              							</div>
              							<div className="flex flex-row items-center justify-start py-0 px-2.5 gap-[6px] text-right">
                								<div className="relative">GBP</div>
                								<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/arrow-down.svg" />
              							</div>
            						</div>
          					</div>
          					<div className="flex flex-col items-start justify-start gap-[10px]">
            						<div className="flex flex-row items-center justify-start gap-[6px]">
              							<div className="relative font-semibold">Address</div>
              							<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg"/>
            						</div>
            						<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border overflow-hidden flex flex-row items-center justify-start p-3 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative">Enter your address</div>
            						</div>
          					</div>
          					<div className="flex flex-col items-start justify-start gap-[10px]">
            						<div className="flex flex-row items-center justify-start gap-[6px]">
              							<div className="relative font-semibold">Postcode</div>
              							<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg"/>
            						</div>
            						<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border overflow-hidden flex flex-row items-center justify-start p-3 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative">Enter your postcode</div>
            						</div>
          					</div>
          					<div className="w-[400px] flex flex-row items-start justify-start">
            						<div className="w-[400px] flex flex-col items-start justify-start gap-[10px]">
              							<div className="flex flex-row items-center justify-start gap-[6px]">
                								<div className="relative font-semibold">Select due date</div>
                								<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg"/>
              							</div>
              							<div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 overflow-hidden flex flex-row items-center justify-start p-3 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
                								<div className="relative">01/01/2024</div>
              							</div>
            						</div>
          					</div>
        				</div>
      			</div>
    		</div>
    		{isLPDConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLPDConfirmation}
        				>
        				<LPDConfirmation onClose={closeLPDConfirmation} />
      			</PortalPopup>
    		)}</>);
};

export default LPDAutoPopulate;