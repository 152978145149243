import { useState, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import NavigationDropdown from "../../components/NavigationDropdown";
import NavigationPanel from '../../components/NavigationPanel';
import PortalPopup from "../../components/PortalPopup";
import LogoutConfirmation from "../../components/LogoutConfirmation";
import LawFirm from "../../components/LawFirm";
import Breadcrumb from "../../components/Breadcrumb";
import CTAs from '../../components/CTAs';

const Defence_2 = () => {
	const navigate = useNavigate();
  	const [isNavigationDropdownOpen, setNavigationDropdownOpen] = useState(false);
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	const [isLawFirmOpen, setLawFirmOpen] = useState(false);
  	
  	const closeNavigationDropdown = useCallback(() => {
    		setNavigationDropdownOpen(false);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);
  	
  	
  	const onFrameContainerClick = useCallback(() => {
    		// Add your code here
  	}, []);
  	
  	
  	const onFrameContainer1Click = useCallback(() => {
    		// Add your code here
  	}, []);
  	
  	
  	const onFrameContainer2Click = useCallback(() => {
    		// Add your code here
  	}, []);
  	
  	
  	const onFrameContainer3Click = useCallback(() => {
    		// Add your code here
  	}, []);
  	
  	
  	const onTabStatesContainer1Click = useCallback(() => {
		navigate('/defence3');
  	}, [navigate]);
  	
  	
  	const onClickNegotiation = useCallback(() => {
		navigate('/defence4');
  	}, [navigate]);
  	
  	
  	const openLawFirm = useCallback(() => {
    		setLawFirmOpen(true);
  	}, []);
  	
  	const closeLawFirm = useCallback(() => {
    		setLawFirmOpen(false);
  	}, []);

	const navItems = [
		{
			text: 'Debt collection', 
    		bgColor: 'bg-primary-primary-50', 
    		textColor: 'text-neutrals-neutral-900', 
    		hoverBgColor: 'bg-primary-primary-50', 
    		hoverTextColor: 'text-neutrals-neutral-900', 
    		path: "",
    		icon: 'assets/arrow-down.svg'
		},

		{
			text: "Outcomes & costs",
			bgColor: 'bg-neutrals-neutral-50', 
    		textColor: 'text-neutrals-neutral-300', 
    		hoverBgColor: 'bg-neutrals-neutral-50', 
    		hoverTextColor: 'text-primary-primary-500', 
    		path: "/outcome&cost",
    		icon: ''
		},

		{
			text: "AI indicators",
			bgColor: 'bg-neutrals-neutral-50', 
    		textColor: 'text-neutrals-neutral-300', 
    		hoverBgColor: 'bg-neutrals-neutral-50', 
    		hoverTextColor: 'text-primary-primary-500', 
    		path: "/ai1",
    		icon: ''
		},
	];

	const breadcrumb = [
		{ label: "", path: "/postlba2", icon: "assets/home.svg", active: true },
		{ label: "Defence and case handling", path: "/defence1", active: false},
		{ label: "Paras Mistry", path: "", active: true},
	];

  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1811px] overflow-hidden text-left text-sm text-neutrals-neutral-300 font-open-sans-ff-body-strong">
      			<div className="absolute top-[1728px] right-[96px]">Copyright © Think-AI 2023</div>
      			<div className="absolute top-[179px] left-[96px] bg-neutrals-neutral-50 box-border w-[1248px] flex flex-col items-start justify-start pt-0 px-0 pb-6 text-9xl text-neutrals-neutral-900 border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-start justify-start">
          					<div className="flex flex-col items-start justify-start gap-[6px]">
            						<b className="relative">Defence and case handling</b>
            						<div className="relative text-base text-neutrals-neutral-300">Acknowledge defences and revert back.</div>
          					</div>
        				</div>
      			</div>

				{/* Navigation Panel */}
				<NavigationPanel navItems={navItems}/>

				{/* Breadcrumb Navigation */}
				<Breadcrumb breadcrumbs={breadcrumb} position="top-[24px] left-[90px]"/>

      			<div className="absolute top-[668px] left-[96px] flex flex-col items-start justify-start gap-[24px] text-lg text-neutrals-neutral-900">
        				<div className="relative font-semibold">Claim details</div>
        				<div className="flex flex-col items-start justify-start gap-[10px] text-base">
          					<div className="relative">
            						<span className="font-semibold">
              							<span>Amount owed:</span>
              							<span className="text-lg">{` `}</span>
            						</span>
            						<span className="text-lg">
              							<span>£2000</span>
            						</span>
          					</div>
          					<div className="relative">
            						<span className="font-semibold">
              							<span>Nature of claim:</span>
            						</span>
            						<span className="text-lg">
              							<span className="font-semibold">{` `}</span>
              							<span>Goods sold and delivered</span>
            						</span>
          					</div>
          					<div className="relative">
            						<span className="font-semibold">
              							<span>Previous communication history:</span>
              							<span className="text-lg">{` `}</span>
            						</span>
            						<span className="text-lg">
              							<span>Initial LBA sent, no response</span>
            						</span>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[843.5px] left-[95.5px] box-border w-[1249px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
      			<div className="absolute top-[876px] left-[96px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] flex flex-col items-start justify-start text-neutrals-neutral-900">
        				<div className="w-[1248px] rounded-t-3xs rounded-b-none box-border flex flex-row items-center justify-between py-5 px-6 text-lg border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-l-[1px]">
          					<div className="flex flex-row items-center justify-start gap-[10px]">
            						<div className="relative font-semibold">Uploaded documents</div>
            						<div className="rounded-[5px] bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="w-1.5 relative rounded-[50%] bg-primary-primary-500 h-1.5" />
              							<div className="relative font-semibold">3 documents</div>
            						</div>
          					</div>
          					<div className="flex flex-row items-start justify-start text-sm">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center gap-[6px]">
                								<img className="w-5 relative h-5" alt="" src="assets/download.svg"/>
                								<div className="relative font-semibold">Download all</div>
              							</div>
            						</div>
          					</div>
        				</div>
        				<div className="w-[1248px] box-border flex flex-row items-center justify-between py-3 px-6 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-l-[1px]">
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-start border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="rounded-tl-3xs rounded-tr-none rounded-br-none rounded-bl-3xs bg-column-bg flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">View all</div>
            						</div>
            						<div className="flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">PDF</div>
            						</div>
            						<div className="flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">Image</div>
            						</div>
            						<div className="rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none flex flex-row items-center justify-center py-2 px-4">
              							<div className="relative font-semibold">Word</div>
            						</div>
          					</div>
          					<div className="flex flex-row items-start justify-start gap-[10px] text-neutrals-neutral-300">
            						<div className="w-[294px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-start py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-start gap-[10px]">
                								<img className="w-5 relative h-5" alt="" src="assets/search.svg" />
                								<div className="relative">Search</div>
              							</div>
            						</div>
									<CTAs
									type="Ghostbtn"
									text="Filter"
									textColor="text-neutrals-neutral-900"
									bgColor="bg-neutrals-neutral-50"
									borderColor="border-neutrals-neutral-100"
									position="relative"
									icon="assets/filter.svg"
									/>
          					</div>
        				</div>
        				<div className="flex flex-col items-start justify-start">
          					<div className="w-[1248px] flex flex-col items-start justify-start">
            						<div className="self-stretch flex flex-row items-start justify-start">
              							<div className="flex-1 flex flex-col items-start justify-start">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
                  									<div className="relative font-semibold">Name</div>
                								</div>
                								<div className="self-stretch flex flex-col items-start justify-start">
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 gap-[10px] cursor-pointer border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]" onClick={onFrameContainerClick}>
                    										<img className="w-10 relative h-10" alt="" src="assets/pdf.svg"/>
                    										<div className="relative font-semibold">DefenseStatement_JDoe.pdf</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 gap-[10px] cursor-pointer border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]" onClick={onFrameContainer1Click}>
                    										<div className="flex flex-row items-start justify-start relative gap-[10px]">
                                                            <img className="w-10 relative h-10" alt="" src="assets/word_file.svg"/>
                    										</div>
                    										<div className="relative font-semibold">DefenseStatement_JDoe.docx</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 gap-[10px] cursor-pointer border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]" onClick={onFrameContainer2Click}>
                    										<div className="flex flex-row items-start justify-start relative gap-[10px]">
                      											<img className="w-10 relative h-10 z-[0]" alt="" src="assets/image_file.svg" />
                    										</div>
                    										<div className="relative font-semibold">Correspondence_JDoe.png</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[188px] flex flex-col items-start justify-start">
                								<div className="w-[188px] bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">Size</div>
                								</div>
                								<div className="w-[188px] flex flex-col items-start justify-start">
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 cursor-pointer border-b-[1px] border-solid border-neutrals-neutral-100" onClick={onFrameContainer3Click}>
                    										<div className="relative">1.2 MB</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">2.8 MB</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">24 KB</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[260px] flex flex-col items-start justify-start">
                								<div className="w-[448px] bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-b-[1px]">
                  									<div className="relative font-semibold">Kind</div>
                								</div>
                								<div className="self-stretch flex flex-col items-start justify-start">
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">PDF document</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Word document</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">PNG image</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[448px] flex flex-col items-start justify-start">
                								<div className="w-[448px] bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-b-[1px]">
                  									<div className="relative font-semibold">Uploaded date</div>
                								</div>
                								<div className="w-[448px] flex flex-col items-start justify-start">
                  									<div className="w-[448px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">08 Feb 2024 at 10:00 am</div>
                  									</div>
                  									<div className="w-[448px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">08 Feb 2024 at 10:00 am</div>
                  									</div>
                  									<div className="w-[448px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">08 Feb 2024 at 10:00 am</div>
                  									</div>
                								</div>
              							</div>
            						</div>
          					</div>
          					<div className="w-[1248px] rounded-t-none rounded-b-3xs box-border flex flex-row items-center justify-between pt-3 px-6 pb-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">Previous</div>
              							</div>
            						</div>
            						<div className="relative">Page 1 of 10</div>
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">Next</div>
              							</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[1336px] left-[calc(50%_-_624px)] rounded-3xs bg-primary-primary-50 w-[1248px] flex flex-col items-start justify-start p-6 box-border text-base">
        				<div className="flex flex-col items-start justify-start gap-[10px]">
          					<div className="relative">Notes and comments</div>
          					<div className="relative font-semibold text-neutrals-neutral-900">Awaiting response from debtor regarding proposed terms.</div>
        				</div>
      			</div>
      			<div className="absolute top-[1486px] left-[96px] text-lg font-semibold text-neutrals-neutral-900">Next steps:</div>
      			<div className="absolute top-[1535px] left-[96px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-start text-neutrals-neutral-900 ">
        				<div className="rounded-tl-3xs rounded-tr-none rounded-br-none rounded-bl-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2 px-4 border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="relative font-semibold">Proceed to court</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-b-[1px]">
          					<div className="relative font-semibold">Continue negotiation</div>
        				</div>
        				<div className="rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none flex flex-row items-center justify-center py-2 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-b-[1px]">
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Settle the case</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[293px] left-[96px] flex flex-row items-start justify-start gap-[24px] text-base">
        				<div className="bg-neutrals-neutral-50 box-border h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 text-primary-primary-500 border-b-[2px] border-solid border-primary-primary-500">
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Case details</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 box-border cursor-pointer" onClick={onTabStatesContainer1Click}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">AI utilisation</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 box-border cursor-pointer" onClick={onClickNegotiation}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Negotiation</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[1610px] left-[96px] w-[1248px] flex flex-row items-start justify-between text-base">
        				<div className="flex flex-col items-start justify-start gap-[10px]">
          					<div className="relative">Suggestions</div>
          					<div className="relative font-semibold text-neutrals-neutral-900">Consider a follow-up call to discuss the proposal.</div>
        				</div>
        				<div className="rounded-3xs bg-primary-primary-50 flex flex-row items-center justify-start py-2.5 px-3.5 gap-[6px] text-sm text-primary-primary-500">
          					<img className="w-[23px] relative h-[22.6px]" alt="" src="assets/AI.svg"/>
          					<div className="relative font-semibold">Powered by AI</div>
        				</div>
      			</div>
      			<div className="absolute top-[358px] left-[96px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs flex flex-col items-start justify-start text-lg text-neutrals-neutral-900">
        				<div className="w-[400px] rounded-t-3xs rounded-b-none bg-neutrals-neutral-50 box-border flex flex-row items-start justify-between p-6 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-l-[1px]">
          					<div className="flex flex-col items-start justify-start gap-[24px]">
            						<div className="relative font-semibold">Paras Mistry</div>
            						<div className="flex flex-col items-start justify-start gap-[10px] text-base text-neutrals-neutral-300">
              							<div className="flex flex-row items-center justify-start gap-[10px]">
                								<img className="w-5 relative h-5" alt="" src="assets/location.svg" />
                								<div className="relative">21 Turning St., Southampton</div>
              							</div>
              							<div className="flex flex-row items-center justify-start gap-[10px]">
                								<img className="w-5 relative h-5" alt="" src="assets/call.svg" />
                								<div className="relative">+441234567890</div>
              							</div>
              							<div className="flex flex-row items-center justify-start gap-[10px]">
                								<img className="w-5 relative h-5" alt="" src="assets/mail.svg" />
                								<div className="relative">pm@think-ai.io</div>
              							</div>
              							<div className="flex flex-row items-center justify-start gap-[10px]">
                								<img className="w-5 relative h-5" alt="" src="assets/building-1.svg" />
                								<div className="relative">Azure Solutions Ltd.</div>
              							</div>
            						</div>
          					</div>
          					<div className="rounded-[20px] bg-semantics-moderate-moderate-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm text-semantics-moderate-moderate-500 border-[1px] border-solid border-semantics-moderate-moderate-500">
            						<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
            						<div className="relative font-semibold">Pending</div>
          					</div>
        				</div>
        				<div className="w-[400px] rounded-t-none rounded-b-3xs box-border flex flex-col items-end justify-start py-4 px-6 text-sm border-[1px] border-solid border-neutrals-neutral-100">
							<CTAs
							type="Ghostbtn"
							text="Contact law firm"
							textColor="text-neutrals-neutral-900"
							bgColor="bg-neutrals-neutral-50"
							borderColor="border-neutrals-neutral-100"
							position="relative"
							onClick={openLawFirm}
							/>
        				</div>
      			</div>
    		</div>
    		{isNavigationDropdownOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeNavigationDropdown}
        				>
        				<NavigationDropdown onClose={closeNavigationDropdown} />
      			</PortalPopup>
    		)}
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}
    		{isLawFirmOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLawFirm}
        				>
        				<LawFirm onClose={closeLawFirm} />
      			</PortalPopup>
    		)}</>);
};

export default Defence_2;