import { useState, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import LogoutConfirmation from "../../components/LogoutConfirmation";
import PortalPopup from "../../components/PortalPopup";
import ExportModalImage from "../../components/ExportModalImage";
import NavigationPanel from '../../components/NavigationPanel';
import Breadcrumb from '../../components/Breadcrumb';

const SettingsAccount2Page = () => {
    const navigate = useNavigate();
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	const [isExportModalImageOpen, setExportModalImageOpen] = useState(false);
  	
  	const onClickGeneral = useCallback(() => {
        navigate('/setting-general');
  	}, [navigate]);
  	
  	const onClickPreferences = useCallback(() => {
        navigate('/setting-preferences');
  	}, [navigate]);
  	
  	const onClickLBAConfig = useCallback(() => {
        navigate('/setting-lbaconfig');
  	}, [navigate]);
  	
  	const onClickLegalProceedings = useCallback(() => {
        navigate('/setting-legalproceedings');
  	}, [navigate]);
  	
  	const onClickAIIntegration = useCallback(() => {
        navigate('/setting-aiintegration');
  	}, [navigate]);
  	
  	const onClickSecurity = useCallback(() => {
        navigate('/setting-security');
  	}, [navigate]);
  	
  	const onClickBilling = useCallback(() => {
        navigate('/setting-billing');
  	}, []);
  	
  	const onClickHelpSupport = useCallback(() => {
        navigate('/setting-help&support');
  	}, []);
  	
  	const onClickDataManagement = useCallback(() => {
        navigate('/setting-datamanagement');
  	}, []);
  	
  	const onSettingsContainerClick = useCallback(() => {
    		// Add your code here
  	}, []);
  	
  	const openLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(true);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);
  	
  	const onClickClientAccCheck = useCallback(() => {
        navigate('/setting-account');
  	}, [navigate]);
  	
  	const openExportModalImage = useCallback(() => {
    		setExportModalImageOpen(true);
  	}, []);
  	
  	const closeExportModalImage = useCallback(() => {
    		setExportModalImageOpen(false);
  	}, []);

	const breadcrumb = [
		{ label: "", path: "/postlba2", icon: "assets/Home.svg", active: true },
		{ label: "Settings", path: "", icon: "", active: false },
		{ label: "Account", path: "", icon: "", active: true },
	];
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[2686px] overflow-hidden text-left text-base text-neutrals-neutral-300 font-open-sans-ff-meta">
      			<div className="absolute top-[293px] left-[96px] w-[188px] flex flex-col items-start justify-start gap-[6px]">
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickGeneral}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">General</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickPreferences}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Preferences</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickLBAConfig}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">LBA configuration</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickLegalProceedings}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Legal proceedings</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickAIIntegration}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">AI integration</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickSecurity}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Security</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickBilling}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Billing</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickHelpSupport}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">{`Help & support`}</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickDataManagement}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Data management</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 box-border h-[41px] flex flex-row items-center justify-center py-2 px-3 text-primary-primary-500 border-l-[2px] border-solid border-primary-primary-500">
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Account</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[2603px] right-[96px] text-sm">Copyright © Think-AI 2023</div>
      			<div className="absolute top-[179px] left-[96px] bg-neutrals-neutral-50 box-border w-[1248px] flex flex-col items-start justify-start pt-0 px-0 pb-6 gap-[6px] text-9xl text-primary-primary-900 border-b-[1px] border-solid border-neutrals-neutral-100">
        				<b className="relative">Settings</b>
        				<div className="relative text-base text-neutrals-neutral-300">Get your billing information, generate/review invoices.</div>
      			</div>
      			<div className="absolute top-[293px] left-[390px] box-border w-[954px] flex flex-row items-start justify-start pt-0 px-0 pb-6 text-lg text-neutrals-neutral-900 border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Account</div>
          					<div className="relative text-sm text-neutrals-neutral-300">Allow users to configure how and when communication is initiated with debtors.</div>
        				</div>
      			</div>
      			
				{/* Navigation Panel */}
				<NavigationPanel/>

				{/* Breadcrumb Navigation */}
				<Breadcrumb breadcrumbs={breadcrumb} position="top-[24px] left-[90px]"/>

      			<div className="absolute top-[391px] left-[390px] bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 box-border cursor-pointer" onClick={onClickClientAccCheck}>
        				<div className="flex flex-row items-center justify-center">
          					<div className="relative font-semibold">Client account checks</div>
        				</div>
      			</div>
      			<div className="absolute top-[391px] left-[589px] bg-neutrals-neutral-50 box-border h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 text-primary-primary-500 border-b-[2px] border-solid border-primary-primary-500">
        				<div className="flex flex-row items-center justify-center">
          					<div className="relative font-semibold">Office account payments</div>
        				</div>
      			</div>
      			<div className="absolute top-[456px] left-[390px] font-semibold text-neutrals-neutral-900">Overview</div>
      			<div className="absolute top-[502px] left-[390px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border w-[400px] flex flex-col items-start justify-start p-6 border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-col items-start justify-start gap-[24px]">
          					<div className="self-stretch flex flex-row items-start justify-between">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<img className="w-5 relative h-5" alt="" src="assets/error.svg" />
            						</div>
            						<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
          					</div>
          					<div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
            						<div className="relative">Outstanding amount</div>
            						<div className="self-stretch flex flex-row items-center justify-between text-13xl text-neutrals-neutral-900">
              							<b className="relative">£200,000</b>
              							<div className="rounded-81xl bg-semantics-low-low-50 flex flex-row items-center justify-center py-0.5 px-2 gap-[6px] text-sm text-semantics-low-low-500 border-[1px] border-solid border-semantics-low-low-500">
                								<img className="w-3.5 relative h-3.5 overflow-hidden shrink-0" alt="" src="streamline:graph-arrow-increase.svg" />
                								<div className="relative font-semibold">7.4%</div>
              							</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[714px] left-[390px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border w-[400px] flex flex-col items-start justify-start p-6 border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-col items-start justify-start gap-[24px]">
          					<div className="self-stretch flex flex-row items-start justify-between">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<img className="w-5 relative h-5" alt="" src="assets/tick-circle.svg" />
            						</div>
            						<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
          					</div>
          					<div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
            						<div className="relative">Active cases</div>
            						<div className="self-stretch flex flex-row items-center justify-between text-13xl text-neutrals-neutral-900">
              							<b className="relative">25</b>
              							<div className="rounded-81xl bg-semantics-low-low-50 flex flex-row items-center justify-center py-0.5 px-2 gap-[6px] text-sm text-semantics-low-low-500 border-[1px] border-solid border-semantics-low-low-500">
                								<img className="w-3.5 relative h-3.5 overflow-hidden shrink-0" alt="" src="streamline:graph-arrow-increase.svg" />
                								<div className="relative font-semibold">7.4%</div>
              							</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[502px] left-[814px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border w-[400px] flex flex-col items-start justify-start p-6 border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-col items-start justify-start gap-[24px]">
          					<div className="self-stretch flex flex-row items-start justify-between">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<img className="w-5 relative h-5" alt="" src="assets/tick-circle.svg" />
            						</div>
            						<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
          					</div>
          					<div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
            						<div className="relative">Successful transactions</div>
            						<div className="self-stretch flex flex-row items-center justify-between text-13xl text-neutrals-neutral-900">
              							<b className="relative">15</b>
              							<div className="rounded-81xl bg-semantics-low-low-50 flex flex-row items-center justify-center py-0.5 px-2 gap-[6px] text-sm text-semantics-low-low-500 border-[1px] border-solid border-semantics-low-low-500">
                								<img className="w-3.5 relative h-3.5 overflow-hidden shrink-0" alt="" src="streamline:graph-arrow-increase.svg" />
                								<div className="relative font-semibold">7.4%</div>
              							</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[940px] left-[390px] w-[954px] flex flex-row items-start justify-start gap-[24px] text-sm">
        				<div className="flex-1 rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="self-stretch flex flex-row items-center justify-between text-lg text-neutrals-neutral-900">
            						<div className="relative font-semibold">Outstanding balances</div>
            						<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
          					</div>
          					<div className="self-stretch flex flex-row items-center justify-start gap-[10px]">
            						<div className="h-[204px] flex flex-col items-start justify-start gap-[11px]">
              							<div className="relative">700 -</div>
              							<div className="relative">600 -</div>
              							<div className="relative">500 -</div>
              							<div className="relative">400 -</div>
              							<div className="relative">300 -</div>
              							<div className="relative">200 -</div>
              							<div className="relative">100 -</div>
            						</div>
            						<div className="flex-1 flex flex-col items-start justify-start relative gap-[10px]">
              							<div className="self-stretch flex flex-col items-start justify-start gap-[30px] z-[0]">
                								<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                								<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                								<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                								<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                								<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                								<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                								<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
              							</div>
              							<div className="w-[374px] !m-[0] absolute top-[61px] left-[0px] flex flex-col items-start justify-start z-[1]">
                								<img className="self-stretch relative max-w-full overflow-hidden h-[59px] shrink-0" alt="" src="Vector.svg" />
                								<img className="self-stretch relative max-w-full overflow-hidden h-[117px] shrink-0 mt-[-58px]" alt="" src="Vector.svg" />
              							</div>
            						</div>
          					</div>
          					<div className="flex flex-row items-start justify-start gap-[64px]">
            						<div className="flex flex-col items-start justify-start gap-[6px]">
              							<div className="flex flex-row items-center justify-start gap-[6px]">
                								<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="workspaces.svg" />
                								<div className="relative font-semibold">Monthly</div>
              							</div>
              							<div className="relative text-5xl font-semibold text-neutrals-neutral-900">£10,000</div>
            						</div>
            						<div className="flex flex-col items-start justify-start gap-[6px]">
              							<div className="flex flex-row items-center justify-start gap-[6px]">
                								<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="workspaces.svg" />
                								<div className="relative font-semibold">Yearly</div>
              							</div>
              							<div className="relative text-5xl font-semibold text-neutrals-neutral-900 text-right">£500,000</div>
            						</div>
          					</div>
        				</div>
        				<div className="flex-1 rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="self-stretch flex flex-row items-center justify-between text-lg text-neutrals-neutral-900">
            						<div className="relative font-semibold">Recovery trends</div>
            						<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
          					</div>
          					<div className="self-stretch flex flex-row items-center justify-start gap-[10px]">
            						<div className="h-[204px] flex flex-col items-start justify-start gap-[11px]">
              							<div className="relative">700 -</div>
              							<div className="relative">600 -</div>
              							<div className="relative">500 -</div>
              							<div className="relative">400 -</div>
              							<div className="relative">300 -</div>
              							<div className="relative">200 -</div>
              							<div className="relative">100 -</div>
            						</div>
            						<div className="w-[374px] flex flex-col items-start justify-start relative gap-[10px]">
              							<div className="self-stretch flex flex-col items-start justify-start gap-[30px] z-[0]">
                								<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                								<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                								<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                								<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                								<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                								<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                								<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
              							</div>
              							<div className="w-96 !m-[0] absolute top-[20px] left-[0px] flex flex-col items-start justify-start z-[1]">
                								<img className="self-stretch relative max-w-full overflow-hidden h-[59px] shrink-0" alt="" src="Vector.svg" />
                								<img className="self-stretch relative max-w-full overflow-hidden h-[58px] shrink-0 mt-[-17px]" alt="" src="Vector.svg" />
                								<img className="self-stretch relative max-w-full overflow-hidden h-[54px] shrink-0 object-contain mt-[-17px]" alt="" src="Vector.svg" />
              							</div>
            						</div>
          					</div>
          					<div className="flex flex-row items-start justify-start gap-[64px]">
            						<div className="flex flex-col items-start justify-start gap-[6px]">
              							<div className="flex flex-row items-center justify-start gap-[6px]">
                								<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="workspaces.svg" />
                								<div className="relative font-semibold">Monthly</div>
              							</div>
              							<div className="relative text-5xl font-semibold text-neutrals-neutral-900">£10,000</div>
            						</div>
            						<div className="flex flex-col items-start justify-start gap-[6px]">
              							<div className="flex flex-row items-center justify-start gap-[6px]">
                								<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="workspaces.svg" />
                								<div className="relative font-semibold">Yearly</div>
              							</div>
              							<div className="relative text-5xl font-semibold text-neutrals-neutral-900 text-right">£500,000</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[1388px] left-[390px] flex flex-col items-start justify-start gap-[24px] text-lg text-neutrals-neutral-900">
        				<div className="relative font-semibold">Office account transactions</div>
        				<div className="flex flex-col items-start justify-start gap-[24px] text-sm text-neutrals-neutral-300">
          					<div className="w-[954px] flex flex-row items-start justify-between">
            						<div className="w-[294px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-start py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-start gap-[10px]">
                								<img className="w-5 relative h-5" alt="" src="assets/search.svg" />
                								<div className="relative">Search</div>
              							</div>
            						</div>
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 text-neutrals-neutral-900 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center gap-[6px]">
                								<img className="w-5 relative h-5" alt="" src="assets/filter.svg" />
                								<div className="relative font-semibold">Filter</div>
              							</div>
            						</div>
          					</div>
          					<div className="w-[954px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] flex flex-col items-start justify-start text-neutrals-neutral-900">
            						<div className="self-stretch flex flex-row items-start justify-start">
              							<div className="w-[190px] flex flex-col items-start justify-start">
                								<div className="self-stretch rounded-tl-3xs rounded-tr-none rounded-b-none bg-column-bg flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
                  									<div className="relative font-semibold">Date</div>
                								</div>
                								<div className="self-stretch flex flex-col items-start justify-start">
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">04-01-2024</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">05-01-2024</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">05-01-2024</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">07-01-2024</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">11-01-2024</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">11-01-2024</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">14-01-2024</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">15-01-2024</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[233px] flex flex-col items-center justify-start">
                								<div className="self-stretch bg-column-bg flex flex-row items-start justify-center py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">Type</div>
                								</div>
                								<div className="self-stretch flex flex-col items-center justify-start">
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Client payment</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Client payment</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Client payment</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
                      											<div className="relative font-semibold">Solicitor fees</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-secure-blue-500 h-1.5" />
                      											<div className="relative font-semibold">Bank transfer</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-secure-blue-500 h-1.5" />
                      											<div className="relative font-semibold">Bank transfer</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
                      											<div className="relative font-semibold">Solicitor fees</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-secure-blue-500 h-1.5" />
                      											<div className="relative font-semibold">Bank transfer</div>
                    										</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[204px] flex flex-col items-start justify-start text-right">
                								<div className="self-stretch bg-column-bg flex flex-row items-start justify-center py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">Amount</div>
                								</div>
                								<div className="self-stretch flex flex-col items-center justify-start">
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£10,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£8,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£6,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£5,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£3,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£12,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£1,000</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">£10,000</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[225px] flex flex-col items-start justify-start">
                								<div className="self-stretch bg-column-bg flex flex-row items-center justify-center py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">Status</div>
                								</div>
                								<div className="self-stretch flex flex-col items-start justify-start text-semantics-low-low-500">
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Completed</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Completed</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Completed</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Completed</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 text-semantics-moderate-moderate-500 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-moderate-moderate-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-moderate-moderate-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
                      											<div className="relative font-semibold">Pending</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 text-semantics-moderate-moderate-500 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-moderate-moderate-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-moderate-moderate-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
                      											<div className="relative font-semibold">Pending</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 text-semantics-high-high-500 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-high-high-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-high-high-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-high-high-500 h-1.5" />
                      											<div className="relative font-semibold">Failed</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 text-semantics-high-high-500 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-high-high-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-high-high-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-high-high-500 h-1.5" />
                      											<div className="relative font-semibold">Failed</div>
                    										</div>
                  									</div>
                								</div>
              							</div>
              							<div className="flex flex-col items-start justify-start">
                								<div className="w-[102px] rounded-tl-none rounded-tr-3xs rounded-b-none bg-column-bg box-border flex flex-row items-center justify-center py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-b-[1px]">
                  									<div className="relative font-semibold">Reference</div>
                								</div>
                								<div className="flex flex-col items-start justify-start">
                  									<div className="w-[102px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">#764382</div>
                  									</div>
                  									<div className="w-[102px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">#715386</div>
                  									</div>
                  									<div className="w-[102px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">#729476</div>
                  									</div>
                  									<div className="w-[102px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">#752952</div>
                  									</div>
                  									<div className="w-[102px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">#710376</div>
                  									</div>
                  									<div className="w-[102px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">#739465</div>
                  									</div>
                  									<div className="w-[102px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">#704725</div>
                  									</div>
                  									<div className="w-[102px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">#773965</div>
                  									</div>
                								</div>
              							</div>
            						</div>
            						<div className="self-stretch rounded-t-none rounded-b-3xs flex flex-row items-center justify-between pt-3 px-6 pb-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
              							<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
                								<div className="flex flex-row items-center justify-center">
                  									<div className="relative font-semibold">Previous</div>
                								</div>
              							</div>
              							<div className="relative">Page 1 of 10</div>
              							<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
                								<div className="flex flex-row items-center justify-center">
                  									<div className="relative font-semibold">Next</div>
                								</div>
              							</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[1355.5px] left-[389.5px] box-border w-[955px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
      			<div className="absolute top-[2075px] left-[390px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border w-[954px] flex flex-col items-start justify-start p-6 gap-[24px] text-sm text-neutrals-neutral-900 border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-center justify-between text-lg">
          					<div className="relative font-semibold">Payment trends</div>
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 cursor-pointer text-sm border-[1px] border-solid border-neutrals-neutral-100" onClick={openExportModalImage}>
            						<div className="flex flex-row items-center justify-center gap-[6px]">
              							<img className="w-5 relative h-5" alt="" src="assets/export.svg" />
              							<div className="relative font-semibold">Export</div>
            						</div>
          					</div>
        				</div>
        				<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-start border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="rounded-tl-3xs rounded-tr-none rounded-br-none rounded-bl-3xs bg-column-bg flex flex-row items-center justify-center py-2 px-4 border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="relative font-semibold">Daily</div>
          					</div>
          					<div className="flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-solid border-neutrals-neutral-100">
            						<div className="relative font-semibold">Weekly</div>
          					</div>
          					<div className="flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-solid border-neutrals-neutral-100">
            						<div className="relative font-semibold">Monthly</div>
          					</div>
          					<div className="rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none flex flex-row items-center justify-center py-2 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-b-[1px]">
            						<div className="flex flex-row items-center justify-center gap-[6px]">
              							<img className="w-5 relative h-5" alt="" src="assets/add.svg" />
              							<div className="relative font-semibold">Custom</div>
            						</div>
          					</div>
        				</div>
        				<div className="self-stretch flex flex-col items-end justify-start gap-[24px] text-neutrals-neutral-300">
          					<div className="flex flex-row items-start justify-start gap-[10px]">
            						<div className="flex flex-row items-center justify-start gap-[6px]">
              							<div className="w-2 relative rounded-[50%] bg-primary-primary-100 h-2" />
              							<div className="relative">Outgoing transactions</div>
            						</div>
            						<div className="flex flex-row items-center justify-start gap-[6px]">
              							<div className="w-2 relative rounded-[50%] bg-primary-primary-500 h-2" />
              							<div className="relative">Incoming transactions</div>
            						</div>
          					</div>
          					<div className="self-stretch h-[249px] flex flex-col items-start justify-between text-center text-neutrals-neutral-900">
            						<div className="self-stretch flex flex-row items-center justify-start gap-[10px]">
              							<div className="flex flex-row items-center justify-start gap-[10px]">
                								<div className="relative font-semibold [transform:_rotate(-90deg)]">Payment amount</div>
                								<div className="h-[201px] flex flex-col items-end justify-start gap-[11px] text-left text-neutrals-neutral-300">
                  									<div className="relative">6</div>
                  									<div className="relative">5</div>
                  									<div className="relative">4</div>
                  									<div className="relative">3</div>
                  									<div className="relative">2</div>
                  									<div className="relative">1</div>
                  									<div className="relative">0</div>
                								</div>
              							</div>
              							<div className="flex-1 flex flex-col items-start justify-start relative gap-[10px]">
                								<div className="self-stretch flex flex-col items-start justify-start gap-[30px] z-[0]">
                  									<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                								</div>
                								<div className="w-[858px] !m-[0] absolute top-[25.5px] left-[0px] h-[129px] flex flex-col items-end justify-start z-[1]">
                  									<img className="self-stretch relative max-w-full overflow-hidden h-[114px] shrink-0" alt="" src="Vector.svg" />
                  									<img className="self-stretch relative max-w-full overflow-hidden h-[138.5px] shrink-0 mt-[-115px]" alt="" src="Vector.svg" />
                								</div>
              							</div>
            						</div>
            						<div className="self-stretch h-12 flex flex-col items-center justify-between text-neutrals-neutral-300">
              							<div className="self-stretch flex flex-row items-start justify-between">
                								<div className="relative">Mon</div>
                								<div className="relative">Tue</div>
                								<div className="relative">Wed</div>
                								<div className="relative">Thu</div>
                								<div className="relative">Fri</div>
                								<div className="relative">Sat</div>
                								<div className="relative">Sun</div>
              							</div>
              							<div className="self-stretch relative font-semibold text-neutrals-neutral-900">Time</div>
            						</div>
          					</div>
        				</div>
      			</div>
    		</div>
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}
    		{isExportModalImageOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeExportModalImage}
        				>
        				<ExportModalImage onClose={closeExportModalImage} />
      			</PortalPopup>
    		)}</>);
};

export default SettingsAccount2Page;