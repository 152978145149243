import { useState, useCallback } from 'react';
import CTAs from './CTAs';
import Dropdown from './Dropdown';

const ExportModalImage = ({onClose}) => {

const dropdownContents = ["JPG", "PNG"];

      return (
              <div className="relative shadow-[0px_10px_60px_rgba(0,_0,_0,_0.1)] rounded-3xs bg-neutrals-neutral-50 box-border w-full flex flex-col items-start justify-start gap-[24px] text-left text-sm text-neutrals-neutral-900 font-open-sans-ff-meta-strong border-[1px] border-solid border-neutrals-neutral-100">
                      <div className="flex flex-col items-start justify-start pt-6 px-6 pb-0 gap-[24px] text-lg">
                              <div className="flex flex-row items-start justify-start gap-[292px]">
                                      <div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
                                              <img className="w-5 relative h-5" alt="" src="assets/export.svg" />
                                      </div>
                                      <img className="w-5 relative h-5 overflow-hidden shrink-0 cursor-pointer" alt="" src="assets/cancel.svg" onClick={onClose} />
                              </div>
                              <div className="flex flex-col items-start justify-start gap-[6px]">
                                      <div className="w-[352px] relative font-semibold inline-block">Export options</div>
                                      <div className="w-[352px] relative text-base text-neutrals-neutral-300 inline-block">Select the file type.</div>
                              </div>
                      </div>
                      <div className="flex flex-col items-start justify-start py-0 px-6">
                              <div className="w-[352px] flex flex-col items-start justify-start gap-[10px]">
                                <Dropdown
                                title="Choose a file format"
                                placeholder="Select format"
                                width='352px'
                                dropdownContents={dropdownContents}
                                />
                              </div>
                      </div>
                      <div className="w-[400px] flex flex-row items-start justify-center pt-0 px-6 pb-6 box-border gap-[24px]">
                        <CTAs
                        type="Ghostbtn"
                        text="Cancel"
                        textColor="text-neutrals-neutral-900"
                        bgColor="bg-neutrals-neutral-50"
                        borderColor="border-neutrals-neutral-100"
                        width="165px"
                        position="relative"
                        onClick={onClose}
                        />

                        <CTAs
                        type="Primarybtn"
                        text="Export"
                        textColor="text-neutrals-neutral-50"
                        bgColor="bg-primary-primary-500"
                        borderColor="border-primary-primary-500"
                        width="165px"
                        position="relative"
                        />
                      </div>
              </div>);
};

export default ExportModalImage;