import { useState, useCallback } from 'react';
import LBAConfirmation from "../components/LBAConfirmation";
import PortalPopup from "../components/PortalPopup";
import CTAs from './CTAs';

const ProgressBar = () => {
  const [isLBAConfirmationOpen, setLBAConfirmationOpen] = useState(false);

  const openLBAConfirmation = useCallback(() => {
    setLBAConfirmationOpen(true);
  }, []);

  const closeLBAConfirmation = useCallback(() => {
    setLBAConfirmationOpen(false);
  }, []);

  return (
    <>
      <div className="w-full relative shadow-[0px_4px_10px_rgba(0,_0,_0,_0.05)] bg-neutrals-neutral-50 flex flex-row flex-wrap items-end justify-start py-6 px-24 box-border gap-[24px_872px] text-left text-9xl text-neutrals-neutral-900 font-open-sans-ff-heading-2">
        <div className="w-full relative flex flex-col items-start justify-start gap-[10px]">
          <b className="relative">LBA Process</b>
          <div className="w-[1248px] flex flex-row items-center justify-start gap-[10px] text-sm">
            <div className="w-full relative h-[8.3px]">
              <div className="absolute top-[0px] left-[0px] rounded-81xl bg-primary-primary-50 w-[1204px] h-2" />
              <div className="absolute top-[0.27px] left-[0px] shadow-[0px_10px_12px_rgba(0,_0,_0,_0.1)_inset] rounded-81xl bg-primary-primary-500 w-[214px] h-2" />
            </div>
            <div className="relative font-semibold">28%</div>
          </div>
        </div>

        <div className="w-full relative flex flex-row items-start justify-between text-sm">
          <CTAs
            type="Ghostbtn"
            text="Back"
            textColor="text-neutrals-neutral-900"
            bgColor="bg-neutrals-neutral-50"
            borderColor="border-neutrals-neutral-100"
            icon="assets/arrow-left.svg"
            width="188px"
            position="relative"
          />

          <CTAs
            type="Primarybtn"
            text="Continue"
            textColor="text-neutrals-neutral-50"
            bgColor="bg-primary-primary-500"
            borderColor="border-primary-primary-500"
            icon="assets/arrow-right.svg"
            width="188px"
            position="relative"
            onClick={openLBAConfirmation}
          />
        </div>
      </div>

      {isLBAConfirmationOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeLBAConfirmation}
        >
          <LBAConfirmation onClose={closeLBAConfirmation} />
        </PortalPopup>
      )}
    </>
  );
};

export default ProgressBar;