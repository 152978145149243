import { useState, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import NewTriggerModal from "../../components/NewTriggerModal";
import PortalPopup from "../../components/PortalPopup";
import ToastMsg from "../../components/ToastMsg";
import LogoutConfirmation from "../../components/LogoutConfirmation";
import Toggle from '../../components/Toggle';
import NavigationPanel from '../../components/NavigationPanel';
import Breadcrumb from '../../components/Breadcrumb';
import CTAs from '../../components/CTAs';
import Dropdown from '../../components/Dropdown';

const SettingsPreferencesPage = () => {
	const navigate = useNavigate();
  	const [isNewTriggerModalOpen, setNewTriggerModalOpen] = useState(false);
  	const [isToastMsgOpen, setToastMsgOpen] = useState(false);
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	
  	const onTabStatesGeneralClick = useCallback(() => {
		navigate('/setting-general');
  	}, []);
  	
  	
  	const onClickLBAConfig = useCallback(() => {
		navigate('/setting-lbaconfig');
  	}, [navigate]);
  	
  	
  	const onClickLegalProceedings = useCallback(() => {
		navigate('/setting-legalproceedings');
  	}, [navigate]);
  	
  	
  	const onClickAIIntegration = useCallback(() => {
		navigate('/setting-aiintegration');
  	}, [navigate]);
  	
  	
  	const onClickSecurity = useCallback(() => {
		navigate('/setting-security');
  	}, [navigate]);
  	
  	
  	const onClickBilling = useCallback(() => {
		navigate('/setting-billing');
  	}, [navigate]);
  	
  	
  	const onClickHelpSupport = useCallback(() => {
		navigate('/setting-help&support');
  	}, [navigate]);
  	
  	
  	const onClickDataManagement = useCallback(() => {
		navigate('/setting-datamanagement');
  	}, [navigate]);
  	
  	
  	const onClickAccount = useCallback(() => {
		navigate('/setting-account');
  	}, [navigate]);
  	
  	
  	const openNewTriggerModal = useCallback(() => {
    		setNewTriggerModalOpen(true);
  	}, []);
  	
  	const closeNewTriggerModal = useCallback(() => {
    		setNewTriggerModalOpen(false);
  	}, []);
  	
  	
  	const openToastMsg = useCallback(() => {
    		setToastMsgOpen(true);
  	}, []);
  	
  	const closeToastMsg = useCallback(() => {
    		setToastMsgOpen(false);
  	}, []);
  	
  	const openLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(true);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);

	const breadcrumb = [
		{ label: "", path: "/postlba2", icon: "assets/Home.svg", active: true },
		{ label: "Settings", path: "", icon: "", active: false },
		{ label: "Preferences", path: "", icon: "", active: true },
	];

	const dropdownContents = ["SMS", "Email", "Phone call"];

	const handleToggle = (state) => {
		console.log("Toggle state is:", state);
	  };
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1799px] overflow-hidden text-left text-base text-neutrals-neutral-900 font-open-sans-ff-body-strong">
      			<div className="absolute top-[293px] left-[96px] w-[188px] flex flex-col items-start justify-start gap-[6px] text-neutrals-neutral-300">
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onTabStatesGeneralClick}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">General</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 box-border h-[41px] flex flex-row items-center justify-center py-2 px-3 text-primary-primary-500 border-l-[2px] border-solid border-primary-primary-500">
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Preferences</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickLBAConfig}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">LBA configuration</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickLegalProceedings}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Legal proceedings</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickAIIntegration}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">AI integration</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickSecurity}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Security</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickBilling}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Billing</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickHelpSupport}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">{`Help & support`}</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickDataManagement}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Data management</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickAccount}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Account</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[1716px] right-[96px] text-sm text-neutrals-neutral-300">Copyright © Think-AI 2023</div>
      			<div className="absolute top-[392px] left-[390px] font-semibold">Communication preferences</div>

				{/* For SMS dialog box */}
      			<div className="absolute top-[438px] left-[390px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border w-[318px] h-[178px] flex flex-col items-start justify-start p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-start justify-between">
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
            						<img className="w-5 relative h-5" alt="" src="assets/sms.svg"/>
          					</div>
							<Toggle
							onToggle={handleToggle}
							isOn={true}
							onColor='bg-primary-primary-500'
							offColor='bg-primary-primary-50'
							knobColor='bg-neutrals-neutral-50'
							/>
        				</div>
        				<div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
                        <div className="relative text-base font-semibold font-open-sans-ff-body-strong text-neutrals-neutral-900 text-left">SMS</div>
          					<div className="self-stretch relative text-sm text-neutrals-neutral-300">User will be communicated through sms.</div>
        				</div>
      			</div>

				{/* For Email dialog box */}
      			<div className="absolute top-[438px] left-[732px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border w-[318px] flex flex-col items-start justify-start p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-start justify-between">
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
            						<img className="w-5 relative h-5" alt="" src="assets/mail.svg"/>
          					</div>
							<Toggle
							onToggle={handleToggle}
							isOn={true}
							onColor='bg-primary-primary-500'
							offColor='bg-primary-primary-50'
							knobColor='bg-neutrals-neutral-50'
							/>
        				</div>
        				<div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
                        <div className="relative text-base font-semibold font-open-sans-ff-body-strong text-neutrals-neutral-900 text-left">Email</div>
                        <div className="w-full relative text-sm font-open-sans-ff-meta text-neutrals-neutral-300 text-left inline-block">User will be communicated through emails.</div>
        				</div>
      			</div>

				{/* For Phone Call dialog box */}
      			<div className="absolute top-[646px] left-[390px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border w-[318px] flex flex-col items-start justify-start p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-start justify-between">
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
            						<img className="w-5 relative h-5" alt="" src="assets/call.svg"/>
          					</div>
							<Toggle
							onToggle={handleToggle}
							isOn={true}
							onColor='bg-primary-primary-500'
							offColor='bg-primary-primary-50'
							knobColor='bg-neutrals-neutral-50'
							/>
        				</div>
        				<div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Phone calls</div>
          					<div className="self-stretch relative text-sm text-neutrals-neutral-300">User will be communicated through phone calls.</div>
        				</div>
      			</div>

				{/* For Payment Reminders dialog box */}
      			<div className="absolute top-[646px] left-[732px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border w-[318px] flex flex-col items-start justify-start p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-start justify-between">
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
            						<img className="w-5 relative h-5" alt="" src="assets/card.svg"/>
          					</div>
							<Toggle
							onToggle={handleToggle}
							isOn={true}
							onColor='bg-primary-primary-500'
							offColor='bg-primary-primary-50'
							knobColor='bg-neutrals-neutral-50'
							/>
        				</div>
        				<div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Payment reminders</div>
          					<div className="self-stretch relative text-sm text-neutrals-neutral-300">User will be notified for payment reminders.</div>
        				</div>
      			</div>

				{/* For Case Updates dialog box */}
      			<div className="absolute top-[854px] left-[390px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border w-[318px] flex flex-col items-start justify-start p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-start justify-between">
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
            						<img className="w-5 relative h-5" alt="" src="assets/tick-circle.svg" />
          					</div>
							<Toggle
							onToggle={handleToggle}
							isOn={true}
							onColor='bg-primary-primary-500'
							offColor='bg-primary-primary-50'
							knobColor='bg-neutrals-neutral-50'
							/>
        				</div>
        				<div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Case updates</div>
          					<div className="self-stretch relative text-sm text-neutrals-neutral-300">User will be notified for their respected case updates.</div>
        				</div>
      			</div>


      			<div className="absolute top-[1236px] left-[390px] flex flex-col items-start justify-start gap-[24px]">
        				<div className="w-[954px] box-border flex flex-row items-start justify-between pt-0 px-0 pb-6 border-b-[1px] border-solid border-neutrals-neutral-100">
          					<div className="flex flex-col items-start justify-start gap-[6px]">
            						<div className="relative font-semibold">Action triggers</div>
            						<div className="w-full relative text-sm font-open-sans-ff-meta text-neutrals-neutral-300 text-left inline-block">User will be communicated through sms.</div>
          					</div>
							  <CTAs
								type="Ghostbtn"
								text="Add trigger"
								textColor="text-neutrals-neutral-900"
								bgColor="bg-neutrals-neutral-50"
								borderColor="border-neutrals-neutral-100"
								position="relative"
								onClick={openNewTriggerModal}
								/>
        				</div>
						
        				<div className="w-[954px] [filter:drop-shadow(0px_1px_2px_rgba(0,_0,_0,_0.05))] flex flex-row items-start justify-start text-sm">
          					<div className="w-[177px] flex flex-col items-start justify-start">
            						<div className="self-stretch rounded-tl-3xs rounded-tr-none rounded-b-none bg-column-bg flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
              							<div className="relative font-semibold">Name</div>
            						</div>
            						<div className="self-stretch flex flex-col items-start justify-start text-left">
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                								<div className="relative">Payment delay</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                								<div className="relative">Defence raised</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                								<div className="relative">Billing schedule</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                								<div className="relative">Payment Delay</div>
              							</div>
              							<div className="self-stretch rounded-t-none rounded-br-none rounded-bl-3xs bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                								<div className="relative">Payment Delay</div>
              							</div>
            						</div>
          					</div>
          					<div className="w-[363px] flex flex-col items-start justify-start text-right">
            						<div className="self-stretch bg-column-bg flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
              							<div className="relative font-semibold">Condition</div>
            						</div>
            						<div className="self-stretch flex flex-col items-start justify-start text-left">
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="flex-1 relative">If payment is delayed by more than 14 days.</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="flex-1 relative">If defence is raised by the debtor.</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="flex-1 relative">If court proceedings go beyond 7 days.</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="flex-1 relative">If payment is delayed by more than 14 days.</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="flex-1 relative">If payment is delayed by more than 14 days.</div>
              							</div>
            						</div>
          					</div>
          					<div className="w-[312px] flex flex-col items-start justify-start text-right">
            						<div className="self-stretch bg-column-bg flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
              							<div className="relative font-semibold">Associated action</div>
            						</div>
            						<div className="self-stretch flex flex-col items-start justify-start text-center">
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="relative">Escalate the case to legal proceedings.</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="relative">Schedule a communication with him.</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="relative">Initiate billing schedule.</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="relative">Escalate the case to legal proceedings.</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="relative">Escalate the case to legal proceedings.</div>
              							</div>
            						</div>
          					</div>
          					<div className="flex flex-col items-start justify-start">
            						<div className="w-[102px] rounded-tl-none rounded-tr-3xs rounded-b-none bg-column-bg box-border flex flex-row items-center justify-center py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-b-[1px]">
              							<div className="relative font-semibold">Actions</div>
            						</div>
            						<div className="flex flex-col items-start justify-start">
              							<div className="w-[102px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                								<img className="w-5 relative h-5" alt="" src="assets/edit.svg"/>
              							</div>
              							<div className="w-[102px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                								<img className="w-5 relative h-5" alt="" src="assets/edit.svg"/>
              							</div>
              							<div className="w-[102px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                								<img className="w-5 relative h-5" alt="" src="assets/edit.svg"/>
              							</div>
              							<div className="w-[102px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                								<img className="w-5 relative h-5" alt="" src="assets/edit.svg"/>
              							</div>
              							<div className="w-[102px] rounded-t-none rounded-br-3xs rounded-bl-none bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                								<img className="w-5 relative h-5" alt="" src="assets/edit.svg"/>
              							</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[1059.5px] left-[389.5px] box-border w-[955px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
      			<div className="absolute top-[1203.5px] left-[389.5px] box-border w-[955px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
      			<div className="absolute top-[293px] left-[390px] box-border w-[954px] flex flex-row items-start justify-between pt-0 px-0 pb-6 text-lg border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Preferences</div>
          					<div className="relative text-sm text-neutrals-neutral-300">Allow users to configure how and when communication is initiated with debtors.</div>
        				</div>
        				<div className="flex flex-row items-start justify-start gap-[12px] text-sm">
							<CTAs
							type="Ghostbtn"
							text="Cancel"
							textColor="text-neutrals-neutral-900"
							bgColor="bg-neutrals-neutral-50"
							borderColor="border-neutrals-neutral-100"
							position="relative"
							/>
          					
							<CTAs
							type="Primarybtn"
							text="Save"
							textColor="text-neutrals-neutral-50"
							bgColor="bg-primary-primary-500"
							borderColor="border-primary-primary-500"
							position="relative"
							onClick={openToastMsg}
							/>
        				</div>
      			</div>
      			<div className="absolute top-[179px] left-[96px] bg-neutrals-neutral-50 box-border w-[1248px] flex flex-col items-start justify-start pt-0 px-0 pb-6 gap-[6px] text-9xl text-primary-primary-900 border-b-[1px] border-solid border-neutrals-neutral-100">
        				<b className="relative">Settings</b>
        				<div className="relative text-base text-neutrals-neutral-300">Get your billing information, generate/review invoices.</div>
      			</div>

				{/* Navigation Panel */}
				<NavigationPanel/>

				{/* Breadcrumb Navigation */}
				<Breadcrumb breadcrumbs={breadcrumb} position="top-[24px] left-[90px]" />

				{/* Dropdown */}
      			<div className="absolute top-[1092px] left-[390px] w-[400px] flex flex-col items-start justify-start gap-[10px] text-sm">
					<Dropdown
					title="Default communication"
					icon="assets/help-circle.svg"
					placeholder="Select communication"
					dropdownContents={dropdownContents}
					/>
        				{/* <div className="flex flex-row items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Default communication</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg"/>
        				</div>
        				<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-end justify-start py-3.5 px-2.5 gap-[175px] text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="relative">Select communication</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/arrow-down.svg"/>
        				</div> */}
      			</div>
    		</div>
    		{isNewTriggerModalOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeNewTriggerModal}
        				>
        				<NewTriggerModal onClose={closeNewTriggerModal} />
      			</PortalPopup>
    		)}
    		{isToastMsgOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeToastMsg}
        				>
        				<ToastMsg onClose={closeToastMsg} />
      			</PortalPopup>
    		)}
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}</>);
};

export default SettingsPreferencesPage;