import { useCallback, useState } from "react";
import PropTypes from 'prop-types';
import CTAs from "./CTAs";

const CourtStatusFilter = ({ onClose }) => {
    return (
          <div className={"relative shadow-[0px_10px_60px_rgba(0,_0,_0,_0.1)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start gap-[24px] max-w-full max-h-full overflow-auto text-left text-sm text-neutrals-neutral-900 font-open-sans-ff-body-strong border-[1px] border-solid border-neutrals-neutral-100 ${className}"}>
                <div className="self-stretch flex flex-col items-start justify-start pt-6 px-4 pb-0">
                      <div className="w-[400px] flex flex-col items-start justify-start gap-[10px]">
                            <div className="relative font-semibold">Court status</div>
                            <div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-3.5 px-2.5 gap-[258px] text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
                                  <div className="relative">Select status</div>
                                  <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/arrow-down.svg" />
                            </div>
                      </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start">
                      <div className="flex flex-col items-start justify-start gap-[32px]">
                            <div className="flex flex-col items-start justify-start py-0 px-4 gap-[24px]">
                                  <div className="flex flex-row items-start justify-start">
                                        <div className="relative font-semibold">Case ID range</div>
                                  </div>
                                  <div className="w-[400px] relative h-[52px] text-base text-neutrals-neutral-300">
                                        <div className="absolute w-full top-[calc(50%_+_4px)] right-[0%] left-[0%] flex flex-row items-center justify-between">
                                              <div className="relative">0</div>
                                              <div className="relative">45</div>
                                              <div className="w-[5px] relative font-semibold font-inter-family-font-body-strong text-right hidden">.</div>
                                              <div className="relative text-right">100</div>
                                        </div>
                                        <img className="absolute h-[46.15%] w-full top-[0%] right-[0%] bottom-[53.85%] left-[0%] max-w-full overflow-hidden max-h-full" alt="" src="Group 152.svg" />
                                  </div>
                            </div>
                            <div className="flex flex-row items-start justify-start pt-4 px-4 pb-6 gap-[24px]">
                              <CTAs
                              type="Ghostbtn"
                              text="Cancel"
                              textColor="text-neutrals-neutral-900"
                              bgColor="bg-neutrals-neutral-50"
                              borderColor="border-neutrals-neutral-100"
                              width="188px"
                              position="relative"
                              onClick={onClose}
                              />

                              <CTAs
                              type="Primarybtn"
                              text="Apply"
                              textColor="text-neutrals-neutral-50"
                              bgColor="bg-primary-primary-500"
                              borderColor="border-primary-primary-500"
                              width="188px"
                              position="relative"
                              />
                            </div>
                      </div>
                </div>
          </div>);
};

CourtStatusFilter.propTypes = {
    className: PropTypes.string
};

export default CourtStatusFilter;