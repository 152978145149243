import { useState, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import InstructionDeletion from "../../components/InstructionDeletion";
import PortalPopup from "../../components/PortalPopup";
import FileDeletion from "../../components/FileDeletion";
import LogoutConfirmation from "../../components/LogoutConfirmation";
import NavigationPanelLawyer from '../../components/NavigationPanelLawyer';
import Breadcrumb from '../../components/Breadcrumb';
import CTAs from '../../components/CTAs';

const CDI_3 = ({onClose}) => {
    const navigate = useNavigate();
  	const [isInstructionDeletionOpen, setInstructionDeletionOpen] = useState(false);
  	const [isFileDeletionOpen, setFileDeletionOpen] = useState(false);
  	const [isFileDeletion1Open, setFileDeletion1Open] = useState(false);
  	const [isFileDeletion2Open, setFileDeletion2Open] = useState(false);
  	const [isFileDeletion3Open, setFileDeletion3Open] = useState(false);
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	
  	const openInstructionDeletion = useCallback(() => {
    		setInstructionDeletionOpen(true);
  	}, []);
  	
  	const closeInstructionDeletion = useCallback(() => {
    		setInstructionDeletionOpen(false);
  	}, []);
  	
  	const openFileDeletion = useCallback(() => {
    		setFileDeletionOpen(true);
  	}, []);
  	
  	const closeFileDeletion = useCallback(() => {
    		setFileDeletionOpen(false);
  	}, []);
  	
  	const openFileDeletion1 = useCallback(() => {
    		setFileDeletion1Open(true);
  	}, []);
  	
  	const closeFileDeletion1 = useCallback(() => {
    		setFileDeletion1Open(false);
  	}, []);
  	
  	const openFileDeletion2 = useCallback(() => {
    		setFileDeletion2Open(true);
  	}, []);
  	
  	const closeFileDeletion2 = useCallback(() => {
    		setFileDeletion2Open(false);
  	}, []);
  	
  	const openFileDeletion3 = useCallback(() => {
    		setFileDeletion3Open(true);
  	}, []);
  	
  	const closeFileDeletion3 = useCallback(() => {
    		setFileDeletion3Open(false);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);

	const navItems = [
		{ text: "Dashboard", bgColor: "bg-neutrals-neutral-50", textColor: "text-neutrals-neutral-300", hoverBgColor: "bg-neutrals-neutral-50", hoverTextColor: "text-primary-primary-500", path: "" },
		{ text: "Legal proceedings & billing", bgColor: "bg-neutrals-neutral-50", textColor: "text-neutrals-neutral-300", hoverBgColor: "bg-neutrals-neutral-50", hoverTextColor: "text-primary-primary-500", path: "/legal_1" },
		{ text: "Pre-LBA checks", bgColor: "bg-neutrals-neutral-50", textColor: "text-neutrals-neutral-300", hoverBgColor: "bg-neutrals-neutral-50", hoverTextColor: "text-primary-primary-500", path: "/prelbacheck" },
		{ text: "Client-debtor interaction", bgColor: "bg-primary-primary-50", textColor: "text-neutrals-neutral-900", hoverBgColor: "bg-primary-primary-50", hoverTextColor: "text-neutrals-neutral-900", path: "/cdi1" },
	];

	const breadcrumb = [
		{ label: "", path: "", icon: "assets/home.svg", active: true },
		{ label: "Client instructions", path: "/cdi1", icon: "", active: false },
		{ label: "Review payment history", path: "", icon: "", active: true },
	];
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1776px] overflow-hidden text-left text-sm text-neutrals-neutral-300 font-open-sans-ff-body-strong">
      			<div className="absolute top-[1693px] right-[96px]">Copyright © Think-AI 2023</div>
      			<b className="absolute top-[128px] left-[96px] text-9xl text-neutrals-neutral-900">Client-debtor interaction</b>
      			<div className="absolute top-[172px] left-[96px] text-base inline-block w-[400px]">{`Get all your information for client & debtor over here.`}</div>
      			<div className="absolute top-[269px] left-[96px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start text-base text-neutrals-neutral-900 border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="w-[400px] rounded-t-3xs rounded-b-none bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start p-6 gap-[24px]">
          					<div className="self-stretch flex flex-row items-start justify-between">
            						<div className="flex-1 flex flex-col items-start justify-start gap-[10px]">
              							<div className="self-stretch relative font-semibold">Review payment history</div>
              							<div className="self-stretch relative text-neutrals-neutral-300">Review debtor's recent payment history and provide recommendations.</div>
              							</div>
              							<div className="rounded-[20px] bg-semantics-moderate-moderate-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm text-semantics-moderate-moderate-500 border-[1px] border-solid border-semantics-moderate-moderate-500">
                								<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
                								<div className="relative font-semibold">Pending</div>
              							</div>
            						</div>
            						<div className="self-stretch flex flex-col items-start justify-start gap-[10px] text-neutrals-neutral-300">
              							<div className="flex flex-row items-center justify-start gap-[6px]">
                								<img className="w-5 relative h-5" alt="" src="assets/calendar.svg"/>
                								<div className="relative">02/02/24</div>
              							</div>
              							<div className="rounded-[5px] bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm text-neutrals-neutral-900 border-[1px] border-solid border-neutrals-neutral-100">
                								<div className="w-1.5 relative rounded-[50%] bg-semantics-high-high-500 h-1.5" />
                								<div className="relative font-semibold">High</div>
              							</div>
            						</div>
          					</div>
          					<div className="w-[400px] box-border flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
            						<div className="flex flex-row items-start justify-end gap-[10px]">
										<CTAs
										type="Ghostbtn"
										text="Delete"
										textColor="text-neutrals-neutral-900"
										bgColor="bg-neutrals-neutral-50"
										borderColor="border-neutrals-neutral-100"
										position="relative"
										onClick={openInstructionDeletion}
										/>

										<CTAs
										type="Ghostbtn"
										text="Mark as complete"
										textColor="text-neutrals-neutral-900"
										bgColor="bg-neutrals-neutral-50"
										borderColor="border-neutrals-neutral-100"
										position="relative"
										/>
            						</div>
          					</div>
        				</div>
        				<div className="absolute top-[629px] left-[96px] flex flex-col items-start justify-start gap-[24px] text-lg text-neutrals-neutral-900">
          					<div className="w-[1248px] box-border flex flex-col items-start justify-start pt-0 px-0 pb-6 border-b-[1px] border-solid border-neutrals-neutral-100">
            						<div className="self-stretch flex flex-row items-start justify-between">
              							<div className="flex flex-col items-start justify-start gap-[6px]">
                								<div className="relative font-semibold">Attached documents</div>
                								<div className="relative text-base text-neutrals-neutral-300">List of all attached documents.</div>
              							</div>
              							<div className="flex flex-row items-start justify-end gap-[10px] text-sm">
											<CTAs
											type="Ghostbtn"
											text="Delete all"
											textColor="text-neutrals-neutral-900"
											bgColor="bg-neutrals-neutral-50"
											borderColor="border-neutrals-neutral-100"
											position="relative"
											icon="assets/delete.svg"
											onClick={openFileDeletion}
											/>

											<CTAs
											type="Ghostbtn"
											text="Download all"
											textColor="text-neutrals-neutral-900"
											bgColor="bg-neutrals-neutral-50"
											borderColor="border-neutrals-neutral-100"
											position="relative"
											icon="assets/download.svg"
											/>
              							</div>
            						</div>
          					</div>
          					<div className="w-[1248px] flex flex-row flex-wrap items-start justify-start gap-[24px] text-base">
            						<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="self-stretch flex flex-row items-start justify-between p-6">
                								<div className="flex flex-col items-start justify-start gap-[10px]">
                  									<img className="w-10 relative h-10" alt="" src="assets/pdf.svg" />
                  									<div className="relative font-semibold">Invoice.pdf</div>
                  									<div className="relative text-neutrals-neutral-300">1.2MB</div>
                								</div>
                								<div className="relative text-neutrals-neutral-300">29/01/24</div>
              							</div>
              							<div className="self-stretch flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
                								<div className="flex flex-row items-start justify-end gap-[10px]">
													<CTAs
													type="Ghostbtn"
													text="Delete"
													textColor="text-neutrals-neutral-900"
													bgColor="bg-neutrals-neutral-50"
													borderColor="border-neutrals-neutral-100"
													position="relative"
													icon="assets/delete.svg"
													onClick={openFileDeletion1}
													/>

													<CTAs
													type="Ghostbtn"
													text="Download"
													textColor="text-neutrals-neutral-900"
													bgColor="bg-neutrals-neutral-50"
													borderColor="border-neutrals-neutral-100"
													position="relative"
													icon="assets/download.svg"
													/>
                								</div>
              							</div>
            						</div>
            						<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="self-stretch flex flex-row items-start justify-between p-6">
                								<div className="flex flex-col items-start justify-start gap-[10px]">
                  									<div className="flex flex-row items-start justify-start relative gap-[10px]">
                    										<img className="w-10 relative h-10 z-[0]" alt="" src="assets/word_file.svg" />
                  									</div>
                  									<div className="relative font-semibold">Contract.docx</div>
                  									<div className="relative text-neutrals-neutral-300">24KB</div>
                								</div>
                								<div className="relative text-neutrals-neutral-300">29/01/24</div>
              							</div>
              							<div className="self-stretch flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
                								<div className="flex flex-row items-start justify-end gap-[10px]">
													<CTAs
													type="Ghostbtn"
													text="Delete"
													textColor="text-neutrals-neutral-900"
													bgColor="bg-neutrals-neutral-50"
													borderColor="border-neutrals-neutral-100"
													position="relative"
													icon="assets/delete.svg"
													onClick={openFileDeletion1}
													/>

													<CTAs
													type="Ghostbtn"
													text="Download"
													textColor="text-neutrals-neutral-900"
													bgColor="bg-neutrals-neutral-50"
													borderColor="border-neutrals-neutral-100"
													position="relative"
													icon="assets/download.svg"
													/>
                								</div>
              							</div>
            						</div>
            						<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="self-stretch flex flex-row items-start justify-between p-6">
                								<div className="flex flex-col items-start justify-start gap-[10px]">
                  									<img className="w-10 relative h-10" alt="" src="assets/pdf.svg" />
                  									<div className="relative font-semibold">Acknowledgment.pdf</div>
                  									<div className="relative text-neutrals-neutral-300">2.5MB</div>
                								</div>
                								<div className="relative text-neutrals-neutral-300">29/01/24</div>
              							</div>
              							<div className="self-stretch flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
                								<div className="flex flex-row items-start justify-end gap-[10px]">
													<CTAs
													type="Ghostbtn"
													text="Delete"
													textColor="text-neutrals-neutral-900"
													bgColor="bg-neutrals-neutral-50"
													borderColor="border-neutrals-neutral-100"
													position="relative"
													icon="assets/delete.svg"
													onClick={openFileDeletion1}
													/>

													<CTAs
													type="Ghostbtn"
													text="Download"
													textColor="text-neutrals-neutral-900"
													bgColor="bg-neutrals-neutral-50"
													borderColor="border-neutrals-neutral-100"
													position="relative"
													icon="assets/download.svg"
													/>
                								</div>
              							</div>
            						</div>
          					</div>
        				</div>

						

        				<div className="absolute top-[1018px] left-[96px] w-[612px] flex flex-col items-start justify-start gap-[10px] text-neutrals-neutral-900">
                            
                            {/* Upload window */}
          					<div className="relative font-semibold">{`Upload `}</div>
          					<div className="self-stretch flex flex-col items-start justify-start gap-[24px] text-primary-primary-500">
            						<div className="self-stretch flex flex-col items-center justify-start">
              							<div className="self-stretch rounded-3xs bg-neutrals-neutral-50 box-border h-[231px] flex flex-col items-center justify-center p-4 gap-[10px] border-[2px] border-solid border-primary-primary-500">
                								<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
                  									<img className="w-5 relative h-5" alt="" src="assets/document-upload.svg" />
                								</div>
                								<div className="flex flex-col items-center justify-center gap-[6px]">
                  									<div className="relative">
                    										<span className="font-semibold">Click to upload</span>
                    										<span className="text-neutrals-neutral-300"> or drag and drop</span>
                  									</div>
                  									<div className="relative text-neutrals-neutral-300">(Max. File Size: 20 MB)</div>
                								</div>
              							</div>
            						</div>
            						<div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-center justify-center p-4 text-neutrals-neutral-900 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="self-stretch flex flex-row items-start justify-start gap-[10px]">
                								<img className="w-10 relative h-10" alt="" src="assets/pdf.svg" />
                								<div className="flex-1 flex flex-col items-center justify-center gap-[6px]">
                  									<div className="self-stretch flex flex-row items-start justify-between">
                    										<div className="flex-1 flex flex-col items-start justify-start">
                      											<div className="relative font-semibold">Debt_settlement_agreement.pdf</div>
                      											<div className="relative text-neutrals-neutral-300">220 KB</div>
                    										</div>
                    										<img className="w-5 relative h-5" alt="" src="assets/delete.svg" />
                  									</div>
                  									<div className="self-stretch flex flex-row items-center justify-center gap-[10px]">
                    										<div className="flex-1 flex flex-col items-start justify-start relative gap-[10px]">
                      											<div className="self-stretch relative rounded-81xl bg-primary-primary-50 h-2 z-[0]" />
                      											<div className="w-[424px] absolute !m-[0] top-[0px] left-[0px] rounded-81xl bg-primary-primary-500 h-2 z-[1]" />
                    										</div>
                    										<div className="relative font-semibold">60%</div>
                  									</div>
                								</div>
              							</div>
            						</div>
          					</div>

        				</div>
        				<div className="absolute top-[1018px] left-[814px] shadow-[0px_10px_60px_rgba(0,_0,_0,_0.1)] rounded-3xs bg-primary-primary-50 flex flex-row items-start justify-start p-1.5 border-[1px] border-solid border-neutrals-neutral-100">
          					<img className="w-[518px] relative rounded-3xs h-[599px] object-cover" alt="" src="image 4.png" />
        				</div>

						{/* Navigation Panel */}
						<NavigationPanelLawyer navItems={navItems}/>

						{/* Breadcrumb Navigation */}
						<Breadcrumb breadcrumbs={breadcrumb} position="top-[110px] left-[90px]" />
						
        				
      			</div>
      			{isInstructionDeletionOpen && (
        				<PortalPopup
          					overlayColor="rgba(113, 113, 113, 0.3)"
          					placement="Centered"
          					onOutsideClick={closeInstructionDeletion}
          					>
          					<InstructionDeletion onClose={closeInstructionDeletion} />
        				</PortalPopup>
      			)}
      			{isFileDeletionOpen && (
        				<PortalPopup
          					overlayColor="rgba(113, 113, 113, 0.3)"
          					placement="Centered"
          					
          					
          					
          					
          					
          					onOutsideClick={closeFileDeletion}
          					>
          					<FileDeletion onClose={closeFileDeletion} />
        				</PortalPopup>
      			)}
      			{isFileDeletion1Open && (
        				<PortalPopup
          					overlayColor="rgba(113, 113, 113, 0.3)"
          					placement="Centered"
          					
          					
          					
          					
          					
          					onOutsideClick={closeFileDeletion1}
          					>
          					<FileDeletion onClose={closeFileDeletion1} />
        				</PortalPopup>
      			)}
      			{isFileDeletion2Open && (
        				<PortalPopup
          					overlayColor="rgba(113, 113, 113, 0.3)"
          					placement="Centered"
          					
          					
          					
          					
          					
          					onOutsideClick={closeFileDeletion2}
          					>
          					<FileDeletion onClose={closeFileDeletion2} />
        				</PortalPopup>
      			)}
      			{isFileDeletion3Open && (
        				<PortalPopup
          					overlayColor="rgba(113, 113, 113, 0.3)"
          					placement="Centered"
          					
          					
          					
          					
          					
          					onOutsideClick={closeFileDeletion3}
          					>
          					<FileDeletion onClose={closeFileDeletion3} />
        				</PortalPopup>
      			)}
      			{isLogoutConfirmationOpen && (
        				<PortalPopup
          					overlayColor="rgba(113, 113, 113, 0.3)"
          					placement="Centered"
          					
          					
          					
          					
          					
          					onOutsideClick={closeLogoutConfirmation}
          					>
          					<LogoutConfirmation onClose={closeLogoutConfirmation} />
        				</PortalPopup>
      			)}</>);
      			};
      			
      			export default CDI_3;