import { useState, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import ToastMsg from "../../components/ToastMsg";
import PortalPopup from "../../components/PortalPopup";
import LogoutConfirmation from "../../components/LogoutConfirmation";
import NavigationPanel from '../../components/NavigationPanel';
import Breadcrumb from '../../components/Breadcrumb';
import CTAs from '../../components/CTAs';

const SettingsBilling2Page = () => {
    const navigate = useNavigate();
  	const [isToastMsgOpen, setToastMsgOpen] = useState(false);
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	
  	const onBillingTextClick = useCallback(() => {
        navigate('/setting-billing');
  	}, [navigate]);
  	
  	
  	const onInvoicePreviewTextClick = useCallback(() => {
    		// Add your code here
  	}, []);
  	
  	
  	const onClickGeneral = useCallback(() => {
		navigate('/setting-general');
  	}, [navigate]);
  	
  	
  	const onClickPreferences = useCallback(() => {
		navigate('/setting-preferences');
  	}, [navigate]);
  	
  	
  	const onClickLBAConfig = useCallback(() => {
		navigate('/setting-lbaconfig');
  	}, [navigate]);
  	
  	
  	const onClickLegalProceedings = useCallback(() => {
		navigate('/setting-legalproceedings');
  	}, [navigate]);
  	
  	
  	const onClickAIIntegration = useCallback(() => {
		navigate('/setting-aiintegration');
  	}, [navigate]);
  	
  	
  	const onClickSecurity = useCallback(() => {
		navigate('/setting-security');
  	}, [navigate]);
  	
  	
  	const onClickHelpSupport = useCallback(() => {
		navigate('/setting-help&support');
  	}, [navigate]);
  	
  	
  	const onClickDataManagement = useCallback(() => {
		navigate('/setting-datamanagement');
  	}, [navigate]);
  	
  	
  	const onClickAccount = useCallback(() => {
		navigate('/setting-account');
  	}, [navigate]);
  	
  	
  	const openToastMsg = useCallback(() => {
    		setToastMsgOpen(true);
  	}, []);
  	
  	const closeToastMsg = useCallback(() => {
    		setToastMsgOpen(false);
  	}, []);
  	
  	const openLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(true);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);

	const breadcrumb = [
		{ label: "", path: "/postlba2", icon: "assets/Home.svg", active: true },
		{ label: "Settings", path: "", icon: "", active: false },
		{ label: "Billing", path: "/setting-billing", icon: "", active: true },
	];
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1413px] overflow-hidden text-left text-sm text-neutrals-neutral-900 font-open-sans-ff-body-strong">
      			<div className="absolute top-[293px] left-[96px] w-[188px] flex flex-col items-start justify-start gap-[6px] text-base text-neutrals-neutral-300">
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickGeneral}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">General</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickPreferences}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Preferences</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickLBAConfig}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">LBA configuration</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickLegalProceedings}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Legal proceedings</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickAIIntegration}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">AI integration</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickSecurity}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Security</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 box-border h-[41px] flex flex-row items-center justify-center py-2 px-3 text-primary-primary-500 border-l-[2px] border-solid border-primary-primary-500">
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Billing</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickHelpSupport}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">{`Help & support`}</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickDataManagement}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Data management</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickAccount}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Account</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[1330px] right-[96px] text-neutrals-neutral-300">Copyright © Think-AI 2023</div>
      			<div className="absolute top-[179px] left-[96px] bg-neutrals-neutral-50 box-border w-[1248px] flex flex-col items-start justify-start pt-0 px-0 pb-6 gap-[6px] text-9xl text-primary-primary-900 border-b-[1px] border-solid border-neutrals-neutral-100">
        				<b className="relative">Settings</b>
        				<div className="relative text-base text-neutrals-neutral-300">Get your billing information, generate/review invoices.</div>
      			</div>
      			<div className="absolute top-[293px] left-[390px] box-border w-[954px] flex flex-row items-start justify-between pt-0 px-0 pb-6 text-lg border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Payment method</div>
          					<div className="relative text-sm text-neutrals-neutral-300">{`Update your billing details and address. `}</div>
        				</div>
        				<div className="flex flex-row items-start justify-start gap-[12px] text-sm">
							<CTAs
							type="Ghostbtn"
							text="Cancel"
							textColor="text-neutrals-neutral-900"
							bgColor="bg-neutrals-neutral-50"
							borderColor="border-neutrals-neutral-100"
							position="relative"
							/>
          					
							<CTAs
							type="Primarybtn"
							text="Save"
							textColor="text-neutrals-neutral-50"
							bgColor="bg-primary-primary-500"
							borderColor="border-primary-primary-500"
							position="relative"
							onClick={openToastMsg}
							/>
        				</div>
      			</div>

				{/* Navigation Panel */}
				<NavigationPanel/>
      			
				{/* Breadcrumb Navigation */}
				<Breadcrumb breadcrumbs={breadcrumb} position="top-[24px] left-[90px]" />

      			<div className="absolute top-[391px] left-[390px] text-base font-semibold">Card details</div>
      			<div className="absolute top-[436px] left-[390px] flex flex-col items-start justify-start gap-[10px] text-base">
        				<div className="flex flex-row items-center justify-start gap-[6px] text-sm">
          					<div className="relative font-semibold">Card details</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg" />
        				</div>
        				<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border overflow-hidden flex flex-row items-center justify-start p-3 border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="relative">1234 1234 1234 1234</div>
        				</div>
        				<div className="flex flex-row items-start justify-start gap-[24px]">
          					<div className="w-[188px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border overflow-hidden shrink-0 flex flex-row items-center justify-start p-3 border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="relative">06/24</div>
          					</div>
          					<div className="w-[188px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border overflow-hidden shrink-0 flex flex-row items-center justify-start p-3 border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="relative">123</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[592px] left-[390px] flex flex-col items-start justify-start gap-[10px]">
        				<div className="flex flex-row items-center justify-start gap-[6px]">
          					<div className="relative font-semibold">Email address</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg" />
        				</div>
        				<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border overflow-hidden flex flex-row items-center justify-start p-3 text-base border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="flex-1 relative">pm@thinkai.io</div>
        				</div>
      			</div>
      			<div className="absolute top-[678px] left-[390px] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5">
        				<div className="flex flex-row items-center justify-center gap-[6px]">
          					<img className="w-5 relative h-5" alt="" src="icons-v2.svg" />
          					<div className="relative font-semibold">Add another</div>
        				</div>
      			</div>
      			<div className="absolute top-[742px] left-[390px] flex flex-col items-start justify-start gap-[10px]">
        				<div className="flex flex-row items-center justify-start gap-[6px]">
          					<div className="relative font-semibold">Address</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg" />
        				</div>
        				<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border overflow-hidden flex flex-row items-center justify-start p-3 text-base border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="relative">21 Turning St.</div>
        				</div>
      			</div>
      			<div className="absolute top-[742px] left-[814px] flex flex-col items-start justify-start gap-[10px]">
        				<div className="flex flex-row items-center justify-start gap-[6px]">
          					<div className="relative font-semibold">City</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg" />
        				</div>
        				<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border overflow-hidden flex flex-row items-center justify-start p-3 text-base border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="flex-1 relative">Southampton</div>
        				</div>
      			</div>
      			<div className="absolute top-[942px] left-[390px] w-[400px] flex flex-col items-start justify-start gap-[10px]">
        				<div className="flex flex-row items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Country</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg" />
        				</div>
        				<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-end justify-start py-3.5 px-2.5 gap-[175px] text-base border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="w-[162px] relative font-semibold inline-block shrink-0">United kingdom</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/arrow-down.svg" />
        				</div>
      			</div>
      			<div className="absolute top-[842px] left-[390px] flex flex-col items-start justify-start gap-[10px]">
        				<div className="flex flex-row items-center justify-start gap-[6px]">
          					<div className="relative font-semibold">Postcode</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg" />
        				</div>
        				<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border overflow-hidden flex flex-row items-center justify-start p-3 text-base border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="relative">SO14 5FF</div>
        				</div>
      			</div>
    		</div>
    		{isToastMsgOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeToastMsg}
        				>
        				<ToastMsg onClose={closeToastMsg} />
      			</PortalPopup>
    		)}
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}</>);
};

export default SettingsBilling2Page;