import { useState, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import ExportModalImage from "../../components/ExportModalImage";
import PortalPopup from "../../components/PortalPopup";
import NavigationDropdown from "../../components/NavigationDropdown";
import LogoutConfirmation from "../../components/LogoutConfirmation";
import NavigationPanel from '../../components/NavigationPanel';
import DebtCollectionNav from '../../components/DebtCollectionNav';
import CTAs from '../../components/CTAs';
import BasicPricingCard from '../../components/BasicPricingCard';

const PostLBAActionsPageWithLa = () => {
	const navigate = useNavigate();
  	const [isExportModalImageOpen, setExportModalImageOpen] = useState(false);
  	const [isNavigationDropdownOpen, setNavigationDropdownOpen] = useState(false);
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	
	const onClickViewDetails = useCallback(() => {
		navigate('/lba');
  	}, [navigate]);

	const onCTAsProcessingClick = useCallback(() => {
		navigate('/documentprocessing');
  	}, [navigate]);
  	
  	const openExportModalImage = useCallback(() => {
    		setExportModalImageOpen(true);
  	}, []);
  	
  	const closeExportModalImage = useCallback(() => {
    		setExportModalImageOpen(false);
  	}, []);
  	
  	
  	const openNavigationDropdown = useCallback(() => {
    		setNavigationDropdownOpen(true);
  	}, []);
  	
  	const closeNavigationDropdown = useCallback(() => {
    		setNavigationDropdownOpen(false);
  	}, []);
  	
  	
  	const openLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(true);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);

	const navItems = [
		{
			text: 'Debt collection',
    		bgColor: 'bg-primary-primary-50',
    		textColor: 'text-neutrals-neutral-900',
    		hoverBgColor: 'bg-primary-primary-50',
    		hoverTextColor: 'text-neutrals-neutral-900',
    		path: "",
    		icon: 'assets/arrow-down.svg',
		},

		{
			text: "Outcomes & costs",
			bgColor: 'bg-neutrals-neutral-50',
    		textColor: 'text-neutrals-neutral-300',
    		hoverBgColor: "bg-neutrals-neutral-50",
    		hoverTextColor: "text-primary-primary-500",
    		path: "/outcome&cost",
    		icon: "",
		},

		{
			text: "AI indicators",
			bgColor: 'bg-neutrals-neutral-50',
    		textColor: 'text-neutrals-neutral-300',
    		hoverBgColor: 'bg-neutrals-neutral-50',
    		hoverTextColor: 'text-primary-primary-500',
    		path: "/ai1",
    		icon: "",
		},
	];

	const handleSelectPlan = () => {
		console.log("Plan selected");
	};
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1690px] overflow-hidden text-left text-base text-neutrals-neutral-300 font-open-sans-ff-heading-1">
      			<div className="absolute top-[1607px] right-[96px] text-sm">Copyright © Think-AI 2023</div>
      			
      			<div className="absolute top-[1052px] left-[96px] flex flex-col items-start justify-start gap-[24px] text-lg text-neutrals-neutral-900">
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Pricing for lawyers</div>
          					<div className="relative text-base text-neutrals-neutral-900-light">Simple, transparent pricing.</div>
        				</div>

						{/* Pricing Cards */}
        				<div className="flex flex-row items-center justify-start gap-[24px] text-base text-neutrals-neutral-300">
							{/* Basic pricing card */}
							<BasicPricingCard
							planName="Basic plan"
							price="Free "
							period="per month"
							description="Get started with basic features"
							features={["3 users", "Product support", "Plan features"]}
							buttonText="Select"
							onSelect={handleSelectPlan}
							isMostPopular={false}
							/>

							{/* Team pricing card */}
							<BasicPricingCard
							planName="Team plan"
							price="£75 "
							period="per month"
							description="Everything you need for a growing business"
							features={["5 users", "Product support", "Plan features"]}
							buttonText="Select"
							onSelect={handleSelectPlan}
							isMostPopular={true}
							/>

							{/* Enterprise pricing card */}
							<BasicPricingCard
							planName="Enterprise plan"
							price="£500 "
							period="per month"
							description="Advanced features for scaling your business"
							features={["Up to 10 users", "Product support", "Plan features"]}
							buttonText="Select"
							onSelect={handleSelectPlan}
							isMostPopular={false}
							/>
        				</div>
						
      			</div>
      			<div className="absolute top-[128px] left-[96px] bg-neutrals-neutral-50 box-border w-[1248px] flex flex-col items-start justify-start pt-0 px-0 pb-6 gap-[6px] text-9xl text-neutrals-neutral-900 border-b-[1px] border-solid border-neutrals-neutral-100">
        				<b className="relative">Post-LBA actions</b>
        				<div className="relative text-base text-neutrals-neutral-300">Get to know time remaining for your LBA</div>
      			</div>
      			<div className="absolute top-[242px] left-[96px] rounded-3xs bg-neutrals-neutral-50 box-border w-[400px] flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-start justify-between p-6">
          					<div className="flex flex-col items-start justify-start gap-[24px]">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<img className="w-5 relative h-5" alt="" src="assets/timer.svg" />
            						</div>
            						<div className="flex flex-col items-start justify-start gap-[10px]">
              							<div className="relative">Time remaining</div>
              							<b className="relative text-13xl text-neutrals-neutral-900">27d 20hr 20m</b>
            						</div>
          					</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
        				</div>
        				<div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-t-none rounded-b-3xs bg-neutrals-neutral-50 flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
							<CTAs
							type="Ghostbtn"
							text="Action initiated"
							textColor="text-neutrals-neutral-300"
							bgColor="bg-primary-primary-50"
							borderColor="border-neutrals-neutral-100"
							position="relative"
							/>
        				</div>
      			</div>
      			<div className="absolute top-[242px] left-[520px] rounded-3xs bg-neutrals-neutral-50 box-border w-[400px] flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-start justify-between p-6">
          					<div className="flex flex-col items-start justify-start gap-[24px]">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<img className="w-5 relative h-5" alt="" src="assets/lba.svg" />
            						</div>
            						<div className="flex flex-col items-start justify-start gap-[10px]">
              							<div className="relative">Total LBA’s sent</div>
              							<b className="relative text-13xl text-neutrals-neutral-900">15</b>
            						</div>
          					</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
        				</div>
        				<div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-t-none rounded-b-3xs bg-neutrals-neutral-50 flex flex-col items-end justify-start py-4 px-6 text-sm text-neutrals-neutral-900 border-t-[1px] border-solid border-neutrals-neutral-100">
							<CTAs
							type="Ghostbtn"
							text="View details"
							textColor="text-neutrals-neutral-900"
							bgColor="bg-neutrals-neutral-50"
							borderColor="border-neutrals-neutral-100"
							position="relative"
							onClick={onClickViewDetails}
							/>
        				</div>
      			</div>
      			<div className="absolute top-[525px] left-[96px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start p-6 gap-[24px] text-sm text-neutrals-neutral-900 border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-center justify-between text-lg">
          					<div className="relative font-semibold">Timeline visualisation</div>
							<CTAs
							type="Ghostbtn"
							text="Export"
							textColor="text-neutrals-neutral-900"
							bgColor="bg-neutrals-neutral-50"
							borderColor="border-neutrals-neutral-100"
							icon="assets/export.svg"
							position="relative"
							onClick={openExportModalImage}
							/>
        				</div>

						{/* Timeline Filter */}
        				<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-start ">
          					<div className="rounded-tl-3xs rounded-tr-none rounded-br-none rounded-bl-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2 px-4 border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="relative font-semibold">7 days</div>
          					</div>
          					<div className="bg-column-bg flex flex-row items-center justify-center py-2 px-4 border-t-[1px] border-solid border-neutrals-neutral-100  border-b-[1px]">
            						<div className="relative font-semibold">14 days</div>
          					</div>
          					<div className="flex flex-row items-center justify-center py-2 px-4 border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="relative font-semibold">21 days</div>
          					</div>
          					<div className="rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none flex flex-row items-center justify-center py-2 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-b-[1px]">
            						<div className="flex flex-row items-center justify-center">
              							<div className="relative font-semibold">28 days</div>
            						</div>
          					</div>
        				</div>

        				<div className="self-stretch h-[292px] flex flex-col items-end justify-center text-center">
          					<div className="flex flex-col items-start justify-start">
            						<div className="flex flex-row items-center justify-start gap-[10px]">
              							<div className="flex flex-row items-center justify-start gap-[10px]">
                								<div className="relative font-semibold [transform:_rotate(-90deg)]">Actions</div>
                								<div className="h-[201px] flex flex-col items-end justify-start gap-[11px] text-left text-neutrals-neutral-300">
                  									<div className="relative">60</div>
                  									<div className="relative">50</div>
                  									<div className="relative">40</div>
                  									<div className="relative">30</div>
                  									<div className="relative">20</div>
                  									<div className="relative">10</div>
                  									<div className="relative">0</div>
                								</div>
              							</div>
              							<div className="flex flex-col items-start justify-start relative gap-[10px]">
                								<div className="flex flex-col items-start justify-start gap-[30px] z-[0]">
                  									<div className="w-[1145px] relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="w-[1145px] relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="w-[1145px] relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="w-[1145px] relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="w-[1145px] relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="w-[1145px] relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="w-[1145px] relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                								</div>
                								<div className="!m-[0] absolute top-[25px] left-[0px] flex flex-col items-end justify-start z-[1]">
                  									<img className="w-[1144px] relative h-[153px]" alt="" src="Group 227.svg" />
                								</div>
              							</div>
            						</div>
            						<div className="w-[1200px] h-12 flex flex-col items-center justify-between text-neutrals-neutral-300">
              							<div className="w-[1036px] flex flex-row items-start justify-between">
                								<div className="relative">Mon</div>
                								<div className="relative">Tue</div>
                								<div className="relative">Wed</div>
                								<div className="relative">Thu</div>
                								<div className="relative">Fri</div>
                								<div className="relative">Sat</div>
                								<div className="relative">Sun</div>
                								<div className="relative">Mon</div>
                								<div className="relative">Tue</div>
                								<div className="relative">Wed</div>
                								<div className="relative">Thu</div>
                								<div className="relative">Fri</div>
                								<div className="relative">Sat</div>
                								<div className="relative">Sun</div>
              							</div>
              							<div className="self-stretch relative font-semibold text-neutrals-neutral-900">Days</div>
            						</div>
          					</div>
        				</div>
      			</div>

				<NavigationPanel navItems={navItems}/>
      			
      			<div className="absolute top-[242px] left-[944px] rounded-3xs bg-neutrals-neutral-50 box-border w-[400px] flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-start justify-between p-6">
          					<div className="flex flex-col items-start justify-start gap-[24px]">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<img className="w-5 relative h-5" alt="" src="assets/scan.svg" />
            						</div>
            						<div className="flex flex-col items-start justify-start gap-[10px]">
              							<div className="relative">Documents processed</div>
              							<b className="relative text-13xl text-neutrals-neutral-900">10</b>
            						</div>
          					</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
        				</div>
        				<div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-t-none rounded-b-3xs bg-neutrals-neutral-50 flex flex-col items-end justify-start py-4 px-6 text-sm text-neutrals-neutral-900 border-t-[1px] border-solid border-neutrals-neutral-100">
							<CTAs
							type="Ghostbtn"
							text="Start processing"
							textColor="text-neutrals-neutral-900"
							bgColor="bg-neutrals-neutral-50"
							borderColor="border-neutrals-neutral-100"
							position="relative"
							onClick={onCTAsProcessingClick}
							/>
        				</div>
      			</div>
    		</div>
    		{isExportModalImageOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeExportModalImage}
        				>
        				<ExportModalImage onClose={closeExportModalImage} />
      			</PortalPopup>
    		)}
    		{isNavigationDropdownOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeNavigationDropdown}
        				>
        				<NavigationDropdown onClose={closeNavigationDropdown} />
      			</PortalPopup>
    		)}
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}</>);
};

export default PostLBAActionsPageWithLa;