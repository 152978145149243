import React, { useState } from "react";

const BasicPricingCard = ({
  planName,
  price,
  period,
  description,
  features,
  buttonText,
  onSelect,
  isMostPopular = false,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div
      className={`relative rounded-3xs box-border border-solid text-left text-sm font-open-sans-ff-meta transition-all duration-300 ${
        isHovered ? "border-solid border-[2px] border-primary-primary-500 shadow-lg" : "border-neutrals-neutral-100 shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)]"
      } border-[1px]`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isMostPopular && (
        <div className="absolute top-[25px] left-[205px] bg-neutrals-neutral-50 border-solid border-[1px] text-primary-primary-500 text-xs font-semibold py-0.5 px-2 rounded-[100px]">
          Most popular
        </div>
      )}
      <div className="bg-neutrals-neutral-50 flex flex-col items-start justify-start p-6 gap-6 rounded-3xs">
        <div className="w-[294px] flex flex-col items-start justify-start gap-4">
          <div className="relative text-base font-sm text-neutrals-neutral-300">{planName}</div>
          <div className="flex flex-col items-start justify-start gap-2 text-4xl">
            <div className="relative">
              <b className="text-9xl font-extrabold text-neutrals-neutral-900">{price}</b>
              <span className="text-sm text-neutrals-neutral-300">{period}</span>
            </div>
            <div className="w-[294px] text-sm text-neutrals-neutral-300">{description}</div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-6 text-lg">
          <div className="w-[294px] relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
          <div className="flex flex-col items-start justify-start gap-6">
            <div className="flex flex-col items-start justify-start gap-2">
              <div className="relative font-semibold text-neutrals-neutral-900">FEATURES</div>
              <div className="relative text-sm text-neutrals-neutral-300">Everything in our free plan.</div>
            </div>
            <div className="flex flex-col items-start justify-start gap-2 text-sm">
              {features.map((feature, index) => (
                <div key={index} className="flex flex-row items-center justify-start gap-2">
                  <img className="w-5 h-5" alt="" src="assets/tick-circle.svg" />
                  <div className="w-full relative inline-block">{feature}</div>
                </div>
              ))}
            </div>
          </div>
          <div
            className={`self-stretch rounded-3xs flex flex-row items-center justify-center py-2.5 px-3.5 text-sm cursor-pointer transition-all duration-300 ${
              isHovered ? "bg-primary-primary-500 text-white" : "bg-neutrals-neutral-50 text-neutrals-neutral-900 border-[1px] border-solid border-neutrals-neutral-100"
            }`}
            onClick={onSelect}
          >
            <div className="relative font-semibold">{buttonText}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicPricingCard;