import { useState, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import NavigationDropdown from "../../components/NavigationDropdown";
import NavigationPanel from '../../components/NavigationPanel';
import PortalPopup from "../../components/PortalPopup";
import LogoutConfirmation from "../../components/LogoutConfirmation";
import DownloadModalTables from "../../components/DownloadModalTables";
import Breadcrumb from '../../components/Breadcrumb';
import CTAs from '../../components/CTAs';

const Defence_4 = () => {
    const navigate = useNavigate();
  	const [isNavigationDropdownOpen, setNavigationDropdownOpen] = useState(false);
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	const [isDownloadModalTablesOpen, setDownloadModalTablesOpen] = useState(false);
  	
  	const closeNavigationDropdown = useCallback(() => {
    		setNavigationDropdownOpen(false);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);
  	
  	const openDownloadModalTables = useCallback(() => {
    		setDownloadModalTablesOpen(true);
  	}, []);
  	
  	const closeDownloadModalTables = useCallback(() => {
    		setDownloadModalTablesOpen(false);
  	}, []);
  	
  	
  	const onTabStatesContainerClick = useCallback(() => {
        navigate('/defence2');
  	}, [navigate]);
  	
  	
  	const onTabStatesContainer1Click = useCallback(() => {
        navigate('/defence3');
  	}, [navigate]);

	const navItems = [
		{
			text: 'Debt collection', 
    		bgColor: 'bg-primary-primary-50', 
    		textColor: 'text-neutrals-neutral-900', 
    		hoverBgColor: 'bg-primary-primary-50', 
    		hoverTextColor: 'text-neutrals-neutral-900', 
    		path: "",
    		icon: 'assets/arrow-down.svg'
		},

		{
			text: "Outcomes & costs",
			bgColor: 'bg-neutrals-neutral-50', 
    		textColor: 'text-neutrals-neutral-300', 
    		hoverBgColor: 'bg-neutrals-neutral-50', 
    		hoverTextColor: 'text-primary-primary-500', 
    		path: "/outcome&cost",
    		icon: ''
		},

		{
			text: "AI indicators",
			bgColor: 'bg-neutrals-neutral-50', 
    		textColor: 'text-neutrals-neutral-300', 
    		hoverBgColor: 'bg-neutrals-neutral-50', 
    		hoverTextColor: 'text-primary-primary-500', 
    		path: "/ai1",
    		icon: ''
		},
	];

	const breadcrumb = [
		{ label: "", path: "/postlba2", icon: "assets/home.svg", active: true },
		{ label: "Defence and case handling", path: "/defence1", active: false},
		{ label: "Paras Mistry", path: "", active: true},
	];
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1810px] overflow-hidden text-left text-sm text-neutrals-neutral-900 font-open-sans-font-family-meta-strong">
      			<div className="absolute top-[1727px] right-[96px] text-neutrals-neutral-300">Copyright © Think-AI 2023</div>
      			<div className="absolute top-[179px] left-[96px] bg-neutrals-neutral-50 box-border w-[1248px] flex flex-col items-start justify-start pt-0 px-0 pb-6 text-9xl border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-start justify-start">
          					<div className="flex flex-col items-start justify-start gap-[6px]">
            						<b className="relative font-open-sans-ff-heading-2">Defence and case handling</b>
            						<div className="relative text-base text-neutrals-neutral-300">Acknowledge defences and revert back.</div>
          					</div>
        				</div>
      			</div>

                {/* Navigation Panel */}
				<NavigationPanel navItems={navItems}/>

				{/* Breadcrumb Navigation */}
				<Breadcrumb breadcrumbs={breadcrumb} position="top-[24px] left-[90px]"/>

      			<div className="absolute top-[1054px] left-[96px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] flex flex-col items-start justify-start">
        				<div className="w-[1248px] rounded-t-3xs rounded-b-none box-border flex flex-row items-center justify-between py-5 px-6 text-lg border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-l-[1px]">
          					<div className="flex flex-row items-center justify-start gap-[10px]">
            						<div className="relative font-semibold">Recent communication</div>
            						<div className="rounded-[5px] bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="w-1.5 relative rounded-[50%] bg-primary-primary-500 h-1.5" />
              							<div className="relative font-semibold">2 entries</div>
            						</div>
          					</div>
							<CTAs
							type="Ghostbtn"
							text="Download all"
							textColor="text-neutrals-neutral-900"
							bgColor="bg-neutrals-neutral-50"
							borderColor="border-neutrals-neutral-100"
							icon="assets/download.svg"
							position="relative"
							/>
        				</div>
        				<div className="w-[1248px] box-border flex flex-row items-center justify-between py-3 px-6 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-l-[1px]">
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-start border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="rounded-tl-3xs rounded-tr-none rounded-br-none rounded-bl-3xs bg-column-bg flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">View all</div>
            						</div>
            						<div className="flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">No response</div>
            						</div>
            						<div className="rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none flex flex-row items-center justify-center py-2 px-4">
              							<div className="relative font-semibold">Successful</div>
            						</div>
          					</div>
          					<div className="flex flex-row items-start justify-start gap-[10px] text-neutrals-neutral-300">
            						<div className="w-[294px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-start py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-start gap-[10px]">
                								<img className="w-5 relative h-5" alt="" src="assets/search.svg"/>
                								<div className="relative">Search</div>
              							</div>
            						</div>
									<CTAs
									type="Ghostbtn"
									text="Filter"
									textColor="text-neutrals-neutral-900"
									bgColor="bg-neutrals-neutral-50"
									borderColor="border-neutrals-neutral-100"
									icon="assets/filter.svg"
									position="relative"
									/>
          					</div>
        				</div>
        				<div className="flex flex-col items-start justify-start">
          					<div className="w-[1248px] flex flex-col items-start justify-start">
            						<div className="self-stretch flex flex-row items-start justify-start">
              							<div className="flex-1 flex flex-col items-start justify-start">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
                  									<div className="relative font-semibold">Communication</div>
                								</div>
                								<div className="self-stretch flex flex-col items-start justify-start">
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">Email sent</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">Attempted call</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[260px] flex flex-col items-center justify-start">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-center py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">Status</div>
                								</div>
                								<div className="self-stretch flex flex-col items-center justify-start text-semantics-high-high-500">
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-high-high-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-high-high-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-high-high-500 h-1.5" />
                      											<div className="relative font-semibold">No response</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-high-high-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-high-high-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-high-high-500 h-1.5" />
                      											<div className="relative font-semibold">No response</div>
                    										</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[448px] flex flex-col items-start justify-start">
                								<div className="w-[448px] bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-b-[1px]">
                  									<div className="relative font-semibold">Date</div>
                								</div>
                								<div className="w-[448px] flex flex-col items-start justify-start">
                  									<div className="w-[448px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">08 Feb 2024 at 10:00 am</div>
                  									</div>
                  									<div className="w-[448px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">09 Feb 2024 at 10:00 am</div>
                  									</div>
                								</div>
              							</div>
            						</div>
          					</div>
          					<div className="w-[1248px] rounded-t-none rounded-b-3xs box-border flex flex-row items-center justify-between pt-3 px-6 pb-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">Previous</div>
              							</div>
            						</div>
            						<div className="relative">Page 1 of 10</div>
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">Next</div>
              							</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[358px] left-[96px] box-border w-[1248px] flex flex-col items-start justify-start pt-0 px-0 pb-6 gap-[10px] text-lg border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-col items-start justify-start gap-[6px] z-[0]">
          					<div className="relative font-semibold">Communication panel</div>
          					<div className="relative text-base text-neutrals-neutral-300">Acknowledges any formal defence raised by the debtor.</div>
        				</div>
        				<div className="!m-[0] absolute top-[0px] left-[1094px] rounded-3xs bg-primary-primary-50 flex flex-row items-center justify-start py-2.5 px-3.5 gap-[6px] z-[1] text-sm text-primary-primary-500">
          					<img className="w-[23px] relative h-[22.6px]" alt="" src="assets/AI.svg"/>
          					<div className="relative font-semibold">Powered by AI</div>
        				</div>
      			</div>
      			<div className="absolute top-[459px] left-[96px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border w-[1248px] h-[555px] flex flex-col items-start justify-between p-6 border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-center justify-between pt-0 px-0 pb-6 border-b-[1px] border-solid border-neutrals-neutral-100">
          					<div className="flex flex-col items-start justify-start gap-[75px]">
            						<div className="flex flex-row items-start justify-start gap-[10px]">
              							<div className="flex flex-row items-start justify-start relative gap-[10px]">
                								<div className="flex flex-row items-start justify-start relative gap-[10px] z-[0]">
                  									<div className="rounded-81xl bg-primary-primary-50 flex flex-row items-start justify-start p-2.5 z-[0]">
                    										<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/AI.svg" />
                  									</div>
                  									<div className="w-2.5 absolute !m-[0] top-[30px] left-[30px] rounded-[50%] bg-primary-primary-500 box-border h-2.5 z-[1] border-[1px] border-solid border-neutrals-neutral-50" />
                								</div>
                								<div className="w-2.5 absolute !m-[0] top-[30px] left-[30px] rounded-[50%] bg-primary-primary-500 box-border h-2.5 z-[1] border-[1px] border-solid border-neutrals-neutral-50" />
              							</div>
              							<div className="flex flex-col items-start justify-start gap-[6px]">
                								<div className="w-[308px] flex flex-row items-start justify-between">
                  									<div className="relative font-semibold">AI bot</div>
                  									<div className="relative text-neutrals-neutral-300 text-right">Monday 2:30 pm</div>
                								</div>
                								<div className="w-[308px] rounded-tl-none rounded-tr-3xs rounded-b-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-center py-2.5 px-3.5 text-base border-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="flex-1 relative">Hey Nathan, can you please review the documents I uploaded.</div>
                								</div>
              							</div>
            						</div>
            						<div className="flex flex-col items-end justify-start gap-[6px]">
              							<div className="flex flex-row items-start justify-start gap-[10px]">
                								<div className="flex flex-row items-start justify-start relative gap-[10px]">
                  									<div className="flex flex-row items-start justify-start relative gap-[10px] z-[0]">
                    										<div className="rounded-81xl bg-primary-primary-50 flex flex-row items-start justify-start p-2.5 z-[0]">
                      											<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/AI.svg"/>
                    										</div>
                    										<div className="w-2.5 absolute !m-[0] top-[30px] left-[30px] rounded-[50%] bg-primary-primary-500 box-border h-2.5 z-[1] border-[1px] border-solid border-neutrals-neutral-50" />
                  									</div>
                  									<div className="w-2.5 absolute !m-[0] top-[30px] left-[30px] rounded-[50%] bg-primary-primary-500 box-border h-2.5 z-[1] border-[1px] border-solid border-neutrals-neutral-50" />
                								</div>
                								<div className="flex flex-col items-start justify-start gap-[6px]">
                  									<div className="w-[308px] flex flex-row items-start justify-between">
                    										<div className="relative font-semibold">AI bot</div>
                    										<div className="relative text-neutrals-neutral-300 text-right">Monday 2:36 pm</div>
                  									</div>
                  									<div className="w-[308px] rounded-tl-none rounded-tr-3xs rounded-b-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="self-stretch flex flex-row items-start justify-start gap-[10px]">
                      											<div className="rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
                        												<img className="w-5 relative h-5" alt="" src="assets/document.svg" />
                      											</div>
                      											<div className="flex-1 flex flex-col items-start justify-start gap-[6px]">
                        												<div className="self-stretch relative font-semibold">File_name.pdf</div>
                        												<div className="self-stretch relative text-neutrals-neutral-300">1.2MB</div>
                      											</div>
                    										</div>
                  									</div>
                								</div>
              							</div>
              							<div className="flex flex-row items-start justify-end gap-[4px] text-center">
                								<div className="rounded-81xl bg-primary-primary-50 flex flex-col items-center justify-center py-1 px-2">
                  									<div className="relative">❤️</div>
                								</div>
                								<div className="rounded-81xl bg-primary-primary-50 flex flex-col items-center justify-center py-1 px-2">
                  									<div className="relative">👍🏻</div>
                								</div>
              							</div>
            						</div>
            						<div className="flex flex-row items-start justify-start gap-[10px]">
              							<div className="flex flex-row items-start justify-start relative gap-[10px]">
                								<div className="flex flex-row items-start justify-start relative gap-[10px] z-[0]">
                  									<div className="rounded-81xl bg-primary-primary-50 flex flex-row items-start justify-start p-2.5 z-[0]">
                    										<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/AI.svg"/>
                  									</div>
                  									<div className="w-2.5 absolute !m-[0] top-[30px] left-[30px] rounded-[50%] bg-primary-primary-500 box-border h-2.5 z-[1] border-[1px] border-solid border-neutrals-neutral-50" />
                								</div>
                								<div className="w-2.5 absolute !m-[0] top-[30px] left-[30px] rounded-[50%] bg-primary-primary-500 box-border h-2.5 z-[1] border-[1px] border-solid border-neutrals-neutral-50" />
              							</div>
              							<div className="flex flex-col items-start justify-start gap-[6px]">
                								<div className="relative font-semibold">AI bot</div>
                								<div className="rounded-tl-none rounded-tr-3xs rounded-b-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="flex flex-row items-start justify-start gap-[4px]">
                    										<div className="flex flex-row items-start justify-start pt-1 px-0 pb-0">
                      											<div className="w-1 relative rounded-[50%] bg-neutrals-neutral-200 h-1" />
                    										</div>
                    										<div className="flex flex-row items-end justify-start pt-1 px-0 pb-0">
                      											<div className="w-1 relative rounded-[50%] bg-neutrals-neutral-900 h-1" />
                    										</div>
                    										<div className="flex flex-row items-end justify-start pt-1 px-0 pb-0">
                      											<div className="w-1 relative rounded-[50%] bg-neutrals-neutral-300 h-1" />
                    										</div>
                  									</div>
                								</div>
              							</div>
            						</div>
          					</div>
          					<div className="h-[267px] flex flex-col items-end justify-between">
            						<div className="flex flex-row items-start justify-start">
              							<div className="flex flex-col items-start justify-start gap-[6px]">
                								<div className="w-[308px] flex flex-row items-start justify-between">
                  									<div className="relative font-semibold">Nathan James</div>
                  									<div className="relative text-neutrals-neutral-300 text-right">Monday 2:35 pm</div>
                								</div>
                								<div className="w-[308px] rounded-tl-3xs rounded-tr-none rounded-b-3xs bg-primary-primary-500 flex flex-row items-center justify-center py-2.5 px-3.5 box-border text-base text-neutrals-neutral-50">
                  									<div className="flex-1 relative">Hey [Client_name], sure will have a look at the file once.</div>
                								</div>
              							</div>
            						</div>
            						<div className="flex flex-row items-start justify-start">
              							<div className="flex flex-col items-start justify-start gap-[6px]">
                								<div className="w-[308px] flex flex-row items-start justify-between">
                  									<div className="relative font-semibold">Nathan James</div>
                  									<div className="relative text-neutrals-neutral-300 text-right">Monday 2:45 pm</div>
                								</div>
                								<div className="w-[308px] rounded-tl-3xs rounded-tr-none rounded-b-3xs bg-primary-primary-500 flex flex-row items-center justify-center py-2.5 px-3.5 box-border text-base text-neutrals-neutral-50">
                  									<div className="flex-1 relative">Hi [Client_name], I have seen the file and it seems good to me.</div>
                								</div>
              							</div>
            						</div>
          					</div>
        				</div>
        				<div className="self-stretch flex flex-row items-center justify-between py-4 px-6 text-base text-neutrals-neutral-300">
          					<div className="w-[988px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-start justify-start py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="relative whitespace-pre-wrap">Write  your message here...</div>
          					</div>
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
            						<img className="w-5 relative h-5" alt="" src="assets/document-upload.svg" />
          					</div>
							<CTAs
							type="Primarybtn"
							text="Send"
							textColor="text-neutrals-neutral-50"
							bgColor="bg-primary-primary-500"
							borderColor="border-primary-primary-500"
							icon="assets/send.svg"
							/>
        				</div>
      			</div>
      			<div className="absolute top-[293px] left-[96px] flex flex-row items-start justify-start gap-[24px] text-base text-neutrals-neutral-300">
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 box-border cursor-pointer" onClick={onTabStatesContainerClick}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Case details</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 box-border cursor-pointer" onClick={onTabStatesContainer1Click}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">AI utilisation</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 box-border h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 text-primary-primary-500 border-b-[2px] border-solid border-primary-primary-500">
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Negotiation</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[1483px] left-[96px] text-lg font-semibold">Proposal</div>
      			<div className="absolute top-[1532px] left-[96px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start text-base border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="w-[400px] flex flex-col items-start justify-start p-6 box-border gap-[10px]">
          					<div className="relative font-semibold">Proposed terms</div>
          					<div className="w-[294px] relative inline-block">Installment plan of £1,000 per month for 2 months.</div>
        				</div>
        				<div className="w-[400px] box-border flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
          					<div className="flex flex-row items-start justify-end gap-[10px]">
							  <CTAs
							  type="Ghostbtn"
							  text="Reject"
							  textColor="text-neutrals-neutral-900"
							  bgColor="bg-neutrals-neutral-50"
							  borderColor="border-neutrals-neutral-100"
							  position="relative"
							  />

							  <CTAs
							  type="Ghostbtn"
							  text="Accept"
							  textColor="text-neutrals-neutral-900"
							  bgColor="bg-neutrals-neutral-50"
							  borderColor="border-neutrals-neutral-100"
							  position="relative"
							  />
          					</div>
        				</div>
      			</div>
    		</div>
    		{isNavigationDropdownOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeNavigationDropdown}
        				>
        				<NavigationDropdown onClose={closeNavigationDropdown} />
      			</PortalPopup>
    		)}
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}
    		{isDownloadModalTablesOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeDownloadModalTables}
        				>
        				<DownloadModalTables onClose={closeDownloadModalTables} />
      			</PortalPopup>
    		)}</>);
};

export default Defence_4;