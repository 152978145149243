import { useCallback, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import CTAs from  '../../components/CTAs';
import EmailInputField from '../../components/EmailInputField';

const ForgotPasswordPage = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState('');
  	
  	const onCTAsContainerClick = useCallback(() => {
		navigate('/resendmail');
  	}, [navigate]);
  	
  	const onClickToResendClick = useCallback(() => {
		navigate('/resendmail');
  	}, [navigate]);
  	
  	const onCTABack2LoginClick = useCallback(() => {
		navigate('/login');
  	}, [navigate]);

	const handleLogin = () => {
		console.log('Email:', email);
	}
  	
  	return (
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1024px] overflow-hidden text-left text-sm text-neutrals-neutral-900 font-open-sans-ff-meta">
      			<div className="absolute top-[288px] left-[539px] flex flex-col items-center justify-start gap-[24px] text-center text-9xl">
        				<div className="w-12 shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-12 flex flex-row items-center justify-center p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
          					<img className="w-5 relative h-5" alt="" src="assets/password-check.svg" />
        				</div>
        				<div className="flex flex-col items-center justify-start gap-[10px]">
          					<b className="relative">Forgot password?</b>
            						<div className="relative text-base text-neutrals-neutral-300">No worries, we’ll send you the reset instructions.</div>
            						</div>
            						</div>

									
            						<div className="absolute top-[494px] left-[520px] flex flex-col items-start justify-start gap-[10px]">
									<EmailInputField/>
            						</div>

									<div className='absolute top-[610px] left-[520px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs flex flex-row items-center justify-center'>
										<CTAs
										type="Primarybtn"
										text="Reset password"
										textColor="text-neutrals-neutral-50"
										bgColor="bg-primary-primary-500"
										borderColor="border-primary-primary-500"
										width="400px"
										position="relative"
										onClick={onCTAsContainerClick}
										/>
									</div>
									
            						<div className="absolute top-[673px] left-[588px] flex flex-row items-start justify-center gap-[6px] text-neutrals-neutral-300">
              							<div className="relative">Didn’t receive the email?</div>
                								<div className="relative font-semibold text-primary-primary-500 cursor-pointer" onClick={onClickToResendClick}>Click to resend</div>
                								</div>
                								<div className="absolute top-[716px] left-[664px] flex flex-row items-start justify-center gap-[6px] cursor-pointer text-center" onClick={onCTABack2LoginClick}>
                  									<img className="w-5 relative h-5" alt="" src="assets/arrow-left.svg" />
                  									<div className="flex flex-row items-start justify-center">
                    										<div className="relative font-semibold">Back to login</div>
                  									</div>
                								</div>
                								<div className="absolute top-[941px] right-[96px] text-neutrals-neutral-300">Copyright © Think-AI 2023</div>
                								</div>);
              							};
              							
              							export default ForgotPasswordPage;