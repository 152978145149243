import './App.css';
import { Route, Routes as BaseRoutes, useRoutes } from 'react-router-dom';
import LoginPage from './pages/Authentication/LoginPage';
import RegisterPage1 from './pages/Authentication/RegisterPage-1';
import ForgetPasswordPage from './pages/Authentication/ForgetPasswordPage';
import ResendMailPage from './pages/Authentication/ResendMailPage';
import RegisterPage2 from './pages/Authentication/RegisterPage-2';
import DocumentUploadPage from './pages/Authentication/DocumentUploadPage';
import PricingPageMonthly from './pages/Authentication/PricingPageMonthly';
import RegistrationCompletedPage from './pages/Authentication/RegistrationCompletedPage';
import DebtorDetailsPage from './pages/LBA form/DebtorDetailsPage';
import LogoutConfirmation from './components/LogoutConfirmation';
import PostLBAActionsPage from './pages/Post LBA Actions/PostLBAActionsPage';
import PostLBAActionsPageWithLa from './pages/Post LBA Actions/PostLBAActionsPageWithLa';
import SettingsGeneralPage from './pages/Settings/SettingsGeneralPage';
import SettingsPreferencesPage from './pages/Settings/SettingsPreferencesPage';
import SettingsLBAConfigPage from './pages/Settings/SettingsLBAConfigPage';
import SettingsLegalProceedingsPage from './pages/Settings/SettingsLegalProceedingsPage';
import SettingsAIIntegrationPage from './pages/Settings/SettingsAIIntegrationPage';
import SettingsSecurityPage from './pages/Settings/SettingsSecurityPage';
import SettingsBillingPage from './pages/Settings/SettingsBillingPage';
import SettingsBilling2Page from './pages/Settings/SettingsBilling2Page';
import SettingsHelpSupportPage from './pages/Settings/SettingsHelpSupportPage';
import SettingsDataManagementPage from './pages/Settings/SettingsDataManagementPage';
import SettingsAccountPage from './pages/Settings/SettingsAccountPage';
import SettingsAccount2Page from './pages/Settings/SettingsAccount2Page';
import DocumentProcessing from './pages/Post LBA Actions/DocumentProcessing';
import SelectAccountPage from './pages/Authentication/SelectAccountPage';
import ParticularsOfClaim from './pages/Claim form/ParticularsOfClaim';
import LBA from './pages/LBA/LBA';
import LBA_2 from './pages/LBA/LBA_2';
import OutcomeAndCostPage_1 from './pages/Outcomes and Cost/OutcomeAndCostPage_1';
import OutcomeAndCostPage_2 from './pages/Outcomes and Cost/OutcomeAndCostPage_2';
import OutcomeAndCostPage_3 from './pages/Outcomes and Cost/OutcomeAndCostPage_3';
import OutcomesAndCostsPage_4 from './pages/Outcomes and Cost/OutcomeAndCostPage_4';
import OutcomesAndCostsPage_5 from './pages/Outcomes and Cost/OutcomeAndCostPage_5';
import LPDPage from './pages/LPD/LPDPage';
import LPDPage_2 from './pages/LPD/LPDPage_2';
import DefenceAndCaseHandling_1 from './pages/Defence and Case Handling/main';
import DefenceAndCaseHandling_2 from './pages/Defence and Case Handling/Defence_2';
import DefenceAndCaseHandling_3 from './pages/Defence and Case Handling/Defence_3';
import DefenceAndCaseHandling_4 from './pages/Defence and Case Handling/Defence_4';
import Legal_proceedings_1 from './pages/Legal proceedings and billing/Legal_proceedings_1';
import Legal_proceedings_2 from './pages/Legal proceedings and billing/Legal_proceedings_2';
import Legal_proceedings_3 from './pages/Legal proceedings and billing/Legal_proceedings_3';
import AI_Indicators_1 from './pages/AI Indicators/AI_Indicators_1';
import AI_Indicators_2 from './pages/AI Indicators/AI_Indicators_2';
import PreLBAChecks from './pages/Pre LBA Check/PreLBAChecks';
import InvoiceGeneratorPage from './pages/Invoice Generator/InvoiceGeneratorPage';
import InvoicePreviewPage from './pages/Invoice Generator/InvoicePreviewPage';
import CDI_1 from './pages/Client debtor interaction/CDI_1';
import CDI_2 from './pages/Client debtor interaction/CDI_2';
import CDI_3 from './pages/Client debtor interaction/CDI_3';
import CDI_4 from './pages/Client debtor interaction/CDI_4';
import NewPayment2 from './components/NewPayment2';
import LandingPage from './pages/Landing Page/LandingPage';

function App() {
  const routelist = useRoutes ([
    {
      path: '/login', element: <LoginPage />,
    }
  ]) 
  return (
    <>
    <BaseRoutes>
        <Route path='/login' element={<LoginPage/>}/>
        <Route path='/register1' element={<RegisterPage1/>}/>
        <Route path='/forgetpassword' element={<ForgetPasswordPage/>}/>
        <Route path='/resendmail' element={<ResendMailPage/>}/>
        <Route path='/register2' element={<RegisterPage2/>}/>
        <Route path='/documentupload' element={<DocumentUploadPage/>}/>
        <Route path='/pricingpagemonthly' element={<PricingPageMonthly/>}/>
        <Route path='/registrationcomplete' element={<RegistrationCompletedPage/>}/>
        <Route path='/lbaform' element={<DebtorDetailsPage/>}/>
        <Route path='/logoutpopup' element={<LogoutConfirmation/>}/>
        <Route path='/postlba' element={<PostLBAActionsPage/>}/>
        <Route path='/postlba2' element={<PostLBAActionsPageWithLa/>}/>
        <Route path='/setting-general' element={<SettingsGeneralPage/>}/>
        <Route path='/setting-preferences' element={<SettingsPreferencesPage/>}/>
        <Route path='/setting-lbaconfig' element={<SettingsLBAConfigPage/>}/>
        <Route path='/setting-legalproceedings' element={<SettingsLegalProceedingsPage/>}/>
        <Route path='/setting-aiintegration' element={<SettingsAIIntegrationPage/>}/>
        <Route path='/setting-security' element={<SettingsSecurityPage/>}/>
        <Route path='/setting-billing' element={<SettingsBillingPage/>}/>
        <Route path='/setting-billing2' element={<SettingsBilling2Page/>}/>
        <Route path='/setting-help&support' element={<SettingsHelpSupportPage/>}/>
        <Route path='/setting-datamanagement' element={<SettingsDataManagementPage/>}/>
        <Route path='/setting-account' element={<SettingsAccountPage/>}/>
        <Route path='/setting-account2' element={<SettingsAccount2Page/>}/>
        <Route path='/documentprocessing' element={<DocumentProcessing/>}/>
        <Route path='/selectaccount' element={<SelectAccountPage/>}/>
        <Route path='/claimform' element={<ParticularsOfClaim/>}/>
        <Route path='/lba' element={<LBA/>}/>
        <Route path='/lba2' element={<LBA_2/>}/>
        <Route path='/outcome&cost' element={<OutcomeAndCostPage_1/>}/>
        <Route path='/outcome&cost2' element={<OutcomeAndCostPage_2/>}/>
        <Route path='/outcome&cost3' element={<OutcomeAndCostPage_3/>}/>
        <Route path='/outcome&cost4' element={<OutcomesAndCostsPage_4/>}/>
        <Route path='/outcome&cost5' element={<OutcomesAndCostsPage_5/>}/>
        <Route path='/lpd' element={<LPDPage/>}/>
        <Route path='/lpd2' element={<LPDPage_2/>}/>
        <Route path='/defence1' element={<DefenceAndCaseHandling_1/>}/>
        <Route path='/defence2' element={<DefenceAndCaseHandling_2/>}/>
        <Route path='/defence3' element={<DefenceAndCaseHandling_3/>}/>
        <Route path='/defence4' element={<DefenceAndCaseHandling_4/>}/>
        <Route path='/legal_1' element={<Legal_proceedings_1/>}/>
        <Route path='/legal_2' element={<Legal_proceedings_2/>}/>
        <Route path='/legal_3' element={<Legal_proceedings_3/>}/>
        <Route path='/ai1' element={<AI_Indicators_1/>}/>
        <Route path='/ai2' element={<AI_Indicators_2/>}/>
        <Route path='/prelbacheck' element={<PreLBAChecks/>}/>
        <Route path='/invoice' element={<InvoiceGeneratorPage/>}/>
        <Route path='/invoicepreview' element={<InvoicePreviewPage/>}/>
        <Route path='/cdi1' element={<CDI_1/>}/>
        <Route path='/cdi2' element={<CDI_2/>}/>
        <Route path='/cdi3' element={<CDI_3/>}/>
        <Route path='/cdi4' element={<CDI_4/>}/>
        <Route path='/newpayment2' element={<NewPayment2/>}/>
        <Route path='/landingpage' element={<LandingPage/>}/>
    </BaseRoutes>
    </>
  );
}

export default App;
