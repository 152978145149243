// import { useState, useCallback } from "react";
// import PropTypes from 'prop-types';

// const Upload = ({ width }) => {
//     return (
//         <div className="relative w-full flex flex-col items-start justify-start gap-[10px] text-left text-sm text-neutrals-neutral-900 font-open-sans-ff-meta">
//             <div className="relative font-semibold">Upload</div>
//             <div className="flex flex-col items-start justify-start gap-[24px] text-primary-primary-500">
//                 <div className="flex flex-col items-center justify-start" style={{ width }}>
//                     <div className="w-full relative rounded-3xs bg-neutrals-neutral-50 box-border h-[231px] flex flex-col items-center justify-center p-4 gap-[10px] border-[2px] border-solid border-primary-primary-500">
//                         <div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
//                             <img className="w-5 relative h-5" alt="" src="assets/document-upload.svg" />
//                         </div>
//                         <div className="flex flex-col items-center justify-center gap-[6px]">
//                             <div className="relative">
//                                 <span className="font-semibold">Click to upload</span>
//                                 <span className="text-neutrals-neutral-300"> or drag and drop</span>
//                             </div>
//                             <div className="relative text-neutrals-neutral-300">(Max. File Size: 20 MB)</div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-center justify-center p-4 text-neutrals-neutral-900 border-[1px] border-solid border-neutrals-neutral-100" style={{ width }}>
//                     <div className="self-stretch flex flex-row items-start justify-start gap-[10px]">
//                         <img className="w-10 relative h-10" alt="" src="assets/pdf.svg" />
//                         <div className="flex-1 flex flex-col items-center justify-center gap-[6px]">
//                             <div className="self-stretch flex flex-row items-start justify-between">
//                                 <div className="flex-1 flex flex-col items-start justify-start">
//                                     <div className="relative font-semibold">Contract.pdf</div>
//                                     <div className="relative text-neutrals-neutral-300">220 KB</div>
//                                 </div>
//                                 <img className="w-5 relative h-5" alt="" src="assets/delete.svg" />
//                             </div>
//                             <div className="self-stretch flex flex-row items-center justify-center gap-[10px]">
//                                 <div className="flex-1 flex flex-col items-start justify-start relative gap-[10px]">
//                                     <div className="self-stretch relative rounded-81xl bg-primary-primary-50 h-2 z-[0]" />
//                                     <div className="absolute w-full !m-[0] top-[0px] left-[0px] rounded-81xl bg-primary-primary-500 h-2 z-[1]" style={{ width: '60%' }} />
//                                 </div>
//                                 <div className="relative font-semibold">60%</div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// Upload.propTypes = {
//     width: PropTypes.string
// };

// Upload.defaultProps = {
//     width: '100%'
// };

// export default Upload;

//Code 2
import { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";

const Upload = ({ width }) => {
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState({});

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    // Initialize progress for each file
    newFiles.forEach((file) => setProgress((prev) => ({ ...prev, [file.name]: 0 })));
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const newFiles = Array.from(event.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    // Initialize progress for each file
    newFiles.forEach((file) => setProgress((prev) => ({ ...prev, [file.name]: 0 })));
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDelete = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
    setProgress((prevProgress) => {
      const newProgress = { ...prevProgress };
      delete newProgress[fileName];
      return newProgress;
    });
  };

  const simulateUpload = useCallback(() => {
    files.forEach((file) => {
      const interval = setInterval(() => {
        setProgress((prev) => {
          const currentProgress = prev[file.name];
          if (currentProgress >= 100) {
            clearInterval(interval);
            return prev;
          }
          return { ...prev, [file.name]: currentProgress + 1 };
        });
      }, 100); // Adjust the speed of the upload simulation
    });
  }, [files]);

  useEffect(() => {
    if (files.length > 0) {
      simulateUpload();
    }
  }, [files, simulateUpload]);

  return (
    <div className="relative w-full flex flex-col items-start justify-start gap-[10px] text-left text-sm text-neutrals-neutral-900 font-open-sans-ff-meta">
      <div className="relative font-semibold">Upload</div>
      <div className="flex flex-col items-start justify-start gap-[24px] text-primary-primary-500">
        <div className="flex flex-col items-center justify-start" style={{ width }}>
          <div
            className="w-full relative rounded-3xs bg-neutrals-neutral-50 box-border h-[231px] flex flex-col items-center justify-center p-4 gap-[10px] border-[2px] border-solid border-primary-primary-500"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <input
              type="file"
              className="absolute inset-0 opacity-0 cursor-pointer"
              multiple
              onChange={handleFileChange}
            />
            <div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
              <img className="w-5 relative h-5" alt="" src="assets/document-upload.svg" />
            </div>
            <div className="flex flex-col items-center justify-center gap-[6px]">
              <div className="relative">
                <span className="font-semibold">Click to upload</span>
                <span className="text-neutrals-neutral-300"> or drag and drop</span>
              </div>
              <div className="relative text-neutrals-neutral-300">(Max. File Size: 20 MB)</div>
            </div>
          </div>
        </div>
        {files.map((file) => (
          <div
            key={file.name}
            className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-center justify-center p-4 text-neutrals-neutral-900 border-[1px] border-solid border-neutrals-neutral-100"
            style={{ width }}
          >
            <div className="self-stretch flex flex-row items-start justify-start gap-[10px]">
              <img className="w-10 relative h-10" alt="" src="assets/pdf.svg" />
              <div className="flex-1 flex flex-col items-center justify-center gap-[6px]">
                <div className="self-stretch flex flex-row items-start justify-between">
                  <div className="flex-1 flex flex-col items-start justify-start">
                    <div className="relative font-semibold">{file.name}</div>
                    <div className="relative text-neutrals-neutral-300">{(file.size / 1024).toFixed(2)} KB</div>
                  </div>
                  <img
                    className="w-5 relative h-5 cursor-pointer"
                    alt="Delete"
                    src="assets/delete.svg"
                    onClick={() => handleDelete(file.name)}
                  />
                </div>
                <div className="self-stretch flex flex-row items-center justify-center gap-[10px]">
                  <div className="flex-1 flex flex-col items-start justify-start relative gap-[10px]">
                    <div className="self-stretch relative rounded-81xl bg-primary-primary-50 h-2 z-[0]" />
                    <div
                      className="absolute w-full !m-[0] top-[0px] left-[0px] rounded-81xl bg-primary-primary-500 h-2 z-[1]"
                      style={{ width: `${progress[file.name]}%` }}
                    />
                  </div>
                  <div className="relative font-semibold">{progress[file.name]}%</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

Upload.propTypes = {
  width: PropTypes.string,
};

Upload.defaultProps = {
  width: "100%",
};

export default Upload;