import { useState, useCallback } from 'react';
import NameInput from '../components/NameInput';

const LbaForm = () => {

const [name, setName] = useState('');

    return (
          <div className="relative w-full flex flex-row items-start justify-start gap-[130px] text-left text-sm text-neutrals-neutral-900 font-open-sans-ff-body-strong">
                <div className="flex flex-col items-start justify-start gap-[10px]">
                      <div className="flex flex-col items-start justify-start gap-[24px]">
                            <div className="flex flex-col items-start justify-start gap-[10px]">
                              <NameInput
                              title='Full name'
                              placeholder='John Clark'
                              width="400px"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              />
                                  {/* <div className="flex flex-row items-center justify-start gap-[6px]">
                                        <div className="relative font-semibold">Full name</div>
                                        <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg"/>
                                  </div>
                                  <div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border overflow-hidden flex flex-row items-center justify-start p-3 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
                                        <div className="flex-1 relative">John Doe</div>
                                  </div> */}
                            </div>
                            <div className="flex flex-col items-start justify-start gap-[10px]">
                                  <div className="flex flex-row items-center justify-start gap-[6px]">
                                        <div className="relative font-semibold">Account number</div>
                                        <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg"/>
                                  </div>
                                  <div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border overflow-hidden flex flex-row items-center justify-start p-3 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
                                        <div className="flex-1 relative">Enter account number</div>
                                  </div>
                            </div>
                            <div className="w-[400px] flex flex-row items-start justify-start">
                                  <div className="w-[400px] flex flex-col items-start justify-start gap-[10px]">
                                        <div className="flex flex-row items-center justify-start gap-[6px]">
                                              <div className="relative font-semibold">Select due date</div>
                                              <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg"/>
                                        </div>
                                        <div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 overflow-hidden flex flex-row items-center justify-start p-3 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
                                              <div className="relative">01/01/2024</div>
                                        </div>
                                  </div>
                            </div>
                            <div className="flex flex-col items-start justify-start gap-[10px]">
                                  <div className="relative font-semibold">Amount</div>
                                  <div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-between p-3 text-base border-[1px] border-solid border-neutrals-neutral-100">
                                        <div className="flex-1 flex flex-row items-center justify-between">
                                              <div className="relative">
                                                    <span className="font-semibold">
                                                          <span>£</span>
                                                    </span>
                                                    <span className="text-neutrals-neutral-300">
                                                          <span className="font-semibold">{` `}</span>
                                                          <span>1,000.00</span>
                                                    </span>
                                              </div>
                                              <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg"/>
                                        </div>
                                        <div className="flex flex-row items-center justify-start py-0 px-2.5 gap-[6px] text-right">
                                              <div className="relative">GBP</div>
                                              <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/arrow-down.svg"/>
                                        </div>
                                  </div>
                            </div>
                            <div className="flex flex-col items-start justify-start gap-[10px]">
                                  <div className="flex flex-row items-center justify-start gap-[6px]">
                                        <div className="relative font-semibold">Address</div>
                                        <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg"/>
                                  </div>
                                  <div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border overflow-hidden flex flex-row items-center justify-start p-3 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
                                        <div className="flex-1 relative">Enter your address</div>
                                  </div>
                            </div>
                            <div className="flex flex-col items-start justify-start gap-[10px]">
                                  <div className="flex flex-row items-center justify-start gap-[6px]">
                                        <div className="relative font-semibold">Postcode</div>
                                        <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg"/>
                                  </div>
                                  <div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border overflow-hidden flex flex-row items-center justify-start p-3 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
                                        <div className="flex-1 relative">Enter your postcode</div>
                                  </div>
                            </div>
                      </div>
                      <div className="flex flex-row items-center justify-start gap-[6px] text-primary-primary-500">
                            <div className="relative [text-decoration:underline]">Advance options</div>
                            <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/arrow-down.svg"/>
                      </div>
                </div>
                <div className="w-[718px] shadow-[0px_4px_10px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border overflow-hidden shrink-0 flex flex-col items-start justify-start p-6 gap-[40px] text-base border-[1px] border-solid border-neutrals-neutral-100">
                      <div className="self-stretch flex flex-row items-center justify-between text-center text-sm text-neutrals-neutral-50">
                            <img className="w-[143px] relative h-[49px] object-cover" alt="" src="assets/logo-horizontal.svg"/>
                            <div className="bg-primary-primary-500 flex flex-col items-start justify-start p-2.5">
                                  <div className="flex flex-col items-center justify-start gap-[6px]">
                                        <div className="relative">Debt Co-Pilot Account Number:</div>
                                        <b className="relative text-13xl">D9001</b>
                                  </div>
                            </div>
                      </div>
                      <div className="self-stretch relative">
                            <p className="m-0">Date: 25 April 2024</p>
                            <p className="m-0">Recipient Name: Dominic Reynolds</p>
                            <p className="m-0">Business Name: Llanelli Performance Centre Ltd.</p>
                            <p className="m-0">Address: Fairways, Pendline, Carmarthen, Carmarthenshire, SA33 4PA</p>
                      </div>
                      <div className="self-stretch relative font-semibold">
                            <span>{`Subject: `}</span>
                            <span className="text-semantics-high-high-500">Urgent:</span>
                            <span> Outstanding Account Balance – Invoice no. 2517, 2290, 2397</span>
                      </div>
                      <div className="self-stretch flex flex-col items-start justify-start gap-[24px]">
                            <div className="self-stretch flex flex-row items-center justify-start gap-[10px]">
                                  <div className="relative">Dear</div>
                                  <div className="w-[164px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-[42px] border-[1px] border-solid border-neutrals-neutral-100" />
                            </div>
                            <div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
                                  <div className="self-stretch flex flex-row items-center justify-start gap-[10px]">
                                        <div className="relative">Account Number:</div>
                                        <div className="w-[152px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-[42px] border-[1px] border-solid border-neutrals-neutral-100" />
                                  </div>
                                  <div className="self-stretch relative">Invoice Number: 2517, 2290, 2397</div>
                                  <div className="self-stretch flex flex-row items-center justify-start gap-[10px]">
                                        <div className="relative">Due Date:</div>
                                        <div className="w-[232px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-[42px] border-[1px] border-solid border-neutrals-neutral-100" />
                                  </div>
                                  <div className="self-stretch flex flex-row items-center justify-start gap-[10px]">
                                        <div className="relative">
                                              <span>{`Outstanding Amount: `}</span>
                                              <span className="font-semibold text-semantics-high-high-500">£</span>
                                        </div>
                                        <div className="w-[164px] rounded-3xs bg-neutrals-neutral-50 box-border h-[42px] border-[1px] border-solid border-neutrals-neutral-100" />
                                  </div>
                            </div>
                      </div>
                      <div className="self-stretch flex flex-col items-start justify-start gap-[40px]">
                            <div className="self-stretch flex flex-col items-start justify-start gap-[24px]">
                                  <div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
                                        <div className="self-stretch relative">This letter serves as a formal notice regarding the outstanding balance on your account. Despite previous communications, we have not received payment for the above-</div>
                                        <div className="self-stretch flex flex-row items-center justify-start gap-[6px]">
                                              <div className="relative">mentioned invoice, which was due on</div>
                                              <div className="w-[75px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-[42px] border-[1px] border-solid border-neutrals-neutral-100" />
                                        </div>
                                  </div>
                                  <div className="self-stretch relative">As we value our relationship and wish to resolve this matter amicably, we are keen to offer assistance and discuss potential solutions that may be available to you. It is important to address this issue promptly to avoid any further implications.</div>
                            </div>
                            <div className="self-stretch flex flex-row items-start justify-start">
                                  <div className="flex flex-col items-center justify-start gap-[10px]">
                                        <div className="relative font-semibold">Advantages of Settling</div>
                                        <div className="bg-primary-primary-500 flex flex-col items-end justify-start py-6 px-[13px] gap-[16px] text-neutrals-neutral-50">
                                              <div className="relative">
                                                    <ul className="m-0 pl-[21px]">
                                                          <li className="mb-0">
                                                                <span className="font-semibold font-open-sans-ff-body-strong">
                                                                      <span className="text-base">Quick Resolution:</span>
                                                                      <span className="text-sm"> U</span>
                                                                </span>
                                                                <span>
                                                                      <span>sing debt co-pilot,</span>
                                                                </span>
                                                          </li>
                                                    </ul>
                                                    <p className="m-0">
                                                          <span>
                                                                <span>      we can quickly resolve and monitor all</span>
                                                          </span>
                                                    </p>
                                                    <p className="m-0">
                                                          <span>
                                                                <span>      of your debts</span>
                                                          </span>
                                                    </p>
                                                    <ul className="m-0 pl-[21px]">
                                                          <li className="mb-0">
                                                                <span>
                                                                      <span className="font-semibold font-open-sans-ff-body-strong">Improved Cash Flow:</span>
                                                                      <span> By clearing debt</span>
                                                                </span>
                                                          </li>
                                                    </ul>
                                                    <p className="m-0">
                                                          <span>
                                                                <span>      earlier, you free up the monthly funds</span>
                                                          </span>
                                                    </p>
                                                    <p className="m-0">
                                                          <span>
                                                                <span>      that were previously allocated to debt</span>
                                                          </span>
                                                    </p>
                                                    <p className="m-0">
                                                          <span>
                                                                <span>      payments</span>
                                                          </span>
                                                    </p>
                                                    <ul className="m-0 pl-[21px]">
                                                          <li className="mb-0">
                                                                <span>
                                                                      <span className="font-semibold font-open-sans-ff-body-strong">Interest Savings:</span>
                                                                      <span> Avoid additional</span>
                                                                </span>
                                                          </li>
                                                    </ul>
                                                    <p className="m-0">
                                                          <span>
                                                                <span>      interest and charges by setting your</span>
                                                          </span>
                                                    </p>
                                                    <p className="m-0">
                                                          <span>
                                                                <span>      balance promptly</span>
                                                          </span>
                                                    </p>
                                              </div>
                                              <img className="w-10 relative h-10 overflow-hidden shrink-0" alt="" src="assets/check-circle.svg"/>
                                        </div>
                                  </div>
                                  <div className="self-stretch flex-1 flex flex-col items-center justify-start gap-[10px]">
                                        <div className="relative font-semibold">Consequences of Non-Payment</div>
                                        <div className="self-stretch flex-1 bg-semantics-high-high-600 flex flex-col items-center justify-start py-6 px-[13px] text-neutrals-neutral-50">
                                              <div className="self-stretch relative">
                                                    <ul className="m-0 pl-[21px]">
                                                          <li className="mb-0">
                                                                <span className="font-semibold font-open-sans-ff-body-strong">
                                                                      <span className="text-base">Legal Action:</span>
                                                                      <span className="text-sm">{` `}</span>
                                                                </span>
                                                                <span>
                                                                      <span>Facing legal action for unpaid debts can have serious consequences</span>
                                                                </span>
                                                          </li>
                                                          <li className="mb-0">
                                                                <span>
                                                                      <span className="font-semibold font-open-sans-ff-body-strong">Late Payment Fees:</span>
                                                                      <span className="font-open-sans-ff-body-strong"> Additional fees may be applied to the outstanding amount</span>
                                                                </span>
                                                          </li>
                                                          <li>
                                                                <span>
                                                                      <span className="font-semibold font-open-sans-ff-body-strong">Country Court Judgements:</span>
                                                                      <span className="font-open-sans-ff-body-strong">{` Non-payment will result in a CCJ being applied against your business. This will affect your credit score which will significantly reduce and will directly affect your ability to borrow & utlise credit facilities.`}</span>
                                                                </span>
                                                          </li>
                                                    </ul>
                                              </div>
                                        </div>
                                  </div>
                            </div>
                            <div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
                                  <div className="self-stretch relative font-semibold">How to settle your debt</div>
                                  <div className="self-stretch flex flex-row items-start justify-between">
                                        <div className="self-stretch w-[215px] flex flex-col items-start justify-start gap-[10px]">
                                              <div className="self-stretch bg-primary-primary-700 flex flex-col items-start justify-start py-6 px-[13px] gap-[16px]">
                                                    <div className="flex flex-row items-center justify-center gap-[16px]">
                                                          <div className="flex flex-row items-center justify-center relative gap-[10px]">
                                                                <div className="w-[30px] relative rounded-[50%] bg-neutrals-neutral-50 h-[30px] z-[0]" />
                                                                <div className="w-2.5 absolute !m-[0] top-[4.5px] left-[10px] font-semibold inline-block z-[1]">1</div>
                                                          </div>
                                                          <div className="relative font-semibold text-neutrals-neutral-50">Direct to client</div>
                                                    </div>
                                                    <div className="self-stretch relative text-neutrals-neutral-50">
                                                          <p className="m-0">
                                                                <span className="font-semibold font-open-sans-ff-body-strong">{`Full Payment: `}</span>
                                                                <span>You can settle your account via paying our client (the company you owe money to) directly via their invoice, ensure you quote reference D9001 when making payment so we receive notification.</span>
                                                          </p>
                                                          <p className="m-0">&nbsp;</p>
                                                          <p className="m-0">Who do I owe money:</p>
                                                          <p className="m-0">Peach Loves Digital Limited.</p>
                                                    </div>
                                              </div>
                                              <div className="self-stretch flex-1 bg-primary-primary-700 flex flex-col items-start justify-start py-6 px-[13px] text-neutrals-neutral-50">
                                                    <div className="self-stretch relative">
                                                          <p className="m-0 font-semibold">How to pay:</p>
                                                          <p className="m-0 whitespace-pre-wrap">Use your original  invoice</p>
                                                    </div>
                                              </div>
                                        </div>
                                        <div className="self-stretch w-[215px] flex flex-col items-start justify-start gap-[10px]">
                                              <div className="self-stretch bg-primary-primary-500 h-[402px] flex flex-col items-start justify-start py-6 px-[13px] box-border gap-[16px]">
                                                    <div className="flex flex-row items-center justify-center gap-[16px]">
                                                          <div className="flex flex-row items-center justify-center relative gap-[10px]">
                                                                <div className="w-[30px] relative rounded-[50%] bg-neutrals-neutral-50 h-[30px] z-[0]" />
                                                                <div className="w-2.5 absolute !m-[0] top-[4.5px] left-[10px] font-semibold inline-block z-[1]">2</div>
                                                          </div>
                                                          <div className="relative font-semibold text-neutrals-neutral-50">Direct to us</div>
                                                    </div>
                                                    <div className="self-stretch relative text-neutrals-neutral-50">
                                                          <p className="m-0">
                                                                <span className="font-semibold font-open-sans-ff-body-strong">{`Full Payment: `}</span>
                                                                <span>You can settle your account by bank transfer, online payment, or by sending a cheque.</span>
                                                          </p>
                                                          <p className="m-0">Full payment will resolve the account immediately and no further actions will be required.</p>
                                                          <p className="m-0">&nbsp;</p>
                                                          <ul className="m-0 text-inherit pl-[21px]">
                                                                <li className="mb-0">
                                                                      <span>Full: £8,100</span>
                                                                </li>
                                                                <li className="mb-0">
                                                                      <span>Half: £4,050</span>
                                                                </li>
                                                                <li>
                                                                      <span>Quarter: £2,025</span>
                                                                </li>
                                                          </ul>
                                                    </div>
                                              </div>
                                              <div className="self-stretch bg-primary-primary-500 flex flex-col items-start justify-start py-6 px-[13px] text-neutrals-neutral-50">
                                                    <div className="self-stretch relative">
                                                          <p className="m-0 font-semibold">How to pay:</p>
                                                          <p className="m-0">Online at www.debt.finance.co.uk</p>
                                                    </div>
                                              </div>
                                        </div>
                                        <div className="self-stretch w-[215px] flex flex-col items-start justify-start gap-[10px]">
                                              <div className="self-stretch bg-primary-primary-300 h-[402px] flex flex-col items-start justify-start py-6 px-[13px] box-border gap-[16px]">
                                                    <div className="flex flex-row items-center justify-center gap-[16px]">
                                                          <div className="flex flex-row items-center justify-center relative gap-[10px]">
                                                                <div className="w-[30px] relative rounded-[50%] bg-neutrals-neutral-50 h-[30px] z-[0]" />
                                                                <div className="w-2.5 absolute !m-[0] top-[4.5px] left-[10px] font-semibold inline-block z-[1]">3</div>
                                                          </div>
                                                          <div className="relative font-semibold text-neutrals-neutral-50">Arrangement</div>
                                                    </div>
                                                    <div className="self-stretch relative text-neutrals-neutral-50">
                                                          <p className="m-0">
                                                                <span className="font-semibold font-open-sans-ff-body-strong">{`Monthly Direct Debit: `}</span>
                                                                <span>This can provide you with the convenience of paying over a period of time, with the assurance that your payments are made on time, avoiding any potential late fees.</span>
                                                          </p>
                                                          <p className="m-0">&nbsp;</p>
                                                          <ul className="m-0 text-inherit pl-[21px]">
                                                                <li>
                                                                      <span>12 Payments: £675</span>
                                                                </li>
                                                          </ul>
                                                    </div>
                                              </div>
                                              <div className="self-stretch bg-primary-primary-300 flex flex-col items-start justify-start py-6 px-[13px] text-neutrals-neutral-50">
                                                    <div className="self-stretch relative">
                                                          <p className="m-0 font-semibold">How to pay:</p>
                                                          <p className="m-0">Online at www.debt.finance.co.uk</p>
                                                    </div>
                                              </div>
                                        </div>
                                  </div>
                            </div>
                            <div className="self-stretch flex flex-row items-start justify-start p-4 text-center border-[1px] border-solid border-neutrals-neutral-900">
                                  <div className="flex-1 relative">
                                        <p className="m-0">
                                              <span className="font-open-sans-ff-body-strong">{`We urge you to contact us by `}</span>
                                              <span className="font-semibold font-open-sans-ff-body-strong">1st May 2024</span>
                                              <span> to arrange payment or discuss a possible payment plan. Please note that failure to respond to this notice may necessitate further actions, which we would prefer to avoid.</span>
                                        </p>
                                        <p className="m-0">&nbsp;</p>
                                        <p className="m-0">You can reach us directly at Debt-CO phone or [Debt-co Email Address] to ensure a prompt resolution to this matter before any further action is initiated.</p>
                                  </div>
                            </div>
                            <div className="self-stretch flex flex-col items-start justify-start gap-[24px]">
                                  <div className="self-stretch relative">Yours sincerely,</div>
                                  <div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
                                        <div className="w-[188px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-center justify-center p-3 border-[1px] border-solid border-neutrals-neutral-100">
                                              <div className="flex flex-row items-center justify-start gap-[6px]">
                                                    <img className="w-5 relative h-5" alt="" src="assets/edit.svg"/>
                                                    <div className="relative">Signature</div>
                                              </div>
                                        </div>
                                        <div className="self-stretch relative">Debt Co-Pilot Ltd.</div>
                                  </div>
                            </div>
                            <div className="self-stretch flex flex-row items-center justify-end gap-[40px] text-sm">
                                  <div className="flex-1 relative">Terms and Conditions: Please be aware that this communication is intended for settlement purposes only and is not to be construed as a legally binding document. It is a pre-legal attempt to resolve the outstanding balance and avoid potential legal proceedings.. We appreciate your immediate attention to this matter and look forward to your cooperation in settling this account. Thank you for your prompt response.</div>
                                  <img className="w-[123px] relative h-[43px] object-cover" alt="" src="assets/barcode.png"/>
                            </div>
                      </div>
                </div>
          </div>);
};

export default LbaForm;