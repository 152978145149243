import { useState, useCallback } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import BasicPricingCard from "../../components/BasicPricingCard";
import PricingLandingPage from "../../components/PricingLandingPage";

const PricingPageMonthly = () => {
    const navigate = useNavigate();

    const handleSelectPlan = () => {
		console.log("Plan selected");
	};

    return (
          <div className="w-full relative bg-neutrals-neutral-50 h-[1151px] overflow-hidden text-left text-sm text-neutrals-neutral-900 font-open-sans-font-family-meta-strong">
                <div className="absolute top-[948.53px] left-[calc(50%_-_176px)] flex flex-row items-center justify-center gap-[24px]">
                      <div className="relative font-semibold">Need a custom plan?</div>
                            <div className="w-[188px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-center py-2.5 px-3.5 text-center border-[1px] border-solid border-neutrals-neutral-100">
                                  <div className="flex flex-row items-center justify-center">
                                        <div className="relative font-semibold">Contact us</div>
                                  </div>
                            </div>
                            </div>
                            <div className="absolute top-[255px] left-[434px] flex flex-col items-center justify-start gap-[6px] text-center text-9xl">
                                  <b className="relative">Pricing plans</b>
                                  <div className="flex flex-row items-start justify-start text-base text-neutrals-neutral-300">
                                        <div className="relative">We believe ThinkAI should be accessible to all companies, no matter the size.</div>
                                  </div>
                            </div>
                            <div className="absolute top-[255px] left-[96px] flex flex-row items-start justify-start gap-[6px]">
                                  <img className="w-5 relative h-5" alt="" src="assets/arrow-left.svg" />
                                  <div className="relative font-semibold">Back</div>
                            </div>
                            <div className="absolute top-[1068px] left-[1173px] text-neutrals-neutral-300">Copyright © Think-AI 2023</div>

                        <div className="absolute top-[352.53px] left-[calc(50%_-_561px)] flex flex-col items-center justify-start gap-[64px] text-base">
                              <PricingLandingPage/>
                              {/* <div className="rounded-3xs bg-column-bg flex flex-row items-start justify-start p-2 gap-[10px] border-[1px] border-solid border-neutrals-neutral-100">
                                     <div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2 px-3">
                                              <div className="relative font-semibold">Monthly billing</div>
                                        </div>
                                        <div className="rounded-3xs flex flex-row items-center justify-center py-2 px-3 text-neutrals-neutral-300">
                                              <div className="relative font-semibold">Annual billing</div>
                                    </div>
                               </div>
                                  
                              <div className="w-[1122px] relative h-[414.5px] text-sm text-neutrals-neutral-300">
                                          <div className="absolute top-[0.47px] left-[382px] rounded-3xs bg-neutrals-neutral-50 box-border h-[414px] overflow-hidden flex flex-col items-start justify-start py-6 px-8 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
                                                <div className="flex flex-col items-start justify-start">
                                                    <div className="flex flex-col items-start justify-start gap-[6px]">
                                                          <div className="w-[294px] flex flex-row items-center justify-between text-center">
                                                                <div className="relative">Business plan</div>
                                                                <div className="rounded-[100px] bg-primary-primary-50 flex flex-row items-center justify-center py-0.5 px-2 text-left text-primary-primary-500 border-[1px] border-solid border-primary-primary-200">
                                                                      <div className="relative font-semibold">Most popular</div>
                                                                </div>
                                                          </div>
                                                          <div className="relative text-13xl text-neutrals-neutral-900">
                                                                <b>{`£59.99 `}</b>
                                                                <span className="text-sm text-neutrals-neutral-300">per month</span>
                                                          </div>
                                                          <div className="w-[294px] relative inline-block">Everything you need for a growing business</div>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col items-start justify-start gap-[24px] text-lg text-neutrals-neutral-900">
                                                <div className="w-[295px] relative box-border h-px border-t-[1px] border-solid border-bg-primary" />
                                                    <div className="flex flex-col items-start justify-start gap-[24px]">
                                                          <div className="flex flex-col items-start justify-start gap-[6px]">
                                                                <div className="relative font-semibold">FEATURES</div>
                                                                <div className="relative text-sm text-neutrals-neutral-300">Everything in our advance plan.</div>
                                                          </div>
                                                          <div className="flex flex-col items-start justify-start gap-[10px] text-sm">
                                                                <div className="flex flex-row items-center justify-start gap-[6px]">
                                                                      <img className="w-5 relative h-5" alt="" src="Icons.svg" />
                                                                      <div className="w-[243px] relative inline-block shrink-0">Over 5 users</div>
                                                                </div>
                                                                <div className="flex flex-row items-center justify-start gap-[6px]">
                                                                      <img className="w-5 relative h-5" alt="" src="Icons.svg" />
                                                                      <div className="w-[243px] relative inline-block shrink-0">Product support</div>
                                                                </div>
                                                                <div className="flex flex-row items-center justify-start gap-[6px]">
                                                                      <img className="w-5 relative h-5" alt="" src="Icons.svg" />
                                                                      <div className="w-[243px] relative inline-block shrink-0">Plan features</div>
                                                                </div>
                                                          </div>
                                                    </div>
                                                    <div className="w-[294px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-center py-2.5 px-3.5 text-center text-sm border-[1px] border-solid border-neutrals-neutral-100">
                                                          <div className="flex flex-row items-center justify-center">
                                                                <div className="relative font-semibold">Sign up</div>
                                                          </div>
                                                    </div>
                                              </div>
                                        </div>

                                        <div className="absolute top-[0px] left-[0px] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start py-6 px-8 gap-[24px] text-base border-[1px] border-solid border-neutrals-neutral-100">
                                              <div className="flex flex-col items-start justify-start gap-[10px]">
                                                    <div className="relative">Basic plan</div>
                                                    <div className="flex flex-col items-start justify-start gap-[6px] text-13xl text-neutrals-neutral-900">
                                                          <div className="relative">
                                                                <b>{`£49.99 `}</b>
                                                                <span className="text-sm text-neutrals-neutral-300">per month</span>
                                                          </div>
                                                          <div className="relative text-sm text-neutrals-neutral-300">Get started with basic features</div>
                                                    </div>
                                              </div>
                                              <div className="flex flex-col items-start justify-start gap-[24px] text-lg text-neutrals-neutral-900">
                                                    <div className="w-[295px] relative box-border h-px border-t-[1px] border-solid border-bg-primary" />
                                                    <div className="flex flex-col items-start justify-start gap-[24px]">
                                                          <div className="flex flex-col items-start justify-start gap-[6px]">
                                                                <div className="relative font-semibold">FEATURES</div>
                                                                <div className="relative text-sm text-neutrals-neutral-300">Everything in our free plan.</div>
                                                          </div>
                                                          <div className="flex flex-col items-start justify-start gap-[10px] text-sm">
                                                                <div className="flex flex-row items-center justify-start gap-[6px]">
                                                                      <img className="w-5 relative h-5" alt="" src="Icons.svg" />
                                                                      <div className="w-[243px] relative inline-block shrink-0">Over 10 users</div>
                                                                </div>
                                                                <div className="flex flex-row items-center justify-start gap-[6px]">
                                                                      <img className="w-5 relative h-5" alt="" src="Icons.svg" />
                                                                      <div className="w-[243px] relative inline-block shrink-0">Product support</div>
                                                                </div>
                                                                <div className="flex flex-row items-center justify-start gap-[6px]">
                                                                      <img className="w-5 relative h-5" alt="" src="Icons.svg" />
                                                                      <div className="w-[243px] relative inline-block shrink-0">Plan features</div>
                                                                </div>
                                                          </div>
                                                    </div>
                                                    <div className="w-[294px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-center py-2.5 px-3.5 text-center text-sm border-[1px] border-solid border-neutrals-neutral-100">
                                                          <div className="flex flex-row items-center justify-center">
                                                                <div className="relative font-semibold">Sign up</div>
                                                          </div>
                                                    </div>
                                              </div>
                                        </div>
                                        <div className="absolute top-[2.47px] left-[764px] rounded-3xs bg-neutrals-neutral-50 box-border h-[412px] flex flex-col items-start justify-start py-6 px-8 gap-[24px] text-center border-[1px] border-solid border-neutrals-neutral-100">
                                              <div className="w-[294px] flex flex-col items-start justify-start gap-[10px]">
                                                    <div className="relative">Enterprise plan</div>
                                                    <div className="flex flex-col items-start justify-start gap-[6px] text-left text-13xl text-neutrals-neutral-900">
                                                          <div className="relative">
                                                                <b>{`£69.99 `}</b>
                                                                <span className="text-sm text-neutrals-neutral-300">per month</span>
                                                          </div>
                                                          <div className="w-[294px] relative text-sm text-neutrals-neutral-300 inline-block">Advanced features for scaling your business</div>
                                                    </div>
                                              </div>
                                              <div className="flex flex-col items-start justify-start gap-[24px] text-left text-lg text-neutrals-neutral-900">
                                                    <div className="w-[295px] relative box-border h-px border-t-[1px] border-solid border-bg-primary" />
                                                    <div className="flex flex-col items-start justify-start gap-[24px]">
                                                          <div className="flex flex-col items-start justify-start gap-[6px]">
                                                                <div className="relative font-semibold">FEATURES</div>
                                                                <div className="relative text-sm text-neutrals-neutral-300">Everything in our enterprise plan.</div>
                                                          </div>
                                                          <div className="flex flex-col items-start justify-start gap-[10px] text-sm">
                                                                <div className="flex flex-row items-center justify-start gap-[6px]">
                                                                      <img className="w-5 relative h-5" alt="" src="Icons.svg" />
                                                                      <div className="w-[243px] relative inline-block shrink-0">5 and under users</div>
                                                                </div>
                                                                <div className="flex flex-row items-center justify-start gap-[6px]">
                                                                      <img className="w-5 relative h-5" alt="" src="Icons.svg" />
                                                                      <div className="w-[243px] relative inline-block shrink-0">Product support</div>
                                                                </div>
                                                                <div className="flex flex-row items-center justify-start gap-[6px]">
                                                                      <img className="w-5 relative h-5" alt="" src="Icons.svg" />
                                                                      <div className="w-[243px] relative inline-block shrink-0">Plan features</div>
                                                                </div>
                                                          </div>
                                                    </div>
                                                    <div className="w-[294px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-center py-2.5 px-3.5 text-center text-sm border-[1px] border-solid border-neutrals-neutral-100">
                                                          <div className="flex flex-row items-center justify-center">
                                                                <div className="relative font-semibold">Sign up</div>
                                                          </div>
                                                    </div>
                                              </div>
                                        </div>
                                  </div> */}

                            </div>
                            <img className="absolute top-[164px] left-[calc(50%_-_30px)] w-[60px] h-[67px] object-cover" alt="" src="assets/logo-vertical.png" />
                            </div>);
                      };
                      
                      export default PricingPageMonthly;          