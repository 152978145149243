import { useState, useCallback } from 'react';
import NavigationDropdown from "../../components/NavigationDropdown";
import NavigationPanel from '../../components/NavigationPanel';
import PortalPopup from "../../components/PortalPopup";
import LogoutConfirmation from "../../components/LogoutConfirmation";

const ParticularsOfClaim = () => {
  	const [isNavigationDropdownOpen, setNavigationDropdownOpen] = useState(false);
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	
  	const openNavigationDropdown = useCallback(() => {
    		setNavigationDropdownOpen(true);
  	}, []);
  	
  	const closeNavigationDropdown = useCallback(() => {
    		setNavigationDropdownOpen(false);
  	}, []);
  	
  	
  	const openLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(true);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);

	const navItems = [
		{
			text: 'Debt collection', 
    		bgColor: 'bg-primary-primary-50', 
    		textColor: 'text-neutrals-neutral-900', 
    		hoverBgColor: 'bg-primary-primary-50', 
    		hoverTextColor: 'text-neutrals-neutral-900', 
    		path: "",
    		icon: 'assets/arrow-down.svg'
		},

		{
			text: "Outcomes & costs",
			bgColor: 'bg-neutrals-neutral-50', 
    		textColor: 'text-neutrals-neutral-300', 
    		hoverBgColor: 'bg-neutrals-neutral-50', 
    		hoverTextColor: 'text-primary-primary-500', 
    		path: "/outcome&cost",
    		icon: ''
		},

		{
			text: "AI indicators",
			bgColor: 'bg-neutrals-neutral-50', 
    		textColor: 'text-neutrals-neutral-300', 
    		hoverBgColor: 'bg-neutrals-neutral-50', 
    		hoverTextColor: 'text-primary-primary-500', 
    		path: "/ai1",
    		icon: ''
		},
	];
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1940px] overflow-hidden text-left text-base text-neutrals-neutral-900 font-open-sans-ff-heading-1">
      			<div className="absolute top-[1831px] right-[96px] text-sm text-neutrals-neutral-300">Copyright © Think-AI 2023</div>
      			<div className="absolute top-[128px] left-[96px] box-border w-[1248px] flex flex-row items-start justify-start pt-0 px-0 pb-6 text-lg border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Preview claim form</div>
          					<div className="relative text-sm text-neutrals-neutral-300">Get your claim form previewed and edit the details.</div>
        				</div>
      			</div>

				{/* Navigation Panel */}
				<NavigationPanel navItems={navItems}/>
      			
      			<div className="absolute top-[226px] left-[96px] w-[1248px] flex flex-row items-start justify-between text-13xl">
        				<b className="relative">Claim Form</b>
        				<div className="flex flex-col items-end justify-start gap-[24px] text-base">
          					<div className="flex flex-col items-end justify-start gap-[10px]">
            						<div className="flex flex-row items-center justify-start gap-[41px]">
              							<div className="relative font-semibold">In the</div>
              							<div className="w-[440px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-[42px] border-[1px] border-solid border-neutrals-neutral-100" />
            						</div>
            						<div className="flex flex-row items-center justify-start gap-[18px]">
              							<div className="relative font-semibold">Fee Amount no.</div>
              							<div className="w-[386px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-[42px] border-[1px] border-solid border-neutrals-neutral-100" />
            						</div>
            						<div className="w-[558px] flex flex-row items-center justify-start gap-[17px]">
              							<div className="relative">
                								<p className="m-0 font-semibold">Help with Fees -</p>
                								<p className="m-0 font-semibold">Ref no.</p>
                								<p className="m-0">(if applicable)</p>
              							</div>
              							<div className="flex flex-row items-start justify-start text-center border-[1px] border-solid border-neutrals-neutral-100">
                								<div className="flex flex-col items-center justify-center py-2.5 px-3.5 border-r-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative font-semibold">H</div>
                								</div>
                								<div className="flex flex-col items-center justify-center py-2.5 px-3.5">
                  									<div className="relative font-semibold">W</div>
                								</div>
                								<div className="flex flex-col items-center justify-center py-2.5 px-3.5 border-r-[1px] border-l-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative font-semibold">F</div>
                								</div>
                								<div className="w-[37px] flex flex-col items-center justify-center py-2.5 px-3.5 box-border">
                  									<div className="relative font-semibold">-</div>
                								</div>
                								<div className="w-[37px] box-border h-[42px] border-l-[1px] border-r-[1px] border-solid border-neutrals-neutral-100" />
                								<div className="w-[37px] h-[42px]" />
                								<div className="w-[37px] box-border h-[42px] border-l-[1px] border-r-[1px] border-solid border-neutrals-neutral-100" />
                								<div className="w-[37px] flex flex-col items-center justify-center py-2.5 px-3.5 box-border">
                  									<div className="relative font-semibold">-</div>
                								</div>
                								<div className="w-[37px] box-border h-[42px] border-l-[1px] border-r-[1px] border-solid border-neutrals-neutral-100" />
                								<div className="w-[37px] h-[42px]" />
                								<div className="w-[37px] box-border h-[42px] border-l-[1px] border-solid border-neutrals-neutral-100" />
              							</div>
            						</div>
          					</div>
          					<div className="w-[526px] flex flex-col items-start justify-start gap-[10px] text-sm">
            						<div className="w-[526px] flex flex-col items-end justify-start">
              							<div className="w-[435px] rounded-t-3xs rounded-b-none bg-primary-primary-50 flex flex-row items-center justify-center py-0.5 px-2 box-border">
                								<div className="relative">For court use only</div>
              							</div>
              							<div className="flex flex-row items-center justify-start gap-[18px] text-base">
                								<div className="relative">Claim no.</div>
                								<div className="w-[435px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-t-none rounded-b-3xs bg-neutrals-neutral-50 box-border h-[42px] border-[1px] border-solid border-neutrals-neutral-100" />
              							</div>
            						</div>
            						<div className="w-[526px] flex flex-row items-center justify-start gap-[12px] text-base">
              							<div className="relative">Issue date</div>
              							<div className="flex-1 shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-[42px] border-[1px] border-solid border-neutrals-neutral-100" />
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[549px] left-[96px] w-[718px] flex flex-col items-start justify-start gap-[10px]">
        				<div className="flex flex-row items-center justify-start gap-[6px]">
          					<div className="relative font-semibold">Claimant(s) name(s) and address(es) including postcode</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg"/>
        				</div>
        				<div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-[282px] flex flex-col items-start justify-start p-4 text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="relative">Enter text for the email</div>
        				</div>
      			</div>
      			<div className="absolute top-[1225px] left-[96px] w-[1248px] flex flex-col items-start justify-start gap-[10px]">
        				<div className="flex flex-row items-center justify-start gap-[6px]">
          					<div className="relative font-semibold">Brief details of claim</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg"/>
        				</div>
        				<div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-[282px] flex flex-col items-start justify-start p-4 text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="relative">Enter text for the email</div>
        				</div>
      			</div>
      			<div className="absolute top-[887px] left-[96px] w-[1248px] flex flex-col items-start justify-start gap-[10px]">
        				<div className="flex flex-row items-center justify-start gap-[6px]">
          					<div className="relative font-semibold">Dependant(s) name(s) and address(es) including postcode</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg"/>
        				</div>
        				<div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-[282px] flex flex-col items-start justify-start p-4 text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="relative">Enter text for the email</div>
        				</div>
      			</div>
      			<div className="absolute top-[1579px] left-[818px] flex flex-col items-start justify-start gap-[10px]">
        				<div className="w-[526px] flex flex-row items-center justify-start gap-[41px]">
          					<div className="relative">Amount claimed</div>
          					<div className="flex-1 shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-[42px] border-[1px] border-solid border-neutrals-neutral-100" />
        				</div>
        				<div className="flex flex-row items-center justify-start gap-[18px]">
          					<div className="relative">Court Fee</div>
          					<div className="w-[435px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-[42px] border-[1px] border-solid border-neutrals-neutral-100" />
        				</div>
        				<div className="w-[526px] flex flex-row items-center justify-start gap-[18px]">
          					<div className="relative">
            						<p className="m-0">Legal representative’s</p>
            						<p className="m-0">costs</p>
          					</div>
          					<div className="flex-1 shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-[42px] border-[1px] border-solid border-neutrals-neutral-100" />
        				</div>
        				<div className="w-[526px] flex flex-row items-center justify-start gap-[18px]">
          					<div className="relative">Total</div>
          					<div className="flex-1 shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-[42px] border-[1px] border-solid border-neutrals-neutral-100" />
        				</div>
      			</div>
    		</div>
    		{isNavigationDropdownOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeNavigationDropdown}
        				>
        				<NavigationDropdown onClose={closeNavigationDropdown} />
      			</PortalPopup>
    		)}
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}</>);
};

export default ParticularsOfClaim;