import { useState, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import NavigationDropdown from "../../components/NavigationDropdown";
import NavigationPanel from '../../components/NavigationPanel';
import PortalPopup from "../../components/PortalPopup";
import LogoutConfirmation from "../../components/LogoutConfirmation";
import LPDConfirmation from "../../components/LPDConfirmation";
import LPDAutoPopulate from '../../components/LPDAutoPopulate';
import Breadcrumb from '../../components/Breadcrumb';

const LPDPage_2 = () => {
    const navigate = useNavigate();
  	const [isNavigationDropdownOpen, setNavigationDropdownOpen] = useState(false);
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	const [isLPDConfirmationOpen, setLPDConfirmationOpen] = useState(false);
  	
  	const openNavigationDropdown = useCallback(() => {
    		setNavigationDropdownOpen(true);
  	}, []);
  	
  	const closeNavigationDropdown = useCallback(() => {
    		setNavigationDropdownOpen(false);
  	}, []);
  	
  	
  	const openLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(true);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);
  	
  	
  	const onIconsV2Click = useCallback(() => {
    		// Add your code here
  	}, []);
  	
  	
  	const onTextClick = useCallback(() => {
        navigate('/lpd');
  	}, [navigate]);
  	
  	
  	const openLPDConfirmation = useCallback(() => {
    		setLPDConfirmationOpen(true);
  	}, []);
  	
  	const closeLPDConfirmation = useCallback(() => {
    		setLPDConfirmationOpen(false);
  	}, []);

	const navItems = [
		{
			text: 'Debt collection', 
    		bgColor: 'bg-primary-primary-50', 
    		textColor: 'text-neutrals-neutral-900', 
    		hoverBgColor: 'bg-primary-primary-50', 
    		hoverTextColor: 'text-neutrals-neutral-900', 
    		path: "",
    		icon: 'assets/arrow-down.svg'
		},

		{
			text: "Outcomes & costs",
			bgColor: 'bg-neutrals-neutral-50', 
    		textColor: 'text-neutrals-neutral-300', 
    		hoverBgColor: 'bg-neutrals-neutral-50', 
    		hoverTextColor: 'text-primary-primary-500', 
    		path: "/outcome&cost",
    		icon: ''
		},

		{
			text: "AI indicators",
			bgColor: 'bg-neutrals-neutral-50', 
    		textColor: 'text-neutrals-neutral-300', 
    		hoverBgColor: 'bg-neutrals-neutral-50', 
    		hoverTextColor: 'text-primary-primary-500', 
    		path: "/ai1",
    		icon: ''
		},
	];

	const breadcrumb = [
		{ label: "", path: "/postlba2", icon: "assets/home.svg", active: true},
		{ label: "Late Payment Demand (LPD)", path: "/lpd", active: false },
		{ label: "New LPD", path: "", active: true },
	]
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1286px] overflow-hidden text-left text-sm text-neutrals-neutral-300 font-open-sans-ff-body-strong">
      			<div className="absolute top-[1203px] right-[96px]">Copyright © Think-AI 2023</div>
      			<div className="absolute top-[179px] left-[96px] box-border w-[1248px] flex flex-row items-start justify-start pt-0 px-0 pb-6 text-lg text-neutrals-neutral-900 border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="relative font-semibold">Debtor’s details</div>
      			</div>

				{/* Navigation Panel */}
				<NavigationPanel navItems={navItems}/>

				{/* Breadcrumb Navigation */}
				<Breadcrumb breadcrumbs={breadcrumb} textColor="" position="top-[24px] left-[90px]"/>

      			<div className="absolute top-[252px] left-[calc(50%_-_624px)] w-[1248px] h-[873px] text-lg text-neutrals-neutral-900">
                    <LPDAutoPopulate/>

      			</div>
    		</div>
    		{isNavigationDropdownOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeNavigationDropdown}
        				>
        				<NavigationDropdown onClose={closeNavigationDropdown} />
      			</PortalPopup>
    		)}
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}
    		{isLPDConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLPDConfirmation}
        				>
        				<LPDConfirmation onClose={closeLPDConfirmation} />
      			</PortalPopup>
    		)}</>);
};

export default LPDPage_2;