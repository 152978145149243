import { useState, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import ExportModalTables from "../../components/ExportModalTables";
import PortalPopup from "../../components/PortalPopup";
import LogoutConfirmation from "../../components/LogoutConfirmation";
import NavigationPanel from '../../components/NavigationPanel';
import Breadcrumb from '../../components/Breadcrumb';
import CTAs from '../../components/CTAs';
import Toggle from '../../components/Toggle';

const SettingsDataManagementPage = () => {
    const navigate = useNavigate();
  	const [isExportModalTablesOpen, setExportModalTablesOpen] = useState(false);
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	
  	const openExportModalTables = useCallback(() => {
    		setExportModalTablesOpen(true);
  	}, []);
  	
  	const closeExportModalTables = useCallback(() => {
    		setExportModalTablesOpen(false);
  	}, []);
  	
  	const onClickGeneral = useCallback(() => {
        navigate('/setting-general');
  	}, [navigate]);
  	
  	
  	const onClickPreferences = useCallback(() => {
        navigate('/setting-preferences');
  	}, [navigate]);
  	
  	
  	const onClickLBAConfig = useCallback(() => {
        navigate('/setting-lbaconfig');
  	}, [navigate]);
  	
  	
  	const onClickLegalProceedings = useCallback(() => {
        navigate('/setting-legalproceedings');
  	}, [navigate]);
  	
  	
  	const onClickAIIntegration = useCallback(() => {
        navigate('/setting-aiintegration');
  	}, [navigate]);
  	
  	
  	const onClickSecurity = useCallback(() => {
        navigate('/setting-security');
  	}, [navigate]);
  	
  	
  	const onClickBilling = useCallback(() => {
        navigate('/setting-billing');
  	}, [navigate]);
  	
  	
  	const onClickHelpSupport = useCallback(() => {
        navigate('/setting-help&support');
  	}, [navigate]);
  	
  	
  	const onClickAccount = useCallback(() => {
		navigate('/setting-account');
  	}, [navigate]);
  	
  	const openLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(true);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);

	const breadcrumb = [
		{ label: "", path: "/postlba2", icon: "assets/Home.svg", active: true },
		{ label: "Settings", path: "", icon: "", active: false },
		{ label: "Data management", path: "", icon: "", active: true },
	];

	const handleToggle = (state) => {
		console.log("Toggle state is:", state);
	};
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1848px] overflow-hidden text-left text-sm text-neutrals-neutral-300 font-open-sans-ff-body-strong">
      			<div className="absolute top-[391px] left-[390px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] w-[954px] flex flex-col items-start justify-start text-neutrals-neutral-900">
        				<div className="self-stretch rounded-t-3xs rounded-b-none flex flex-row items-center justify-between py-5 px-6 text-lg border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-l-[1px]">
          					<div className="flex flex-row items-center justify-start gap-[10px]">
            						<div className="relative font-semibold">Export history</div>
            						<div className="rounded-[5px] bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="w-1.5 relative rounded-[50%] bg-primary-primary-500 h-1.5" />
              							<div className="relative font-semibold">10 entries</div>
            						</div>
          					</div>
          					<div className="flex flex-row items-start justify-start gap-[10px] text-sm">
								<CTAs
								type="Ghostbtn"
								text="Export"
								textColor="text-neutrals-neutral-900"
								bgColor="bg-neutrals-neutral-50"
								borderColor="border-neutrals-neutral-100"
								position="relative"
								icon="assets/export.svg"
								onClick={openExportModalTables}
								/>

								<CTAs
								type="Ghostbtn"
								text="Clear logs"
								textColor="text-neutrals-neutral-900"
								bgColor="bg-neutrals-neutral-50"
								borderColor="border-neutrals-neutral-100"
								position="relative"
								/>
          					</div>
        				</div>
        				<div className="self-stretch flex flex-row items-center justify-between py-3 px-6 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-l-[1px]">
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-start border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="rounded-tl-3xs rounded-tr-none rounded-br-none rounded-bl-3xs bg-column-bg flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">View all</div>
            						</div>
            						<div className="flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">Success</div>
            						</div>
            						<div className="flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">Pending</div>
            						</div>
            						<div className="rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none flex flex-row items-center justify-center py-2 px-4 ">
              							<div className="relative font-semibold">Failed</div>
            						</div>
          					</div>
          					<div className="flex flex-row items-start justify-start gap-[10px] text-neutrals-neutral-300">
            						<div className="w-[294px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-start py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-start gap-[10px]">
                								<img className="w-5 relative h-5" alt="" src="assets/search.svg" />
                								<div className="relative">Search</div>
              							</div>
            						</div>
									<CTAs
									type="Ghostbtn"
									text="Filter"
									textColor="text-neutrals-neutral-900"
									bgColor="bg-neutrals-neutral-50"
									borderColor="border-neutrals-neutral-100"
									icon="assets/filter.svg"
									position="relative"
									/>
          					</div>
        				</div>
        				<div className="self-stretch h-[661px] flex flex-col items-start justify-between">
          					<div className="self-stretch h-[594px] flex flex-col items-start justify-start">
            						<div className="self-stretch flex flex-row items-start justify-start">
              							<div className="w-[212px] flex flex-col items-start justify-start">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
                  									<div className="relative font-semibold">Export date</div>
                								</div>
                								<div className="self-stretch flex flex-col items-start justify-start">
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">02/02/2024, 09:00 AM</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">01/02/2024, 10:30 AM</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">28/01/2024, 03:00 PM</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">02/02/2024, 09:00 AM</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">02/02/2024, 09:00 AM</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">02/02/2024, 09:00 AM</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">02/02/2024, 09:00 AM</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">02/02/2024, 09:00 AM</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">02/02/2024, 09:00 AM</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">02/02/2024, 09:00 AM</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[212px] h-[594px] flex flex-col items-center justify-between">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-center py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">Status</div>
                								</div>
                								<div className="self-stretch flex flex-col items-center justify-start text-semantics-low-low-500">
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Success</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 text-semantics-high-high-500 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-high-high-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-high-high-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-high-high-500 h-1.5" />
                      											<div className="relative font-semibold">Failed</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 text-semantics-high-high-500 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-high-high-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-high-high-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-high-high-500 h-1.5" />
                      											<div className="relative font-semibold">Failed</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 text-semantics-moderate-moderate-500 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-moderate-moderate-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-moderate-moderate-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
                      											<div className="relative font-semibold">Pending</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Success</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 text-semantics-moderate-moderate-500 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-moderate-moderate-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-moderate-moderate-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
                      											<div className="relative font-semibold">Pending</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 text-semantics-high-high-500 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-high-high-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-high-high-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-high-high-500 h-1.5" />
                      											<div className="relative font-semibold">Failed</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Success</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Success</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Success</div>
                    										</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[236px] flex flex-col items-center justify-start">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-center py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">Type</div>
                								</div>
                								<div className="self-stretch flex flex-col items-center justify-start">
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Monthly report</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Custom selection</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Full data export</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Full data export</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Monthly report</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Custom selection</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Monthly report</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Custom selection</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Full data export</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Full data export</div>
                  									</div>
                								</div>
              							</div>
              							<div className="flex-1 flex flex-col items-start justify-start">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-b-[1px]">
                  									<div className="relative font-semibold">Link</div>
                								</div>
                								<div className="self-stretch flex flex-col items-start justify-start text-primary-primary-500">
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative [text-decoration:underline]">Download</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative [text-decoration:underline]">Retry</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative [text-decoration:underline]">Retry</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative [text-decoration:underline]">See details</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative [text-decoration:underline]">Download</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative [text-decoration:underline]">See details</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative [text-decoration:underline]">Retry</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative [text-decoration:underline]">Download</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative [text-decoration:underline]">Download</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative [text-decoration:underline]">Download</div>
                  									</div>
                								</div>
              							</div>
            						</div>
          					</div>
          					<div className="self-stretch rounded-t-none rounded-b-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-between pt-3 px-6 pb-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">Previous</div>
              							</div>
            						</div>
            						<div className="relative">Page 1 of 10</div>
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">Next</div>
              							</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[293px] left-[96px] w-[188px] flex flex-col items-start justify-start gap-[6px] text-base">
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickGeneral}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">General</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickPreferences}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Preferences</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickLBAConfig}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">LBA configuration</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickLegalProceedings}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Legal proceedings</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickAIIntegration}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">AI integration</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickSecurity}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Security</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickBilling}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Billing</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickHelpSupport}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Help & support</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 box-border h-[41px] flex flex-row items-center justify-center py-2 px-3 text-primary-primary-500 border-l-[2px] border-solid border-primary-primary-500">
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Data management</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickAccount}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Account</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[1765px] right-[96px]">Copyright © Think-AI 2023</div>
      			<div className="absolute top-[179px] left-[96px] bg-neutrals-neutral-50 box-border w-[1248px] flex flex-col items-start justify-start pt-0 px-0 pb-6 gap-[6px] text-9xl text-primary-primary-900 border-b-[1px] border-solid border-neutrals-neutral-100">
        				<b className="relative">Settings</b>
        				<div className="relative text-base text-neutrals-neutral-300">Get your billing information, generate/review invoices.</div>
      			</div>
      			<div className="absolute top-[293px] left-[390px] box-border w-[954px] flex flex-row items-start justify-start pt-0 px-0 pb-6 text-lg text-neutrals-neutral-900 border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Data management</div>
          					<div className="relative text-sm text-neutrals-neutral-300">Allow users to configure how and when communication is initiated with debtors.</div>
        				</div>
      			</div>

				{/* Navigation Panel */}
				<NavigationPanel/>

				{/* Breadcrumb Navigation */}
				<Breadcrumb breadcrumbs={breadcrumb} position="top-[24px] left-[90px]"/>
      			
      			<div className="absolute top-[1260px] left-[390px] w-[954px] flex flex-col items-start justify-start gap-[24px] text-lg text-neutrals-neutral-900">
        				<div className="self-stretch relative font-semibold">Data sharing preferences</div>
        				<div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[24px] text-base">
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="self-stretch flex flex-row items-start justify-between">
              							<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
                								<img className="w-5 relative h-5" alt="" src="assets/auto-pay.svg" />
              							</div>
										{/* Toggle */}
										<Toggle
										onToggle={handleToggle}
										isOn={true}
										onColor='bg-primary-primary-500'
										offColor='bg-primary-primary-50'
										knobColor='bg-neutrals-neutral-50'
										/>
            						</div>
            						<div className="flex flex-col items-start justify-start gap-[6px]">
              							<div className="relative font-semibold">Share usage analytics</div>
              							<div className="w-[294px] relative text-neutrals-neutral-300 inline-block">Get notified before your court date so you can be prepared in advance.</div>
            						</div>
          					</div>
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="self-stretch flex flex-row items-start justify-between">
              							<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
                								<img className="w-5 relative h-5" alt="" src="assets/auto-pay.svg" />
              							</div>
										{/* Toggle */}
										<Toggle
										onToggle={handleToggle}
										isOn={true}
										onColor='bg-primary-primary-500'
										offColor='bg-primary-primary-50'
										knobColor='bg-neutrals-neutral-50'
										/>
            						</div>
            						<div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
              							<div className="relative font-semibold">Share personalised recommendations</div>
              							<div className="w-[294px] relative text-neutrals-neutral-300 inline-block">Get notified before your court date so you can be prepared in advance.</div>
            						</div>
          					</div>
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="self-stretch flex flex-row items-start justify-between">
              							<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
                								<img className="w-5 relative h-5" alt="" src="assets/auto-pay.svg" />
              							</div>
										{/* Toggle */}
										<Toggle
										onToggle={handleToggle}
										isOn={true}
										onColor='bg-primary-primary-500'
										offColor='bg-primary-primary-50'
										knobColor='bg-neutrals-neutral-50'
										/>
            						</div>
            						<div className="flex flex-col items-start justify-start gap-[6px]">
              							<div className="relative font-semibold">Share diagnostic data</div>
              							<div className="w-[294px] relative text-neutrals-neutral-300 inline-block">Get notified before your court date so you can be prepared in advance.</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[1227.5px] left-[389.5px] box-border w-[955px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
    		</div>
    		{isExportModalTablesOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeExportModalTables}
        				>
        				<ExportModalTables onClose={closeExportModalTables} />
      			</PortalPopup>
    		)}
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}</>);
};

export default SettingsDataManagementPage;