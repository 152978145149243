import { useCallback, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import EmailInputField from '../../components/EmailInputField';
import PasswordInputField from '../../components/PasswordInputField';
import CTAs from '../../components/CTAs';
import NameInput from '../../components/NameInput';

const RegisterPage = () => {
	const navigate = useNavigate();
	const [name, setName] = useState('');
  	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [isDarkMode, setIsDarkMode] = useState(false);
	const handleNameChange = (event) => {
		setName(event.target.value);
	};
	
	const handleEmailChange = (value) => {
		setEmail(value);
	  };
	const handlePasswordChange = (value) => {
		setPassword(value);
	  };
	
  	const onCTAsContainerClick = useCallback(() => {
		navigate('/register2')
  	}, [navigate]);
  	
  	const onLogInTextClick = useCallback(() => {
		navigate('/login')
  	}, [navigate]);

  	return (
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1024px] overflow-hidden text-left text-sm text-neutrals-neutral-900 font-open-sans-ff-meta">
      			<div className="absolute top-[263px] left-[calc(50%_-_128px)] flex flex-col items-center justify-start gap-[10px] text-center text-9xl">
        				<b className="relative">Create an account</b>
        				<div className="relative text-base text-neutrals-neutral-300">Register to your account</div>
      			</div>

      			<div className="absolute top-[397px] left-[520px] flex flex-col items-start justify-start gap-[10px]">
				<NameInput
					title='Name'
					placeholder='John Doe'
					value={name}
					onChange={handleNameChange}
					width='400px'
					validationMessage='Please provide your full name.'
					iconColor='#D93C3D'
					iconSize='20px'
					apiEndpoint='https://example.com/api/save-name'
					isDarkMode={isDarkMode}
					
					
				/>	
				
      			</div>

      			<div className="absolute top-[497px] left-[520px] flex flex-col items-start justify-start gap-[10px]">
					<EmailInputField title="Email"
								  placeholder="name@yourcompany.com"
								  value={email}
								  onChange={(e) => handleEmailChange(e.target.value)}
								  validationMessage="Please provide a valid email address."
								  iconColor="#D93C3D"
								  iconSize="20px"
								  tooltipPosition="top"
								  apiEndpoint="https://example.com/api/email" />
      			</div>

      			<div className="absolute top-[597px] left-[520px] flex flex-col items-start justify-start gap-[10px]">
					 <PasswordInputField title="Password"
								  placeholder="Enter your password"
								  value={password}
								  onChange={(e) => handlePasswordChange(e.target.value)}
								  validationMessage="Please provide your password."
								  iconColor="#D93C3D"
								  iconSize="20px"
								  tooltipPosition="top"
								  apiEndpoint="https://example.com/api/email" />
      			</div>
      			<div className="absolute top-[713px] left-[520px] flex flex-col items-center justify-start gap-[24px] text-neutrals-neutral-50">
        				<div className="self-stretch flex flex-col items-center justify-start gap-[16px]">
							<CTAs
							type="Primarybtn"
							text="Continue"
							textColor="text-neutrals-neutral-50"
							bgColor="bg-primary-primary-500"
							borderColor="border-primary-primary-500"
							width="400px"
							position="relative"
							onClick={onCTAsContainerClick}
							/>

							<CTAs
							type="Primarybtn"
							text="Sign up with Google"
							textColor="text-neutrals-neutral-900"
							bgColor="bg-neutrals-neutral-50"
							borderColor="border-neutrals-neutral-100"
							icon="assets/google.svg"
							width="400px"
							position="relative"
							/>

        				</div>
        				<div className="flex flex-row items-center justify-center gap-[6px] text-neutrals-neutral-300">
          					<div className="relative">Already have an account?</div>
            				<div className="relative font-semibold text-primary-primary-500 cursor-pointer" onClick={onLogInTextClick}>Log in</div>
            			</div>
            	</div>
            						<div className="absolute top-[941px] right-[96px] text-neutrals-neutral-300">Copyright © Think-AI 2023</div>
            						<img className="absolute top-[172px] left-[calc(50%_-_30px)] w-[60px] h-[67px] object-cover" alt="" src="assets/logo-vertical.svg" />
            						</div>);
          					};
          					
          					export default RegisterPage;