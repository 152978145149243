import { useState, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import ExportModalTables from "../../components/ExportModalTables";
import PortalPopup from "../../components/PortalPopup";
import LogoutConfirmation from "../../components/LogoutConfirmation";
import Breadcrumb from '../../components/Breadcrumb';
import NavigationPanelLawyer from '../../components/NavigationPanelLawyer';

const InvoicePreviewPage = () => {
    const navigate = useNavigate();
  	const [isExportModalTablesOpen, setExportModalTablesOpen] = useState(false);
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	
  	const openExportModalTables = useCallback(() => {
    		setExportModalTablesOpen(true);
  	}, []);
  	
  	const closeExportModalTables = useCallback(() => {
    		setExportModalTablesOpen(false);
  	}, []);
  	
  	
  	const onIconsV2Click = useCallback(() => {
    		// Add your code here
  	}, []);
  	
  	
  	const onBillingTextClick = useCallback(() => {
    		// Add your code here
  	}, []);
  	
  	
  	const onDebtCollectionNavContainer1Click = useCallback(() => {
    		// Add your code here
  	}, []);
  	
  	
  	const openLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(true);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);

	  const navItems = [
		{ text: "Dashboard", bgColor: "bg-neutrals-neutral-50", textColor: "text-neutrals-neutral-300", hoverBgColor: "bg-neutrals-neutral-50", hoverTextColor: "text-primary-primary-500", path: "" },
		{ text: "Legal proceedings & billing", bgColor: "bg-primary-primary-50", textColor: "text-neutrals-neutral-900", hoverBgColor: "bg-primary-primary-50", hoverTextColor: "text-neutrals-neutral-900", path: "" },
		{ text: "Pre-LBA checks", bgColor: "bg-neutrals-neutral-50", textColor: "text-neutrals-neutral-300", hoverBgColor: "bg-neutrals-neutral-50", hoverTextColor: "text-primary-primary-500", path: "/prelbacheck" },
		{ text: "Client-debtor interaction", bgColor: "bg-neutrals-neutral-50", textColor: "text-neutrals-neutral-300", hoverBgColor: "bg-neutrals-neutral-50", hoverTextColor: "text-primary-primary-500", path: "/cdi1" },
	];

	const breadcrumb = [
		{ label: "", path: "", icon: "assets/home.svg", active: true },
		{ label: "Invoice generator", path: "/invoice", icon: "", active: false },
		{ label: "Invoice preview", path: "", icon: "", active: true },
	];
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1444px] overflow-hidden text-left text-sm text-neutrals-neutral-300 font-open-sans-ff-body-strong">
      			<div className="absolute top-[1361px] right-[96px]">Copyright © Think-AI 2023</div>
      			<div className="absolute top-[179px] left-[96px] box-border w-[1248px] flex flex-row items-start justify-between pt-0 px-0 pb-6 text-9xl text-neutrals-neutral-900 border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<b className="relative">Invoice preview</b>
          					<div className="relative text-sm text-neutrals-neutral-300">Generate/preview your invoice for your clients.</div>
        				</div>
        				<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 cursor-pointer text-sm border-[1px] border-solid border-neutrals-neutral-100" onClick={openExportModalTables}>
          					<div className="flex flex-row items-center justify-center gap-[6px]">
            						<img className="w-5 relative h-5" alt="" src="assets/export.svg" />
            						<div className="relative font-semibold">Export</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[290px] left-[96px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border w-[1248px] flex flex-col items-start justify-start p-6 gap-[64px] text-base border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-col items-start justify-start gap-[64px] text-right">
          					<div className="self-stretch flex flex-row items-center justify-between">
            						<img className="w-[118px] relative h-[41px] object-cover" alt="" src="assets/dummy-law-firm.png" />
            						<div className="flex flex-col items-end justify-start gap-[6px]">
              							<div className="relative">Invoice no.</div>
              							<div className="relative font-semibold text-neutrals-neutral-900">#123456</div>
            						</div>
          					</div>
          					<div className="self-stretch h-[268px] flex flex-col items-start justify-between text-left text-neutrals-neutral-900">
            						<div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-6 border-b-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">Summary</div>
            						</div>
            						<div className="self-stretch flex flex-row items-start justify-between text-neutrals-neutral-300">
              							<div className="flex flex-row items-start justify-start gap-[24px]">
                								<div className="flex flex-col items-start justify-start gap-[24px]">
                  									<div className="relative">Company information</div>
                  									<div className="flex flex-col items-start justify-start gap-[10px]">
                    										<div className="relative font-semibold text-neutrals-neutral-900">Paras Law Firm</div>
                    										<div className="relative">
                      											<p className="m-0">Unit 24, Basepoint Enterprise Centre,</p>
                      											<p className="m-0">Steven’s Road,</p>
                      											<p className="m-0">Southampton</p>
                      											<p className="m-0">SO14 5FF</p>
                    										</div>
                    										<div className="relative">0800 433 2316</div>
                  									</div>
                								</div>
                								<div className="flex flex-col items-start justify-start gap-[24px]">
                  									<div className="relative">Bill to</div>
                  									<div className="flex flex-col items-start justify-start gap-[10px]">
                    										<div className="relative font-semibold text-neutrals-neutral-900">Paras Law Firm</div>
                    										<div className="relative">
                      											<p className="m-0">Unit 24, Basepoint Enterprise Centre,</p>
                      											<p className="m-0">Steven’s Road,</p>
                      											<p className="m-0">Southampton</p>
                      											<p className="m-0">SO14 5FF</p>
                    										</div>
                    										<div className="relative">0800 433 2316</div>
                  									</div>
                								</div>
              							</div>
              							<div className="flex flex-col items-end justify-start gap-[24px] text-right">
                								<div className="relative">
                  									<span>{`Date issued: `}</span>
                  									<span className="font-semibold text-neutrals-neutral-900">16 Jan, 2024</span>
                								</div>
                								<div className="relative">
                  									<span>{`Billing period: `}</span>
                  									<span className="font-semibold text-neutrals-neutral-900">6 Jan, 2024 - 12 Jan, 2024</span>
                								</div>
              							</div>
            						</div>
          					</div>
        				</div>
        				<div className="self-stretch rounded-3xs h-[347px] flex flex-col items-end justify-between text-sm text-neutrals-neutral-900">
          					<div className="self-stretch rounded-tl-3xs rounded-tr-none rounded-b-none flex flex-row items-start justify-between">
            						<div className="w-[471px] flex flex-col items-start justify-start">
              							<div className="self-stretch rounded-tl-3xs rounded-tr-none rounded-b-none bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
                								<div className="relative font-semibold">Legal services</div>
              							</div>
              							<div className="self-stretch flex flex-col items-start justify-start">
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                  									<div className="flex-1 relative">Negotiation</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                  									<div className="flex-1 relative">Legal consultation</div>
                								</div>
                								<div className="self-stretch rounded-t-none rounded-br-none rounded-bl-3xs bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                  									<div className="flex-1 relative">Compliance with debt collection laws</div>
                								</div>
              							</div>
            						</div>
            						<div className="w-[282px] flex flex-col items-start justify-start">
              							<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                								<div className="relative font-semibold">Rate</div>
              							</div>
              							<div className="self-stretch flex flex-col items-start justify-start">
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="flex-1 relative">£5,000</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="flex-1 relative">£3,200</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="flex-1 relative">£4,000</div>
                								</div>
              							</div>
            						</div>
            						<div className="w-[282px] flex flex-col items-start justify-start">
              							<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                								<div className="relative font-semibold">Hours</div>
              							</div>
              							<div className="self-stretch flex flex-col items-start justify-start">
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="flex-1 relative">4</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="flex-1 relative">2</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="flex-1 relative">5</div>
                								</div>
              							</div>
            						</div>
            						<div className="w-[212px] flex flex-col items-start justify-start">
              							<div className="w-[212px] rounded-tl-none rounded-tr-3xs rounded-b-none bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-b-[1px]">
                								<div className="flex-1 relative font-semibold">Total amount</div>
              							</div>
              							<div className="w-[212px] flex flex-col items-start justify-start">
                								<div className="w-[212px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="flex-1 relative">£20,000</div>
                								</div>
                								<div className="w-[212px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="flex-1 relative">£6,400</div>
                								</div>
                								<div className="w-[212px] rounded-t-none rounded-br-3xs rounded-bl-none bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="flex-1 relative">£20,000</div>
                								</div>
              							</div>
            						</div>
          					</div>
          					<div className="flex flex-col items-start justify-start gap-[24px] text-right text-base">
            						<div className="w-[294px] flex flex-row items-start justify-between text-left">
              							<div className="relative font-semibold">Subtotal</div>
              							<div className="relative font-semibold text-right">£46,400</div>
            						</div>
            						<div className="w-[294px] flex flex-row items-center justify-between">
              							<div className="relative font-semibold">Tax</div>
              							<div className="relative font-semibold text-left">Free</div>
            						</div>
            						<div className="w-[294px] flex flex-row items-center justify-between">
              							<div className="relative font-semibold">Total</div>
              							<div className="relative font-semibold text-left">£46,400</div>
            						</div>
          					</div>
        				</div>
        				<div className="self-stretch rounded-3xs bg-column-bg flex flex-col items-start justify-start p-6 gap-[10px]">
          					<div className="relative">Notes</div>
          					<div className="relative font-semibold text-neutrals-neutral-900">Payment be made through PayPal. Thank you for your business!</div>
        				</div>
      			</div>

      			{/* <div className="absolute top-[128px] left-[96px] flex flex-row items-center justify-start gap-[6px]">
        				<img className="w-5 relative h-5 cursor-pointer" alt="" src="icons-v2.svg" onClick={onIconsV2Click} />
        				<img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src="chevron_right.svg" />
        				<div className="flex flex-row items-center justify-center py-1 px-2 gap-[10px]">
          					<div className="relative font-semibold cursor-pointer" onClick={onBillingTextClick}>Invoice generator</div>
          					<img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src="chevron_right.svg" />
          					<div className="relative font-semibold text-primary-primary-500">Invoice preview</div>
        				</div>
      			</div> */}

				{/* Navigation Panel */}
				<NavigationPanelLawyer navItems={navItems}/>
      			
				{/* Breadcrumb Navigation */}
				<Breadcrumb breadcrumbs={breadcrumb} position="top-[24px] left-[90px]" />


    		</div>
    		{isExportModalTablesOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeExportModalTables}
        				>
        				<ExportModalTables onClose={closeExportModalTables} />
      			</PortalPopup>
    		)}
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}</>);
};

export default InvoicePreviewPage;