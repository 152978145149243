import { useState, useCallback } from "react";
import CTAs from "./CTAs";

const ClaimIdFilter = ({onClose}) => {
    return (
          <div className="relative shadow-[0px_10px_60px_rgba(0,_0,_0,_0.1)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start max-w-full max-h-full overflow-auto text-left text-sm text-neutrals-neutral-900 font-open-sans-ff-meta-link border-[1px] border-solid border-neutrals-neutral-100">
                <div className="self-stretch flex flex-col items-start justify-start">
                      <div className="flex flex-col items-start justify-start gap-[32px]">
                            <div className="flex flex-col items-start justify-start pt-6 px-4 pb-0 gap-[24px]">
                                  <div className="flex flex-row items-start justify-start">
                                        <div className="relative font-semibold">Claim ID range</div>
                                  </div>
                                  <div className="w-[400px] relative h-[52px] text-base text-neutrals-neutral-300">
                                        <div className="absolute w-full top-[calc(50%_+_4px)] right-[0%] left-[0%] flex flex-row items-center justify-between">
                                              <div className="relative">0</div>
                                              <div className="relative">45</div>
                                              <div className="w-[5px] relative font-semibold font-inter-family-font-body-strong text-right hidden">.</div>
                                              <div className="relative text-right">100</div>
                                        </div>
                                        <img className="absolute h-[46.15%] w-full top-[0%] right-[0%] bottom-[53.85%] left-[0%] max-w-full overflow-hidden max-h-full" alt="" src="Group 152.svg" />
                                  </div>
                            </div>
                            <div className="flex flex-row items-start justify-start pt-4 px-4 pb-6 gap-[24px]">
                              <CTAs
                              type="Ghostbtn"
                              text="Cancel"
                              textColor="text-neutrals-neutral-900"
                              bgColor="bg-neutrals-neutral-50"
                              borderColor="border-neutrals-neutral-100"
                              width="188px"
                              position="relative"
                              onClick={onClose}
                              />

                              <CTAs
                              type="Primarybtn"
                              text="Apply"
                              textColor="text-neutrals-neutral-50"
                              bgColor="bg-primary-primary-500"
                              borderColor="border-primary-primary-500"
                              width="188px"
                              position="relative"
                              onClick={onClose}
                              />
                            </div>
                      </div>
                </div>
          </div>);
};

export default ClaimIdFilter;