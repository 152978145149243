import { useState, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import AddNewInstructionsPage from "../../components/AddNewInstructionsPage";
import NavigationPanelLawyer from '../../components/NavigationPanelLawyer';
import PortalPopup from "../../components/PortalPopup";
import ExportModalImage from "../../components/ExportModalImage";
import LogoutConfirmation from "../../components/LogoutConfirmation";

const CDI_1 = () => {
    const navigate = useNavigate();
  	const [isAddNewInstructionsPage1Open, setAddNewInstructionsPage1Open] = useState(false);
  	const [isExportModalImageOpen, setExportModalImageOpen] = useState(false);
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	
  	const onClickHandlingDefences = useCallback(() => {
        navigate('/cdi2');
  	}, [navigate]);
  	
  	
  	const openAddNewInstructionsPage1 = useCallback(() => {
    		setAddNewInstructionsPage1Open(true);
  	}, []);
  	
  	const closeAddNewInstructionsPage1 = useCallback(() => {
    		setAddNewInstructionsPage1Open(false);
  	}, []);
  	
  	
  	const openExportModalImage = useCallback(() => {
    		setExportModalImageOpen(true);
  	}, []);
  	
  	const closeExportModalImage = useCallback(() => {
    		setExportModalImageOpen(false);
  	}, []);
  	
  	
  	const onCTAsContainerClick = useCallback(() => {
        navigate('/cdi3');
  	}, [navigate]);
  	
  	
  	const onDebtCollectionNavContainer3Click = useCallback(() => {
    		// Add your code here
  	}, []);
  	
  	
  	const openLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(true);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);

	const navItems = [
		{ text: "Dashboard", bgColor: "bg-neutrals-neutral-50", textColor: "text-neutrals-neutral-300", hoverBgColor: "bg-neutrals-neutral-50", hoverTextColor: "text-primary-primary-500", path: "" },
		{ text: "Legal proceedings & billing", bgColor: "bg-neutrals-neutral-50", textColor: "text-neutrals-neutral-300", hoverBgColor: "bg-neutrals-neutral-50", hoverTextColor: "text-primary-primary-500", path: "/legal_1" },
		{ text: "Pre-LBA checks", bgColor: "bg-neutrals-neutral-50", textColor: "text-neutrals-neutral-300", hoverBgColor: "bg-neutrals-neutral-50", hoverTextColor: "text-primary-primary-500", path: "/prelbacheck" },
		{ text: "Client-debtor interaction", bgColor: "bg-primary-primary-50", textColor: "text-neutrals-neutral-900", hoverBgColor: "bg-primary-primary-50", hoverTextColor: "text-neutrals-neutral-900", path: "/cdi1" },
	];
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1962px] overflow-hidden text-left text-base text-neutrals-neutral-900 font-open-sans-ff-body-strong">
      			<div className="absolute top-[1879px] right-[96px] text-sm text-neutrals-neutral-300">Copyright © Think-AI 2023</div>
      			<b className="absolute top-[128px] left-[96px] text-9xl">Client-debtor interaction</b>
      			<div className="absolute top-[172px] left-[96px] text-neutrals-neutral-300">{`Get all your information for client & debtor over here.`}</div>
      			<div className="absolute top-[218px] left-[96px] flex flex-row items-start justify-start gap-[24px] text-primary-primary-500">
        				<div className="bg-neutrals-neutral-50 box-border h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 border-b-[2px] border-solid border-primary-primary-500">
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Client instructions</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 box-border cursor-pointer text-neutrals-neutral-300" onClick={onClickHandlingDefences}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Handling defences</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[447px] left-[96px] flex flex-row items-start justify-start pt-0 px-0 pb-6 gap-[712px] text-lg border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Instruction list</div>
          					<div className="relative text-base text-neutrals-neutral-300">List of all the pending and completed instructions.</div>
        				</div>
        				<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 cursor-pointer text-sm border-[1px] border-solid border-neutrals-neutral-100" onClick={openAddNewInstructionsPage1}>
          					<div className="flex flex-row items-center justify-center gap-[6px]">
            						<img className="w-5 relative h-5" alt="" src="assets/add.svg" />
            						<div className="relative font-semibold">New instruction</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[548px] left-[96px] flex flex-row items-start justify-start gap-[739px] text-sm text-neutrals-neutral-300">
        				<div className="w-[294px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-start py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="flex flex-row items-center justify-start gap-[10px]">
            						<img className="w-5 relative h-5" alt="" src="assets/search.svg" />
            						<div className="relative">Search</div>
          					</div>
        				</div>
        				<div className="flex flex-row items-start justify-start gap-[10px] text-neutrals-neutral-900">
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="flex flex-row items-center justify-center">
              							<div className="relative font-semibold">Sort by: Date</div>
            						</div>
          					</div>
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="flex flex-row items-center justify-center gap-[6px]">
              							<img className="w-5 relative h-5" alt="" src="assets/filter.svg" />
              							<div className="relative font-semibold">Filter</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[283px] left-[96px] flex flex-row items-start justify-start gap-[24px] text-neutrals-neutral-300">
        				<div className="w-[294px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start p-6 gap-[10px] border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="self-stretch flex flex-row items-center justify-between">
            						<div className="relative">Total instructions</div>
            						<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
          					</div>
          					<b className="relative text-13xl text-neutrals-neutral-900">15</b>
        				</div>
        				<div className="w-[294px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start p-6 gap-[10px] border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="self-stretch flex flex-row items-center justify-between">
            						<div className="relative">Pending instructions</div>
            						<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
          					</div>
          					<b className="relative text-13xl text-neutrals-neutral-900">7</b>
        				</div>
        				<div className="w-[294px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start p-6 gap-[10px] border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="self-stretch flex flex-row items-center justify-between">
            						<div className="relative">Completed instructions</div>
            						<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
          					</div>
          					<b className="relative text-13xl text-neutrals-neutral-900">8</b>
        				</div>
      			</div>
      			<div className="absolute top-[1411px] left-[96px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-[404px] flex flex-col items-start justify-start p-6 gap-[24px] text-lg border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="w-[404px] box-border flex flex-row items-center justify-between pt-0 px-0 pb-5 border-b-[1px] border-solid border-neutrals-neutral-100">
          					<div className="relative font-semibold">Distribution of instruction</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
        				</div>
        				<div className="flex flex-row items-start justify-start gap-[24px] text-sm">
          					<img className="w-[280px] relative h-[280px]" alt="" src="Group 224.svg" />
          					<div className="flex flex-col items-start justify-start gap-[6px]">
            						<div className="flex flex-row items-center justify-start gap-[6px]">
              							<div className="w-2 relative rounded-[50%] bg-primary-primary-700 h-2" />
              							<div className="relative">High</div>
            						</div>
            						<div className="flex flex-row items-center justify-start gap-[6px]">
              							<div className="w-2 relative rounded-[50%] bg-primary-primary-500 h-2" />
              							<div className="relative">Medium</div>
            						</div>
            						<div className="flex flex-row items-center justify-start gap-[6px]">
              							<div className="w-2 relative rounded-[50%] bg-primary-primary-50 h-2" />
              							<div className="relative">Low</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[1292px] left-[96px] box-border w-[1248px] flex flex-col items-start justify-start pt-0 px-0 pb-6 gap-[10px] text-lg border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-col items-start justify-start gap-[6px] z-[0]">
          					<div className="relative font-semibold">Data visualisation</div>
          					<div className="relative text-base text-neutrals-neutral-300">Get useful insights.</div>
        				</div>
        				<div className="!m-[0] absolute top-[0px] left-[1094px] rounded-3xs bg-primary-primary-50 flex flex-row items-center justify-start py-2.5 px-3.5 gap-[6px] z-[1] text-sm text-primary-primary-500">
          					<img className="w-[23px] relative h-[22.6px]" alt="" src="assets/AI.svg" />
          					<div className="relative font-semibold">Powered by AI</div>
        				</div>
      			</div>
      			<div className="absolute top-[1411px] left-[572px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-end justify-start p-6 text-sm border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-col items-start justify-start gap-[24px]">
          					<div className="w-[724px] flex flex-row items-center justify-between text-lg">
            						<div className="relative font-semibold">Distribution of instructions by status</div>
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 cursor-pointer text-sm border-[1px] border-solid border-neutrals-neutral-100" onClick={openExportModalImage}>
              							<div className="flex flex-row items-center justify-center gap-[6px]">
                								<img className="w-5 relative h-5" alt="" src="assets/export.svg"/>
                								<div className="relative font-semibold">Export</div>
              							</div>
            						</div>
          					</div>
          					<div className="flex flex-row items-start justify-start gap-[10px] text-neutrals-neutral-300">
            						<div className="flex flex-row items-center justify-start gap-[6px]">
              							<div className="w-2 relative rounded-[50%] bg-primary-primary-50 h-2" />
              							<div className="relative">Pending</div>
            						</div>
            						<div className="flex flex-row items-center justify-start gap-[6px]">
              							<div className="w-2 relative rounded-[50%] bg-primary-primary-500 h-2" />
              							<div className="relative">Completed</div>
            						</div>
            						<div className="flex flex-row items-center justify-start gap-[6px]">
              							<div className="w-2 relative rounded-[50%] bg-primary-primary-700 h-2" />
              							<div className="relative">In progress</div>
            						</div>
          					</div>
          					<div className="w-[724px] flex flex-row items-center justify-start gap-[10px] text-center">
            						<div className="flex flex-row items-center justify-start gap-[10px]">
              							<div className="relative font-semibold [transform:_rotate(-90deg)]">Percentage of instructions</div>
              							<div className="h-[201px] flex flex-col items-end justify-start gap-[11px] text-left text-neutrals-neutral-300">
                								<div className="relative">60</div>
                								<div className="relative">50</div>
                								<div className="relative">40</div>
                								<div className="relative">30</div>
                								<div className="relative">20</div>
                								<div className="relative">10</div>
                								<div className="relative">0</div>
              							</div>
            						</div>
            						<div className="flex-1 flex flex-col items-start justify-start relative gap-[10px]">
              							<div className="self-stretch flex flex-col items-start justify-start gap-[30px] z-[0]">
                								<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                								<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                								<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                								<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                								<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                								<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                								<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
              							</div>
              							<div className="w-[589px] !m-[0] absolute top-[44.5px] left-[47px] flex flex-row items-end justify-between z-[1]">
                								<div className="w-8 relative h-[115px]">
                  									<div className="absolute top-[0px] left-[0px] rounded-t-3xs rounded-b-none bg-primary-primary-50 w-8 h-[115px]" />
                								</div>
                								<div className="w-8 relative h-[135px]">
                  									<div className="absolute top-[0px] left-[0px] rounded-t-3xs rounded-b-none bg-primary-primary-500 w-8 h-[135px]" />
                								</div>
                								<div className="w-8 relative h-[85px]">
                  									<div className="absolute top-[0px] left-[0px] rounded-t-3xs rounded-b-none bg-primary-primary-700 w-8 h-[85px]" />
                								</div>
              							</div>
            						</div>
          					</div>
        				</div>
        				<div className="flex flex-col items-center justify-start gap-[10px] text-center text-neutrals-neutral-300">
          					<div className="flex flex-row items-start justify-start gap-[211px]">
            						<div className="relative">Pending</div>
            						<div className="relative">Completed</div>
            						<div className="relative">In progress</div>
          					</div>
          					<div className="relative font-semibold text-neutrals-neutral-900">Status</div>
        				</div>
      			</div>
      			<div className="absolute top-[612px] left-[96px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="w-[400px] rounded-t-3xs rounded-b-none bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start p-6 gap-[24px]">
          					<div className="self-stretch flex flex-row items-start justify-between">
            						<div className="flex-1 flex flex-col items-start justify-start gap-[10px]">
              							<div className="self-stretch relative font-semibold">Review payment history</div>
              							<div className="self-stretch relative text-neutrals-neutral-300">Review debtor's recent payment history and provide recommendations.</div>
              							</div>
              							<div className="rounded-xl bg-semantics-moderate-moderate-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm text-semantics-moderate-moderate-500 border-[1px] border-solid border-semantics-moderate-moderate-500">
                								<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
                								<div className="relative font-semibold">Pending</div>
              							</div>
            						</div>
            						<div className="self-stretch flex flex-col items-start justify-start gap-[10px] text-neutrals-neutral-300">
              							<div className="flex flex-row items-center justify-start gap-[6px]">
                								<img className="w-5 relative h-5" alt="" src="assets/calendar.svg" />
                								<div className="relative">02/02/24</div>
              							</div>
              							<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm text-neutrals-neutral-900 border-[1px] border-solid border-neutrals-neutral-100">
                								<div className="w-1.5 relative rounded-[50%] bg-semantics-high-high-500 h-1.5" />
                								<div className="relative font-semibold">High</div>
              							</div>
            						</div>
          					</div>
          					<div className="w-[400px] box-border flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 cursor-pointer border-[1px] border-solid border-neutrals-neutral-100" onClick={onCTAsContainerClick}>
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">View details</div>
              							</div>
            						</div>
          					</div>
        				</div>
        				<div className="absolute top-[932px] left-[520px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="w-[400px] rounded-t-3xs rounded-b-none bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start p-6 gap-[24px]">
            						<div className="self-stretch flex flex-row items-start justify-between">
              							<div className="flex-1 flex flex-col items-start justify-start gap-[10px]">
                								<div className="self-stretch relative font-semibold">Review payment history</div>
                								<div className="self-stretch relative text-neutrals-neutral-300">Review debtor's recent payment history and provide recommendations.</div>
                								</div>
                								<div className="rounded-xl bg-semantics-moderate-moderate-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm text-semantics-moderate-moderate-500 border-[1px] border-solid border-semantics-moderate-moderate-500">
                  									<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
                  									<div className="relative font-semibold">Pending</div>
                								</div>
              							</div>
              							<div className="self-stretch flex flex-col items-start justify-start gap-[10px] text-neutrals-neutral-300">
                								<div className="flex flex-row items-center justify-start gap-[6px]">
                  									<img className="w-5 relative h-5" alt="" src="assets/calendar.svg" />
                  									<div className="relative">02/02/24</div>
                								</div>
                								<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm text-neutrals-neutral-900 border-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="w-1.5 relative rounded-[50%] bg-semantics-high-high-500 h-1.5" />
                  									<div className="relative font-semibold">High</div>
                								</div>
              							</div>
            						</div>
            						<div className="w-[400px] box-border flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
              							<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
                								<div className="flex flex-row items-center justify-center">
                  									<div className="relative font-semibold">View details</div>
                								</div>
              							</div>
            						</div>
          					</div>
          					<div className="absolute top-[612px] left-[520px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="w-[400px] rounded-t-3xs rounded-b-none bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start p-6 gap-[24px]">
              							<div className="self-stretch flex flex-row items-start justify-between">
                								<div className="flex-1 flex flex-col items-start justify-start gap-[10px]">
                  									<div className="self-stretch relative font-semibold">Review payment history</div>
                  									<div className="self-stretch relative text-neutrals-neutral-300">Draft a follow-up email to the debtor regarding the outstanding payment.</div>
                								</div>
                								<div className="rounded-xl bg-semantics-secure-blue-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm text-semantics-secure-blue-500 border-[1px] border-solid border-semantics-secure-blue-500">
                  									<div className="w-1.5 relative rounded-[50%] bg-semantics-secure-blue-500 h-1.5" />
                  									<div className="relative font-semibold">Completed</div>
                								</div>
              							</div>
              							<div className="self-stretch flex flex-col items-start justify-start gap-[10px] text-neutrals-neutral-300">
                								<div className="flex flex-row items-center justify-start gap-[6px]">
                  									<img className="w-5 relative h-5" alt="" src="assets/calendar.svg" />
                  									<div className="relative">05/02/24</div>
                								</div>
                								<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm text-neutrals-neutral-900 border-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
                  									<div className="relative font-semibold">Medium</div>
                								</div>
              							</div>
            						</div>
            						<div className="w-[400px] box-border flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
              							<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
                								<div className="flex flex-row items-center justify-center">
                  									<div className="relative font-semibold">View details</div>
                								</div>
              							</div>
            						</div>
          					</div>
          					<div className="absolute top-[612px] left-[944px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="w-[400px] rounded-t-3xs rounded-b-none bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start p-6 gap-[24px]">
              							<div className="self-stretch flex flex-row items-start justify-between">
                								<div className="flex-1 flex flex-col items-start justify-start gap-[10px]">
                  									<div className="self-stretch relative font-semibold">Review payment history</div>
                  									<div className="self-stretch relative text-neutrals-neutral-300">Review debtor's recent payment history and provide recommendations.</div>
                  									</div>
                  									<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm text-semantics-low-low-500 border-[1px] border-solid border-semantics-low-low-500">
                    										<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                    										<div className="relative font-semibold">In progress</div>
                  									</div>
                								</div>
                								<div className="self-stretch flex flex-col items-start justify-start gap-[10px] text-neutrals-neutral-300">
                  									<div className="flex flex-row items-center justify-start gap-[6px]">
                    										<img className="w-5 relative h-5" alt="" src="assets/calendar.svg" />
                    										<div className="relative">05/02/24</div>
                  									</div>
                  									<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm text-neutrals-neutral-900 border-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
                    										<div className="relative font-semibold">Medium</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[400px] box-border flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
                								<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="flex flex-row items-center justify-center">
                    										<div className="relative font-semibold">View details</div>
                  									</div>
                								</div>
              							</div>
            						</div>
            						<div className="absolute top-[932px] left-[944px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="w-[400px] rounded-t-3xs rounded-b-none bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start p-6 gap-[24px]">
                								<div className="self-stretch flex flex-row items-start justify-between">
                  									<div className="flex-1 flex flex-col items-start justify-start gap-[10px]">
                    										<div className="self-stretch relative font-semibold">Review payment history</div>
                    										<div className="self-stretch relative text-neutrals-neutral-300">Draft a follow-up email to the debtor regarding the outstanding payment.</div>
                  									</div>
                  									<div className="rounded-xl bg-semantics-secure-blue-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm text-semantics-secure-blue-500 border-[1px] border-solid border-semantics-secure-blue-500">
                    										<div className="w-1.5 relative rounded-[50%] bg-semantics-secure-blue-500 h-1.5" />
                    										<div className="relative font-semibold">Completed</div>
                  									</div>
                								</div>
                								<div className="self-stretch flex flex-col items-start justify-start gap-[10px] text-neutrals-neutral-300">
                  									<div className="flex flex-row items-center justify-start gap-[6px]">
                    										<img className="w-5 relative h-5" alt="" src="assets/calendar.svg" />
                    										<div className="relative">05/02/24</div>
                  									</div>
                  									<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm text-neutrals-neutral-900 border-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
                    										<div className="relative font-semibold">Medium</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[400px] box-border flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
                								<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="flex flex-row items-center justify-center">
                    										<div className="relative font-semibold">View details</div>
                  									</div>
                								</div>
              							</div>
            						</div>
            						<div className="absolute top-[932px] left-[96px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="w-[400px] rounded-t-3xs rounded-b-none bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start p-6 gap-[24px]">
                								<div className="self-stretch flex flex-row items-start justify-between">
                  									<div className="flex-1 flex flex-col items-start justify-start gap-[10px]">
                    										<div className="self-stretch relative font-semibold">Review payment history</div>
                    										<div className="self-stretch relative text-neutrals-neutral-300">Review debtor's recent payment history and provide recommendations.</div>
                    										</div>
                    										<div className="rounded-xl bg-semantics-moderate-moderate-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm text-semantics-moderate-moderate-500 border-[1px] border-solid border-semantics-moderate-moderate-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
                      											<div className="relative font-semibold">Pending</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch flex flex-col items-start justify-start gap-[10px] text-neutrals-neutral-300">
                    										<div className="flex flex-row items-center justify-start gap-[6px]">
                      											<img className="w-5 relative h-5" alt="" src="assets/calendar.svg" />
                      											<div className="relative">02/02/24</div>
                    										</div>
                    										<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm text-neutrals-neutral-900 border-[1px] border-solid border-neutrals-neutral-100">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-high-high-500 h-1.5" />
                      											<div className="relative font-semibold">High</div>
                    										</div>
                  									</div>
                								</div>
                								<div className="w-[400px] box-border flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="flex flex-row items-center justify-center">
                      											<div className="relative font-semibold">View details</div>
                    										</div>
                  									</div>
                								</div>
              							</div>

										{/* Navigation Panel */}
										<NavigationPanelLawyer navItems={navItems}/>
              							
            						</div>
            						{isAddNewInstructionsPage1Open && (
              							<PortalPopup
                								overlayColor="rgba(113, 113, 113, 0.3)"
                								placement="Centered"
                								
                								
                								
                								
                								
                								onOutsideClick={closeAddNewInstructionsPage1}
                								>
                								<AddNewInstructionsPage onClose={closeAddNewInstructionsPage1} />
              							</PortalPopup>
            						)}
            						{isExportModalImageOpen && (
              							<PortalPopup
                								overlayColor="rgba(113, 113, 113, 0.3)"
                								placement="Centered"
                								
                								
                								
                								
                								
                								onOutsideClick={closeExportModalImage}
                								>
                								<ExportModalImage onClose={closeExportModalImage} />
              							</PortalPopup>
            						)}
            						{isLogoutConfirmationOpen && (
              							<PortalPopup
                								overlayColor="rgba(113, 113, 113, 0.3)"
                								placement="Centered"
                								
                								
                								
                								
                								
                								onOutsideClick={closeLogoutConfirmation}
                								>
                								<LogoutConfirmation onClose={closeLogoutConfirmation} />
              							</PortalPopup>
            						)}</>);
            						};
            						
            						export default CDI_1;