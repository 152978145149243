

const ToastMsg = () => {
    return (
          <div className="relative shadow-[0px_10px_60px_rgba(0,_0,_0,_0.1)] rounded-[100px] bg-neutrals-neutral-50 box-border flex flex-row items-center justify-start py-1.5 px-2.5 gap-[6px] max-w-full max-h-full overflow-auto text-left text-sm text-neutrals-neutral-900 font-open-sans-ff-body-strong border-[1px] border-solid border-neutrals-neutral-100">
                <img className="w-5 relative h-5" alt="" src="assets/circle.svg"/>
                <div className="relative">Your details have been saved successfully.</div>
          </div>);
};

export default ToastMsg;