import { useState, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import LogoutConfirmation from "../../components/LogoutConfirmation";
import PortalPopup from "../../components/PortalPopup";
import NavigationPanel from '../../components/NavigationPanel';
import Breadcrumb from '../../components/Breadcrumb';
import Fqs from '../../components/Fqs';

const SettingsHelpSupportPage = () => {
    const navigate = useNavigate();
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);

  	const onClickGeneral = useCallback(() => {
        navigate('/setting-general');
  	}, [navigate]);
  	
  	
  	const onClickPreferences = useCallback(() => {
        navigate('/setting-preferences');
  	}, [navigate]);
  	
  	
  	const onClickLBAConfig = useCallback(() => {
        navigate('/setting-lbaconfig');
  	}, [navigate]);
  	
  	
  	const onClickLegalProceedings = useCallback(() => {
        navigate('/setting-legalproceedings');
  	}, [navigate]);
  	
  	
  	const onClickAIIntegration = useCallback(() => {
        navigate('/setting-aiintegration');
  	}, [navigate]);
  	
  	
  	const onClickSecurity = useCallback(() => {
        navigate('/setting-security');
  	}, [navigate]);
  	
  	
  	const onClickBilling = useCallback(() => {
        navigate('/setting-billing');
  	}, [navigate]);
  	
  	
  	const onClickDataManagement = useCallback(() => {
		navigate('/setting-datamanagement');
  	}, [navigate]);
  	
  	
  	const onClickAccount = useCallback(() => {
		navigate('/setting-account');
  	}, [navigate]);
  	
  	const openLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(true);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);

	const breadcrumb = [
		{ label: "", path: "/postlba2", icon: "assets/Home.svg", active: true },
		{ label: "Settings", path: "", icon: "", active: false },
		{ label: "Help & support", path: "", icon: "", active: true },
	];

	// const faqData1 = [
	// 	{
	// 	question: "What is the difference between LBA and LPD?",
	// 	answer: "The main distinction between Letter Before Action (LBA) and Late Payment Demand (LPD) lies in their purpose and timing. The LBA is typically the initial step in the debt collection process, notifying the debtor of an outstanding amount and providing a grace period for payment. On the other hand, the LPD serves to clarify additional costs and consequences if the debtor fails to pay after receiving the LBA. It is a follow-up communication that emphasizes the urgency of payment and outlines specific repercussions for delayed payment. Both letters play crucial roles in the debt recovery process, with the LBA initiating the communication and the LPD reinforcing the consequences of non-payment."
	// 	},

	// 	{
	// 	question: "What will be the cost implications?",
	// 	answer: "The main distinction between Letter Before Action (LBA) and Late Payment Demand (LPD) lies in their purpose and timing. The LBA is typically the initial step in the debt collection process, notifying the debtor of an outstanding amount and providing a grace period for payment. On the other hand, the LPD serves to clarify additional costs and consequences if the debtor fails to pay after receiving the LBA. It is a follow-up communication that emphasizes the urgency of payment and outlines specific repercussions for delayed payment. Both letters play crucial roles in the debt recovery process, with the LBA initiating the communication and the LPD reinforcing the consequences of non-payment."
	// 	},
	// ];
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1115px] overflow-hidden text-left text-sm text-neutrals-neutral-300 font-open-sans-font-family-meta">
      			<div className="absolute top-[293px] left-[96px] w-[188px] flex flex-col items-start justify-start gap-[6px] text-base">
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickGeneral}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">General</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickPreferences}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Preferences</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickLBAConfig}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">LBA configuration</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickLegalProceedings}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Legal proceedings</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickAIIntegration}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">AI integration</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickSecurity}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Security</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickBilling}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Billing</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 box-border h-[41px] flex flex-row items-center justify-center py-2 px-3 text-primary-primary-500 border-l-[2px] border-solid border-primary-primary-500">
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">{'Help & support'}</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickDataManagement}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Data management</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickAccount}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Account</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[1032px] right-[96px]">Copyright © Think-AI 2023</div>
      			<div className="absolute top-[179px] left-[96px] bg-neutrals-neutral-50 box-border w-[1248px] flex flex-col items-start justify-start pt-0 px-0 pb-6 gap-[6px] text-9xl text-primary-primary-900 border-b-[1px] border-solid border-neutrals-neutral-100">
        				<b className="relative">Settings</b>
        				<div className="relative text-base text-neutrals-neutral-300">Get your billing information, generate/review invoices.</div>
      			</div>
      			<div className="absolute top-[293px] left-[390px] box-border w-[954px] flex flex-row items-start justify-start pt-0 px-0 pb-6 text-lg text-neutrals-neutral-900 border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Frequently asked questions</div>
          					<div className="relative text-sm text-neutrals-neutral-300">Need something cleared up? Here are our most frequently asked questions.</div>
            						</div>
            						</div>

									{/* Navigation Panel */}
									<NavigationPanel/>
            						
									{/* Breadcrumb Navigation */}
									<Breadcrumb breadcrumbs={breadcrumb} position="top-[24px] left-[90px]"/>

            						<div className="absolute top-[391px] left-[390px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border w-[294px] flex flex-row items-center justify-start py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-start gap-[10px]">
                								<img className="w-5 relative h-5" alt="" src="assets/search.svg" />
                								<div className="relative">Search</div>
              							</div>
            						</div>

            						<div className="absolute top-[455px] left-[390px] flex flex-col items-start justify-start gap-[10px] text-lg text-neutrals-neutral-900">
										<Fqs />
              							{/* <div className="w-[954px] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-start p-6 box-border gap-[24px]">
                								<img className="w-5 relative h-5" alt="" src="assets/add-circle.svg" />
                								<div className="w-[656px] relative font-semibold inline-block shrink-0">What is the difference between LBA vs LPD?</div>
                  						</div>
                  						<div className="w-[954px] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-start p-6 box-border gap-[24px]">
                    							<img className="w-5 relative h-5" alt="" src="assets/add-circle.svg" />
                    							<div className="w-[656px] relative font-semibold inline-block shrink-0">What will be the cost implications?</div>
                      					</div>
                      					<div className="w-[954px] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-start p-6 box-border gap-[24px]">
                        						<img className="w-5 relative h-5" alt="" src="assets/add-circle.svg" />
                        						<div className="w-[656px] relative font-semibold inline-block shrink-0">What will be security measures to be taken?</div>
                          				</div>
                          				<div className="w-[954px] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-start p-6 box-border gap-[24px]">
                            					<img className="w-5 relative h-5" alt="" src="assets/add-circle.svg" />
                            					<div className="w-[656px] relative font-semibold inline-block shrink-0">What will be security measures to be taken?</div>
                              			</div>
                              			<div className="w-[954px] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-start p-6 box-border gap-[24px]">
                                				<img className="w-5 relative h-5" alt="" src="assets/add-circle.svg" />
                                				<div className="w-[656px] relative font-semibold inline-block shrink-0">How do I contact support for further assistance?</div>
                                  		</div> */}
                                  	</div>
                                  																	</div>
                                  																	{isLogoutConfirmationOpen && (
                                    																		<PortalPopup
                                      																			overlayColor="rgba(113, 113, 113, 0.3)"
                                      																			placement="Centered"
                                      																			
                                      																			
                                      																			
                                      																			
                                      																			
                                      																			onOutsideClick={closeLogoutConfirmation}
                                      																			>
                                      																			<LogoutConfirmation onClose={closeLogoutConfirmation} />
                                    																		</PortalPopup>
                                  																	)}</>);
                                																};
                                																
                                																export default SettingsHelpSupportPage;