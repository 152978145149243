import { useState, useCallback } from 'react';
import LogoutConfirmation from "../../components/LogoutConfirmation";
import PortalPopup from "../../components/PortalPopup";
import NavigationPanelLawyer from '../../components/NavigationPanelLawyer';
import { Navigate, useNavigate } from 'react-router-dom';

const PreLBAChecks = () => {
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	
  	const onDebtCollectionNavContainer2Click = useCallback(() => {
    		// Add your code here
  	}, []);
  	
  	
  	const openLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(true);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);

	const navItems = [
		{ text: "Dashboard", bgColor: "bg-neutrals-neutral-50", textColor: "text-neutrals-neutral-300", hoverBgColor: "bg-neutrals-neutral-50", hoverTextColor: "text-primary-primary-500", path: "" },
		{ text: "Legal proceedings & billing", bgColor: "bg-neutrals-neutral-50", textColor: "text-neutrals-neutral-300", hoverBgColor: "bg-neutrals-neutral-50", hoverTextColor: "text-primary-primary-500", path: "/legal_1" },
		{ text: "Pre-LBA checks", bgColor: "bg-primary-primary-50", textColor: "text-neutrals-neutral-900", hoverBgColor: "bg-primary-primary-50", hoverTextColor: "text-neutrals-neutral-900", path: "" },
		{ text: "Client-debtor interaction", bgColor: "bg-neutrals-neutral-50", textColor: "text-neutrals-neutral-300", hoverBgColor: "bg-neutrals-neutral-50", hoverTextColor: "text-primary-primary-500", path: "/cdi1" },
	];
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1470px] overflow-hidden text-left text-sm text-neutrals-neutral-900 font-open-sans-ff-body-strong">
      			<div className="absolute top-[1387px] right-[96px] text-neutrals-neutral-300">Copyright © Think-AI 2023</div>
      			<div className="absolute top-[343px] left-[96px] flex flex-col items-start justify-start gap-[10px]">
        				<div className="flex flex-row items-center justify-start gap-[6px]">
          					<div className="relative font-semibold">Case number</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg" />
        				</div>
        				<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border overflow-hidden flex flex-row items-center justify-start p-3 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="flex-1 relative">Enter your case number</div>
        				</div>
      			</div>
      			<div className="absolute top-[242px] left-[96px] flex flex-row items-start justify-start pt-0 px-0 pb-6 gap-[872px] text-lg border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Conflict check</div>
          					<div className="relative text-base text-neutrals-neutral-300">Verify the case number to avoid conflicts.</div>
        				</div>
        				<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 text-sm border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Check</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[128px] left-[96px] bg-neutrals-neutral-50 box-border w-[1248px] flex flex-col items-start justify-start pt-0 px-0 pb-6 text-9xl border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-start justify-start">
          					<div className="flex flex-col items-start justify-start gap-[6px]">
            						<b className="relative">Pre-LBA checks</b>
            						<div className="relative text-base text-neutrals-neutral-300">Acknowledge defences and revert back.</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[1160px] left-[96px] flex flex-col items-start justify-start gap-[24px] text-lg">
        				<div className="w-[1248px] box-border flex flex-row items-start justify-between pt-0 px-0 pb-6 border-b-[1px] border-solid border-neutrals-neutral-100">
          					<div className="relative font-semibold">Postal code verification</div>
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 text-sm border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="flex flex-row items-center justify-center">
              							<div className="relative font-semibold">Verify</div>
            						</div>
          					</div>
        				</div>
        				<div className="flex flex-col items-start justify-start gap-[10px] text-sm">
          					<div className="flex flex-row items-center justify-start gap-[6px]">
            						<div className="relative font-semibold">Postal code</div>
            						<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg" />
          					</div>
          					<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border overflow-hidden flex flex-row items-center justify-start p-3 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="relative">Enter debtor’s postal code</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[505px] left-[96px] w-[1248px] flex flex-col items-start justify-start gap-[24px] text-neutrals-neutral-300">
        				<div className="w-[1248px] flex flex-row items-start justify-between">
          					<div className="w-[294px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-start py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="flex flex-row items-center justify-start gap-[10px]">
              							<img className="w-5 relative h-5" alt="" src="assets/search.svg" />
              							<div className="relative">Search clients/cases</div>
            						</div>
          					</div>
          					<div className="flex flex-row items-start justify-end gap-[10px] text-neutrals-neutral-900">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-2.5 px-3.5 gap-[10px] border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">Show:</div>
              							<div className="flex flex-row items-center justify-start gap-[6px]">
                								<div className="relative font-semibold">All</div>
                								<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/arrow-down.svg" />
              							</div>
            						</div>
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center gap-[6px]">
                								<img className="w-5 relative h-5" alt="" src="assets/filter.svg" />
                								<div className="relative font-semibold">Filter</div>
              							</div>
            						</div>
          					</div>
        				</div>
        				<div className="self-stretch flex flex-col items-start justify-start text-neutrals-neutral-900">
          					<div className="self-stretch flex flex-row items-start justify-start">
            						<div className="w-[294px] flex flex-col items-start justify-start">
              							<div className="self-stretch rounded-tl-3xs rounded-tr-none rounded-b-none bg-column-bg flex flex-row items-center justify-start py-3 px-4 gap-[6px] border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
                								<img className="w-5 relative h-5" alt="" src="assets/checkbox.svg" />
                								<div className="relative font-semibold">Client name</div>
              							</div>
              							<div className="self-stretch flex flex-col items-start justify-start">
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 gap-[6px] border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                  									<img className="w-5 relative h-5" alt="" src="assets/checkbox.svg" />
                  									<div className="relative">Paras Law Firm</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 gap-[6px] border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                  									<img className="w-5 relative h-5" alt="" src="assets/checkbox.svg" />
                  									<div className="relative">{`Smith & Associates`}</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 gap-[6px] border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                  									<img className="w-5 relative h-5" alt="" src="assets/checkbox.svg" />
                  									<div className="relative">Legal Solutions Ltd.</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 gap-[6px] border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                  									<img className="w-5 relative h-5" alt="" src="assets/checkbox.svg" />
                  									<div className="relative">Thomson Legal Group</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 gap-[6px] border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                  									<img className="w-5 relative h-5" alt="" src="assets/checkbox.svg" />
                  									<div className="relative">Legal Minds Co.</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 gap-[6px] border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                  									<img className="w-5 relative h-5" alt="" src="assets/checkbox.svg" />
                  									<div className="relative">Johnson Legal Associates</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 gap-[6px] border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                  									<img className="w-5 relative h-5" alt="" src="assets/checkbox.svg" />
                  									<div className="relative">Legal Aid Foundations</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 gap-[6px] border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                  									<img className="w-5 relative h-5" alt="" src="assets/checkbox.svg" />
                  									<div className="relative">Legal Services Ltd.</div>
                								</div>
              							</div>
            						</div>
            						<div className="w-[236px] flex flex-col items-start justify-start">
              							<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                								<div className="relative font-semibold">Contact details</div>
              							</div>
              							<div className="self-stretch flex flex-col items-start justify-start">
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative">plf.law@outlook.com</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative">smith@outlook.com</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative">legal.solutions@outlok.com</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative">thompson@outlook.com</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative">legal.minds@outlook.com</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative">johnson@outlook.com</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative">legal.aid@outlook.com</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative">legal.services@outlook.com</div>
                								</div>
              							</div>
            						</div>
            						<div className="w-[318px] flex flex-col items-start justify-start">
              							<div className="w-[483px] bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                								<div className="relative font-semibold">Case details</div>
              							</div>
              							<div className="self-stretch flex flex-col items-start justify-start">
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative">Debt collection - case #123</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative">Contract dispute - case #456</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative">Real estate dispute - case #345</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative">Debt collection - case #678</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative">Debt collection - case #678</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative">Business contracts - case #789</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative">Contract dispute - case #476</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative">Debt collection - case #287</div>
                								</div>
              							</div>
            						</div>
            						<div className="flex flex-col items-center justify-start">
              							<div className="w-[123px] bg-column-bg box-border h-11 flex flex-row items-start justify-center py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                								<div className="relative font-semibold">Status</div>
              							</div>
              							<div className="flex flex-col items-start justify-start text-semantics-low-low-500">
                								<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 text-semantics-secure-blue-500 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="rounded-xl bg-semantics-secure-blue-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-secure-blue-500">
                    										<div className="w-1.5 relative rounded-[50%] bg-semantics-secure-blue-500 h-1.5" />
                    										<div className="relative font-semibold">Identified</div>
                  									</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 text-semantics-moderate-moderate-500 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="rounded-xl bg-semantics-moderate-moderate-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-moderate-moderate-500">
                    										<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
                    										<div className="relative font-semibold">Pending</div>
                  									</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                    										<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                    										<div className="relative font-semibold">Resolved</div>
                  									</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                    										<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                    										<div className="relative font-semibold">Resolved</div>
                  									</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                    										<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                    										<div className="relative font-semibold">Resolved</div>
                  									</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 text-semantics-secure-blue-500 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="rounded-xl bg-semantics-secure-blue-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-secure-blue-500">
                    										<div className="w-1.5 relative rounded-[50%] bg-semantics-secure-blue-500 h-1.5" />
                    										<div className="relative font-semibold">Identified</div>
                  									</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                    										<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                    										<div className="relative font-semibold">Resolved</div>
                  									</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                    										<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                    										<div className="relative font-semibold">Resolved</div>
                  									</div>
                								</div>
              							</div>
            						</div>
            						<div className="flex-1 flex flex-col items-center justify-start">
              							<div className="self-stretch rounded-tl-none rounded-tr-3xs rounded-b-none bg-column-bg box-border h-11 flex flex-row items-start justify-center py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-b-[1px]">
                								<div className="relative font-semibold">Actions</div>
              							</div>
              							<div className="self-stretch flex flex-col items-start justify-start">
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 gap-[24px] border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">View Details</div>
                  									<div className="relative font-semibold">Resolve</div>
                  									<div className="relative font-semibold">Dismiss</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 gap-[24px] border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">View Details</div>
                  									<div className="relative font-semibold">Resolve</div>
                  									<div className="relative font-semibold">Dismiss</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 gap-[24px] border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">View Details</div>
                  									<div className="relative font-semibold">Resolve</div>
                  									<div className="relative font-semibold">Dismiss</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 gap-[24px] border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">View Details</div>
                  									<div className="relative font-semibold">Resolve</div>
                  									<div className="relative font-semibold">Dismiss</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 gap-[24px] border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">View Details</div>
                  									<div className="relative font-semibold">Resolve</div>
                  									<div className="relative font-semibold">Dismiss</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 gap-[24px] border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">View Details</div>
                  									<div className="relative font-semibold">Resolve</div>
                  									<div className="relative font-semibold">Dismiss</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 gap-[24px] border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">View Details</div>
                  									<div className="relative font-semibold">Resolve</div>
                  									<div className="relative font-semibold">Dismiss</div>
                								</div>
                								<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 gap-[24px] border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">View Details</div>
                  									<div className="relative font-semibold">Resolve</div>
                  									<div className="relative font-semibold">Dismiss</div>
                								</div>
              							</div>
            						</div>
          					</div>
          					<div className="w-[1248px] rounded-t-none rounded-b-3xs box-border flex flex-row items-center justify-between pt-3 px-6 pb-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">Previous</div>
            						</div>
            						<div className="relative">Page 1 of 10</div>
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">Next</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[459px] left-[96px] text-base font-semibold text-primary-primary-900">Client list</div>

				{/* Navigation Panel */}
				<NavigationPanelLawyer navItems={navItems}/>

    		</div>
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}</>);
};

export default PreLBAChecks;