import { useState, useCallback } from 'react';
import DownloadModalTables from '../../components/DownloadModalTables';
import DateFilter from "../../components/DateFilter";
import PortalPopup from "../../components/PortalPopup";
import DebtorDeletion from "../../components/DebtorDeletion";
import ImagePreview from "../../components/ImagePreview";
import LBAReminder from "../../components/LBAReminder";
import NavigationDropdown from "../../components/NavigationDropdown";
import LogoutConfirmation from "../../components/LogoutConfirmation";
import {Navigate, useNavigate} from "react-router-dom";
import NavigationPanel from '../../components/NavigationPanel';
import Breadcrumb from '../../components/Breadcrumb';
import CTAs from '../../components/CTAs';

const LBA_2 = () => {
	const [isDownloadModalTablesOpen, setDownloadModalTablesOpen] = useState(false);
  	const [isDateFilterOpen, setDateFilterOpen] = useState(false);
  	const [isDebtorDeletionOpen, setDebtorDeletionOpen] = useState(false);
  	const [isImagePreviewOpen, setImagePreviewOpen] = useState(false);
  	const [isLBAReminderOpen, setLBAReminderOpen] = useState(false);
  	const [isNavigationDropdownOpen, setNavigationDropdownOpen] = useState(false);
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	const navigate = useNavigate();
  	
  	const openDateFilter = useCallback(() => {
    		setDateFilterOpen(true);
  	}, []);
  	
  	const closeDateFilter = useCallback(() => {
    		setDateFilterOpen(false);
  	}, []);
  	
	const openDownloadModalTables = useCallback(() => {
		setDownloadModalTablesOpen(true);
  	}, []);

  	const onFrameContainerClick = useCallback(() => {
    		navigate("/");
  	}, [navigate]);
  	
  	const openDebtorDeletion = useCallback(() => {
    		setDebtorDeletionOpen(true);
  	}, []);
  	
  	const closeDebtorDeletion = useCallback(() => {
    		setDebtorDeletionOpen(false);
  	}, []);
  	
  	
  	const openImagePreview = useCallback(() => {
    		setImagePreviewOpen(true);
  	}, []);
  	
  	const closeImagePreview = useCallback(() => {
    		setImagePreviewOpen(false);
  	}, []);
  	
  	
  	const openLBAReminder = useCallback(() => {
    		setLBAReminderOpen(true);
  	}, []);
  	
  	const closeLBAReminder = useCallback(() => {
    		setLBAReminderOpen(false);
  	}, []);
  	
  	
  	const onIconsV2Click = useCallback(() => {
    		// Add your code here
  	}, []);
  	
  	
  	const onBillingTextClick = useCallback(() => {
    		// Add your code here
  	}, []);
  	
  	
  	const openNavigationDropdown = useCallback(() => {
    		setNavigationDropdownOpen(true);
  	}, []);
  	
  	const closeNavigationDropdown = useCallback(() => {
    		setNavigationDropdownOpen(false);
  	}, []);
  	
  	
  	const openLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(true);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);

	const navItems = [
		{
			text: 'Debt collection', 
    		bgColor: 'bg-primary-primary-50', 
    		textColor: 'text-neutrals-neutral-900', 
    		hoverBgColor: 'bg-primary-primary-50', 
    		hoverTextColor: 'text-neutrals-neutral-900', 
    		path: "",
    		icon: 'assets/arrow-down.svg'
		},

		{
			text: "Outcomes & costs",
			bgColor: 'bg-neutrals-neutral-50', 
    		textColor: 'text-neutrals-neutral-300', 
    		hoverBgColor: 'bg-neutrals-neutral-50', 
    		hoverTextColor: 'text-primary-primary-500', 
    		path: "/outcome&cost",
    		icon: ''
		},

		{
			text: "AI indicators",
			bgColor: 'bg-neutrals-neutral-50', 
    		textColor: 'text-neutrals-neutral-300', 
    		hoverBgColor: 'bg-neutrals-neutral-50', 
    		hoverTextColor: 'text-primary-primary-500', 
    		path: "/ai1",
    		icon: ''
		},
	];

	const breadcrumbs = [
		{ label: "", path: "/postlba2", active: true, icon: "assets/home.svg" },
		{ label: "Letter before action (LBA)", path: "/lba", active: false },
		{ label: "Paras Mistry", path: "", active: true },
	];
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1795px] overflow-hidden text-left text-base text-neutrals-neutral-300 font-open-sans-ff-heading-1">
      			<div className="absolute top-[1712px] right-[96px] text-sm">Copyright © Think-AI 2023</div>
      			<div className="absolute top-[547px] left-[96px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] w-[824px] flex flex-col items-start justify-start text-sm text-neutrals-neutral-900">
        				<div className="self-stretch rounded-t-3xs rounded-b-none flex flex-row items-center justify-between py-5 px-6 text-lg border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-l-[1px]">
          					<div className="flex flex-row items-center justify-start gap-[10px]">
            						<div className="relative font-semibold">Communication history</div>
            						<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="w-1.5 relative rounded-[50%] bg-primary-primary-500 h-1.5" />
              							<div className="relative font-semibold">2 entries</div>
            						</div>
          					</div>
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 text-sm border-[1px] border-solid border-neutrals-neutral-100" onClick={openDownloadModalTables}>
            						<div className="flex flex-row items-center justify-center gap-[6px]">
              							<img className="w-5 relative h-5" alt="" src="assets/download.svg"/>
              							<div className="relative font-semibold">Download</div>
            						</div>
          					</div>
        				</div>
        				<div className="self-stretch flex flex-row items-center justify-end py-3 px-6 text-neutrals-neutral-300 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-l-[1px]">
          					<div className="w-[776px] flex flex-row items-start justify-between">
            						<div className="w-[294px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-start py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-start gap-[10px]">
                								<img className="w-5 relative h-5" alt="" src="assets/search.svg"/>
                								<div className="relative">Search</div>
              							</div>
            						</div>
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 cursor-pointer text-neutrals-neutral-900 border-[1px] border-solid border-neutrals-neutral-100" onClick={openDateFilter}>
              							<div className="flex flex-row items-center justify-center gap-[6px]">
                								<img className="w-5 relative h-5" alt="" src="assets/filter.svg"/>
                								<div className="relative font-semibold">Filter</div>
              							</div>
            						</div>
          					</div>
        				</div>
        				<div className="self-stretch flex flex-col items-start justify-start">
          					<div className="self-stretch flex flex-col items-start justify-start">
            						<div className="self-stretch flex flex-row items-start justify-start">
              							<div className="w-[292px] flex flex-col items-start justify-start">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
                  									<div className="relative font-semibold">Submission date</div>
                								</div>
                								<div className="self-stretch flex flex-col items-start justify-start">
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">01/02/24, 11:00 AM</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">01/02/24, 12:00 PM</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[532px] flex flex-col items-start justify-start">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-b-[1px]">
                  									<div className="relative font-semibold">Description</div>
                								</div>
                								<div className="self-stretch flex flex-col items-start justify-start">
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 cursor-pointer border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]" onClick={onFrameContainerClick}>
                    										<div className="relative">Email send to John Doe</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">Letter emailed to John Doe</div>
                  									</div>
                								</div>
              							</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[179px] left-[96px] box-border w-[1248px] flex flex-row items-start justify-between pt-0 px-0 pb-6 text-9xl text-neutrals-neutral-900 border-b-[1px] border-solid border-neutrals-neutral-100">
        				<b className="relative">Debtor details</b>
						<CTAs
						type="Ghostbtn"
						text="Delete"
						textColor="text-neutrals-neutral-900"
						bgColor="bg-neutrals-neutral-50"
						borderColor="border-neutrals-neutral-100"
						position="relative"
						icon="assets/delete.svg"
						onClick={openDebtorDeletion}
						/>
      			</div>
      			<div className="absolute top-[909px] left-[96px] flex flex-col items-start justify-start gap-[24px] text-lg text-neutrals-neutral-900">
        				<div className="w-[1248px] box-border flex flex-col items-start justify-start pt-0 px-0 pb-6 border-b-[1px] border-solid border-neutrals-neutral-100">
          					<div className="self-stretch flex flex-row items-start justify-between">
            						<div className="flex flex-col items-start justify-start gap-[6px]">
              							<div className="relative font-semibold">Attached documents</div>
              							<div className="relative text-base text-neutrals-neutral-300">List of all attached documents.</div>
            						</div>
									<CTAs
									type="Ghostbtn"
									text="Download all"
									textColor="text-neutrals-neutral-900"
									bgColor="bg-neutrals-neutral-50"
									borderColor="border-neutrals-neutral-100"
									icon="assets/download.svg"
									position="relative"
									/>
          					</div>
        				</div>
        				<div className="w-[1248px] flex flex-row flex-wrap items-start justify-start gap-[24px] text-base">
          					<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="self-stretch flex flex-row items-start justify-between p-6">
              							<div className="flex flex-col items-start justify-start gap-[10px]">
                								<img className="w-10 relative h-10" alt="" src="assets/pdf.svg"/>
                								<div className="relative font-semibold">Invoice.pdf</div>
                								<div className="relative text-neutrals-neutral-300">1.2MB</div>
              							</div>
              							<div className="relative text-neutrals-neutral-300">29/01/24</div>
            						</div>
            						<div className="self-stretch flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-start justify-end gap-[10px]">
											<CTAs
											type="Ghostbtn"
											text="Preview"
											textColor="text-neutrals-neutral-900"
											bgColor="bg-neutrals-neutral-50"
											borderColor="border-neutrals-neutral-100"
											icon="assets/preview.svg"
											position="relative"
											/>

											<CTAs
											type="Ghostbtn"
											text="Download"
											textColor="text-neutrals-neutral-900"
											bgColor="bg-neutrals-neutral-50"
											borderColor="border-neutrals-neutral-100"
											icon="assets/download.svg"
											position="relative"
											/>
              							</div>
            						</div>
          					</div>
          					<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="self-stretch flex flex-row items-start justify-between p-6">
              							<div className="flex flex-col items-start justify-start gap-[10px]">
                								<img className="w-10 relative h-10" alt="" src="assets/pdf.svg"/>
                								<div className="relative font-semibold">Invoice.pdf</div>
                								<div className="relative text-neutrals-neutral-300">1.9MB</div>
              							</div>
              							<div className="relative text-neutrals-neutral-300">29/01/24</div>
            						</div>
            						<div className="self-stretch flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-start justify-end gap-[10px]">
										  	<CTAs
											type="Ghostbtn"
											text="Preview"
											textColor="text-neutrals-neutral-900"
											bgColor="bg-neutrals-neutral-50"
											borderColor="border-neutrals-neutral-100"
											icon="assets/preview.svg"
											position="relative"
											/>

											<CTAs
											type="Ghostbtn"
											text="Download"
											textColor="text-neutrals-neutral-900"
											bgColor="bg-neutrals-neutral-50"
											borderColor="border-neutrals-neutral-100"
											icon="assets/download.svg"
											position="relative"
											/>
              							</div>
            						</div>
          					</div>
          					<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="self-stretch flex flex-row items-start justify-between p-6">
              							<div className="flex flex-col items-start justify-start gap-[10px]">
                								<div className="flex flex-row items-start justify-start relative gap-[10px]">
                                                    <img className="w-10 relative h-10" alt="" src="assets/word_file.svg" />
                								</div>
                								<div className="relative font-semibold">Contract.docx</div>
                								<div className="relative text-neutrals-neutral-300">24KB</div>
              							</div>
              							<div className="relative text-neutrals-neutral-300">29/01/24</div>
            						</div>
            						<div className="self-stretch flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-start justify-end gap-[10px]">
										  <CTAs
											type="Ghostbtn"
											text="Preview"
											textColor="text-neutrals-neutral-900"
											bgColor="bg-neutrals-neutral-50"
											borderColor="border-neutrals-neutral-100"
											icon="assets/preview.svg"
											position="relative"
											onClick={openImagePreview}
											/>

											<CTAs
											type="Ghostbtn"
											text="Download"
											textColor="text-neutrals-neutral-900"
											bgColor="bg-neutrals-neutral-50"
											borderColor="border-neutrals-neutral-100"
											icon="assets/download.svg"
											position="relative"
											/>
              							</div>
            						</div>
          					</div>
          					<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="self-stretch flex flex-row items-start justify-between p-6">
              							<div className="flex flex-col items-start justify-start gap-[10px]">
                								<img className="w-10 relative h-10" alt="" src="assets/pdf.svg" />
                								<div className="relative font-semibold">Acknowledgment.pdf</div>
                								<div className="relative text-neutrals-neutral-300">2.5MB</div>
              							</div>
              							<div className="relative text-neutrals-neutral-300">29/01/24</div>
            						</div>
            						<div className="self-stretch flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-start justify-end gap-[10px]">
										  <CTAs
											type="Ghostbtn"
											text="Preview"
											textColor="text-neutrals-neutral-900"
											bgColor="bg-neutrals-neutral-50"
											borderColor="border-neutrals-neutral-100"
											icon="assets/preview.svg"
											position="relative"
											onClick={openImagePreview}
											/>

											<CTAs
											type="Ghostbtn"
											text="Download"
											textColor="text-neutrals-neutral-900"
											bgColor="bg-neutrals-neutral-50"
											borderColor="border-neutrals-neutral-100"
											icon="assets/download.svg"
											position="relative"
											/>
              							</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute bottom-[147px] left-[96px] rounded-3xs bg-column-bg w-[1248px] flex flex-col items-start justify-start p-6 box-border gap-[10px]">
        				<div className="relative">Notes</div>
        				<div className="relative font-semibold text-neutrals-neutral-900">This debtor has an history of late payments.</div>
      			</div>
      			<div className="absolute top-[267px] left-[520px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start text-neutrals-neutral-900">
        				<div className="w-[400px] rounded-t-3xs rounded-b-none bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="self-stretch flex flex-row items-start justify-between">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<img className="w-5 relative h-5" alt="" src="assets/calendar.svg"/>
            						</div>
            						<div className="w-9 rounded-[100px] bg-primary-primary-500 flex flex-row items-start justify-end p-0.5 box-border">
              							<div className="w-4 relative shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-[50%] bg-neutrals-neutral-50 h-4" />
            						</div>
          					</div>
          					<div className="flex flex-col items-start justify-start gap-[6px]">
            						<div className="relative font-semibold">Automated reminder</div>
            						<div className="w-[294px] relative text-neutrals-neutral-300 inline-block">Reminders are send based on the frequency set.</div>
          					</div>
        				</div>
        				<div className="w-[400px] rounded-t-none rounded-b-3xs bg-neutrals-neutral-50 box-border flex flex-col items-end justify-start py-4 px-6 text-sm border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
							<CTAs
							type="Ghostbtn"
							text="Adjust"
							textColor="text-neutrals-neutral-900"
							bgColor="bg-neutrals-neutral-50"
							borderColor="border-neutrals-neutral-100"
							icon="assets/customise.svg"
							position="relative"
							onClick={openLBAReminder}
							/>
        				</div>
      			</div>

				{/* Navigation Panel */}
				<NavigationPanel navItems={navItems}/>

				{/* Breadcrumb Navigation */}
				<Breadcrumb breadcrumbs={breadcrumbs} textColor="" position="top-[24px] left-[90px]"/>
      			
      			<div className="absolute top-[267px] left-[96px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-64 flex flex-col items-start justify-start text-lg text-neutrals-neutral-900 border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="w-[400px] flex flex-row items-start justify-between p-6 box-border">
          					<div className="flex flex-col items-start justify-start">
            						<div className="flex flex-col items-start justify-start gap-[24px]">
              							<div className="relative font-semibold">Paras Mistry</div>
              							<div className="flex flex-col items-start justify-start gap-[10px] text-base text-neutrals-neutral-300">
                								<div className="flex flex-row items-center justify-start gap-[10px]">
                  									<img className="w-5 relative h-5" alt="" src="assets/location.svg" />
                  									<div className="relative">21 Turning St.</div>
                								</div>
                								<div className="flex flex-row items-center justify-start gap-[10px]">
                  									<img className="w-5 relative h-5" alt="" src="assets/mail.svg" />
                  									<div className="relative">john.d@gmail.com</div>
                								</div>
                								<div className="flex flex-row items-start justify-start gap-[10px]">
                  									<img className="w-5 relative h-5" alt="" src="assets/call.svg" />
                  									<div className="relative">+447826284524</div>
                								</div>
              							</div>
            						</div>
          					</div>
          					<div className="rounded-[20px] bg-semantics-high-high-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm text-semantics-high-high-500 border-[1px] border-solid border-semantics-high-high-500">
            						<div className="w-1.5 relative rounded-[50%] bg-semantics-high-high-500 h-1.5" />
            						<div className="relative font-semibold">Unsettled</div>
          					</div>
        				</div>
        				<div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-t-none rounded-b-3xs bg-neutrals-neutral-50 box-border h-[73px] flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
							<CTAs
							type="Ghostbtn"
							text="Mark as settled"
							textColor="text-neutrals-neutral-900"
							bgColor="bg-neutrals-neutral-50"
							borderColor="border-neutrals-neutral-100"
							icon="assets/tick-circle.svg"
							position="relative"
							/>
        				</div>
      			</div>
      			<div className="absolute top-[267px] left-[944px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="w-[400px] rounded-t-3xs rounded-b-none bg-neutrals-neutral-50 box-border h-[184px] flex flex-col items-start justify-start border-t-[1px]">
          					<div className="self-stretch h-[184px] flex flex-row items-start justify-between p-6 box-border">
            						<div className="flex flex-col items-start justify-start gap-[24px]">
              							<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
                								<img className="w-5 relative h-5" alt="" src="assets/error.svg"/>
              							</div>
              							<div className="flex flex-col items-start justify-start gap-[10px]">
                								<div className="relative">Amount due</div>
                								<b className="relative text-13xl text-neutrals-neutral-900">£2,000</b>
              							</div>
            						</div>
            						<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg"/>
          					</div>
        				</div>
        				<div className="w-[400px] rounded-t-none rounded-b-3xs bg-neutrals-neutral-50 box-border flex flex-col items-end justify-start py-4 px-6 text-sm text-neutrals-neutral-900 border-t-[1px] border-solid border-neutrals-neutral-100">
							<CTAs
							type="Ghostbtn"
							text="Request payment"
							textColor="text-neutrals-neutral-900"
							bgColor="bg-neutrals-neutral-50"
							borderColor="border-neutrals-neutral-100"
							icon="assets/arrow-right.svg"
							position="relative"
							/>
        				</div>
      			</div>
    		</div>
    		{isDateFilterOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeDateFilter}
        				>
        				<DateFilter onClose={closeDateFilter} />
      			</PortalPopup>
    		)}
    		{isDebtorDeletionOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeDebtorDeletion}
        				>
        				<DebtorDeletion onClose={closeDebtorDeletion} />
      			</PortalPopup>
    		)}
    		{isImagePreviewOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeImagePreview}
        				>
        				<ImagePreview onClose={closeImagePreview} />
      			</PortalPopup>
    		)}
    		{isLBAReminderOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLBAReminder}
        				>
        				<LBAReminder onClose={closeLBAReminder} />
      			</PortalPopup>
    		)}
    		{isNavigationDropdownOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeNavigationDropdown}
        				>
        				<NavigationDropdown onClose={closeNavigationDropdown} />
      			</PortalPopup>
    		)}
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}</>);
};

export default LBA_2;