import { useState, useCallback } from 'react';
import LogoutConfirmation from "../../components/LogoutConfirmation";
import PortalPopup from "../../components/PortalPopup";
import DefenceStatementImagePrevie from "../../components/DefenceStatementImagePreview";
import ExportModalImage from "../../components/ExportModalImage";
import {useNavigate} from "react-router-dom";
import DebtCollectionNav from '../../components/DebtCollectionNav';
import NavigationPanel from '../../components/NavigationPanel';
import Breadcrumb from '../../components/Breadcrumb';

const AI_Indicators_2 = () => {
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	const [isDefenceStatementImagePrevieOpen, setDefenceStatementImagePrevieOpen] = useState(false);
  	const [isExportModalImageOpen, setExportModalImageOpen] = useState(false);
  	const navigate = useNavigate();
  	
  	const openLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(true);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);
  	
  	const onBillingTextClick = useCallback(() => {
        navigate('/ai1');
  	}, [navigate]);
  	
  	
  	const openDefenceStatementImagePrevie = useCallback(() => {
    		setDefenceStatementImagePrevieOpen(true);
  	}, []);
  	
  	const closeDefenceStatementImagePrevie = useCallback(() => {
    		setDefenceStatementImagePrevieOpen(false);
  	}, []);
  	
  	
  	const openExportModalImage = useCallback(() => {
    		setExportModalImageOpen(true);
  	}, []);
  	
  	const closeExportModalImage = useCallback(() => {
    		setExportModalImageOpen(false);
  	}, []);

	const navItems = [
		{
			text: 'Debt collection', 
    		bgColor: 'bg-neutrals-neutral-50', 
    		textColor: 'text-neutrals-neutral-300', 
    		hoverBgColor: 'bg-neutrals-neutral-50', 
    		hoverTextColor: 'text-primary-primary-500', 
    		path: "",
    		icon: 'assets/arrow-down.svg'
		},

		{
			text: "Outcomes & costs",
			bgColor: 'bg-neutrals-neutral-50',
    		textColor: 'text-neutrals-neutral-300', 
    		hoverBgColor: "bg-neutrals-neutral-50", 
    		hoverTextColor: "text-primary-primary-500", 
    		path: "/outcome&cost",
    		icon: ""
		},

		{
			text: "AI indicators",
			bgColor: 'bg-primary-primary-50', 
    		textColor: 'text-neutrals-neutral-900', 
    		hoverBgColor: 'bg-primary-primary-50', 
    		hoverTextColor: 'text-neutrals-neutral-900', 
    		path: "",
    		icon: ''
		},
	];

	const breadcrumb = [
		{ label: "AI indicators", path: "/ai1", icon: "", active: false },
		{ label: "Paras Mistry", path: "", icon: "", active: true },
	];
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[2501px] overflow-hidden text-left text-sm text-neutrals-neutral-900 font-open-sans-ff-heading-2">
      			<div className="absolute top-[2417.5px] right-[96px] text-neutrals-neutral-300">Copyright © Think-AI 2023</div>

                {/* Navigation Panel */}
      			<NavigationPanel navItems={navItems}/>

				{/* Breadcrumb Navigation */}
				<Breadcrumb breadcrumbs={breadcrumb} position="top-[24px] left-[90px]"/>

      			<div className="absolute top-[179px] left-[96px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border w-[400px] flex flex-row items-start justify-between p-6 text-lg border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-col items-start justify-start gap-[24px]">
          					<div className="relative font-semibold">Paras Mistry</div>
          					<div className="flex flex-col items-start justify-start gap-[10px] text-base text-neutrals-neutral-300">
            						<div className="flex flex-row items-center justify-start gap-[10px]">
              							<img className="w-5 relative h-5" alt="" src="assets/location.svg" />
              							<div className="relative">21 Turning St., Southampton</div>
            						</div>
            						<div className="flex flex-row items-center justify-start gap-[10px]">
              							<img className="w-5 relative h-5" alt="" src="assets/call.svg" />
              							<div className="relative">+441234567890</div>
            						</div>
            						<div className="flex flex-row items-center justify-start gap-[10px]">
              							<img className="w-5 relative h-5" alt="" src="assets/mail.svg" />
              							<div className="relative">pm@think-ai.io</div>
            						</div>
          					</div>
        				</div>
        				<div className="rounded-xl bg-semantics-moderate-moderate-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm text-semantics-moderate-moderate-500 border-[1px] border-solid border-semantics-moderate-moderate-500">
          					<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
          					<div className="relative font-semibold">On going</div>
        				</div>
      			</div>
      			<div className="absolute top-[386px] left-[96px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] flex flex-col items-start justify-start">
        				<div className="w-[1248px] rounded-t-3xs rounded-b-none box-border flex flex-row items-center justify-between py-5 px-6 text-lg border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-l-[1px]">
          					<div className="flex flex-row items-center justify-start gap-[10px]">
            						<div className="relative font-semibold">Uploaded documents</div>
            						<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="w-1.5 relative rounded-[50%] bg-primary-primary-500 h-1.5" />
              							<div className="relative font-semibold">3 documents</div>
            						</div>
          					</div>
          					<div className="flex flex-row items-start justify-start text-sm">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center gap-[6px]">
                								<img className="w-5 relative h-5" alt="" src="assets/download.svg" />
                								<div className="relative font-semibold">Download all</div>
              							</div>
            						</div>
          					</div>
        				</div>
        				<div className="w-[1248px] box-border flex flex-row items-center justify-between py-3 px-6 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-l-[1px]">
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-start border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="rounded-tl-3xs rounded-tr-none rounded-br-none rounded-bl-3xs bg-column-bg flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">View all</div>
            						</div>
            						<div className="flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">PDF</div>
            						</div>
            						<div className="flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">Image</div>
            						</div>
            						<div className="rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none flex flex-row items-center justify-center py-2 px-4">
              							<div className="relative font-semibold">Word</div>
            						</div>
          					</div>
          					<div className="flex flex-row items-start justify-start gap-[10px] text-neutrals-neutral-300">
            						<div className="w-[294px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-start py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-start gap-[10px]">
                								<img className="w-5 relative h-5" alt="" src="assets/search.svg" />
                								<div className="relative">Search</div>
              							</div>
            						</div>
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 text-neutrals-neutral-900 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center gap-[6px]">
                								<img className="w-5 relative h-5" alt="" src="assets/filter.svg" />
                								<div className="relative font-semibold">Filter</div>
              							</div>
            						</div>
          					</div>
        				</div>
        				<div className="flex flex-col items-start justify-start">
          					<div className="w-[1248px] flex flex-col items-start justify-start">
            						<div className="self-stretch flex flex-row items-start justify-start">
              							<div className="flex-1 flex flex-col items-start justify-start">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
                  									<div className="relative font-semibold">Name</div>
                								</div>
                								<div className="self-stretch flex flex-col items-start justify-start">
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 gap-[10px] border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<img className="w-10 relative h-10" alt="" src="assets/pdf.svg" />
                    										<div className="relative font-semibold">DefenseStatement_JDoe.pdf</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 gap-[10px] border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="flex flex-row items-start justify-start relative gap-[10px]">
                      											<img className="w-10 relative h-10 z-[0]" alt="" src="assets/word_file.svg"/>
                    										</div>
                    										<div className="relative font-semibold">DefenseStatement_JDoe.docx</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-center justify-start p-4 gap-[10px] border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="flex flex-row items-start justify-start relative gap-[10px]">
                      											<img className="w-10 relative h-10 z-[0]" alt="" src="assets/image_file.svg"/>
                    										</div>
                    										<div className="relative font-semibold">Correspondence_JDoe.png</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[188px] flex flex-col items-start justify-start">
                								<div className="w-[188px] bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">Size</div>
                								</div>
                								<div className="w-[188px] flex flex-col items-start justify-start">
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">1.2 MB</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">2.8 MB</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">24 KB</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[260px] flex flex-col items-start justify-start">
                								<div className="w-[448px] bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-b-[1px]">
                  									<div className="relative font-semibold">Kind</div>
                								</div>
                								<div className="self-stretch flex flex-col items-start justify-start">
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">PDF document</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">Word document</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="relative">PNG image</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[448px] flex flex-col items-start justify-start">
                								<div className="w-[448px] bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-b-[1px]">
                  									<div className="relative font-semibold">Uploaded date</div>
                								</div>
                								<div className="w-[448px] flex flex-col items-start justify-start">
                  									<div className="w-[448px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">08 Feb 2024 at 10:00 am</div>
                  									</div>
                  									<div className="w-[448px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">08 Feb 2024 at 10:00 am</div>
                  									</div>
                  									<div className="w-[448px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="relative">08 Feb 2024 at 10:00 am</div>
                  									</div>
                								</div>
              							</div>
            						</div>
          					</div>
          					<div className="w-[1248px] rounded-t-none rounded-b-3xs box-border flex flex-row items-center justify-between pt-3 px-6 pb-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">Previous</div>
              							</div>
            						</div>
            						<div className="relative">Page 1 of 10</div>
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">Next</div>
              							</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[846px] left-[96px] box-border w-[1248px] flex flex-col items-start justify-start pt-0 px-0 pb-6 gap-[10px] text-lg border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-col items-start justify-start gap-[6px] z-[0]">
          					<div className="relative font-semibold">AI assessment details</div>
          					<div className="relative text-base text-neutrals-neutral-300">Acknowledges any formal defence raised by the debtor.</div>
        				</div>
        				<div className="!m-[0] absolute top-[0px] left-[1094px] rounded-3xs bg-primary-primary-50 flex flex-row items-center justify-start py-2.5 px-3.5 gap-[6px] z-[1] text-sm text-primary-primary-500">
          					<img className="w-[23px] relative h-[22.6px]" alt="" src="assets/AI.svg" />
          					<div className="relative font-semibold">Powered by AI</div>
        				</div>
      			</div>
      			<div className="absolute top-[947px] left-[96px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="w-[400px] flex flex-col items-start justify-start p-6 box-border gap-[24px]">
          					<div className="self-stretch flex flex-col items-start justify-start gap-[24px]">
            						<div className="self-stretch flex flex-row items-start justify-center">
              							<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                								<div className="w-1.5 relative rounded-[50%] bg-semantics-high-high-500 h-1.5" />
                								<div className="relative font-semibold">Document mismatch</div>
              							</div>
            						</div>
            						<div className="self-stretch flex flex-col items-start justify-start gap-[10px] text-base text-neutrals-neutral-300">
              							<div className="flex flex-row items-center justify-start gap-[10px] text-sm text-neutrals-neutral-900">
                								<img className="w-10 relative h-10" alt="" src="assets/pdf.svg" />
                								<div className="relative font-semibold">DefenseStatement_JDoe.pdf</div>
              							</div>
              							<div className="self-stretch relative">AI has identified language patterns suggesting a possible dispute.</div>
              							<div className="self-stretch flex flex-row items-center justify-start gap-[6px]">
                								<img className="w-5 relative h-5" alt="" src="assets/calendar.svg" />
                								<div className="relative">09/02/24</div>
              							</div>
            						</div>
          					</div>
          					<div className="rounded-xl bg-semantics-high-high-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-semantics-high-high-500 border-[1px] border-solid border-semantics-high-high-500">
            						<div className="w-1.5 relative rounded-[50%] bg-semantics-high-high-500 h-1.5" />
            						<div className="relative font-semibold">High</div>
          					</div>
        				</div>
        				<div className="w-[400px] box-border flex flex-col items-end justify-start py-4 px-6 border-t-[1px] border-solid border-neutrals-neutral-100">
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 cursor-pointer border-[1px] border-solid border-neutrals-neutral-100" onClick={openDefenceStatementImagePrevie}>
            						<div className="flex flex-row items-center justify-center">
              							<div className="relative font-semibold">View document</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[947px] left-[520px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="w-[400px] flex flex-col items-start justify-start p-6 box-border gap-[24px]">
          					<div className="self-stretch flex flex-col items-start justify-start gap-[24px]">
            						<div className="self-stretch flex flex-row items-start justify-center">
              							<div className="rounded-8xs bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
                								<div className="w-1.5 relative rounded-[50%] bg-semantics-high-high-500 h-1.5" />
                								<div className="relative font-semibold">Document mismatch</div>
              							</div>
            						</div>
            						<div className="self-stretch flex flex-col items-start justify-start gap-[10px] text-base text-neutrals-neutral-300">
              							<div className="flex flex-row items-center justify-start gap-[10px] text-sm text-neutrals-neutral-900">
                								<div className="flex flex-row items-start justify-start relative gap-[10px]">
                  									<img className="w-10 relative h-10 z-[0]" alt="" src="assets/image_file.svg" />
                								</div>
                								<div className="relative font-semibold">Correspondence_JDoe.png</div>
              							</div>
              							<div className="self-stretch relative">AI has identified language patterns suggesting a possible dispute.</div>
              							<div className="self-stretch flex flex-row items-center justify-start gap-[6px]">
                								<img className="w-5 relative h-5" alt="" src="assets/calendar.svg" />
                								<div className="relative">09/02/24</div>
              							</div>
            						</div>
          					</div>
          					<div className="rounded-xl bg-semantics-moderate-moderate-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-semantics-moderate-moderate-500 border-[1px] border-solid border-semantics-moderate-moderate-500">
            						<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
            						<div className="relative font-semibold">Medium</div>
          					</div>
        				</div>
        				<div className="w-[400px] box-border flex flex-col items-end justify-start py-4 px-6 border-t-[1px] border-solid border-neutrals-neutral-100">
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="flex flex-row items-center justify-center">
              							<div className="relative font-semibold">View document</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[1361px] left-[96px] flex flex-col items-start justify-start gap-[24px] text-lg">
        				<div className="w-[1248px] box-border flex flex-row items-start justify-between pt-0 px-0 pb-6 border-b-[1px] border-solid border-neutrals-neutral-100">
          					<div className="flex flex-col items-start justify-start gap-[6px]">
            						<div className="relative font-semibold">AI insights</div>
            						<div className="relative text-base text-neutrals-neutral-300">List of all green flag documents.</div>
          					</div>
          					<div className="rounded-3xs bg-primary-primary-50 flex flex-row items-center justify-start py-2.5 px-3.5 gap-[6px] text-sm text-primary-primary-500">
            						<img className="w-[23px] relative h-[22.6px]" alt="" src="assets/AI.svg" />
            						<div className="relative font-semibold">Powered by AI</div>
          					</div>
        				</div>
        				<div className="flex flex-col items-start justify-start gap-[24px]">
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="w-[1200px] flex flex-row items-center justify-between">
              							<div className="relative font-semibold">Trends over time</div>
              							<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 cursor-pointer text-sm border-[1px] border-solid border-neutrals-neutral-100" onClick={openExportModalImage}>
                								<div className="flex flex-row items-center justify-center gap-[6px]">
                  									<img className="w-5 relative h-5" alt="" src="assets/export.svg" />
                  									<div className="relative font-semibold">Export</div>
                								</div>
              							</div>
            						</div>
            						<div className="flex flex-col items-start justify-start gap-[24px] text-sm">
              							<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-start border-[1px] border-solid border-neutrals-neutral-100">
                								<div className="rounded-tl-3xs rounded-tr-none rounded-br-none rounded-bl-3xs bg-column-bg flex flex-row items-center justify-center py-2 px-4 border-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative font-semibold">Days</div>
                								</div>
                								<div className="flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative font-semibold">Weeks</div>
                								</div>
                								<div className="flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="relative font-semibold">Months</div>
                								</div>
                								<div className="rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none flex flex-row items-center justify-center py-2 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-b-[1px]">
                  									<div className="flex flex-row items-center justify-center gap-[6px]">
                    										<img className="w-5 relative h-5" alt="" src="assets/add.svg" />
                    										<div className="relative font-semibold">Custom</div>
                  									</div>
                								</div>
              							</div>
              							<div className="flex flex-col items-end justify-start gap-[24px] text-neutrals-neutral-300">
                								<div className="flex flex-row items-center justify-start gap-[6px]">
                  									<div className="w-2 relative rounded-[50%] bg-primary-primary-500 h-2" />
                  									<div className="relative">Red flags</div>
                								</div>
                								<div className="w-[1200px] relative h-[249.5px] text-center text-neutrals-neutral-900">
                  									<div className="absolute top-[0px] left-[0px] flex flex-row items-center justify-start gap-[10px]">
                    										<div className="relative font-semibold [transform:_rotate(-90deg)]">Number of flags</div>
                    										<div className="h-[201px] flex flex-col items-end justify-start gap-[11px] text-left text-neutrals-neutral-300">
                      											<div className="relative">6</div>
                      											<div className="relative">5</div>
                      											<div className="relative">4</div>
                      											<div className="relative">3</div>
                      											<div className="relative">2</div>
                      											<div className="relative">1</div>
                      											<div className="relative">0</div>
                    										</div>
                  									</div>
                  									<div className="absolute top-[10.5px] left-[48px] flex flex-col items-start justify-start gap-[11px] text-neutrals-neutral-300">
                    										<div className="flex flex-col items-start justify-start relative gap-[10px]">
                      											<div className="w-[1152px] flex flex-col items-start justify-start gap-[30px] z-[0]">
                        												<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                        												<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                        												<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                        												<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                        												<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                        												<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                        												<div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                      											</div>
                      											<div className="w-[1152px] !m-[0] absolute top-[32.5px] left-[0px] h-[114.5px] flex flex-row items-start justify-start p-2.5 box-border z-[1]">
                        												<img className="w-[1152px] absolute !m-[0] top-[0.5px] left-[0px] h-[146px] z-[0]" alt="" src="Group 231.svg" />
                      											</div>
                    										</div>
                    										<div className="flex flex-col items-start justify-start gap-[10px]">
                      											<div className="w-[1152px] flex flex-row items-start justify-between">
                        												<div className="relative">Mon</div>
                        												<div className="relative">Tue</div>
                        												<div className="relative">Wed</div>
                        												<div className="relative">Thu</div>
                        												<div className="relative">Fri</div>
                        												<div className="relative">Sat</div>
                        												<div className="relative">Sun</div>
                      											</div>
                      											<div className="w-[1152px] relative font-semibold text-neutrals-neutral-900 inline-block">Time</div>
                    										</div>
                  									</div>
                								</div>
              							</div>
            						</div>
          					</div>
          					<div className="flex flex-row items-end justify-start gap-[24px]">
            						<div className="w-[434px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-[403px] flex flex-col items-start justify-start p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="self-stretch bg-neutrals-neutral-50 flex flex-row items-center justify-between pt-0 px-0 pb-6 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="relative font-semibold">Accuracy metrics</div>
                								<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg"/>
              							</div>
              							<div className="self-stretch flex flex-row items-start justify-start gap-[24px] text-center text-sm text-neutrals-neutral-300">
                								<div className="w-60 h-60 flex flex-row items-center justify-center relative gap-[10px]">
                  									<div className="flex flex-row items-start justify-start relative gap-[10px] z-[0]">
                    										<img className="w-[234px] relative h-[234px] z-[0]" alt="" src="Group 169.svg" />
                    										<img className="w-[194px] absolute !m-[0] top-[calc(50%_-_97px)] left-[calc(50%_-_98px)] h-[194px] z-[1]" alt="" src="Group 168.svg" />
                    										<img className="w-[159.4px] absolute !m-[0] top-[37.32px] left-[36.32px] h-[159.4px] z-[2]" alt="" src="Group 167.svg" />
                  									</div>
                  									<div className="!m-[0] absolute top-[90px] left-[90px] flex flex-col items-center justify-start gap-[2px] z-[1]">
                    										<div className="relative">Total</div>
                    										<b className="relative text-9xl text-neutrals-neutral-900 text-left">85%</b>
                  									</div>
                								</div>
                								<div className="flex-1 flex flex-col items-start justify-start gap-[24px] text-right">
                  									<div className="flex flex-row items-start justify-start gap-[6px]">
                    										<div className="flex flex-row items-start justify-start pt-1.5 px-0 pb-0">
                      											<div className="w-2 relative rounded-[50%] bg-primary-primary-500 h-2" />
                    										</div>
                    										<div className="flex flex-col items-start justify-start gap-[4px]">
                      											<div className="relative">Overall accuracy</div>
                      											<div className="relative text-5xl font-semibold text-neutrals-neutral-900 text-left">85%</div>
                    										</div>
                  									</div>
                  									<div className="flex flex-row items-start justify-start gap-[6px]">
                    										<div className="flex flex-row items-start justify-start pt-1.5 px-0 pb-0">
                      											<div className="w-2 relative rounded-[50%] bg-primary-primary-300 h-2" />
                    										</div>
                    										<div className="flex flex-col items-start justify-start gap-[4px]">
                      											<div className="relative">False positive</div>
                      											<div className="relative text-5xl font-semibold text-neutrals-neutral-900 text-left">15%</div>
                    										</div>
                  									</div>
                  									<div className="flex flex-row items-start justify-start gap-[6px]">
                    										<div className="flex flex-row items-start justify-start pt-1.5 px-0 pb-0">
                      											<div className="w-2 relative rounded-[50%] bg-primary-primary-100 h-2" />
                    										</div>
                    										<div className="flex flex-col items-start justify-start gap-[4px]">
                      											<div className="relative">False negative</div>
                      											<div className="relative text-5xl font-semibold text-neutrals-neutral-900 text-left">10%</div>
                    										</div>
                  									</div>
                								</div>
              							</div>
            						</div>
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-[404px] flex flex-col items-start justify-start p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="w-[352px] box-border flex flex-row items-center justify-between pt-0 px-0 pb-6 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="relative font-semibold">Overall accuracy</div>
                								<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
              							</div>
              							<div className="flex flex-row items-start justify-start gap-[10px] text-center text-sm text-neutrals-neutral-300">
                								<div className="flex flex-row items-start justify-start relative gap-[10px]">
                  									<img className="w-60 relative h-60 z-[0]" alt="" src="Group 213.svg" />
                  									<div className="!m-[0] absolute top-[90.5px] left-[91px] flex flex-col items-center justify-start gap-[2px] z-[1]">
                    										<div className="relative">Total</div>
                    										<b className="relative text-9xl text-neutrals-neutral-900 text-left">85%</b>
                  									</div>
                								</div>
                								<div className="flex flex-col items-start justify-start gap-[10px] text-left text-neutrals-neutral-900">
                  									<div className="flex flex-row items-center justify-start gap-[6px]">
                    										<div className="w-2 relative rounded-[50%] bg-primary-primary-500 h-2" />
                    										<div className="relative">True positive</div>
                  									</div>
                  									<div className="flex flex-row items-center justify-start gap-[6px]">
                    										<div className="w-2 relative rounded-[50%] bg-primary-primary-50 h-2" />
                    										<div className="relative">False positive</div>
                  									</div>
                								</div>
              							</div>
            						</div>
          					</div>
        				</div>
      			</div>
    		</div>
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}
    		{isDefenceStatementImagePrevieOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeDefenceStatementImagePrevie}
        				>
        				<DefenceStatementImagePrevie onClose={closeDefenceStatementImagePrevie} />
      			</PortalPopup>
    		)}
    		{isExportModalImageOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeExportModalImage}
        				>
        				<ExportModalImage onClose={closeExportModalImage} />
      			</PortalPopup>
    		)}</>);
};

export default AI_Indicators_2;