import CTAs from "../components/CTAs";

const LBAReminder = ({onClose}) => {
    return (
          <div className="relative shadow-[0px_10px_60px_rgba(0,_0,_0,_0.1)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start max-w-full max-h-full overflow-auto text-left text-9xl text-neutrals-neutral-900 font-open-sans-ff-heading-1 border-[1px] border-solid border-neutrals-neutral-100">
                <div className="flex flex-col items-start justify-start gap-[40px]">
                      <div className="flex flex-col items-start justify-start gap-[24px]">
                            <div className="w-[480px] flex flex-col items-start justify-start pt-6 px-6 pb-0 box-border">
                                  <div className="self-stretch flex flex-col items-start justify-start">
                                        <div className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-6 gap-[6px] border-b-[1px] border-solid border-neutrals-neutral-100">
                                              <b className="relative">Adjust reminder</b>
                                              <div className="relative text-sm text-neutrals-neutral-300">Adjust your reminders for LBA.</div>
                                        </div>
                                  </div>
                            </div>
                            <div className="self-stretch flex flex-col items-start justify-start py-0 px-6 gap-[24px] text-base">
                                  <div className="relative">
                                        <span>
                                              <span className="font-semibold">Current frequency:</span>
                                              <span className="text-neutrals-neutral-300">{` `}</span>
                                        </span>
                                        <span className="text-neutrals-neutral-300">
                                              <span>Weekly</span>
                                        </span>
                                  </div>
                                  <div className="self-stretch flex flex-col items-start justify-start gap-[16px] text-sm">
                                        <div className="self-stretch flex flex-row items-start justify-start">
                                              <div className="w-[432px] flex flex-col items-start justify-start gap-[10px]">
                                                    <div className="flex flex-row items-start justify-start gap-[6px]">
                                                          <div className="relative font-semibold">Select your frequency</div>
                                                          <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg"/>
                                                    </div>
                                                    <div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-end justify-between py-3.5 px-2.5 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
                                                          <div className="w-[165px] relative inline-block shrink-0">Select frequency</div>
                                                          <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/arrow-down.svg"/>
                                                    </div>
                                              </div>
                                        </div>
                                        <div className="self-stretch flex flex-row items-start justify-start">
                                              <div className="w-[432px] flex flex-row items-start justify-start">
                                                    <div className="w-[432px] flex flex-col items-start justify-start gap-[10px]">
                                                          <div className="flex flex-row items-start justify-start gap-[6px]">
                                                                <div className="relative font-semibold">Customise reminder time</div>
                                                                <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg"/>
                                                          </div>
                                                          <div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-end justify-start py-3.5 px-2.5 gap-[293px] text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
                                                                <div className="relative">Select time</div>
                                                                <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/arrow-down.svg"/>
                                                          </div>
                                                    </div>
                                              </div>
                                        </div>
                                  </div>
                            </div>
                      </div>
                      <div className="flex flex-row items-start justify-start pt-0 px-6 pb-6 gap-[12px] text-sm">
                        <CTAs
                        type="Ghostbtn"
                        text="Cancel"
                        textColor="text-neutrals-neutral-900"
                        bgColor="bg-neutrals-neutral-50"
                        borderColor="border-neutrals-neutral-100"
                        width="210px"
                        position="relative"
                        onClick={onClose}
                        />

                        <CTAs
                        type="Primarybtn"
                        text="Save"
                        textColor="text-neutrals-neutral-50"
                        bgColor="bg-primary-primary-500"
                        borderColor="border-primary-primary-500"
                        width="210px"
                        position="relative"
                        onClick={onClose}
                        />
                      </div>
                </div>
          </div>);
};

export default LBAReminder;