import React, { useState } from "react";

const RadioButton = ({ options }) => {
  const [selectedOption, setSelectedOption] = useState(options[0]); // Default selected option

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  return (
    <div className="flex space-x-4">
      {options.map((option, index) => (
        <div
          key={index}
          className={`flex items-center gap-2 cursor-pointer ${
            option === selectedOption
              ? "text-neutrals-neutral-900"
              : "text-neutrals-neutral-900"
          }`}
          onClick={() => handleOptionChange(option)}
        >
          <img
            className="w-5 h-5"
            src={
              option === selectedOption
                ? "assets/radio-button-checked.svg"
                : "assets/radio-button-unchecked.svg"
            }
            alt=""
          />
          <span className="font-open-sans-ff-body">{option}</span>
        </div>
      ))}
    </div>
  );
};

export default RadioButton;