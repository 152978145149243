import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CTAs from './CTAs';

const LBAConfirmation = ({ onClose }) => {
  const navigate = useNavigate();

  const onCTAsContainer1Click = useCallback(() => {
    navigate('/postlba');
  }, [navigate]);

  return (
    <div className="relative shadow-[0px_10px_60px_rgba(0,_0,_0,_0.1)] rounded-3xs bg-neutrals-neutral-50 box-border w-full flex flex-col items-start justify-start p-6 gap-[40px] text-left text-lg text-neutrals-neutral-900 font-open-sans-ff-meta-strong border-[1px] border-solid border-neutrals-neutral-100">
      <div className="w-[386px] flex flex-row items-start justify-between">
        <div className="flex-1 flex flex-col items-start justify-start gap-[10px]">
          <div className="rounded-3xs bg-semantics-low-low-50 flex flex-row items-start justify-start p-2.5">
            <img className="w-5 relative h-5" alt="" src="assets/tick-circle.svg" />
          </div>
          <div className="flex flex-col items-start justify-start gap-[6px]">
            <div className="relative font-semibold">LBA form sent</div>
            <div className="w-[265px] relative text-base text-neutrals-neutral-300 inline-block">Continue with further actions.</div>
          </div>
        </div>
        <img className="w-5 relative h-5 overflow-hidden shrink-0 cursor-pointer" alt="Close" src="assets/cancel.svg" onClick={onClose} />
      </div>

      <div className="flex flex-row items-start justify-start gap-[10px] text-sm">
        <CTAs
          type="Ghostbtn"
          text="Cancel"
          textColor="text-neutrals-neutral-900"
          bgColor="bg-neutrals-neutral-50"
          borderColor="border-neutrals-neutral-100"
          width="188px"
          position="relative"
          onClick={onClose}
        />

        <CTAs
          type="Primarybtn"
          text="Continue"
          textColor="text-neutrals-neutral-50"
          bgColor="bg-primary-primary-500"
          borderColor="border-primary-primary-500"
          width="188px"
          position="relative"
          onClick={onCTAsContainer1Click}
        />
      </div>
    </div>
  );
};

export default LBAConfirmation;