import { useState, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import NavigationDropdown from "../../components/NavigationDropdown";
import NavigationPanel from "../../components/NavigationPanel";
import PortalPopup from "../../components/PortalPopup";
import LogoutConfirmation from "../../components/LogoutConfirmation";
import Breadcrumb from "../../components/Breadcrumb";
import CTAs from '../../components/CTAs';

const DefenceAndCaseHandling_1 = () => {
	const navigate = useNavigate();
  	const [isNavigationDropdownOpen, setNavigationDropdownOpen] = useState(false);
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	
  	const openNavigationDropdown = useCallback(() => {
    		setNavigationDropdownOpen(true);
  	}, []);
  	
  	const closeNavigationDropdown = useCallback(() => {
    		setNavigationDropdownOpen(false);
  	}, []);
  	
  	
  	const openLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(true);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);
  	
  	
  	const onCTAsContainerClick = useCallback(() => {
		navigate('/defence2');
  	}, [navigate]);

	const navItems = [
		{
			text: 'Debt collection', 
    		bgColor: 'bg-primary-primary-50', 
    		textColor: 'text-neutrals-neutral-900', 
    		hoverBgColor: 'bg-primary-primary-50', 
    		hoverTextColor: 'text-neutrals-neutral-900', 
    		path: "",
    		icon: 'assets/arrow-down.svg'
		},

		{
			text: "Outcomes & costs",
			bgColor: 'bg-neutrals-neutral-50', 
    		textColor: 'text-neutrals-neutral-300', 
    		hoverBgColor: 'bg-neutrals-neutral-50', 
    		hoverTextColor: 'text-primary-primary-500', 
    		path: "/outcome&cost",
    		icon: ''
		},

		{
			text: "AI indicators",
			bgColor: 'bg-neutrals-neutral-50', 
    		textColor: 'text-neutrals-neutral-300', 
    		hoverBgColor: 'bg-neutrals-neutral-50', 
    		hoverTextColor: 'text-primary-primary-500', 
    		path: "/ai1",
    		icon: ''
		},
	];

	const breadcrumb = [
		{ label: "", path: "/postlba2", icon: "assets/home.svg", active: true },
		{ label: "Defence and case handling", path: "", icon: "", active: false },
	];
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1024px] overflow-hidden text-left text-base text-neutrals-neutral-900 font-open-sans-ff-heading-1">
      			<div className="absolute top-[941px] right-[96px] text-sm text-neutrals-neutral-300">Copyright © Think-AI 2023</div>
      			<div className="absolute top-[179px] left-[96px] bg-neutrals-neutral-50 box-border w-[1248px] flex flex-col items-start justify-start pt-0 px-0 pb-6 text-9xl border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<b className="relative">Defence and case handling</b>
          					<div className="relative text-base text-neutrals-neutral-300">Acknowledge defences and revert back.</div>
        				</div>
      			</div>

				<div className="absolute top-[294.5px] left-[1273px] text-primary-primary-500 text-right">See more</div>

				{/* Navigation Panel */}
				<NavigationPanel navItems={navItems}/>

				{/* Breadcrumb Navigation */}
				<Breadcrumb breadcrumbs={breadcrumb} textColor="text-primary-primary-500" position="top-[24px] left-[90px]"/>

				{/* Debtor List */}
      			<div className="w- full relative top-[265px] left-[96px] flex flex-row flex-wrap items-start justify-start gap-[24px]">
        				<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="w-[294px] h-[125px] flex flex-col items-start justify-between p-6 box-border">
            						<div className="self-stretch flex flex-row items-start justify-between">
              							<div className="relative font-semibold">Paras Mistry</div>
              							<div className="rounded-xl bg-semantics-moderate-moderate-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm text-semantics-moderate-moderate-500 border-[1px] border-solid border-semantics-moderate-moderate-500">
                								<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
                								<div className="relative font-semibold">Pending</div>
              							</div>
            						</div>
            						<b className="relative text-13xl">£2000</b>
          					</div>
          					<div className="w-[294px] box-border flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
								<CTAs
								type="Ghostbtn"
								text="View details"
								textColor="text-neutrals-neutral-900"
								bgColor="bg-neutrals-neutral-50"
								borderColor="border-neutrals-neutral-100"
								position="relative"
								onClick={onCTAsContainerClick}
								/>
          					</div>
        				</div>
        				<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="w-[294px] h-[125px] flex flex-col items-start justify-between p-6 box-border">
            						<div className="self-stretch flex flex-row items-start justify-between">
              							<div className="relative font-semibold">Nathan James</div>
              							<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm text-semantics-low-low-500 border-[1px] border-solid border-semantics-low-low-500">
                								<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                								<div className="relative font-semibold">In progress</div>
              							</div>
            						</div>
            						<b className="relative text-13xl">£1000</b>
          					</div>
          					<div className="w-[294px] box-border flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
							  <CTAs
								type="Ghostbtn"
								text="View details"
								textColor="text-neutrals-neutral-900"
								bgColor="bg-neutrals-neutral-50"
								borderColor="border-neutrals-neutral-100"
								position="relative"
								/>
          					</div>
        				</div>
        				<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="w-[294px] h-[125px] flex flex-col items-start justify-between p-6 box-border">
            						<div className="self-stretch flex flex-row items-start justify-between">
              							<div className="relative font-semibold">Baanji</div>
              							<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm text-semantics-low-low-500 border-[1px] border-solid border-semantics-low-low-500">
                								<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                								<div className="relative font-semibold">In progress</div>
              							</div>
            						</div>
            						<b className="relative text-13xl">£1500</b>
          					</div>
          					<div className="w-[294px] box-border flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
							  	<CTAs
								type="Ghostbtn"
								text="View details"
								textColor="text-neutrals-neutral-900"
								bgColor="bg-neutrals-neutral-50"
								borderColor="border-neutrals-neutral-100"
								position="relative"
								/>
          					</div>
        				</div>
        				<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="w-[294px] h-[125px] flex flex-col items-start justify-between p-6 box-border">
            						<div className="self-stretch flex flex-row items-start justify-between">
              							<div className="relative font-semibold">Megan Gibson</div>
              							<div className="rounded-xl bg-semantics-moderate-moderate-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm text-semantics-moderate-moderate-500 border-[1px] border-solid border-semantics-moderate-moderate-500">
                								<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
                								<div className="relative font-semibold">Pending</div>
              							</div>
            						</div>
            						<b className="relative text-13xl">£2000</b>
          					</div>
          					<div className="w-[294px] box-border flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
							  	<CTAs
								type="Ghostbtn"
								text="View details"
								textColor="text-neutrals-neutral-900"
								bgColor="bg-neutrals-neutral-50"
								borderColor="border-neutrals-neutral-100"
								position="relative"
								/>
          					</div>
        				</div>
        				<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="w-[294px] h-[125px] flex flex-col items-start justify-between p-6 box-border">
            						<div className="self-stretch flex flex-row items-start justify-between">
              							<div className="relative font-semibold">Julie Wyse</div>
              							<div className="rounded-xl bg-semantics-moderate-moderate-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm text-semantics-moderate-moderate-500 border-[1px] border-solid border-semantics-moderate-moderate-500">
                								<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
                								<div className="relative font-semibold">Pending</div>
              							</div>
            						</div>
            						<b className="relative text-13xl">£20,000</b>
          					</div>
          					<div className="w-[294px] box-border flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
							  	<CTAs
								type="Ghostbtn"
								text="View details"
								textColor="text-neutrals-neutral-900"
								bgColor="bg-neutrals-neutral-50"
								borderColor="border-neutrals-neutral-100"
								position="relative"
								/>
          					</div>
        				</div>
        				<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="w-[294px] h-[125px] flex flex-col items-start justify-between p-6 box-border">
            						<div className="self-stretch flex flex-row items-start justify-between">
              							<div className="relative font-semibold">Elisa Gashi</div>
              							<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm text-semantics-low-low-500 border-[1px] border-solid border-semantics-low-low-500">
                								<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                								<div className="relative font-semibold">In progress</div>
              							</div>
            						</div>
            						<b className="relative text-13xl">£8000</b>
          					</div>
          					<div className="w-[294px] box-border flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
							  	<CTAs
								type="Ghostbtn"
								text="View details"
								textColor="text-neutrals-neutral-900"
								bgColor="bg-neutrals-neutral-50"
								borderColor="border-neutrals-neutral-100"
								position="relative"
								/>
          					</div>
        				</div>
        				<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="w-[294px] h-[125px] flex flex-col items-start justify-between p-6 box-border">
            						<div className="self-stretch flex flex-row items-start justify-between">
              							<div className="relative font-semibold">Ervins Puksts</div>
              							<div className="rounded-xl bg-semantics-moderate-moderate-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm text-semantics-moderate-moderate-500 border-[1px] border-solid border-semantics-moderate-moderate-500">
                								<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
                								<div className="relative font-semibold">Pending</div>
              							</div>
            						</div>
            						<b className="relative text-13xl">£2000</b>
          					</div>
          					<div className="w-[294px] box-border flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
							  	<CTAs
								type="Ghostbtn"
								text="View details"
								textColor="text-neutrals-neutral-900"
								bgColor="bg-neutrals-neutral-50"
								borderColor="border-neutrals-neutral-100"
								position="relative"
								/>
          					</div>
        				</div>
        				<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="w-[294px] h-[125px] flex flex-col items-start justify-between p-6 box-border">
            						<div className="self-stretch flex flex-row items-start justify-between">
              							<div className="relative font-semibold">Diane Elgar</div>
              							<div className="rounded-xl bg-semantics-moderate-moderate-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm text-semantics-moderate-moderate-500 border-[1px] border-solid border-semantics-moderate-moderate-500">
                								<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
                								<div className="relative font-semibold">Pending</div>
              							</div>
            						</div>
            						<b className="relative text-13xl">£50,000</b>
          					</div>
          					<div className="w-[294px] box-border flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
							  	<CTAs
								type="Ghostbtn"
								text="View details"
								textColor="text-neutrals-neutral-900"
								bgColor="bg-neutrals-neutral-50"
								borderColor="border-neutrals-neutral-100"
								position="relative"
								/>
          					</div>
        				</div>
      			</div>
				
      			<div className="absolute top-[293px] left-[96px] box-border w-[1248px] flex flex-col items-start justify-start pt-0 px-0 pb-6 gap-[6px] text-lg border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-row items-center justify-start gap-[10px]">
          					<div className="relative font-semibold">Overview</div>
          					<div className="rounded-[5px] bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="w-1.5 relative rounded-[50%] bg-primary-primary-500 h-1.5" />
            						<div className="relative font-semibold">15 cases</div>
          					</div>
        				</div>
        				<div className="relative text-base text-neutrals-neutral-300">List of cases with pending defences.</div>
      			</div>
    		</div>
    		{isNavigationDropdownOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeNavigationDropdown}
        				>
        				<NavigationDropdown onClose={closeNavigationDropdown} />
      			</PortalPopup>
    		)}
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}</>);
};

export default DefenceAndCaseHandling_1;