import { useCallback, useState } from 'react';
import { Navigate, useNavigate} from "react-router-dom";

const NavigationDropdown = () => {
  	const navigate = useNavigate();
  	
  	const onPostLBAActionsClick = useCallback(() => {
    		navigate("/postlba");
  	}, [navigate]);
  	
  	const onLetterBeforeActionClick = useCallback(() => {
		navigate('/lba');
  	}, [navigate]);
  	
  	const onLatePaymentDemandClick = useCallback(() => {
		navigate('/lpd');
  	}, [navigate]);
  	
  	const onParticularsOfClaimClick = useCallback(() => {
		navigate('/claimform');
  	}, [navigate]);
  	
  	const onDefenceCaseClick = useCallback(() => {
		navigate('/defence1');
  	}, [navigate]);
  	
  	return (
    		<div className="w-screen relative shadow-[0px_4px_10px_rgba(0,_0,_0,_0.05)] bg-neutrals-neutral-50 flex flex-col items-start justify-start py-6 pr-6 pl-24 box-border gap-[24px] max-w-full max-h-full overflow-auto text-left text-sm text-neutrals-neutral-300 font-open-sans-ff-body-strong">
      			<div className="relative">Explore debt collection</div>
      			<div className="flex flex-col items-start justify-start gap-[10px] text-lg text-neutrals-neutral-900">
        				<div className="relative font-semibold cursor-pointer" onClick={onPostLBAActionsClick}>Post LBA actions</div>
        				<div className="relative font-semibold cursor-pointer" onClick={onLetterBeforeActionClick}>Letter before action (LBA)</div>
        				<div className="relative font-semibold cursor-pointer" onClick={onLatePaymentDemandClick}>Late payment demand (LPD)</div>
        				<div className="relative font-semibold cursor-pointer" onClick={onParticularsOfClaimClick}>Particulars of claim</div>
        				<div className="relative font-semibold cursor-pointer" onClick={onDefenceCaseClick}>{`Defence & case handling`}</div>
      			</div>
    		</div>);
};

export default NavigationDropdown;