// import React, { useState, useCallback } from "react";
// import ClientAccountTypeStates from "./ClientAccountTypeStates";
// import { Navigate, useNavigate } from 'react-router-dom';
// import CTAs from "./CTAs";

// const AccountType = () => {
//     const navigate = useNavigate();
//     const [ctaActive, setCtaActive] = useState(false);

//     const handleContinueClick = useCallback(() => {
//         navigate('/register1');
//     }, [navigate]);

//   const handleBlockClick = (block) => {
//     setCtaActive(true);
//   };

//   const handleNoBlockClick = () => {
//     setCtaActive(false);
//   };

//   return (
//     <div className="flex flex-col items-center">
//       <div className="flex gap-6">
//         <ClientAccountTypeStates onBlockClick={handleBlockClick} />
//       </div>
//       <div className="mt-10">
//         <CTAs
//           type="Primarybtn"
//           text="Continue"
//           textColor={ctaActive ? "text-neutrals-neutral-50" : "text-neutrals-neutral-300"}
//           bgColor={ctaActive ? "bg-primary-primary-500" : "bg-primary-primary-50"}
//           width="400px"
//           borderColor={ctaActive ? "border-primary-primary-500" : "border-neutrals-neutral-100"}
//           position="relative"
//           onClick={ctaActive ? () => {alert("CTA Clicked!")} : null}
//         />
//       </div>
//     </div>
//   );
// };

// export default AccountType;

//Code 2
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ClientAccountTypeStates from "./ClientAccountTypeStates";
import CTAs from "./CTAs";

const AccountType = () => {
  const [selectedBlock, setSelectedBlock] = useState(null);
  const navigate = useNavigate();

  const handleBlockClick = (block) => {
    setSelectedBlock(block);
  };

  const handleCTAClick = () => {
    if (selectedBlock === "block1") {
      navigate("/register1");
    } else if (selectedBlock === "block2") {
      navigate("");
    }
  };

  return (
    <div className="flex flex-col items-center">
      <div className="flex gap-6">
        <ClientAccountTypeStates onBlockClick={handleBlockClick} />
      </div>
      <div className="mt-10">
        <CTAs
          type="Primarybtn"
          text="Continue"
          textColor={selectedBlock ? "text-neutrals-neutral-50" : "text-neutrals-neutral-300"}
          bgColor={selectedBlock ? "bg-primary-primary-500" : "bg-primary-primary-50"}
          width="400px"
          borderColor={selectedBlock ? "border-primary-primary-500" : "border-neutrals-neutral-100"}
          position="relative"
          onClick={selectedBlock ? handleCTAClick : null}
        />
      </div>
    </div>
  );
};

export default AccountType;