import { useState, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import InvoiceImagePreview from "../../components/InvoiceImagePreview";
import PortalPopup from "../../components/PortalPopup";
import LogoutConfirmation from "../../components/LogoutConfirmation";
import NavigationPanelLawyer from '../../components/NavigationPanelLawyer';

const CDI_4 = () => {
    const navigate = useNavigate();
  	const [isInvoiceImagePreviewOpen, setInvoiceImagePreviewOpen] = useState(false);
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	
  	const onTabStatesContainerClick = useCallback(() => {
    		// Add your code here
  	}, []);
  	
  	
  	const onFrameContainer2Click = useCallback(() => {
        navigate('/cdi2');
    		// Add your code here
  	}, [navigate]);
  	
  	
  	const openInvoiceImagePreview = useCallback(() => {
    		setInvoiceImagePreviewOpen(true);
  	}, []);
  	
  	const closeInvoiceImagePreview = useCallback(() => {
    		setInvoiceImagePreviewOpen(false);
  	}, []);
  	
  	
  	const onDebtCollectionNavContainer3Click = useCallback(() => {
    		// Add your code here
  	}, []);
  	
  	
  	const openLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(true);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);

	const navItems = [
		{ text: "Dashboard", bgColor: "bg-neutrals-neutral-50", textColor: "text-neutrals-neutral-300", hoverBgColor: "bg-neutrals-neutral-50", hoverTextColor: "text-primary-primary-500", path: "" },
		{ text: "Legal proceedings & billing", bgColor: "bg-neutrals-neutral-50", textColor: "text-neutrals-neutral-300", hoverBgColor: "bg-neutrals-neutral-50", hoverTextColor: "text-primary-primary-500", path: "/legal_1" },
		{ text: "Pre-LBA checks", bgColor: "bg-neutrals-neutral-50", textColor: "text-neutrals-neutral-300", hoverBgColor: "bg-neutrals-neutral-50", hoverTextColor: "text-primary-primary-500", path: "/prelbacheck" },
		{ text: "Client-debtor interaction", bgColor: "bg-primary-primary-50", textColor: "text-neutrals-neutral-900", hoverBgColor: "bg-primary-primary-50", hoverTextColor: "text-neutrals-neutral-900", path: "/cdi1" },
	];
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1718px] overflow-hidden text-left text-base text-neutrals-neutral-900 font-open-sans-ff-heading-2">
      			<div className="absolute top-[1635px] right-[96px] text-sm text-neutrals-neutral-300">Copyright © Think-AI 2023</div>
      			<b className="absolute top-[128px] left-[96px] text-9xl">Client-debtor interaction</b>
      			<div className="absolute top-[172px] left-[96px] text-neutrals-neutral-300 inline-block w-[400px]">{`Get all your information for client & debtor over here.`}</div>
      			<div className="absolute top-[218px] left-[96px] flex flex-row items-start justify-start gap-[24px] text-neutrals-neutral-300">
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 box-border cursor-pointer" onClick={onTabStatesContainerClick}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Client instructions</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 box-border h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 text-primary-primary-500 border-b-[2px] border-solid border-primary-primary-500">
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Handling defences</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[283px] left-[96px] flex flex-col items-start justify-start gap-[6px] text-lg">
        				<div className="relative font-semibold">Debt collection - 123</div>
        				<div className="relative text-base text-neutrals-neutral-300">#REF-123</div>
      			</div>
      			<div className="absolute top-[360px] left-[96px] flex flex-row items-center justify-start gap-[6px] cursor-pointer" onClick={onFrameContainer2Click}>
        				<img className="w-5 relative h-5" alt="" src="assets/arrow-left.svg" />
        				<div className="relative">Back to list</div>
      			</div>
      			<div className="absolute top-[422px] left-[96px] flex flex-col items-start justify-start gap-[6px] text-lg">
        				<div className="relative font-semibold">Debtor’s response</div>
        				<div className="relative text-base">I dispute the debt due to incomplete services rendered. Attached are relevant invoices and communications.</div>
      			</div>
      			<div className="absolute top-[499px] left-[96px]">
        				<p className="m-0 font-semibold">Key arguments:</p>
        				<ul className="m-0 text-inherit pl-[21px]">
          					<li className="mb-0">
            						<span>Incomplete services</span>
          					</li>
          					<li>
            						<span>Request for additional documentation</span>
          					</li>
        				</ul>
      			</div>
      			<div className="absolute top-[1102px] left-[96px] flex flex-col items-start justify-start gap-[24px] text-lg">
        				<div className="w-[1248px] box-border flex flex-col items-start justify-start pt-0 px-0 pb-6 border-b-[1px] border-solid border-neutrals-neutral-100">
          					<div className="self-stretch flex flex-row items-start justify-between">
            						<div className="flex flex-col items-start justify-start gap-[6px]">
              							<div className="relative font-semibold">Attached documents</div>
              							<div className="relative text-base text-neutrals-neutral-300">List of all attached documents.</div>
            						</div>
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 text-sm border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center gap-[6px]">
                								<img className="w-5 relative h-5" alt="" src="assets/download.svg" />
                								<div className="relative font-semibold">Download all</div>
              							</div>
            						</div>
          					</div>
        				</div>
        				<div className="w-[1248px] flex flex-row flex-wrap items-start justify-start gap-[24px] text-base">
          					<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="self-stretch flex flex-row items-start justify-between p-6">
              							<div className="flex flex-col items-start justify-start gap-[10px]">
                								<img className="w-10 relative h-10" alt="" src="assets/pdf.svg" />
                								<div className="relative font-semibold">Invoice.pdf</div>
                								<div className="relative text-neutrals-neutral-300">1.2MB</div>
              							</div>
              							<div className="relative text-neutrals-neutral-300">29/01/24</div>
            						</div>
            						<div className="self-stretch flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-start justify-end gap-[10px]">
                								<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 cursor-pointer border-[1px] border-solid border-neutrals-neutral-100" onClick={openInvoiceImagePreview}>
                  									<div className="flex flex-row items-center justify-center gap-[6px]">
                    										<img className="w-5 relative h-5" alt="" src="assets/preview.svg" />
                    										<div className="relative font-semibold">Preview</div>
                  									</div>
                								</div>
                								<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="flex flex-row items-center justify-center gap-[6px]">
                    										<img className="w-5 relative h-5" alt="" src="assets/download.svg" />
                    										<div className="relative font-semibold">Download</div>
                  									</div>
                								</div>
              							</div>
            						</div>
          					</div>
          					<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="self-stretch flex flex-row items-start justify-between p-6">
              							<div className="flex flex-col items-start justify-start gap-[10px]">
                								<img className="w-10 relative h-10" alt="" src="assets/pdf.svg" />
                								<div className="relative font-semibold">Email communication.pdf</div>
                								<div className="relative text-neutrals-neutral-300">2.9MB</div>
              							</div>
              							<div className="relative text-neutrals-neutral-300">29/01/24</div>
            						</div>
            						<div className="self-stretch flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-start justify-end gap-[10px]">
                								<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="flex flex-row items-center justify-center gap-[6px]">
                    										<img className="w-5 relative h-5" alt="" src="assets/preview.svg" />
                    										<div className="relative font-semibold">Preview</div>
                  									</div>
                								</div>
                								<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="flex flex-row items-center justify-center gap-[6px]">
                    										<img className="w-5 relative h-5" alt="" src="assets/download.svg" />
                    										<div className="relative font-semibold">Download</div>
                  									</div>
                								</div>
              							</div>
            						</div>
          					</div>
          					<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="self-stretch flex flex-row items-start justify-between p-6">
              							<div className="flex flex-col items-start justify-start gap-[10px]">
                								<div className="flex flex-row items-start justify-start relative gap-[10px]">
                  									<img className="w-10 relative h-10 z-[0]" alt="" src="assets/word_file.svg" />
                								</div>
                								<div className="relative font-semibold">Documentation_request.docx</div>
                								<div className="relative text-neutrals-neutral-300">24KB</div>
              							</div>
              							<div className="relative text-neutrals-neutral-300">29/01/24</div>
            						</div>
            						<div className="self-stretch flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-start justify-end gap-[10px]">
                								<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="flex flex-row items-center justify-center gap-[6px]">
                    										<img className="w-5 relative h-5" alt="" src="assets/preview.svg" />
                    										<div className="relative font-semibold">Preview</div>
                  									</div>
                								</div>
                								<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
                  									<div className="flex flex-row items-center justify-center gap-[6px]">
                    										<img className="w-5 relative h-5" alt="" src="assets/download.svg" />
                    										<div className="relative font-semibold">Download</div>
                  									</div>
                								</div>
              							</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[1491px] left-[96px] w-[400px] flex flex-col items-start justify-start gap-[10px] text-sm">
        				<div className="flex flex-row items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Response option</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg" />
        				</div>
        				<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-end justify-start py-3.5 px-2.5 gap-[175px] text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="w-[162px] relative inline-block shrink-0">Select response</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/arrow-down.svg" />
        				</div>
      			</div>
      			<div className="absolute top-[629px] left-[96px] w-[1248px] flex flex-col items-start justify-start gap-[10px] text-sm">
        				<div className="relative font-semibold">{`Upload `}</div>
        				<div className="self-stretch flex flex-col items-start justify-start gap-[24px] text-primary-primary-500">
          					<div className="self-stretch flex flex-col items-center justify-start">
            						<div className="self-stretch rounded-3xs bg-neutrals-neutral-50 box-border h-[231px] flex flex-col items-center justify-center p-4 gap-[10px] border-[2px] border-solid border-primary-primary-500">
              							<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
                								<img className="w-5 relative h-5" alt="" src="assets/document-upload.svg" />
              							</div>
              							<div className="flex flex-col items-center justify-center gap-[6px]">
                								<div className="relative">
                  									<span className="font-semibold">Click to upload</span>
                  									<span className="text-neutrals-neutral-300"> or drag and drop</span>
                								</div>
                								<div className="relative text-neutrals-neutral-300">(Max. File Size: 20 MB)</div>
              							</div>
            						</div>
          					</div>
          					<div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-center justify-center p-4 text-neutrals-neutral-900 border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="self-stretch flex flex-row items-start justify-start gap-[10px]">
              							<img className="w-10 relative h-10" alt="" src="assets/pdf.svg" />
              							<div className="flex-1 flex flex-col items-center justify-center gap-[6px]">
                								<div className="self-stretch flex flex-row items-start justify-between">
                  									<div className="flex-1 flex flex-col items-start justify-start">
                    										<div className="relative font-semibold">Contract.pdf</div>
                    										<div className="relative text-neutrals-neutral-300">220 KB</div>
                  									</div>
                  									<img className="w-5 relative h-5" alt="" src="assets/delete.svg" />
                								</div>
                								<div className="self-stretch flex flex-row items-center justify-center gap-[10px]">
                  									<div className="self-stretch flex-1 flex flex-col items-start justify-start relative gap-[10px]">
                    										<div className="self-stretch relative rounded-81xl bg-primary-primary-50 h-2 z-[0]" />
                    										<div className="w-[424px] absolute !m-[0] top-[0px] left-[0px] rounded-81xl bg-primary-primary-500 h-2 z-[1]" />
                  									</div>
                  									<div className="relative font-semibold">60%</div>
                								</div>
              							</div>
            						</div>
          					</div>
        				</div>
      			</div>

				{/* Navigation Panel */}
				<NavigationPanelLawyer navItems={navItems}/>
      			
    		</div>
    		{isInvoiceImagePreviewOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeInvoiceImagePreview}
        				>
        				<InvoiceImagePreview onClose={closeInvoiceImagePreview} />
      			</PortalPopup>
    		)}
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}</>);
};

export default CDI_4;