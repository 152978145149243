// import { useState, useCallback } from "react";

// const AddressInputField = () => {
//     return (
//           <div className="relative w-full flex flex-col items-start justify-start gap-[10px] text-left text-sm text-neutrals-neutral-900 font-open-sans-ff-body">
//                 <div className="flex flex-row items-center justify-start gap-[6px]">
//                       <div className="relative font-semibold">Address</div>
//                       <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="icons-v2.svg" />
//                 </div>
//                 <div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-[10px] bg-neutrals-neutral-50 box-border overflow-hidden flex flex-row items-center justify-start p-3 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
//                       <div className="relative">Enter your address</div>
//                 </div>
//           </div>);
// };

// export default AddressInputField;

//Code 2
import React from "react";
import { useFormik } from "formik";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-google-places-autocomplete";

const AddressInputField = () => {
  const formik = useFormik({
    initialValues: {
      address: ""
    },
    onSubmit: (values) => {
      // Handle form submission
      console.log("Submitted address:", values.address);
    },
    validate: (values) => {
      const errors = {};
      if (!values.address) {
        errors.address = "Address is required";
      }
      return errors;
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="relative w-full flex flex-col items-start justify-start gap-[10px] text-left text-sm text-neutrals-neutral-900 font-open-sans-ff-body">
        <div className="flex flex-row items-center justify-start gap-[6px]">
          <div className="relative font-semibold">Address</div>
        </div>
        <PlacesAutocomplete
          value={formik.values.address}
          onChange={(value) => formik.setFieldValue("address", value)}
          onSelect={(selected) => {
            formik.setFieldValue("address", selected.label);
            geocodeByAddress(selected.label)
              .then(results => getLatLng(results[0]))
              .then(latLng => console.log("Latlng:", latLng))
              .catch(error => console.error("Error:", error));
          }}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: "Enter your address",
                  className: "w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-[10px] bg-neutrals-neutral-50 box-border overflow-hidden flex flex-row items-center justify-start p-3 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100"
                })}
              />
              <div>
                {loading && <div>Loading...</div>}
                {suggestions.map(suggestion => (
                  <div {...getSuggestionItemProps(suggestion)}>
                    {suggestion.description}
                  </div>
                ))}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        {formik.touched.address && formik.errors.address ? (
          <div className="text-red-500">{formik.errors.address}</div>
        ) : null}
      </div>
      <button type="submit">Submit</button>
    </form>
  );
};

export default AddressInputField;