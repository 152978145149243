// import React from "react";
// import { useNavigate } from "react-router-dom";

// const CTAs = ({ type, text, textColor, bgColor, icon, width, borderColor, position, onClick }) => {
//   const navigate = useNavigate();

//   const handleClick = () => {
//     if (onClick) {
//       onClick();
//     } else {
//       // Default behavior to navigate to a specific route
//       switch (type) {
//         case "Primarybtn":
//           // Navigate to a specific route for Primarybtn
//           navigate("");
//           break;
//         case "Googlebtn":
//           // Navigate to a specific route for Googlebtn
//           navigate("");
//           break;
//         default:
//           // Default behavior or navigate to a specific route for Ghostbtn
//           navigate("");
//       }
//     }
//   };

//   return (
//     <div
//       className={`relative ${position} cursor-pointer shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs ${bgColor} ${borderColor ? `border border-solid ${borderColor}` : ""} text-sm box-border w-[${width}] flex items-center justify-center py-2.5 px-3.5`}
//       onClick={handleClick}
//     >
//       <div className="flex items-center justify-center gap-[6px]">
//         {icon && <img className="w-5 h-5" alt="" src={icon} />}
//         <div className={`font-semibold ${textColor}`}>{text}</div>
//       </div>
//     </div>
//   );
// };

// export default CTAs;

//Code 2
import React from "react";
import { useNavigate } from "react-router-dom";

const CTAs = ({ type, text, textColor, bgColor, icon, width, borderColor, position, onClick }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      // Default behavior to navigate to a specific route
      switch (type) {
        case "Primarybtn":
          // Navigate to a specific route for Primarybtn
          navigate("");
          break;
        case "Googlebtn":
          // Navigate to a specific route for Googlebtn
          navigate("");
          break;
        default:
          // Default behavior or navigate to a specific route for Ghostbtn
          navigate("");
      }
    }
  };

  return (
    <div
      className={`relative ${position} cursor-pointer shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs ${bgColor} ${borderColor ? `border border-solid ${borderColor}` : ""} text-sm box-border w-[${width}] flex items-center justify-center py-2.5 px-3.5`}
      onClick={handleClick}
    >
      <div className="flex items-center justify-center gap-[6px]">
        {icon && <img className="w-5 h-5" alt="" src={icon} />}
        <div className={`font-semibold ${textColor}`}>{text}</div>
      </div>
    </div>
  );
};

export default CTAs;