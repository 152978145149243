import { useState, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import ExportModalTables from "../../components/ExportModalTables";
import PortalPopup from "../../components/PortalPopup";
import LogoutConfirmation from "../../components/LogoutConfirmation";
import NavigationPanelLawyer from '../../components/NavigationPanelLawyer';
import CTAs from '../../components/CTAs';

const CDI_2 = () => {
    const navigate = useNavigate();
  	const [isExportModalTablesOpen, setExportModalTablesOpen] = useState(false);
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	
  	const onClickClientInstruction = useCallback(() => {
        navigate('/cdi1');
  	}, [navigate]);
  	
  	
  	const openExportModalTables = useCallback(() => {
    		setExportModalTablesOpen(true);
  	}, []);
  	
  	const closeExportModalTables = useCallback(() => {
    		setExportModalTablesOpen(false);
  	}, []);
  	
  	
  	const onSeeDetailsTextClick = useCallback(() => {
        navigate('/cdi4');
  	}, [navigate]);
  	
  	
  	const onDebtCollectionNavContainer3Click = useCallback(() => {
    		// Add your code here
  	}, []);
  	
  	
  	const openLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(true);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);

	const navItems = [
		{ text: "Dashboard", bgColor: "bg-neutrals-neutral-50", textColor: "text-neutrals-neutral-300", hoverBgColor: "bg-neutrals-neutral-50", hoverTextColor: "text-primary-primary-500", path: "" },
		{ text: "Legal proceedings & billing", bgColor: "bg-neutrals-neutral-50", textColor: "text-neutrals-neutral-300", hoverBgColor: "bg-neutrals-neutral-50", hoverTextColor: "text-primary-primary-500", path: "/legal_1" },
		{ text: "Pre-LBA checks", bgColor: "bg-neutrals-neutral-50", textColor: "text-neutrals-neutral-300", hoverBgColor: "bg-neutrals-neutral-50", hoverTextColor: "text-primary-primary-500", path: "/prelbacheck" },
		{ text: "Client-debtor interaction", bgColor: "bg-primary-primary-50", textColor: "text-neutrals-neutral-900", hoverBgColor: "bg-primary-primary-50", hoverTextColor: "text-neutrals-neutral-900", path: "/cdi1" },
	];
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1233px] overflow-hidden text-left text-base text-neutrals-neutral-300 font-open-sans-ff-body-strong">
      			<div className="absolute top-[1152px] right-[96px] text-sm">Copyright © Think-AI 2023</div>
      			<b className="absolute top-[128px] left-[96px] text-9xl text-neutrals-neutral-900">Client-debtor interaction</b>
      			<div className="absolute top-[172px] left-[96px] inline-block w-[400px]">{`Get all your information for client & debtor over here.`}</div>
      			<div className="absolute top-[218px] left-[96px] flex flex-row items-start justify-start gap-[24px]">
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 box-border cursor-pointer" onClick={onClickClientInstruction}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Client instructions</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 box-border h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 text-primary-primary-500 border-b-[2px] border-solid border-primary-primary-500">
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Handling defences</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[283px] left-[96px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] flex flex-col items-start justify-start text-sm text-neutrals-neutral-900">
        				<div className="w-[1248px] rounded-t-3xs rounded-b-none box-border flex flex-row items-center justify-between py-5 px-6 text-lg border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-l-[1px]">
          					<div className="flex flex-row items-center justify-start gap-[10px]">
            						<div className="relative font-semibold">Defence list</div>
            						<div className="rounded-[5px] bg-neutrals-neutral-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] text-sm border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="w-1.5 relative rounded-[50%] bg-primary-primary-500 h-1.5" />
              							<div className="relative font-semibold">10 entries</div>
            						</div>
          					</div>
							<CTAs
							type="Ghostbtn"
							text="Export"
							textColor="text-neutrals-neutral-900"
							bgColor="bg-neutrals-neutral-50"
							borderColor="border-neutrals-neutral-100"
							position="relative"
							icon="assets/export.svg"
							onClick={openExportModalTables}
							/>
        				</div>
        				<div className="w-[1248px] box-border flex flex-row items-center justify-between py-3 px-6 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-l-[1px]">
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-start border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="rounded-tl-3xs rounded-tr-none rounded-br-none rounded-bl-3xs bg-column-bg flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">View all</div>
            						</div>
            						<div className="flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">Resolved</div>
            						</div>
            						<div className="flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">Pending</div>
            						</div>
            						<div className="flex flex-row items-center justify-center py-2 px-4 border-r-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative font-semibold">Negotiating</div>
            						</div>
            						<div className="rounded-tl-none rounded-tr-3xs rounded-br-3xs rounded-bl-none flex flex-row items-center justify-center py-2 px-4">
              							<div className="relative font-semibold">Responded</div>
            						</div>
          					</div>
          					<div className="w-[294px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-start py-2.5 px-3.5 text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="flex flex-row items-center justify-start gap-[10px]">
              							<img className="w-5 relative h-5" alt="" src="assets/search.svg"/>
              							<div className="relative">Search</div>
            						</div>
          					</div>
        				</div>
        				<div className="flex flex-col items-start justify-start">
          					<div className="w-[1248px] flex flex-col items-start justify-start">
            						<div className="self-stretch flex flex-row items-start justify-start">
              							<div className="w-[188px] flex flex-col items-start justify-start">
                								<div className="w-[188px] bg-column-bg box-border h-11 flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
                  									<div className="relative font-semibold">Date</div>
                								</div>
                								<div className="w-[188px] flex flex-col items-start justify-start">
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">02/02/2024</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">15/01/2024</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">14/01/2024</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">12/01/2024</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">06/01/2024</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">02/01/2024</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">01/01/2024</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">28/12/2023</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">28/12/2023</div>
                  									</div>
                  									<div className="w-[188px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                    										<div className="relative">27/12/2023</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[212px] h-[594px] flex flex-col items-center justify-between">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-center py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">Status</div>
                								</div>
                								<div className="self-stretch flex flex-col items-center justify-start text-semantics-low-low-500">
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Resolved</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Resolved</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 text-semantics-moderate-moderate-500 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-moderate-moderate-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-moderate-moderate-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
                      											<div className="relative font-semibold">Pending</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Resolved</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 text-semantics-high-high-500 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-high-high-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-high-high-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-high-high-500 h-1.5" />
                      											<div className="relative font-semibold">Negotiating</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 text-semantics-secure-blue-500 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-secure-blue-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-secure-blue-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-secure-blue-500 h-1.5" />
                      											<div className="relative font-semibold">Responded</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 text-semantics-moderate-moderate-500 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-moderate-moderate-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-moderate-moderate-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
                      											<div className="relative font-semibold">Pending</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 text-semantics-high-high-500 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-high-high-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-high-high-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-high-high-500 h-1.5" />
                      											<div className="relative font-semibold">Negotiating</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                      											<div className="relative font-semibold">Resolved</div>
                    										</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 flex flex-col items-center justify-start p-4 text-semantics-secure-blue-500 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="rounded-xl bg-semantics-secure-blue-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-secure-blue-500">
                      											<div className="w-1.5 relative rounded-[50%] bg-semantics-secure-blue-500 h-1.5" />
                      											<div className="relative font-semibold">Resolved</div>
                    										</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[448px] flex flex-col items-center justify-start">
                								<div className="w-[448px] bg-column-bg box-border h-11 flex flex-row items-start justify-center py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                  									<div className="relative font-semibold">Nature of defence</div>
                								</div>
                								<div className="w-[448px] flex flex-col items-start justify-start text-center">
                  									<div className="w-[448px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="flex-1 relative">Denial of debt</div>
                  									</div>
                  									<div className="w-[448px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="flex-1 relative">Dispute over amount</div>
                  									</div>
                  									<div className="w-[448px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="flex-1 relative">Payment disputes</div>
                  									</div>
                  									<div className="w-[448px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="flex-1 relative">Lack of proper notice</div>
                  									</div>
                  									<div className="w-[448px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="flex-1 relative">Statue of limitations</div>
                  									</div>
                  									<div className="w-[448px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="flex-1 relative">Disputes over contract terms</div>
                  									</div>
                  									<div className="w-[448px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="flex-1 relative">Identity Theft or Fraud</div>
                  									</div>
                  									<div className="w-[448px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="flex-1 relative">Financial hardship</div>
                  									</div>
                  									<div className="w-[448px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="flex-1 relative">Unfair practices</div>
                  									</div>
                  									<div className="w-[448px] bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                    										<div className="flex-1 relative">Counterclaims</div>
                  									</div>
                								</div>
              							</div>
              							<div className="w-[400px] flex flex-col items-start justify-start">
                								<div className="self-stretch bg-column-bg box-border h-11 flex flex-row items-start justify-center py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-b-[1px]">
                  									<div className="relative font-semibold">Action</div>
                								</div>
                								<div className="self-stretch flex flex-col items-start justify-start text-center text-primary-primary-500">
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="flex-1 relative [text-decoration:underline] cursor-pointer" onClick={onSeeDetailsTextClick}>See details</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="flex-1 relative [text-decoration:underline]">See details</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="flex-1 relative [text-decoration:underline]">See details</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="flex-1 relative [text-decoration:underline]">See details</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="flex-1 relative [text-decoration:underline]">See details</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="flex-1 relative [text-decoration:underline]">See details</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="flex-1 relative [text-decoration:underline]">See details</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="flex-1 relative [text-decoration:underline]">See details</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="flex-1 relative [text-decoration:underline]">See details</div>
                  									</div>
                  									<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                    										<div className="flex-1 relative [text-decoration:underline]">See details</div>
                  									</div>
                								</div>
              							</div>
            						</div>
          					</div>
          					<div className="w-[1248px] rounded-t-none rounded-b-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-between pt-3 px-6 pb-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">Previous</div>
              							</div>
            						</div>
            						<div className="relative">Page 1 of 10</div>
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center">
                								<div className="relative font-semibold">Next</div>
              							</div>
            						</div>
          					</div>
        				</div>
      			</div>

				{/* Navigation Panel */}
				<NavigationPanelLawyer navItems={navItems}/>
      			
    		</div>
    		{isExportModalTablesOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeExportModalTables}
        				>
        				<ExportModalTables onClose={closeExportModalTables} />
      			</PortalPopup>
    		)}
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}</>);
};

export default CDI_2;