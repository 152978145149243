import { useCallback, useState } from "react";
import PropTypes from 'prop-types';

const InvoiceImagePreview = ({ className="" }) => {
    return (
          <div className={`relative shadow-[0px_7.796208381652832px_46.78px_rgba(0,_0,_0,_0.1)] rounded-3xs bg-primary-primary-50 box-border flex flex-col items-start justify-start p-2.5 gap-[7.8px] max-w-full max-h-full overflow-auto text-left text-base text-neutrals-neutral-900 font-open-sans-ff-heading-2 border-[0.8px] border-solid border-neutrals-neutral-100 ${className}`}>
                <div className="flex flex-row items-center justify-start gap-[329.8px]">
                      <div className="flex flex-row items-center justify-start gap-[18.7px]">
                            <div className="flex flex-row items-center justify-start gap-[7.8px]">
                                  <img className="w-5 relative h-5" alt="" src="vuesax/bold/close-circle.svg" />
                                  <img className="w-5 relative h-5" alt="" src="vuesax/bold/maximize-3.svg" />
                            </div>
                            <div className="relative font-semibold">Invoice.pdf</div>
                      </div>
                      <div className="flex flex-row items-center justify-start gap-[7.8px] text-sm">
                            <img className="w-5 relative h-5" alt="" src="icons-v2.svg" />
                            <div className="rounded-[5px] flex flex-row items-center justify-center py-[1.6px] px-[6.2px] border-[0.8px] border-solid border-neutrals-neutral-100">
                                  <div className="relative">Open with Adobe Acrobat</div>
                            </div>
                      </div>
                </div>
                <img className="self-stretch relative rounded-3xs max-w-full overflow-hidden h-[563.1px] shrink-0 object-cover" alt="" src="image 4.png" />
          </div>);
};

InvoiceImagePreview.propTypes = {
    className: PropTypes.string
};

export default InvoiceImagePreview;