import { useState, useCallback } from 'react';
import NavigationDropdown from "../../components/NavigationDropdown";
import NavigationPanel from "../../components/NavigationPanel";
import PortalPopup from "../../components/PortalPopup";
import LogoutConfirmation from "../../components/LogoutConfirmation";
import Upload from "../../components/Upload";
import Breadcrumb from "../../components/Breadcrumb";

const DocumentProcessing = () => {
  	const [isNavigationDropdownOpen, setNavigationDropdownOpen] = useState(false);
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	
  	const closeNavigationDropdown = useCallback(() => {
    		setNavigationDropdownOpen(false);
  	}, []);
  	
  	
  	const openLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(true);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);

	const navItems = [
		{
			text: 'Debt collection', 
    		bgColor: 'bg-primary-primary-50', 
    		textColor: 'text-neutrals-neutral-900', 
    		hoverBgColor: 'bg-primary-primary-50', 
    		hoverTextColor: 'text-neutrals-neutral-900', 
    		path: "",
    		icon: 'assets/arrow-down.svg'
		},

		{
			text: "Outcomes & costs",
			bgColor: 'bg-neutrals-neutral-50', 
    		textColor: 'text-neutrals-neutral-300', 
    		hoverBgColor: 'bg-neutrals-neutral-50', 
    		hoverTextColor: 'text-primary-primary-500', 
    		path: "/outcome&cost",
    		icon: ''
		},

		{
			text: "AI indicators",
			bgColor: 'bg-neutrals-neutral-50', 
    		textColor: 'text-neutrals-neutral-300', 
    		hoverBgColor: 'bg-neutrals-neutral-50', 
    		hoverTextColor: 'text-primary-primary-500', 
    		path: "/ai1",
    		icon: ''
		},
	];

	const breadcrumb = [
		{ label: "", path: "/postlba2", icon: "assets/home.svg", active: true },
		{ label: "Data processing", path: "", icon: "", active: false },
	];
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1294px] overflow-hidden text-left text-sm text-neutrals-neutral-900 font-open-sans-ff-body-strong">
      			<div className="absolute top-[1211px] right-[96px] text-neutrals-neutral-300">Copyright © Think-AI 2023</div>
      			<b className="absolute top-[179px] left-[96px] text-9xl">Document processing</b>
      			<div className="absolute top-[785px] left-[96px] flex flex-row items-start justify-start gap-[24px] text-base">
        				<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="w-[294px] rounded-t-3xs rounded-b-none bg-neutrals-neutral-50 box-border h-[290px] flex flex-col items-start justify-between py-4 px-3.5">
            						<div className="self-stretch relative h-[187px]">
              							<img className="absolute top-[0px] left-[0px] w-[266px] h-[187px] object-cover" alt="" src="Group 221.png" />
            						</div>
            						<div className="flex flex-col items-start justify-start gap-[6px]">
              							<div className="relative font-semibold">Contract</div>
              							<div className="relative text-sm text-neutrals-neutral-300">28/01/24</div>
            						</div>
          					</div>
          					<div className="w-[294px] box-border flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center gap-[6px]">
                								<img className="w-5 relative h-5" alt="" src="assets/download.svg" />
                								<div className="relative font-semibold">Download</div>
              							</div>
            						</div>
          					</div>
        				</div>
        				<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="w-[294px] rounded-t-3xs rounded-b-none bg-neutrals-neutral-50 box-border h-[290px] flex flex-col items-start justify-between py-4 px-3.5">
            						<div className="self-stretch relative h-[187px]">
              							<img className="absolute top-[0px] left-[0px] w-[266px] h-[187px] object-cover" alt="" src="Group 221.png" />
            						</div>
            						<div className="flex flex-col items-start justify-start gap-[6px]">
              							<div className="relative font-semibold">Letter</div>
              							<div className="relative text-sm text-neutrals-neutral-300">01/02/24</div>
            						</div>
          					</div>
          					<div className="w-[294px] box-border flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center gap-[6px]">
                								<img className="w-5 relative h-5" alt="" src="assets/download.svg"/>
                								<div className="relative font-semibold">Download</div>
              							</div>
            						</div>
          					</div>
        				</div>
        				<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="w-[294px] rounded-t-3xs rounded-b-none bg-neutrals-neutral-50 box-border h-[290px] flex flex-col items-start justify-between py-4 px-3.5">
            						<div className="self-stretch relative h-[187px]">
              							<img className="absolute top-[0px] left-[0px] w-[266px] h-[187px] object-cover" alt="" src="Group 221.png" />
            						</div>
            						<div className="flex flex-col items-start justify-start gap-[6px]">
              							<div className="relative font-semibold">Contract</div>
              							<div className="relative text-sm text-neutrals-neutral-300">01/02/24</div>
            						</div>
          					</div>
          					<div className="w-[294px] box-border flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center gap-[6px]">
                								<img className="w-5 relative h-5" alt="" src="assets/download.svg" />
                								<div className="relative font-semibold">Download</div>
              							</div>
            						</div>
          					</div>
        				</div>
        				<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="w-[294px] rounded-t-3xs rounded-b-none bg-neutrals-neutral-50 box-border h-[290px] flex flex-col items-start justify-between py-4 px-3.5">
            						<div className="self-stretch relative h-[187px]">
              							<img className="absolute top-[0px] left-[0px] w-[266px] h-[187px] object-cover" alt="" src="Group 221.png" />
            						</div>
            						<div className="flex flex-col items-start justify-start gap-[6px]">
              							<div className="relative font-semibold">Letter</div>
              							<div className="relative text-sm text-neutrals-neutral-300">01/02/24</div>
            						</div>
          					</div>
          					<div className="w-[294px] box-border flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="flex flex-row items-center justify-center gap-[6px]">
                								<img className="w-5 relative h-5" alt="" src="assets/download.svg" />
                								<div className="relative font-semibold">Download</div>
              							</div>
            						</div>
          					</div>
        				</div>
      			</div>

				{/* Navigation Panel */}
				<NavigationPanel navItems={navItems}/>

				{/* Breadcrumb Navigation */}
				<Breadcrumb breadcrumbs={breadcrumb} textColor="text-primary-primary-500" position="top-[24px] left-[90px]"/>

				{/* Upload Component */}
      			<div className="absolute top-[241px] left-[96px] w-[1248px] flex flex-col items-start justify-start gap-[10px]">
				  <Upload width="1248px"/>
      			</div>

      			<div className="absolute top-[684px] left-[96px] flex flex-row items-start justify-start pt-0 px-0 pb-6 gap-[814px] text-lg border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Scanned documents</div>
          					<div className="relative text-base text-neutrals-neutral-300">All your scanned documents at a place.</div>
        				</div>
        				<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 text-sm border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="flex flex-row items-center justify-center gap-[6px]">
            						<img className="w-5 relative h-5" alt="" src="assets/download.svg"/>
            						<div className="relative font-semibold">Download all</div>
          					</div>
        				</div>
      			</div>
    		</div>
    		{isNavigationDropdownOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeNavigationDropdown}
        				>
        				<NavigationDropdown onClose={closeNavigationDropdown} />
      			</PortalPopup>
    		)}
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}</>);
};

export default DocumentProcessing;