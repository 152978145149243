import { useState, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import NavigationDropdown from "../../components/NavigationDropdown";
import NavigationPanel from '../../components/NavigationPanel';
import PortalPopup from "../../components/PortalPopup";
import LogoutConfirmation from "../../components/LogoutConfirmation";
import ExportModalImage from "../../components/ExportModalImage";
import Breadcrumb from '../../components/Breadcrumb';
import CTAs from '../../components/CTAs';

const Defence_3 = () => {
    const navigate = useNavigate();
  	const [isNavigationDropdownOpen, setNavigationDropdownOpen] = useState(false);
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	const [isExportModalImageOpen, setExportModalImageOpen] = useState(false);
  	
  	const closeNavigationDropdown = useCallback(() => {
    		setNavigationDropdownOpen(false);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);
  	
  	const openExportModalImage = useCallback(() => {
    		setExportModalImageOpen(true);
  	}, []);
  	
  	const closeExportModalImage = useCallback(() => {
    		setExportModalImageOpen(false);
  	}, []);
  	
  	const onTabStatesContainerClick = useCallback(() => {
        navigate('/defence2');
  	}, [navigate]);
  	
  	const onClickNegotiation = useCallback(() => {
        navigate('/defence4');
  	}, [navigate]);

	const navItems = [
		{
			text: 'Debt collection', 
    		bgColor: 'bg-primary-primary-50', 
    		textColor: 'text-neutrals-neutral-900', 
    		hoverBgColor: 'bg-primary-primary-50', 
    		hoverTextColor: 'text-neutrals-neutral-900', 
    		path: "",
    		icon: 'assets/arrow-down.svg'
		},

		{
			text: "Outcomes & costs",
			bgColor: 'bg-neutrals-neutral-50', 
    		textColor: 'text-neutrals-neutral-300', 
    		hoverBgColor: 'bg-neutrals-neutral-50', 
    		hoverTextColor: 'text-primary-primary-500', 
    		path: "/outcome&cost",
    		icon: ''
		},

		{
			text: "AI indicators",
			bgColor: 'bg-neutrals-neutral-50', 
    		textColor: 'text-neutrals-neutral-300', 
    		hoverBgColor: 'bg-neutrals-neutral-50', 
    		hoverTextColor: 'text-primary-primary-500', 
    		path: "/ai1",
    		icon: ''
		},
	];

	const breadcrumb = [
		{ label: "", path: "/postlba2", icon: "assets/home.svg", active: true },
		{ label: "Defence and case handling", path: "/defence1", active: false},
		{ label: "Paras Mistry", path: "", active: true},
	];
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1437px] overflow-hidden text-left text-lg text-neutrals-neutral-900 font-open-sans-ff-heading-3">
      			<div className="absolute top-[1354px] right-[96px] text-sm text-neutrals-neutral-300">Copyright © Think-AI 2023</div>
      			<div className="absolute top-[179px] left-[96px] bg-neutrals-neutral-50 box-border w-[1248px] flex flex-col items-start justify-start pt-0 px-0 pb-6 text-9xl border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-start justify-start">
          					<div className="flex flex-col items-start justify-start gap-[6px]">
            						<b className="relative">Defence and case handling</b>
            						<div className="relative text-base text-neutrals-neutral-300">Acknowledge defences and revert back.</div>
          					</div>
        				</div>
      			</div>

				{/* Navigation Panel */}
				<NavigationPanel navItems={navItems}/>

				{/* Breadcrumb Navigation */}
				<Breadcrumb breadcrumbs={breadcrumb} position="top-[24px] left-[90px]"/>
      			
      			<div className="absolute top-[358px] left-[96px] flex flex-col items-start justify-start gap-[24px]">
        				<div className="w-[1248px] box-border flex flex-col items-start justify-start pt-0 px-0 pb-6 relative gap-[10px] border-b-[1px] border-solid border-neutrals-neutral-100">
          					<div className="flex flex-col items-start justify-start gap-[6px] z-[0]">
            						<div className="relative font-semibold">Alert panel</div>
            						<div className="relative text-base text-neutrals-neutral-300">Acknowledges any formal defence raised by the debtor.</div>
          					</div>
          					<div className="!m-[0] absolute top-[0px] left-[1094px] rounded-3xs bg-primary-primary-50 flex flex-row items-center justify-start py-2.5 px-3.5 gap-[6px] z-[1] text-sm text-primary-primary-500">
            						<img className="w-[23px] relative h-[22.6px]" alt="" src="assets/AI.svg"/>
            						<div className="relative font-semibold">Powered by AI</div>
          					</div>
        				</div>
        				<div className="flex flex-row items-start justify-start gap-[24px]">
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="w-[400px] flex flex-col items-start justify-start p-6 box-border gap-[24px]">
              							<div className="self-stretch flex flex-row items-start justify-between">
                								<div className="relative font-semibold">Potential Dispute</div>
                								<img className="w-6 relative h-6" alt="" src="assets/flag.svg"/>
              							</div>
              							<div className="self-stretch flex flex-col items-start justify-start gap-[10px] text-sm">
                								<div className="flex flex-row items-center justify-start gap-[10px]">
                  									<img className="w-10 relative h-10" alt="" src="assets/pdf.svg"/>
                  									<div className="relative font-semibold">DefenseStatement_JDoe.pdf</div>
                								</div>
                								<div className="self-stretch relative text-base text-neutrals-neutral-300">AI has identified language patterns suggesting a possible dispute.</div>
              							</div>
            						</div>
            						<div className="w-[400px] box-border flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
										<CTAs
										type="Ghostbtn"
										text="View document"
										textColor="text-neutrals-neutral-900"
										bgColor="bg-neutrals-neutral-50"
										borderColor="border-neutrals-neutral-100"
										position="relative"
										/>
            						</div>
          					</div>
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="w-[400px] flex flex-col items-start justify-start p-6 box-border gap-[24px]">
              							<div className="self-stretch flex flex-row items-start justify-between">
                								<div className="relative font-semibold">Missing information</div>
                								<img className="w-6 relative h-6" alt="" src="assets/flag.svg"/>
              							</div>
              							<div className="self-stretch flex flex-col items-start justify-start gap-[10px] text-sm">
                								<div className="flex flex-row items-center justify-start gap-[10px]">
                  									<div className="flex flex-row items-start justify-start relative gap-[10px]">
                    										<img className="w-10 relative h-10 z-[0]" alt="" src="assets/image_file.svg"/>
                  									</div>
                  									<div className="relative font-semibold">Correspondence_JDoe.png</div>
                								</div>
                								<div className="self-stretch relative text-base text-neutrals-neutral-300">Key information appears to be missing or obscured.</div>
              							</div>
            						</div>
            						<div className="w-[400px] box-border flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
										<CTAs
										type="Ghostbtn"
										text="View document"
										textColor="text-neutrals-neutral-900"
										bgColor="bg-neutrals-neutral-50"
										borderColor="border-neutrals-neutral-100"
										position="relative"
										/>
            						</div>
          					</div>
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="w-[400px] flex flex-col items-start justify-start p-6 box-border gap-[24px]">
              							<div className="self-stretch flex flex-row items-start justify-between">
                								<div className="relative font-semibold">Payment intent recognised</div>
                								<img className="w-6 relative h-6" alt="" src="assets/flag.svg"/>
              							</div>
              							<div className="self-stretch flex flex-col items-start justify-start gap-[10px] text-sm">
                								<div className="flex flex-row items-center justify-start gap-[10px]">
                  									<div className="flex flex-row items-start justify-start relative gap-[10px]">
                    										<img className="w-10 relative h-10 z-[0]" alt="" src="assets/image_file.svg"/>
                  									</div>
                  									<div className="relative font-semibold">Correspondence_JDoe.png</div>
                								</div>
                								<div className="self-stretch relative text-base text-neutrals-neutral-300">AI detects language indicating a willingness to settle.</div>
              							</div>
            						</div>
            						<div className="w-[400px] box-border flex flex-col items-end justify-start py-4 px-6 text-sm border-t-[1px] border-solid border-neutrals-neutral-100">
										<CTAs
										type="Ghostbtn"
										text="View document"
										textColor="text-neutrals-neutral-900"
										bgColor="bg-neutrals-neutral-50"
										borderColor="border-neutrals-neutral-100"
										position="relative"
										/>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[785px] left-[96px] box-border w-[1248px] flex flex-col items-start justify-start pt-0 px-0 pb-6 gap-[10px] border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-col items-start justify-start gap-[6px] z-[0]">
          					<div className="relative font-semibold">AI insights</div>
          					<div className="relative text-base text-neutrals-neutral-300">Acknowledges any formal defence raised by the debtor.</div>
        				</div>
        				<div className="!m-[0] absolute top-[0px] left-[1094px] rounded-3xs bg-primary-primary-50 flex flex-row items-center justify-start py-2.5 px-3.5 gap-[6px] z-[1] text-sm text-primary-primary-500">
          					<img className="w-[23px] relative h-[22.6px]" alt="" src="assets/AI.svg"/>
          					<div className="relative font-semibold">Powered by AI</div>
        				</div>
      			</div>
      			<div className="absolute top-[886px] left-[910px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border w-[434px] h-[404px] flex flex-col items-start justify-start p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch bg-neutrals-neutral-50 flex flex-row items-center justify-between pt-0 px-0 pb-6 border-b-[1px] border-solid border-neutrals-neutral-100">
          					<div className="relative font-semibold">Overview</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
        				</div>
        				<div className="self-stretch flex flex-row items-start justify-start gap-[24px] text-right text-sm text-neutrals-neutral-300">
          					<div className="w-60 h-60 flex flex-row items-center justify-center">
            						<div className="flex flex-row items-start justify-start relative gap-[10px]">
              							<img className="w-[234px] relative h-[234px] z-[0]" alt="" src="Group 169.svg" />
              							<img className="w-[194px] absolute !m-[0] top-[calc(50%_-_97px)] left-[calc(50%_-_98px)] h-[194px] z-[1]" alt="" src="Group 168.svg" />
              							<img className="w-[159.4px] absolute !m-[0] top-[37.32px] left-[36.32px] h-[159.4px] z-[2]" alt="" src="Group 167.svg" />
            						</div>
          					</div>
          					<div className="flex-1 flex flex-col items-start justify-start gap-[24px]">
            						<div className="flex flex-row items-start justify-start gap-[6px]">
              							<div className="flex flex-row items-start justify-start pt-1.5 px-0 pb-0">
                								<div className="w-2 relative rounded-[50%] bg-primary-primary-500 h-2" />
              							</div>
              							<div className="flex flex-col items-start justify-start gap-[4px]">
                								<div className="relative">Overall accuracy</div>
                								<div className="relative text-5xl font-semibold text-neutrals-neutral-900 text-left">85%</div>
              							</div>
            						</div>
            						<div className="flex flex-row items-start justify-start gap-[6px]">
              							<div className="flex flex-row items-start justify-start pt-1.5 px-0 pb-0">
                								<div className="w-2 relative rounded-[50%] bg-primary-primary-300 h-2" />
              							</div>
              							<div className="flex flex-col items-start justify-start gap-[4px]">
                								<div className="relative">False positive</div>
                								<div className="relative text-5xl font-semibold text-neutrals-neutral-900 text-left">12%</div>
              							</div>
            						</div>
            						<div className="flex flex-row items-start justify-start gap-[6px]">
              							<div className="flex flex-row items-start justify-start pt-1.5 px-0 pb-0">
                								<div className="w-2 relative rounded-[50%] bg-primary-primary-100 h-2" />
              							</div>
              							<div className="flex flex-col items-start justify-start gap-[4px]">
                								<div className="relative">True positive</div>
                								<div className="relative text-5xl font-semibold text-neutrals-neutral-900 text-left">25%</div>
              							</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[886px] left-[96px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border w-[786px] flex flex-col items-start justify-start p-6 gap-[24px] border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="self-stretch flex flex-row items-center justify-between">
          					<div className="relative font-semibold">AI identified issues over time</div>
							  	<CTAs
								type="Ghostbtn"
								text="Export"
								textColor="text-neutrals-neutral-900"
								bgColor="bg-neutrals-neutral-50"
								borderColor="border-neutrals-neutral-100"
								icon="assets/export.svg"
								position="relative"
								onClick={openExportModalImage}
								/>
        				</div>
        				<div className="self-stretch flex flex-col items-start justify-start text-sm text-neutrals-neutral-300">
          					<div className="self-stretch flex flex-col items-end justify-start gap-[24px]">
            						<div className="flex flex-row items-start justify-start gap-[10px]">
              							<div className="flex flex-row items-center justify-start gap-[6px]">
                								<div className="w-2 relative rounded-[50%] bg-primary-primary-500 h-2" />
                								<div className="relative">Green flag</div>
              							</div>
              							<div className="flex flex-row items-center justify-start gap-[6px]">
                								<div className="w-2 relative rounded-[50%] bg-primary-primary-300 h-2" />
                								<div className="relative">Red flag</div>
              							</div>
            						</div>
            						<div className="self-stretch flex flex-row items-center justify-start gap-[10px] text-center text-neutrals-neutral-900">
              							<div className="flex flex-row items-center justify-start">
                								<div className="flex flex-row items-center justify-start gap-[10px]">
                  									<div className="relative font-semibold [transform:_rotate(-90deg)]">Flags</div>
                  									<div className="h-[201px] flex flex-col items-end justify-start gap-[11px] text-left text-neutrals-neutral-300">
                    										<div className="relative">60</div>
                    										<div className="relative">50</div>
                    										<div className="relative">40</div>
                    										<div className="relative">30</div>
                    										<div className="relative">20</div>
                    										<div className="relative">10</div>
                    										<div className="relative">0</div>
                  									</div>
                								</div>
              							</div>
              							<div className="flex-1 flex flex-col items-start justify-start relative gap-[10px]">
                								<div className="self-stretch relative h-[180px] z-[0]">
                  									<div className="absolute top-[-0.5px] left-[-0.5px] box-border w-[683px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="absolute top-[29.5px] left-[-0.5px] box-border w-[683px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="absolute top-[59.5px] left-[-0.5px] box-border w-[683px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="absolute top-[89.5px] left-[-0.5px] box-border w-[683px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="absolute top-[119.5px] left-[-0.5px] box-border w-[683px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="absolute top-[149.5px] left-[-0.5px] box-border w-[683px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                  									<div className="absolute top-[179.5px] left-[-0.5px] box-border w-[683px] h-px border-t-[1px] border-solid border-neutrals-neutral-100" />
                								</div>
                								<img className="w-[682px] absolute !m-[0] top-[12px] left-[0px] h-[168px] z-[1]" alt="" src="Group 233.svg" />
              							</div>
            						</div>
          					</div>
          					<div className="self-stretch h-12 flex flex-col items-start justify-between text-center">
            						<div className="self-stretch flex flex-row items-start justify-between py-0 pr-0 pl-14">
              							<div className="relative">Mon</div>
              							<div className="relative">Tue</div>
              							<div className="relative">Wed</div>
              							<div className="relative">Thu</div>
              							<div className="relative">Fri</div>
              							<div className="relative">Sat</div>
              							<div className="relative">Sun</div>
            						</div>
            						<div className="self-stretch relative font-semibold text-neutrals-neutral-900">Days</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[293px] left-[96px] flex flex-row items-start justify-start gap-[24px] text-base text-neutrals-neutral-300">
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 box-border cursor-pointer" onClick={onTabStatesContainerClick}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Case details</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 box-border h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 text-primary-primary-500 border-b-[2px] border-solid border-primary-primary-500">
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">AI utilisation</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 box-border cursor-pointer" onClick={onClickNegotiation}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Negotiation</div>
          					</div>
        				</div>
      			</div>
    		</div>
    		{isNavigationDropdownOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeNavigationDropdown}
        				>
        				<NavigationDropdown onClose={closeNavigationDropdown} />
      			</PortalPopup>
    		)}
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}
    		{isExportModalImageOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeExportModalImage}
        				>
        				<ExportModalImage onClose={closeExportModalImage} />
      			</PortalPopup>
    		)}</>);
};

export default Defence_3;