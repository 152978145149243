

const EmailTemplate = () => {
    return (
          <div className="w-full relative shadow-[0px_8.377363204956055px_50.26px_rgba(0,_0,_0,_0.1)] rounded-[8.38px] bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start p-[26.8px] gap-[80.4px] text-left text-[23.46px] text-design-light-mode-neutral-neutral-900 font-open-sans border-[0.8px] border-solid border-design-light-mode-neutral-neutral-100">
                <div className="self-stretch flex flex-col items-start justify-start gap-[26.8px]">
                      <div className="self-stretch rounded-[8.38px] bg-design-light-mode-column-bg flex flex-col items-start justify-start py-[53.6px] px-[20.1px] gap-[26.8px]">
                            <img className="w-[292.9px] relative h-[97px] object-cover" alt="" src="image 7.png" />
                            <div className="flex flex-col items-start justify-start gap-[5px]">
                                  <b className="relative">Thank you for choosing us!</b>
                                  <div className="relative text-sm-4 text-design-light-mode-neutral-neutral-300">Below is the generated mail for you.</div>
                            </div>
                      </div>
                      <div className="self-stretch flex flex-col items-start justify-start gap-[20.1px] text-sm-4">
                            <div className="self-stretch flex flex-col items-start justify-start gap-[8.4px]">
                                  <div className="relative text-xs-7">
                                        <span className="font-semibold">Dear</span>
                                        <span className="text-2xs-1 font-inter">{` `}</span>
                                        <span className="font-semibold">Thomas Higgins,</span>
                                  </div>
                                  <div className="relative">
                                        <span>My name is</span>
                                        <span className="text-2xs-1 font-inter">{` `}</span>
                                        <span className="text-xs-7 font-semibold">Paras Mistry, I am [designation] of ThinkAI.</span>
                                  </div>
                                  <div className="self-stretch relative">We have used Debt Co-Pilot to manage our debt collection, in this instance the debtor has not settled within the timeframe required so we now need to proceed with legal assistance and small court claims proceeding. Your legal firm has been identified in the Debt Co-Pilot system as my local expert, can you please get in touch to discuss how we move forward?</div>
                                        </div>
                                        <div className="relative text-[15.08px] font-semibold">Our contact details:</div>
                                        <div className="self-stretch rounded-[8.38px] bg-design-light-mode-column-bg flex flex-col items-start justify-start p-[20.1px]">
                                              <div className="self-stretch flex flex-col items-start justify-start">
                                                    <div className="relative">
                                                          <p className="m-0">
                                                                <span className="text-xs-7 font-semibold font-open-sans">Name:</span>
                                                                <span> Paras Mistry</span>
                                                          </p>
                                                          <p className="m-0">
                                                                <span className="text-xs-7 font-semibold font-open-sans">Email:</span>
                                                                <span> pm@think-ai.io</span>
                                                          </p>
                                                          <p className="m-0">
                                                                <span className="text-xs-7 font-semibold font-open-sans">Direct dial:</span>
                                                                <span> +441234567890</span>
                                                          </p>
                                                          <p className="m-0">
                                                                <span className="text-xs-7 font-semibold font-open-sans">Mobile:</span>
                                                                <span> +445639352845</span>
                                                          </p>
                                                    </div>
                                              </div>
                                        </div>
                                        <div className="self-stretch relative">
                                              <p className="m-0">We are looking forward to receiving your assistance.</p>
                                              <p className="m-0">Many thanks in advance.</p>
                                        </div>
                                        <div className="self-stretch relative">
                                              <p className="m-0">Best regards,</p>
                                              <p className="m-0 font-semibold">Paras Mistry</p>
                                        </div>
                                        </div>
                                        </div>
                                        <div className="self-stretch flex flex-row items-center justify-between">
                                              <img className="w-[87px] relative h-[30px] object-cover" alt="" src="image 7.png" />
                                              <div className="flex flex-row items-center justify-center gap-[20.1px]">
                                                    <img className="w-[13.4px] relative h-[13.4px] overflow-hidden shrink-0" alt="" src="pajamas:twitter.svg" />
                                                    <img className="w-[20.1px] relative h-[20.1px] overflow-hidden shrink-0" alt="" src="ri:instagram-fill.svg" />
                                                    <img className="w-[20.1px] relative h-[20.1px] overflow-hidden shrink-0" alt="" src="mdi:linkedin.svg" />
                                              </div>
                                        </div>
                                        </div>);
                                  };
                                  
                                  export default EmailTemplate;                