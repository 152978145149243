// import React, { useState, useCallback } from 'react';
// import { useNavigate } from 'react-router-dom';
// import LogoutConfirmation from './LogoutConfirmation';
// import PortalPopup from './PortalPopup';
// import DebtCollectionNav from './DebtCollectionNav';

// const NavigationPanel = ({ navItems }) => {
//   const navigate = useNavigate();
//   const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);

//   const openLogoutConfirmation = useCallback(() => {
//     setLogoutConfirmationOpen(true);
//   }, []);

//   const closeLogoutConfirmation = useCallback(() => {
//     setLogoutConfirmationOpen(false);
//   }, []);

//   const openSettingPage = useCallback(() => {
//     navigate('/setting-general');
//   }, []);

//   return (
//     <>
//       <div className="w-full relative h-auto bg-neutrals-neutral-50 box-border flex flex-row items-center justify-between py-8 px-24 text-left text-base text-neutrals-neutral-300 font-open-sans-ff-body-strong border-b-[1px] border-solid border-neutrals-neutral-100">
//         <div className="flex flex-row items-center gap-[51px]">
//           <img className="w-[118px] relative h-10 object-cover" alt="" src="assets/logo-horizontal.svg" />
//           <DebtCollectionNav navItems={navItems} />
//         </div>
//         <div className="flex flex-row items-start justify-end gap-[6px]">
//           <div className="rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5" onClick={openSettingPage}>
//             <img className="w-5 relative h-5" alt="" src="assets/setting.svg" />
//           </div>
//           <div className="rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5">
//             <div className="flex flex-row items-start justify-start relative gap-[10px]">
//               <img className="w-5 relative h-5 z-[0]" alt="" src="assets/notification-default.svg" />
//             </div>
//           </div>
//           <div className="flex flex-row items-start justify-end p-2.5 cursor-pointer" onClick={openLogoutConfirmation}>
//             <img className="w-5 relative h-5" alt="" src="assets/logout.svg" />
//           </div>
//         </div>
//       </div>
//       {isLogoutConfirmationOpen && (
//         <PortalPopup
//           overlayColor="rgba(113, 113, 113, 0.3)"
//           placement="Centered"
//           onOutsideClick={closeLogoutConfirmation}
//         >
//           <LogoutConfirmation onClose={closeLogoutConfirmation} />
//         </PortalPopup>
//       )}
//     </>
//   );
// };

// export default NavigationPanel;

//Code 2
import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoutConfirmation from './LogoutConfirmation';
import PortalPopup from './PortalPopup';
import DebtCollectionNav from './DebtCollectionNav';

const NavigationPanel = () => {
  const navigate = useNavigate();
  const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);

  const openLogoutConfirmation = useCallback(() => {
    setLogoutConfirmationOpen(true);
  }, []);

  const closeLogoutConfirmation = useCallback(() => {
    setLogoutConfirmationOpen(false);
  }, []);

  const openSettingPage = useCallback(() => {
    navigate('/setting-general');
  }, []);

  const navItems = [
    {
      text: 'Debt collection',
      bgColor: 'bg-neutrals-neutral-50',
      textColor: 'text-neutrals-neutral-300',
      hoverBgColor: 'bg-neutrals-neutral-50',
      hoverTextColor: 'text-primary-primary-500',
      path: '/debt-collection',
      dropdown: true,
      icon: 'assets/arrow-down.svg',
    },
    {
      text: 'Outcomes & costs',
      bgColor: 'bg-neutrals-neutral-50',
      textColor: 'text-neutrals-neutral-300',
      hoverBgColor: 'bg-neutrals-neutral-50',
      hoverTextColor: 'text-primary-primary-500',
      path: '/outcome&cost',
      dropdown: false,
      icon: '',
    },
    {
      text: 'AI indicators',
      bgColor: 'bg-neutrals-neutral-50',
      textColor: 'text-neutrals-neutral-300',
      hoverBgColor: 'bg-neutrals-neutral-50',
      hoverTextColor: 'text-primary-primary-500',
      path: '/ai1',
      dropdown: false,
    },
  ];

  return (
    <>
      <div className="w-full relative bg-neutrals-neutral-50 box-border flex flex-row items-center justify-between py-8 px-24 text-left text-base text-neutrals-neutral-300 font-open-sans-ff-body-strong border-b-[1px] border-solid border-neutrals-neutral-100">
        <div className="flex flex-row items-center gap-[51px]">
          <img className="w-[118px] relative h-10 object-cover" alt="" src="assets/logo-horizontal.svg" />
          <DebtCollectionNav navItems={navItems} />
        </div>
        <div className="flex flex-row items-start justify-end gap-[6px]">
          <div className="rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 cursor-pointer" onClick={openSettingPage}>
            <img className="w-5 relative h-5" alt="" src="assets/setting.svg" />
          </div>
          <div className="rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5">
            <div className="flex flex-row items-start justify-start relative gap-[10px]">
              <img className="w-5 relative h-5 z-[0]" alt="" src="assets/notification-default.svg" />
            </div>
          </div>
          <div className="flex flex-row items-start justify-end p-2.5 cursor-pointer" onClick={openLogoutConfirmation}>
            <img className="w-5 relative h-5" alt="" src="assets/logout.svg" />
          </div>
        </div>
      </div>
      {isLogoutConfirmationOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeLogoutConfirmation}
        >
          <LogoutConfirmation onClose={closeLogoutConfirmation} />
        </PortalPopup>
      )}
    </>
  );
};

export default NavigationPanel;