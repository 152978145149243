// import { useCallback, useState } from 'react';
// import { Navigate, useNavigate } from 'react-router-dom';

// const Breadcrumb = () => {
// 	const navigate = useNavigate();
  	
//   	const onClickHome = useCallback(() => {
// 		navigate('/postlba2');
//   	}, [navigate]);
  	
  	
//   	const onIconsV22Click = useCallback(() => {
// 		navigate('/postlba2');
//   	}, [navigate]);
  	
//   	return (
//     		<div className="w-full relative rounded-[5px] box-border h-[118px] overflow-hidden text-left text-sm text-neutrals-neutral-300 font-open-sans-ff-meta-strong border-[1px] border-dashed border-blueviolet">
//       			<div className="absolute top-[71px] left-[20px] flex flex-row items-center justify-start gap-[6px]">
//         				<img className="w-5 relative h-5 cursor-pointer" alt="" src="assets/home.svg" onClick={onClickHome} />
//         				<img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src="assets/arrow-right-chevron.svg" />
//         				<div className="flex flex-row items-center justify-center py-1 px-2 gap-[10px]">
//           					<div className="relative font-semibold">Billing</div>
//           					<img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src="assets/arrow-right-chevron.svg" />
//           					<div className="relative font-semibold text-primary-primary-500">Invoice preview</div>
//         				</div>
//       			</div>
//       			<div className="absolute top-[32px] left-[20px] flex flex-row items-center justify-start gap-[6px] text-primary-primary-500">
//         				<img className="w-5 relative h-5 cursor-pointer" alt="" src="assets/home.svg" onClick={onIconsV22Click} />
//         				<img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src="assets/arrow-right-chevron.svg" />
//         				<div className="flex flex-row items-center justify-center py-1 px-2">
//           					<div className="relative font-semibold">Billing</div>
//         				</div>
//       			</div>
//     		</div>);
// };

// export default Breadcrumb;

//Code 2
// import React from 'react';
// import PropTypes from 'prop-types';
// import { useNavigate } from 'react-router-dom';

// const Breadcrumb = ({ breadcrumbs }) => {
//   const navigate = useNavigate();

//   const handleClick = (path) => {
//     navigate(path);
//   };

//   return (
//     <div className="relative w-full flex flex-row items-center justify-start gap-[6px] text-left text-sm text-primary-primary-500 font-open-sans-ff-meta-strong">
//       <div className="absolute top-[24px] left-[90px] flex flex-row items-center justify-start gap-[6px]">
//         {breadcrumbs.map((breadcrumb, index) => (
//           <React.Fragment key={index}>
//             <div 
//               className={`flex flex-row items-center justify-center py-1 px-2 gap-[10px] cursor-pointer ${breadcrumb.active ? 'text-primary-primary-500' : ''}`} 
//               onClick={() => handleClick(breadcrumb.path)}
//             >
//               <div className="relative font-semibold">{breadcrumb.label}</div>
//             </div>
//             {index < breadcrumbs.length - 1 && (
//               <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/arrow-right-chevron.svg" />
//             )}
//           </React.Fragment>
//         ))}
//       </div>
//     </div>
//   );
// };

// Breadcrumb.propTypes = {
//   breadcrumbs: PropTypes.arrayOf(
//     PropTypes.shape({
//       label: PropTypes.string.isRequired,
//       path: PropTypes.string.isRequired,
//       active: PropTypes.bool
//     })
//   ).isRequired,
// };

// export default Breadcrumb;

//Code 3
// import React from 'react';
// import PropTypes from 'prop-types';
// import { useNavigate } from 'react-router-dom';

// const Breadcrumb = ({ breadcrumbs }) => {
//   const navigate = useNavigate();

//   const handleClick = (path) => {
//     if (path) {
//       navigate(path);
//     }
//   };

//   return (
//     <div className="relative w-full flex flex-row items-center gap-[6px] text-left text-sm font-open-sans-ff-meta-strong">
//       <div className="absolute top-[24px] left-[90px] flex flex-row items-center gap-[6px]">
//         {breadcrumbs.map((breadcrumb, index) => (
//           <React.Fragment key={index}>
//             <div 
//               className={`flex flex-row items-center py-1 px-2 gap-[10px] cursor-pointer ${breadcrumb.active ? 'text-primary-primary-500' : 'text-neutrals-neutral-300'}`} 
//               onClick={() => handleClick(breadcrumb.path)}
//             >
//               {breadcrumb.icon && <img className="w-5 h-5" alt="" src={breadcrumb.icon} />}
//               <div className="relative font-semibold">{breadcrumb.label}</div>
//             </div>
//             {index < breadcrumbs.length - 1 && (
//               <img className="w-5 h-5" alt="arrow-right" src="assets/arrow-right-chevron.svg" />
//             )}
//           </React.Fragment>
//         ))}
//       </div>
//     </div>
//   );
// };

// Breadcrumb.propTypes = {
//   breadcrumbs: PropTypes.arrayOf(
//     PropTypes.shape({
//       label: PropTypes.string.isRequired,
//       path: PropTypes.string,
//       active: PropTypes.bool,
//       icon: PropTypes.string,
//     })
//   ).isRequired,
// };

// export default Breadcrumb;

//Code 4
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const Breadcrumb = ({ breadcrumbs, textColor, position }) => {
  const navigate = useNavigate();

  const handleClick = (path) => {
    if (path) {
      navigate(path);
    }
  };

  return (
    <div className={`relative w-full flex ${position} gap-[6px] text-left text-sm font-open-sans-ff-meta-strong`}>
      <div className="flex flex-row items-center gap-[6px]">
        {breadcrumbs.map((breadcrumb, index) => (
          <React.Fragment key={index}>
            <div 
              className={`flex flex-row items-center py-1 px-2 gap-[10px] cursor-pointer ${breadcrumb.active ? 'text-primary-primary-500' : textColor}`} 
              onClick={() => handleClick(breadcrumb.path)}
            >
              {breadcrumb.icon && <img className="w-5 h-5" alt="" src={breadcrumb.icon} />}
              <div className="relative font-semibold">{breadcrumb.label}</div>
            </div>
            {index < breadcrumbs.length - 1 && (
              <img className="w-5 h-5" alt="arrow-right" src="assets/arrow-right-chevron.svg" />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

Breadcrumb.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string,
      active: PropTypes.bool,
      icon: PropTypes.string,
    })
  ).isRequired,
  textColor: PropTypes.string,
  position: PropTypes.string,
};

Breadcrumb.defaultProps = {
  textColor: 'text-neutrals-neutral-300',
  position: 'items-center justify-start', // Default to left-aligned
};

export default Breadcrumb;