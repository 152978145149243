import { useCallback, useState } from "react";
import PropTypes from 'prop-types';
import CTAs from "../components/CTAs";

const InstructionDeletion = ({ onClose }) => {
    return (
          <div className="relative shadow-[0px_10px_60px_rgba(0,_0,_0,_0.1)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start p-6 gap-[40px] max-w-full max-h-full overflow-auto text-left text-lg text-neutrals-neutral-900 font-open-sans-ff-body-strong border-[1px] border-solid border-neutrals-neutral-100 ${className}">
                <div className="w-[386px] flex flex-row items-start justify-between">
                      <div className="flex-1 flex flex-col items-start justify-start gap-[10px]">
                            <div className="rounded-3xs bg-semantics-high-high-50 flex flex-row items-start justify-start p-2.5">
                                  <img className="w-5 relative h-5" alt="" src="assets/delete.svg" />
                            </div>
                            <div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
                                  <div className="self-stretch relative font-semibold">Delete instruction</div>
                                  <div className="self-stretch relative text-sm leading-[20px] font-inter text-neutrals-neutral-300">Are you sure you want to delete this instruction? This action cannot be undone.</div>
                                        </div>
                                        </div>
                                        <img className="w-5 relative h-5 overflow-hidden shrink-0 cursor-pointer" alt="" src="assets/cancel.svg" onClick={onClose}/>
                                        </div>
                                        <div className="flex flex-row items-start justify-start gap-[10px] text-sm">
                                          <CTAs
                                          type="Ghostbtn"
                                          text="Cancel"
                                          textColor="text-neutrals-neutral-900"
                                          bgColor="bg-neutrals-neutral-50"
                                          borderColor="border-neutrals-neutral-100"
                                          width="188px"
                                          position="relative"
                                          onClick={onClose}
                                          />

                                          <CTAs
                                          type="Primarybtn"
                                          text="Delete"
                                          textColor="text-neutrals-neutral-50"
                                          bgColor="bg-semantics-high-high-500"
                                          borderColor="border-semantics-high-high-500"
                                          width="188px"
                                          position="relative"
                                          onClick={onClose}
                                          />
                                        </div>
                                        </div>);
                                  };
                                  
                                  InstructionDeletion.propTypes = {
                                        className: PropTypes.string
                                  };
                                  
                                  export default InstructionDeletion;