import { useState, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import LogoutConfirmation from "../../components/LogoutConfirmation";
import PortalPopup from "../../components/PortalPopup";
import Breadcrumb from '../../components/Breadcrumb';
import NavigationPanelLawyer from '../../components/NavigationPanelLawyer';

const InvoiceGeneratorPage = () => {
    const navigate = useNavigate();
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	
  	const onCTAsContainerClick = useCallback(() => {
    		// Add your code here
  	}, []);
  	
  	
  	const onIconsV2Click = useCallback(() => {
    		// Add your code here
  	}, []);
  	
  	
  	const onBillingTextClick = useCallback(() => {
    		// Add your code here
  	}, []);
  	
  	
  	const onDebtCollectionNavContainer1Click = useCallback(() => {
    		// Add your code here
  	}, []);
  	
  	
  	const openLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(true);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);

	const navItems = [
		{ text: "Dashboard", bgColor: "bg-neutrals-neutral-50", textColor: "text-neutrals-neutral-300", hoverBgColor: "bg-neutrals-neutral-50", hoverTextColor: "text-primary-primary-500", path: "" },
		{ text: "Legal proceedings & billing", bgColor: "bg-primary-primary-50", textColor: "text-neutrals-neutral-900", hoverBgColor: "bg-primary-primary-50", hoverTextColor: "text-neutrals-neutral-900", path: "" },
		{ text: "Pre-LBA checks", bgColor: "bg-neutrals-neutral-50", textColor: "text-neutrals-neutral-300", hoverBgColor: "bg-neutrals-neutral-50", hoverTextColor: "text-primary-primary-500", path: "/prelbacheck" },
		{ text: "Client-debtor interaction", bgColor: "bg-neutrals-neutral-50", textColor: "text-neutrals-neutral-300", hoverBgColor: "bg-neutrals-neutral-50", hoverTextColor: "text-primary-primary-500", path: "/cdi1" },
	];

	const breadcrumb = [
		{ label: "", path: "", icon: "assets/home.svg", active: true },
		{ label: "Legal proceedings & billing", path: "/legal_2", icon: "", active: false },
		{ label: "Invoice generator", path: "", icon: "", active: true },
	];
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1357px] overflow-hidden text-left text-sm text-neutrals-neutral-900 font-open-sans-ff-body-strong">
      			<div className="absolute top-[1274px] right-[96px] text-neutrals-neutral-300">Copyright © Think-AI 2023</div>
      			<div className="absolute top-[-299px] left-[calc(50%_-_720px)] shadow-[0px_4px_10px_rgba(0,_0,_0,_0.05)] bg-neutrals-neutral-50 w-[1440px] flex flex-row flex-wrap items-end justify-start py-6 px-24 box-border gap-[24px_872px] text-9xl">
        				<div className="w-[1248px] flex flex-col items-start justify-start gap-[10px]">
          					<b className="relative">LBA Process</b>
          					<div className="w-[1248px] flex flex-row items-center justify-start gap-[10px] text-sm">
            						<div className="w-[1204px] relative h-[8.3px]">
              							<div className="absolute top-[0px] left-[0px] rounded-81xl bg-neutrals-neutral-100 w-[1204px] h-2" />
              							<div className="absolute top-[0.27px] left-[0px] shadow-[0px_10px_12px_rgba(0,_0,_0,_0.1)_inset] rounded-81xl bg-neutrals-neutral-900 w-[214px] h-2" />
            						</div>
            						<div className="relative font-semibold">28%</div>
          					</div>
        				</div>
        				<div className="w-[1248px] flex flex-row items-start justify-between text-sm">
          					<div className="w-[188px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-300">
            						<div className="flex flex-row items-center justify-center gap-[6px]">
              							<img className="w-5 relative h-5" alt="" src="icons-v2.svg" />
              							<div className="relative font-semibold">Back</div>
            						</div>
          					</div>
          					<div className="w-[188px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-900 flex flex-row items-center justify-center py-2.5 px-3.5 box-border text-bg-primary">
            						<div className="flex flex-row items-center justify-center gap-[6px]">
              							<img className="w-5 relative h-5" alt="" src="icons-v2.svg" />
              							<div className="relative font-semibold">Continue</div>
            						</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[179px] left-[96px] bg-neutrals-neutral-50 box-border w-[1248px] flex flex-row items-start justify-between pt-0 px-0 pb-6 text-9xl border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-row items-start justify-start">
          					<div className="flex flex-col items-start justify-start gap-[6px]">
            						<b className="relative">Invoice generator</b>
            						<div className="relative text-base text-neutrals-neutral-300">Get your billing information, generate/review invoices.</div>
          					</div>
        				</div>
        				<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 cursor-pointer text-sm border-[1px] border-solid border-neutrals-neutral-100" onClick={onCTAsContainerClick}>
          					<div className="flex flex-row items-center justify-center gap-[6px]">
            						<img className="w-5 relative h-5" alt="" src="assets/scan.svg" />
            						<div className="relative font-semibold">Generate</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[293px] left-[96px] w-[1248px] flex flex-row items-start justify-between">
        				<div className="w-[400px] flex flex-col items-start justify-start gap-[10px]">
          					<div className="relative font-semibold">Logo</div>
          					<div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-start p-4 gap-[6px] border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-start justify-start p-2.5 border-[1px] border-solid border-neutrals-neutral-100">
              							<img className="w-5 relative h-5" alt="" src="assets/upload_image.svg" />
            						</div>
            						<div className="flex flex-col items-start justify-start gap-[6px]">
              							<div className="relative font-semibold">Upload image</div>
              							<div className="relative text-base text-neutrals-neutral-300">JPG, JPEG, PNG, less than 5MB</div>
            						</div>
          					</div>
        				</div>
        				<div className="flex flex-col items-start justify-start gap-[10px]">
          					<div className="flex flex-row items-center justify-start gap-[6px]">
            						<div className="relative font-semibold">Invoice number</div>
            						<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg" />
          					</div>
          					<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-primary-primary-50 box-border overflow-hidden flex flex-row items-center justify-start p-3 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="relative">#0123456</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[426px] left-[96px] w-[1248px] flex flex-row items-start justify-between">
        				<div className="w-[400px] flex flex-col items-start justify-start gap-[10px]">
          					<div className="relative font-semibold">Your company details</div>
          					<div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-[157px] flex flex-col items-start justify-start p-4 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="relative">Enter details over here</div>
          					</div>
        				</div>
        				<div className="w-[400px] flex flex-col items-start justify-start gap-[10px]">
          					<div className="relative font-semibold">Bill to</div>
          					<div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border h-[157px] flex flex-col items-start justify-start p-4 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="relative">Enter details over here</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[640px] left-[96px] w-[1248px] flex flex-row items-start justify-between">
        				<div className="w-[400px] flex flex-col items-start justify-start gap-[10px]">
          					<div className="relative font-semibold">Date issued</div>
          					<div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-col items-start justify-start p-3 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="relative">Select date</div>
          					</div>
        				</div>
        				<div className="flex flex-col items-start justify-start gap-[10px]">
          					<div className="flex flex-row items-center justify-start gap-[6px]">
            						<div className="relative font-semibold">Billing period</div>
            						<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg" />
          					</div>
          					<div className="w-[400px] rounded-3xs bg-primary-primary-50 box-border overflow-hidden flex flex-row items-center justify-start p-3 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="relative">06/01/2024 - 12/01/2024</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[741px] left-[96px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border w-[1248px] flex flex-row items-start justify-between p-6 border-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-col items-start justify-start gap-[10px]">
          					<div className="self-stretch flex flex-row items-start justify-start py-2.5 px-3.5">
            						<div className="relative font-semibold">Legal services</div>
          					</div>
          					<div className="flex flex-col items-start justify-start gap-[10px] text-base">
            						<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border overflow-hidden flex flex-row items-center justify-start p-3 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative">Negotiation</div>
            						</div>
            						<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border overflow-hidden flex flex-row items-center justify-start p-3 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative">Legal consultation</div>
            						</div>
            						<div className="w-[400px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border overflow-hidden flex flex-row items-center justify-start p-3 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative">Compliance with debt collection laws</div>
            						</div>
          					</div>
        				</div>
        				<div className="flex flex-col items-start justify-start gap-[10px]">
          					<div className="self-stretch flex flex-row items-start justify-start py-2.5 px-3.5">
            						<div className="relative font-semibold">Rate</div>
          					</div>
          					<div className="flex flex-col items-start justify-start gap-[10px] text-base">
            						<div className="w-[82px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border overflow-hidden flex flex-row items-center justify-start p-3 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative">£2,000</div>
            						</div>
            						<div className="w-[82px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border overflow-hidden flex flex-row items-center justify-start p-3 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative">£1,000</div>
            						</div>
            						<div className="w-[82px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border overflow-hidden flex flex-row items-center justify-start p-3 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative">£800</div>
            						</div>
          					</div>
        				</div>
        				<div className="flex flex-col items-start justify-start gap-[10px]">
          					<div className="flex flex-row items-start justify-start py-2.5 px-3.5">
            						<div className="relative font-semibold">Hours</div>
          					</div>
          					<div className="self-stretch flex flex-col items-start justify-start gap-[10px] text-center text-base">
            						<div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 overflow-hidden flex flex-row items-center justify-center p-3 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative">1</div>
            						</div>
            						<div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 overflow-hidden flex flex-row items-center justify-center p-3 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative">1</div>
            						</div>
            						<div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 overflow-hidden flex flex-row items-center justify-center p-3 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative">2</div>
            						</div>
          					</div>
        				</div>
        				<div className="flex flex-col items-start justify-start gap-[10px]">
          					<div className="flex flex-row items-start justify-start py-2.5 px-3.5">
            						<div className="relative font-semibold">Amount</div>
          					</div>
          					<div className="self-stretch flex flex-col items-start justify-start gap-[10px] text-base">
            						<div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 overflow-hidden flex flex-row items-center justify-start p-3 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative">£2,000</div>
            						</div>
            						<div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 overflow-hidden flex flex-row items-center justify-start p-3 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative">£1,000</div>
            						</div>
            						<div className="self-stretch shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 overflow-hidden flex flex-row items-center justify-start p-3 border-[1px] border-solid border-neutrals-neutral-100">
              							<div className="relative">£1,600</div>
            						</div>
          					</div>
        				</div>
        				<div className="flex flex-col items-start justify-start gap-[23px]">
          					<div className="w-7 h-[39px]" />
          					<div className="flex flex-col items-start justify-start gap-[36px]">
            						<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/cancel.svg" />
            						<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/cancel.svg" />
            						<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/cancel.svg" />
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[1021px] left-[1050px] flex flex-col items-start justify-start gap-[24px] text-right text-base">
        				<div className="w-[294px] flex flex-row items-start justify-between text-left">
          					<div className="relative font-semibold">Subtotal</div>
          					<div className="relative font-semibold text-right">£4,600</div>
        				</div>
        				<div className="w-[294px] flex flex-row items-center justify-between">
          					<div className="relative font-semibold">Tax</div>
          					<div className="relative font-semibold text-left">Free</div>
        				</div>
        				<div className="w-[294px] flex flex-row items-center justify-between">
          					<div className="relative font-semibold">Total</div>
          					<div className="relative font-semibold text-left">£4,600</div>
        				</div>
      			</div>

      			<div className="absolute top-[1021px] left-[96px] h-[189px] flex flex-col items-start justify-start gap-[10px]">
        				<div className="flex flex-row items-center justify-start gap-[6px]">
          					<div className="relative font-semibold">Notes/payment terms</div>
          					<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/help-circle.svg" />
        				</div>
        				<div className="w-[400px] flex-1 shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-col items-start justify-start p-4 text-base text-neutrals-neutral-300 border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="relative">Enter details over here</div>
        				</div>
      			</div>

				{/* Navigation Panel */}
				<NavigationPanelLawyer navItems={navItems}/>

				{/* Breadcrumb Navigation */}
				<Breadcrumb breadcrumbs={breadcrumb} position="top-[24px] left-[90px]" />

    		</div>
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}</>);
};

export default InvoiceGeneratorPage;