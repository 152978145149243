import { useState, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import LogoutConfirmation from "../../components/LogoutConfirmation";
import PortalPopup from "../../components/PortalPopup";
import NavigationPanel from '../../components/NavigationPanel';
import Breadcrumb from '../../components/Breadcrumb';
import CTAs from '../../components/CTAs';

const SettingsAccountPage = () => {
    const navigate = useNavigate();
  	const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  	
  	const onClickGeneral = useCallback(() => {
        navigate('/setting-general');
  	}, [navigate]);
  	
  	
  	const onClickPreferences = useCallback(() => {
        navigate('/setting-preferences');
  	}, [navigate]);
  	
  	
  	const onClickLBAConfig = useCallback(() => {
        navigate('/setting-lbaconfig');
  	}, [navigate]);
  	
  	
  	const onClickLegalProceedings = useCallback(() => {
        navigate('/setting-legalproceedings');
  	}, [navigate]);
  	
  	
  	const onClickAIIntegration = useCallback(() => {
        navigate('/setting-aiintegration');
  	}, [navigate]);
  	
  	
  	const onClickSecurity = useCallback(() => {
        navigate('/setting-security');
  	}, [navigate]);
  	
  	
  	const onClickBilling = useCallback(() => {
        navigate('/setting-billing');
  	}, [navigate]);
  	
  	
  	const onClickHelpSupport = useCallback(() => {
        navigate('/setting-help&support');
  	}, [navigate]);
  	
  	
  	const onClickDataManagement = useCallback(() => {
        navigate('/setting-datamanagement');
  	}, [navigate]);
  	
  	const openLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(true);
  	}, []);
  	
  	const closeLogoutConfirmation = useCallback(() => {
    		setLogoutConfirmationOpen(false);
  	}, []);
  	
  	
  	const onClickOfficeAccPayments = useCallback(() => {
		navigate('/setting-account2');
  	}, [navigate]);

	const breadcrumb = [
		{ label: "", path: "/postlba2", icon: "assets/Home.svg", active: true },
		{ label: "Settings", path: "", icon: "", active: false },
		{ label: "Account", path: "", icon: "", active: true },
	];
  	
  	return (<>
    		<div className="w-full relative bg-neutrals-neutral-50 h-[1263px] overflow-hidden text-left text-base text-neutrals-neutral-300 font-open-sans-ff-meta">
      			<div className="absolute top-[293px] left-[96px] w-[188px] flex flex-col items-start justify-start gap-[6px]">
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickGeneral}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">General</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickPreferences}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Preferences</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickLBAConfig}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">LBA configuration</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickLegalProceedings}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Legal proceedings</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickAIIntegration}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">AI integration</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickSecurity}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Security</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickBilling}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Billing</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickHelpSupport}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">{`Help & support`}</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center py-2 px-3 box-border cursor-pointer" onClick={onClickDataManagement}>
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Data management</div>
          					</div>
        				</div>
        				<div className="bg-neutrals-neutral-50 box-border h-[41px] flex flex-row items-center justify-center py-2 px-3 text-primary-primary-500 border-l-[2px] border-solid border-primary-primary-500">
          					<div className="flex flex-row items-center justify-center">
            						<div className="relative font-semibold">Account</div>
          					</div>
        				</div>
      			</div>
      			<div className="absolute top-[1180px] right-[96px] text-sm">Copyright © Think-AI 2023</div>
      			<div className="absolute top-[179px] left-[96px] bg-neutrals-neutral-50 box-border w-[1248px] flex flex-col items-start justify-start pt-0 px-0 pb-6 gap-[6px] text-9xl text-primary-primary-900 border-b-[1px] border-solid border-neutrals-neutral-100">
        				<b className="relative">Settings</b>
        				<div className="relative text-base text-neutrals-neutral-300">Get your billing information, generate/review invoices.</div>
      			</div>
      			<div className="absolute top-[293px] left-[390px] box-border w-[954px] flex flex-row items-start justify-start pt-0 px-0 pb-6 text-lg text-neutrals-neutral-900 border-b-[1px] border-solid border-neutrals-neutral-100">
        				<div className="flex flex-col items-start justify-start gap-[6px]">
          					<div className="relative font-semibold">Account</div>
          					<div className="relative text-sm text-neutrals-neutral-300">Allow users to configure how and when communication is initiated with debtors.</div>
        				</div>
      			</div>

                {/* Navigation Panel */}
				<NavigationPanel/>
      			
				{/* Breadcrumb Navigation */}
				<Breadcrumb breadcrumbs={breadcrumb} position="top-[24px] left-[90px]"/>

      			<div className="absolute top-[391px] left-[390px] bg-neutrals-neutral-50 box-border h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 text-primary-primary-500 border-b-[2px] border-solid border-primary-primary-500">
        				<div className="flex flex-row items-center justify-center">
          					<div className="relative font-semibold">Client account checks</div>
        				</div>
      			</div>
      			<div className="absolute top-[391px] left-[589px] bg-neutrals-neutral-50 h-[41px] flex flex-row items-center justify-center pt-0 px-1 pb-3 box-border cursor-pointer" onClick={onClickOfficeAccPayments}>
        				<div className="flex flex-row items-center justify-center">
          					<div className="relative font-semibold">Office account payments</div>
        				</div>
      			</div>
      			<div className="absolute top-[456px] left-[390px] font-semibold text-neutrals-neutral-900">Account overview</div>
      			<div className="absolute top-[502px] left-[390px] w-[954px] flex flex-row items-start justify-between text-sm">
        				<div className="w-[294px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 box-border flex flex-row items-center justify-start py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
          					<div className="flex flex-row items-center justify-start gap-[10px]">
            						<img className="w-5 relative h-5" alt="" src="assets/search.svg" />
            						<div className="relative">Search your clients</div>
          					</div>
        				</div>
						<CTAs
						type="Ghostbtn"
						text="Filter"
						textColor="text-neutrals-neutral-900"
						bgColor="bg-neutrals-neutral-50"
						borderColor="border-neutrals-neutral-100"
						icon="assets/filter.svg"
						position="relative"
						/>
      			</div>
      			<div className="absolute top-[566px] left-[390px] w-[954px] h-[550px] flex flex-col items-start justify-between text-sm text-neutrals-neutral-900">
        				<div className="self-stretch flex flex-row items-start justify-start">
          					<div className="w-[236px] flex flex-col items-start justify-start">
            						<div className="self-stretch rounded-tl-3xs rounded-tr-none rounded-b-none bg-column-bg flex flex-row items-start justify-start py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
              							<div className="relative font-semibold">Name</div>
            						</div>
            						<div className="self-stretch flex flex-col items-start justify-start">
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                								<div className="relative">John Carter</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                								<div className="relative">Nathan James</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                								<div className="relative">Baanji</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                								<div className="relative">Elisa Gashi</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                								<div className="relative">Paras Mistry</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                								<div className="relative">Megan Gibson</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                								<div className="relative">Diane Elgar</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100 border-l-[1px]">
                								<div className="relative">Julie Wyse</div>
              							</div>
            						</div>
          					</div>
          					<div className="w-[188px] flex flex-col items-start justify-start">
            						<div className="self-stretch bg-column-bg box-border h-[43px] flex flex-row items-start justify-center py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
              							<div className="relative font-semibold">Claim status</div>
            						</div>
            						<div className="self-stretch flex flex-col items-center justify-start text-semantics-low-low-500">
              							<div className="self-stretch box-border h-[55px] flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                  									<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                  									<div className="relative font-semibold">Open</div>
                								</div>
              							</div>
              							<div className="self-stretch box-border h-[55px] flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                  									<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                  									<div className="relative font-semibold">Open</div>
                								</div>
              							</div>
              							<div className="self-stretch box-border h-[55px] flex flex-col items-center justify-start p-4 text-semantics-moderate-moderate-500 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="rounded-xl bg-semantics-moderate-moderate-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-moderate-moderate-500">
                  									<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
                  									<div className="relative font-semibold">Pending</div>
                								</div>
              							</div>
              							<div className="self-stretch box-border h-[55px] flex flex-col items-center justify-start p-4 text-semantics-high-high-500 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="rounded-xl bg-semantics-high-high-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-high-high-500">
                  									<div className="w-1.5 relative rounded-[50%] bg-semantics-high-high-500 h-1.5" />
                  									<div className="relative font-semibold">Overdue</div>
                								</div>
              							</div>
              							<div className="self-stretch box-border h-[55px] flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                  									<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                  									<div className="relative font-semibold">Open</div>
                								</div>
              							</div>
              							<div className="self-stretch box-border h-[55px] flex flex-col items-center justify-start p-4 text-semantics-moderate-moderate-500 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="rounded-xl bg-semantics-moderate-moderate-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-moderate-moderate-500">
                  									<div className="w-1.5 relative rounded-[50%] bg-semantics-moderate-moderate-500 h-1.5" />
                  									<div className="relative font-semibold">Pending</div>
                								</div>
              							</div>
              							<div className="self-stretch box-border h-[55px] flex flex-col items-center justify-start p-4 text-semantics-high-high-500 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="rounded-xl bg-semantics-high-high-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-high-high-500">
                  									<div className="w-1.5 relative rounded-[50%] bg-semantics-high-high-500 h-1.5" />
                  									<div className="relative font-semibold">Overdue</div>
                								</div>
              							</div>
              							<div className="self-stretch box-border h-[55px] flex flex-col items-center justify-start p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="rounded-xl bg-semantics-low-low-50 flex flex-row items-center justify-start py-0.5 px-2 gap-[6px] border-[1px] border-solid border-semantics-low-low-500">
                  									<div className="w-1.5 relative rounded-[50%] bg-semantics-low-low-500 h-1.5" />
                  									<div className="relative font-semibold">Open</div>
                								</div>
              							</div>
            						</div>
          					</div>
          					<div className="w-[201px] flex flex-col items-start justify-start">
            						<div className="self-stretch bg-column-bg flex flex-row items-start justify-center py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
              							<div className="relative font-semibold">Reference</div>
            						</div>
            						<div className="self-stretch flex flex-col items-start justify-start">
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="relative">#764382</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="relative">#123456</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="relative">#436286</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="relative">#183604</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="relative">#628234</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="relative">#749213</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="relative">#748732</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="relative">#702634</div>
              							</div>
            						</div>
          					</div>
          					<div className="w-[157px] flex flex-col items-center justify-start text-right">
            						<div className="self-stretch bg-column-bg box-border h-[43px] flex flex-row items-start justify-center py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
              							<div className="relative font-semibold">Balance</div>
            						</div>
            						<div className="self-stretch flex flex-col items-center justify-start">
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="relative">£10,000</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="relative">£5,000</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="relative">£3,500</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="relative">£6,000</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="relative">£25,000</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="relative">£15,000</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="relative">£4,000</div>
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-b-[1px] border-solid border-neutrals-neutral-100">
                								<div className="relative">£8,300</div>
              							</div>
            						</div>
          					</div>
          					<div className="w-[172px] flex flex-col items-center justify-start">
            						<div className="self-stretch rounded-tl-none rounded-tr-3xs rounded-b-none bg-column-bg flex flex-row items-start justify-center py-3 px-4 border-t-[1px] border-solid border-neutrals-neutral-100 border-r-[1px] border-b-[1px]">
              							<div className="relative font-semibold">Actions</div>
            						</div>
            						<div className="self-stretch flex flex-col items-start justify-start">
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                								<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                								<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                								<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                								<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                								<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                								<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                								<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
              							</div>
              							<div className="self-stretch bg-neutrals-neutral-50 box-border h-[55px] flex flex-row items-start justify-center p-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px]">
                								<img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="assets/more-vert.svg" />
              							</div>
            						</div>
          					</div>
        				</div>
        				<div className="self-stretch rounded-t-none rounded-b-3xs flex flex-row items-center justify-between pt-3 px-6 pb-4 border-r-[1px] border-solid border-neutrals-neutral-100 border-b-[1px] border-l-[1px]">
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="flex flex-row items-center justify-center">
              							<div className="relative font-semibold">Previous</div>
            						</div>
          					</div>
          					<div className="relative">Page 1 of 10</div>
          					<div className="shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-neutrals-neutral-50 flex flex-row items-center justify-center py-2.5 px-3.5 border-[1px] border-solid border-neutrals-neutral-100">
            						<div className="flex flex-row items-center justify-center">
              							<div className="relative font-semibold">Next</div>
            						</div>
          					</div>
        				</div>
      			</div>
    		</div>
    		{isLogoutConfirmationOpen && (
      			<PortalPopup
        				overlayColor="rgba(113, 113, 113, 0.3)"
        				placement="Centered"
        				
        				
        				
        				
        				
        				onOutsideClick={closeLogoutConfirmation}
        				>
        				<LogoutConfirmation onClose={closeLogoutConfirmation} />
      			</PortalPopup>
    		)}</>);
};

export default SettingsAccountPage;